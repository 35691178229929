import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Alerts from "../../Alerts";
import Config from "../../Config";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import axios from "axios";
import { useAuthHandling } from "../../Common/useAuthHandling";

function Bedtypeadd() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const[bedtype,setBedtype]=useState("")
  const[tab,setTab]=useState("EN")
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState({});

  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("");
  const [size,setSize]=useState();
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [image,setImage]=useState([]);

  
  const getValue =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers:
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200" ) {
      setLanguage(data.data);
    }
    else if(data.reauth===true){
      handleUnauthorized();
      getValue();
    }
    else {
      console.log("error");

    }
  },[handleUnauthorized]);

  const validate = (values) => {
    const noSpecialCharRegex = /^[A-Za-z\s,/,-,_]+$/;

    const errors = {};
    if (!values) {
      errors.bedtype = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.bedtype = "Name should not contain this special character";
    }
    else if (values.length > 50) {
      errors.bedtype = `Name cannot be more than 50 characters!`;
    }
    return errors;
  };
  const validate2 = (values) => {
    const errors = {};
    if (!values) {
      errors.image = "Image is required!";
    }
    else{
      if(
        values.type === "image/jpeg" ||
        values.type === "image/jpg" ||
        values.type === "image/png" ||
        values.type === "image/webp" 
      ){
      console.log("");
    } else {  
      errors.image = "Only support jpg, jpeg, png, webp image!";
    }
  }
  return errors;
  }
    const validate1 = (values) => {
      const errors = {};
      if (!values) {
        errors.status = "Status is required!";
      }
      return errors;
    };

    const validate3 = (values) => {
      const errors = {};
      if (!values) {
        errors.size = "Bed size is required!";
      }
      return errors;
    };
  

  const PostData = async (e) => {
    e.preventDefault();
      const ress = validate(bedtype);
      const ress1=validate1(status);
      const ress2=validate2(image);
      const ress3=validate3(size)

     setError(ress);
     setError1(ress1);
     setError2(ress2);
     setError3(ress3);

    let formdata=new FormData();
    formdata.append("en_title",bedtype)
    formdata.append("icon_image",image)
    formdata.append("bedsize",size)
    formdata.append("is_active",status)
   
    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].name) {
        if (selectedMulti[i].code !== "en") {
          formdata.append(`multi_lang[${i}][lang_code]`, selectedMulti[i].code);
          formdata.append(`multi_lang[${i}][title]`, selectedMulti[i].name);
        }
      }
    }

  if (!ress.bedtype && !ress1.status && !ress2.image && !ress3.size) {
    
      axios
      .post(`${Config.apiurl}bedtype/add`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((res) => {
          if (res.status_code === "200" ) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            setTimeout(() => navigate("/basic_settings/bedtype-list"), 3000);

            
          }
          else if(res.reauth===true){
            handleUnauthorized();
            PostData();
          }
          else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");

          }
        }) }
  };
  function Reset() {
    setLanguage("");
    setBedtype("");
    setImage("");
    setStatus("");
    setselectedMulti("");
  }
  const onFileChange = (e) => {
    console.log(e);
    setImage( e.target.files[0] );
  };

  useEffect(() => {
    getValue();
  }, [getValue]);

  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Bedtype</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/bedtype-list">Bedtype</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? 
                <Alerts type={alerttype} message={alertmessage} open={open} />
              : 
                ""}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Bedtype
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/basic_settings/bedtype-list"
                          style={{ marginRight: "5px" }}
                        >
                          Bedtypes
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to="" onClick={Reset}
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li className={tab==="EN" ?"active" :""}onClick={(e)=>setTab("EN")}>EN</li>
                                  <li  className={tab==="TRANS" ?"active" :""} onClick={(e)=>setTab("TRANS")}>
                                  
                                      Translations
                                  
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {tab==="EN"?
                            <>
                                  <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 value={bedtype}
                                  onChange={(e) => setBedtype(e.target.value)}
                                />
                                
                            
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror.bedtype}
                                  </label>
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Icon Image
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  onChange={onFileChange}
                                  accept=".jpg, .jpeg, .webp, .png"
                                  />
                                
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror2.image}
                                </label>
                            </div>
                            
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                 Bed size
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Bed size in Square meter"
                                 value={size}
                                  onChange={(e) => setSize(e.target.value)}
                                />
                                
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror3.size}
                                  </label>
                            </div>
                            <div className="row g-3">
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value={1}>Active</option>
                                  <option value={0}>Inactive</option>
                                </select>
                               
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.status}
                                  </label>
                            </div>
                            </div>
                            </>:<>
                            <div class="col-lg-4" style={{marginTop:"20px"}}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                  <div className="col-md-7">
                                <MultiSelect
                                  options={language}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  // closeOnChangedValue={hasSelectAll === true ? true : false}
                                
                                  aria-expanded="true"/>
                              </div>
                            </div>
                            </div>
                            <div className="appendmore"></div>
                            {selectedMulti.length > 0
                              ? selectedMulti.map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      class="col-md-12 translation-fill-section"
                                      id="lang_ +val"
                                      data-value="1" style={{marginTop:"20px"}}
                                    >
                                      <div class="translation-selected-lang">
                                        <p>
                                          <span class="translation-selected-lang-head">
                                            {element.code}
                                          </span>
                                        </p>
                                      </div>

                                      <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                            Name
                                            <span class="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            value={
                                              selectedMulti[index].name
                                            }
                                            onChange={
                                              (e) =>
                                                (selectedMulti[index].name =
                                                  e.target.value)}/>
                                         
                                        
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                            </>}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
export default Bedtypeadd;
