import React, { useCallback, useContext, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Alerts from "../../Alerts";
import axios from "axios";
import Commonheader from "../../Common/Commonheader";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Commonimagepath from "../../Commonimagepath";
import Toastmodal from "../../Common/Toastmodal";
import Loading from "../../Loading";

function Hoteluseredit() {

  let { hotelname, hotelid,userid } = useParams();
  let navigate = useNavigate();

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [active, setActive] = useState("en");
  const [role, setRole] = useState([]);
  const [be_language, setBe_language] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);

  const language = localStorage.getItem("be_language");
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState({});
  const [defaultval, setDefaultval] = useState([]);
  const [status, setStatus] = useState("");
  const [adminuser, setAdminuser] = useState([]);
  const [country, setCountry] = useState([]);
  const [langdata, setLangdata] = useState("");
  const [countrydata, setCountrydata] = useState("");
  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);

  const getRole = useCallback(async (e) => {
    if (language === null) {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    } else {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        body: JSON.stringify({
          language: language,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    }
  },[language]);

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_language(data.data);
    }
  };

  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_languagefull(data.data);
    }
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setCountry(data.data);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAdminuser({ ...adminuser, [name]: value });
  };
 
  const handleInputlang = (langdata) => {
    setLangdata(langdata);
  };
  const handleInputcountry = (countrydata) => {
    setCountrydata(countrydata);
  };
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [selectedMulti1, setselectedMulti1] = useState([]);
  function handleMulti1(selectedMulti1) {
    setselectedMulti1(selectedMulti1);
  }

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  function Reset() {
    setAdminuser({
      user_name: "",
      surname: "",
      email: "",
      image: "",
    });
    setDefaultval("");
    setLangdata("");
    setCountrydata("");
    setselectedMulti([]);
    setselectedMulti1([]);
    setStatus("");
  }

  const getinfo =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extranet-user/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
        user_id:userid
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setAdminuser(data.data.user);
      setDefaultval(data.data.role);
      setLangdata(data.data.language);
      setselectedMulti(data.data.optonal_languages);
      setCountrydata(data.data.country);
      setStatus(data.data.user.is_active);
      setLoader(true);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,hotelid,userid]);

  useEffect(() => {
    getlanguage();
    getcountry();
    getfulllanguage();
  }, []);

  useEffect(() => {
    getRole();
  }, [getRole]);

  useEffect(() => {
    getinfo();
  }, [getinfo]);

  const PostData = async (e) => {
    e.preventDefault();
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const textOnlyRegex = /^[A-Za-z\s]+$/;
    const errors = {};

    if (!adminuser.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    else if (!textOnlyRegex.test(adminuser.name)) {
      isFormValid = false;
      errors.name = "Name should only contain letters and spaces!";
    }
    else if (adminuser.name.length > 50) {
      isFormValid = false;
      errors.name = `Name cannot be more than 50 characters!`;
    }
    if (!adminuser.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    else if (!textOnlyRegex.test(adminuser.surname)) {
      isFormValid = false;
      errors.surname = "Surname should only contain letters and spaces!";
    }
    else if (adminuser.surname.length > 50) {
      isFormValid = false;
      errors.surname = `Surname cannot be more than 50 characters!`;
    }
    if (!adminuser.email) {
      isFormValid = false;
      errors.email = "Email is required!";
    } else if (!regex.test(adminuser.email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if (defaultval.length===0) {
      isFormValid = false;
      errors.defaultval = "Role is required!";
    }
    if (langdata.length===0) {
      isFormValid = false;
      errors.langdata = "Default language is required!";
    }
    if (selectedMulti.length===0) {
      isFormValid = false;
      errors.selectedMulti = "Optional language is required!";
    }
    if (countrydata.length===0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (status.length===0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid===true) {
      const formData = new FormData();
      formData.append("hotel_id", hotelid);
      formData.append("en_name", adminuser.name);
      formData.append("en_surname", adminuser.surname);
      formData.append("email", adminuser.email);
      if (image) {
        formData.append("profile_image", image);
      }
      formData.append("status", status);
      formData.append("role", defaultval.value);
      formData.append("default_language", langdata.value);
      formData.append("country", countrydata.value);

      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(`optional_language[${i}]`, selectedMulti[i].value);
      }
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].code !== "en") {
            formData.append(`multi_lang[${i}][lang_code]`, selectedMulti1[i].code);
            formData.append(`multi_lang[${i}][fields][name]`, selectedMulti1[i].name);
            formData.append(
              `multi_lang[${i}][fields][surname]`,
              selectedMulti1[i].surname
            );
          }
        }
      }
      axios
        .post(`${Config.apiurl}hotel/extranet-user/update/${userid}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(() => navigate("/hotel-user-list"), 3000);
          }
        });
    }
  };

  useEffect(() => {
    if (selectedMulti.length > 0) {
      const filteredBoards = be_languagefull.filter((lang) =>
        selectedMulti.some(
          (data) => data.value === lang.value
        )
      );
      setselectedMulti(filteredBoards);
    }
  }, [selectedMulti, be_languagefull]);

  useEffect(() => {

    const defaultOption = role.find((option) => option.label === "Hotel User");
    setDefaultval(defaultOption);
    
  }, [language, role]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Hotel USER</h4>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Edit Hotel User
                      </h4>
                      <div className="flex-shrink-0">
                       
                          <Link
                            type="button"
                            className="btn db-other-button"
                            to="/hotel-user-list"
                            style={{ marginRight: "5px" }}
                          >
                            Extranet Users
                          </Link>
                        
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                              {loader===false?
                              <Loading/>
                              :
                              <>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.name}
                                      name="name"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.name}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Last Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.surname}
                                      name="surname"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.surname}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Email
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.email}
                                      name="email"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.email}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Profile Image
                                     
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      placeholder=""
                                      id="lastNameinput"
                                      name="image"
                                      onChange={onFileChange}
                                      accept=".jpg, .jpeg, .webp, .png"
                                    />
                                    {adminuser.profile_image? (
                                      <img
                                        className="user-img-edit mt-2"
                                        alt=""
                                        src={`${Commonimagepath.apiurl}${adminuser.profile_image}`}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Role
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>

                                    <Select
                                      className="form-select1"
                                      data-trigger
                                      options={role}
                                      value={defaultval}
                                      defaultValue={defaultval}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Default Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      id="ForminputState"
                                      className="form-select1"
                                      options={be_languagefull}
                                      value={langdata}
                                      onChange={handleInputlang}
                                    ></Select>
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.langdata}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Optional Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={be_languagefull}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                      aria-expanded="true"
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedMulti}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Hotel{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={hotelname}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Country{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-control"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={country}
                                      value={countrydata}
                                      onChange={handleInputcountry}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.countrydata}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected value="">Choose...</option>
                                      <option
                                        {...(status === true
                                          ? "selected"
                                          : "")}
                                        value={true}
                                      >
                                        Active
                                      </option>
                                      <option
                                        {...(status === false
                                          ? "selected"
                                          : "")}
                                        value={false}
                                      >
                                        Inactive
                                      </option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror.status}
                                    </label>
                                  </div>
                                </div>
                              </>
                              }
                              </>
                            ) : (
                              <>
                                <div className="form-translation-choose-lang">
                                  <span
                                    for="firstNameinput"
                                    className="form-label db-lang-choose"
                                  >
                                    Choose Language:{" "}
                                  </span>
                                  <div className="lang-choose-select-option">
                                    <MultiSelect
                                      options={be_language}
                                      value={selectedMulti1}
                                      onChange={setselectedMulti1}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {selectedMulti1.length > 0
                                  ? selectedMulti1.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          class="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1"
                                        >
                                          <div class="translation-selected-lang">
                                            <p>
                                              <span class="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="row">
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Name
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index].name
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].name = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Last Name
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index]
                                                      .surname
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].surname = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

        <Footer />
      </div>
    </div>
  );
}

export default Hoteluseredit;
