import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";

function Extraeditstep1({
  setTab,
  handleAddonChange,
  handleExtraToggle,
  extraname,
  extra,
  subaddonlist,
  extra1Error,
  nextExtrastep1,
  nextExtrastep1sub,
  nextExtrastep1inc,
  handleExtraRadio,
  selectedDatesstay,
  setSelectedDatesstay,
  fpstay,
  handleOpenclosestay,
  activestaydateopen,
  setActivestaydateopen,
  openclosestay,
  setOpenclosestay,
  datefromstay,
  datetostay,
  selectedWeekdaysstay,
  clickedDaysstay,
  handleApplyToCalendarstay,
  toggleSelectedDaystay,
  handleCalendarChangeFrom,
  handleCalendarChangeTo,
  numberOfguestsub,
  handleAddonChangesub,
  language,
  handlechangeExtra1,
  loyalty_club,

  handleExtra4Check,
  Loyaltyclublist,
  handleloyalchangeExtra4,
  handlechangeSelectStep4,
  handlechangeStep4,
  hangleotherloyallang,
  handlelanglo,
  addInputField,
  removeInputFields,
  handleLanguageChangesub,
  multi,
  handleExtraSubaddonToggle,
  sub_addon_status,
  currency,
  numberOfGuests,
  handlePriceChangeStep1,
  handleOptionChangeExtrastep1,
  handleExtra1Check,
  handlechangeStep1,
  handleChangeSubaddon,
  handleLanguageChange,
  newLang,
  loyalty,
  otherloyal_status,
  setOtherloyal_status,
  otherloyal,
  addInputFieldinclusion,
  removeInputFieldinclusion,
  alllanguage,
  loyallang,
  setLoyallang,
  hangleotherloyal,
  handleloyalchange,
  handleOptionChangeExtrastep3,
  handleOptionpriceExtrastep3,
  handlechangeStep3,
  handleExtra3Check,
  formerror,
  handlePriceChangeStep3,
  handleChangeextraname,
  tab2,
  setTab2,
}) {
  const [tab1, setTab1] = useState("EN");
  const [isMatchingLanguage, setisMatchingLanguage] = useState("");

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  useEffect(() => {
    const isMatching = multi.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguage(isMatching);
  }, [newLang,multi]);

  return (
    <div className="row">
      <div className="col-md-12">
        {/* <div className="offer-create-section">
       <div className="offer-create-details">
        <div className="col-md-12">
         <div className="form-translation-style">
          <ul>
          <li className={tab1==="EN"?"active":""} onClick={(e)=>setTab1("EN")}>EN</li>
          <li className={tab1==="TRANS"?"active":""} onClick={(e)=>setTab1("TRANS")}>Translations</li>
          </ul>
           </div>
           </div>
           {tab1==="EN"?
           <> */}
        <div className="offer-content-section">
          <span className="offer-status offer-status2-addon">
            <div
              className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                id="inlineswitch5"
                style={{ width: "50px", height: "25px" }}
                value={extra.status}
                checked={extra.status === true ? true : false}
                onChange={(e) => handleExtraToggle(e.target.checked, "status")}
              />
            </div>
          </span>
          <div className="col-md-12">
            <ul className="offer-create-list_rate">
              <li
                className={
                  tab2 === "addon"
                    ? "extra-step-list steplist-two active"
                    : "extra-step-list steplist-two"
                }
                onClick={(e) => setTab2("addon")}
              >
                Add-ons
              </li>
              <li
                className={
                  tab2 === "subaddon"
                    ? "extra-step-list steplist-two active"
                    : "extra-step-list steplist-two"
                }
                onClick={(e) => setTab2("subaddon")}
                style={{
                  borderLeft: "1px solid #d2d2d2",
                  borderRight: "1px solid #d2d2d2",
                }}
              >
                Sub Add-ons
              </li>
              <li
                className={
                  tab2 === "inclusion"
                    ? "extra-step-list steplist-two active"
                    : "extra-step-list steplist-two"
                }
                onClick={(e) => setTab2("inclusion")}
              >
                Inclusions
              </li>
            </ul>
          </div>
          {tab2 === "addon" ? (
            <>
              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <p className="offer-form-label">
                    Enter the addon you want to display in booking engine search
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Enter Addon Here"
                    value={extraname}
                    onChange={handleChangeextraname}
                    style={{ textTransform: "capitalize" }}
                  />

                  <label style={{ color: "red" }}>
                    {extra1Error.extraname}
                  </label>
                  <div className="padding-5"></div>
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="col-lg-6">
                      <div className="d-flex">
                        <label
                          className="input-group-text input-joint-color add-edit-col"
                          id="inputGroup-sizing-default"
                          style={{
                            width: "130px",
                            borderBottomRightRadius: "0px",
                            borderTopRightRadius: "0px",
                          }}
                        >
                          Add/edit in
                        </label>
                        <select
                          className="form-control addeditin"
                          data-choices
                          data-choices-search-false
                          id="idPayment"
                          style={{
                            width: "130px",
                            height: "38px",
                            marginLeft: "-1px",
                          }}
                          value={newLang}
                          onChange={(e) => handleLanguageChange(e.target.value)}
                        >
                          <option value="">Choose language</option>

                          {language.map((lang, inx) => {
                            return (
                              <option key={inx} value={lang.lang_code}>
                                {lang.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className="appendmore"
                      style={{ marginTop: "7px" }}
                    ></div>
                    {newLang ? (
                      multi.length > 0 ? (
                        <>
                          {multi.some((item) => item.lang_code === newLang) ? (
                            <div
                              className="col-md-12 translation-fill-section"
                              data-value="1"
                            >
                              <div className="col-lg-6">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Add-on:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={multi[isMatchingLanguage]?.title}
                                    onChange={(e) =>
                                      handleAddonChange(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-md-12 translation-fill-section"
                              data-value="1"
                            >
                              {/* <div className="translation-selected-lang">
                    <p>
                      <span className="translation-selected-lang-head">{selectedMulti[selectedMulti?.length-1]?.lang_code}</span>
                    </p>
                  </div> */}
                              <div className="col-lg-6">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Add-on:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={multi[multi?.length - 1]?.title}
                                    onChange={(e) =>
                                      handleAddonChange(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="padding-5"></div>

                  <div className="col-md-2">
                    <h6 className="offer-form-title">Rates</h6>
                  </div>
                  <ul className="offer-create--rate-list cancellation-table-scroll">
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input per_room_once_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="free"
                          value={"free"}
                          checked={
                            extra.extra_price_type === "free" ? true : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "free",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Free<span className="extra-label-sub"> </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input per_room_once_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="room_once"
                          value={"room_once"}
                          checked={
                            extra.extra_price_type === "room_once"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "room_once",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Per room / once:
                          <span className="extra-label-sub">
                            ex. flowers, chocolate, late checkout,{" "}
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "room_once"
                            ? "per_room_once_show"
                            : "per_room_once_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input per_room_per_night_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="room_per_night"
                          value={"room_per_night"}
                          checked={
                            extra.extra_price_type === "room_per_night"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "room_per_night",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Per room / per night:
                          <span className="extra-label-sub">
                            ex. cleaning, crib, etc.
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "room_per_night"
                            ? "per_room_per_night_show"
                            : "per_room_per_night_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "room_per_night"
                            ? "per_room_per_night_show extra-left-padding-5 form-check form-check-inline"
                            : "per_room_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                        }
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck1"
                          value={extra.client_preference_status}
                          checked={
                            extra.client_preference_status === true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleExtra3Check(
                              e.target.checked,
                              "client_preference_status"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            The client can choose the number of nights he wants
                            to enjoy it
                          </span>
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input per_person_once_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="person_once"
                          value={"person_once"}
                          checked={
                            extra.extra_price_type === "person_once"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "person_once",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Per person / once:
                          <span className="extra-label-sub">
                            ex. tickets, tours, etc.
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "person_once"
                            ? "per_person_once_show"
                            : "per_person_once_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>

                      <ul
                        className={
                          extra.extra_price_type === "person_once"
                            ? "offer-create-lists per_person_once_show"
                            : "offer-create-lists per_person_once_show d-none"
                        }
                      >
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="person"
                              value={"person"}
                              checked={
                                extra.price_age_category === "person"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "person",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per person{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="adult"
                              value={"adult"}
                              checked={
                                extra.price_age_category === "adult"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "adult",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per adult{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="child"
                              value={"child"}
                              checked={
                                extra.price_age_category === "child"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "child",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per child{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input per_person_per_night_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="person_per_night"
                          value={"person_per_night"}
                          checked={
                            extra.extra_price_type === "person_per_night"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "person_per_night",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Per person / per night:
                          <span className="extra-label-sub">
                            ex. VIP treatment, etc.
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "person_per_night"
                            ? "per_person_per_night_show"
                            : "per_person_per_night_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>
                      <ul
                        className={
                          extra.extra_price_type === "person_per_night"
                            ? "offer-create-lists per_person_per_night_show"
                            : "offer-create-lists per_person_per_night_show d-none"
                        }
                      >
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="price_age_category"
                              value={"person"}
                              checked={
                                extra.price_age_category === "person"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "person",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per person{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="price_age_category"
                              value={"adult"}
                              checked={
                                extra.price_age_category === "adult"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "adult",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per adult{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              id="WithoutinlineRadio2"
                              name="price_age_category"
                              value={"child"}
                              checked={
                                extra.price_age_category === "child"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOptionChangeExtrastep3(
                                  "child",
                                  "price_age_category"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join">
                                Price per child{" "}
                              </span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      {/* <div className={extraStep3.price_type === 'person_per_night'?"per_person_per_night_show":"per_person_per_night_show d-none"}>
            </div> */}

                      <div
                        className={
                          extra.extra_price_type === "person_per_night"
                            ? "per_person_per_night_show extra-left-padding-5 form-check form-check-inline"
                            : "per_person_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                        }
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck1"
                          value={extra.client_preference_status}
                          checked={
                            extra.client_preference_status === true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleExtra3Check(
                              e.target.checked,
                              "client_preference_status"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            The client can choose the number of nights he wants
                            to enjoy it
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input no_person_once_check"
                          type="radio"
                          name="person_range_once"
                          id="WithoutinlineRadio1"
                          value={"person_range_once"}
                          checked={
                            extra.extra_price_type === "person_range_once"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "person_range_once",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Per number of persons / once:
                          <span className="extra-label-sub">
                            ex. airport shuttle, etc.
                          </span>
                        </label>
                      </div>
                      <div
                        className={
                          extra.extra_price_type === "person_range_once"
                            ? "no_person_once_show no_person_once_tabel_sec "
                            : "no_person_once_show no_person_once_tabel_sec d-none"
                        }
                      >
                        <table className="extra-table-style">
                          <tbody>
                            <tr>
                              <td>
                                <span className="extra-table-label">
                                  Total Price:
                                </span>
                              </td>
                              {numberOfGuests.map((pric, index) => {
                                return (
                                  <td key={index}>
                                    <input
                                      className="form-control extra-table-td"
                                      style={{
                                        width: "60px",
                                        textAlign: "center",
                                      }}
                                      name="price"
                                      value={pric.price}
                                      onChange={(e) =>
                                        handleOptionpriceExtrastep3(index, e)
                                      }
                                    />
                                  </td>
                                );
                              })}
                              <td>{currency.code}</td>
                            </tr>
                            <tr>
                              <td>guests</td>
                              {numberOfGuests.map((guest, index) => {
                                return <td key={index}>{index + 1}</td>;
                              })}
                            </tr>

                            <tr>
                              <td></td>
                            </tr>
                            <tr>
                              <td
                                colSpan="14"
                                className="extra_table_info_note"
                              >
                                You may leave prices empty (not available for
                                that number of people) or set the price to 0
                                (free)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input once_per_reservation_check"
                          type="radio"
                          name="reserve_once"
                          id="WithoutinlineRadio1"
                          value={"reserve_once"}
                          checked={
                            extra.extra_price_type === "reserve_once"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "reserve_once",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          Once per reservation:
                          <span className="extra-label-sub">
                            ex.personal shopper{" "}
                          </span>
                        </label>
                      </div>
                      <div
                        className={
                          extra.extra_price_type === "reserve_once"
                            ? "once_per_reservation_show"
                            : "once_per_reservation_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input reservation_per_night_check"
                          type="radio"
                          id="WithoutinlineRadio1"
                          name="reserve_per_night"
                          value={"reserve_per_night"}
                          checked={
                            extra.extra_price_type === "reserve_per_night"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "reserve_per_night",
                              "extra_price_type"
                            )
                          }
                        />
                        <label>
                          One unit per reservation / per night:
                          <span className="extra-label-sub">
                            One unit per reservation / per night
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          extra.extra_price_type === "reserve_per_night"
                            ? "reservation_per_night_show"
                            : "reservation_per_night_show d-none"
                        }
                      >
                        <span className="extra_freenight_discount_sec">
                          <div className="extra_freenight_discount">
                            <input
                              type="number"
                              className="form-control"
                              value={extra.extra_price}
                              name="extra_price"
                              onChange={handlechangeStep3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                            <span className="offer_freenight_option">
                              {currency.code}
                            </span>
                          </div>
                        </span>
                      </div>
                      <div
                        className={
                          extra.extra_price_type === "reserve_per_night"
                            ? "reservation_per_night_show extra-left-padding-5 form-check form-check-inline"
                            : "reservation_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                        }
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck1"
                          value={extra.client_preference_status}
                          checked={
                            extra.client_preference_status === true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleExtra3Check(
                              e.target.checked,
                              "client_preference_status"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            The client can choose the number of nights he wants
                            to enjoy it
                          </span>
                        </label>
                      </div>
                    </li>
                    <label style={{ color: "red", fontWeight: "500" }}>
                      {extra1Error.extra_price}
                    </label>
                  </ul>
                  <div
                    className={
                      extra.status === true
                        ? "extra-conditions"
                        : "extra-conditions d-none"
                    }
                    style={{ marginTop: "35px" }}
                  >
                    <div className="offer-condition-check">
                      <input
                        className="form-check-input form-stay-check"
                        type="checkbox"
                        id="formCheck1"
                        onChange={(e) =>
                          handleExtraToggle(
                            e.target.checked,
                            "availabilty_status"
                          )
                        }
                        value={extra.availabilty_status}
                        checked={
                          extra.availabilty_status === true ? true : false
                        }
                      />
                      <label className="form-check-label" for="formCheck1">
                        Only offer in reservations:
                      </label>
                    </div>

                    <div
                      className={
                        extra.availabilty_status === true
                          ? "offer-condition-select form-extra-check-show"
                          : "offer-condition-select form-extra-check-show d-none"
                      }
                    >
                      <select
                        className="form-select offer-create-select"
                        name="availabilty_type"
                        value={extra.availabilty_type}
                        onChange={handlechangeExtra1}
                      >
                        <option value="all_between">
                          With all days within
                        </option>
                        <option value="any_in">With at least one day in</option>
                        <option value="check_in">That checkin</option>
                        <option value="check_out">That checkout</option>
                      </select>
                    </div>
                    <div
                      className={
                        extra.availabilty_status === true
                          ? "offer-condition-head-sec"
                          : "offer-condition-head-sec d-none"
                      }
                    >
                      <span className="info-tag">
                        i
                        <span className="tooltiptext">
                          Would you like to make the offer available for users
                          only if it coincides with particular check-in dates?<br></br>
                          Enable and choose the check-in dates.
                        </span>
                      </span>
                      <p
                        className="offer-condition-calendar-choose checkin_choose_status"
                        onClick={(e) =>
                          setActivestaydateopen(!activestaydateopen)
                        }
                      >
                        Open/Close period
                      </p>
                    </div>
                    <br></br>
                    <br></br>
                    <div
                      className={
                        extra.availabilty_status === true
                          ? "offer-condition-calendar-pic form-extra-check-show"
                          : "offer-condition-calendar-pic form-extra-check-show d-none"
                      }
                    >
                      <div className="clearfix"></div>
                      <div
                        className="offer-condition-calendar form-calender-add form-stay-check-show"
                        style={{ width: "310px", height: "315px" }}
                      >
                        <Flatpickr
                          ref={fpstay}
                          className="offer_flat"
                          value={selectedDatesstay}
                          onChange={(date) => setSelectedDatesstay(date)}
                          options={{
                            mode: "multiple",
                            inline: true,
                          }}
                        />
                      </div>

                      <div
                        className={
                          activestaydateopen === true
                            ? "offer-condition-apply stay_choose_open"
                            : "offer-condition-apply stay_choose_open d-none"
                        }
                      >
                        <span className="offer-condition-close">
                          <Link to="" onClick={handleOpenclosestay}>
                            X
                          </Link>
                        </span>
                        <div className="offer-condition-apply-inner-section">
                          <select
                            className="form-select"
                            value={openclosestay}
                            onChange={(e) => setOpenclosestay(e.target.value)}
                          >
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                          </select>
                          <div className="offer-condition-apply-calender">
                            <div className="offer-condition-check">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-from"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datefromstay}
                                    onChange={handleCalendarChangeFrom}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("from")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="offer-condition-check">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="UNTIL"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datetostay}
                                    onChange={handleCalendarChangeTo}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="offer-condition-week">
                            <ul>
                              <li>
                                <p>Mon</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(1) &&
                                    !clickedDaysstay.includes(1)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(1)}
                                />
                              </li>
                              <li>
                                <p>Tue</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(2) &&
                                    !clickedDaysstay.includes(2)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(2)}
                                />
                              </li>
                              <li>
                                <p>Wed</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(3) &&
                                    !clickedDaysstay.includes(3)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(3)}
                                />
                              </li>
                              <li>
                                <p>Thu</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(4) &&
                                    !clickedDaysstay.includes(4)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(4)}
                                />
                              </li>
                              <li>
                                <p>Fri</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(5) &&
                                    !clickedDaysstay.includes(5)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(5)}
                                />
                              </li>
                              <li>
                                <p>Sat</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(6) &&
                                    !clickedDaysstay.includes(6)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(6)}
                                />
                              </li>
                              <li>
                                <p>Sun</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(0) &&
                                    !clickedDaysstay.includes(0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(0)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="condition-apply-calendar-btn-sec">
                            <Link
                              className="condition-apply-calendar-btn"
                              onClick={handleApplyToCalendarstay}
                            >
                              Apply to calendar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-create-details">
                    <div className="reserve-wid-add " style={{ width: "30%" }}>
                      <h6 className="offer-form-title reservation-wid">
                        Only offer in reservations with
                      </h6>
                      <div className="padding-5"></div>
                      <ul className="offer-create--rate-list ">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input extra_min_stay_check"
                              type="checkbox"
                              id="formCheck1"
                              value={extra.min_stay_status}
                              checked={
                                extra.min_stay_status === true ? true : false
                              }
                              onChange={(e) =>
                                handleExtra4Check(
                                  e.target.checked,
                                  "min_stay_status"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join offer-reservations">
                                Minimum stay
                              </span>
                              <span
                                className={
                                  extra.min_stay_status === true
                                    ? "offer_freeningt_join extra_min_stay_show"
                                    : "offer_freeningt_join extra_min_stay_show d-none"
                                }
                              >
                                <select
                                  className="form-select box_style"
                                  value={extra.min_stay_days}
                                  name="min_stay_days"
                                  onChange={handlechangeSelectStep4}
                                >
                                  {[...Array(30).keys()].map((value) => (
                                    <option key={value + 1} value={value + 1}>
                                      {value + 1}
                                    </option>
                                  ))}
                                </select>
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input extra_max_stay_check"
                              type="checkbox"
                              id="formCheck1"
                              value={extra.max_stay_status}
                              checked={
                                extra.max_stay_status === true ? true : false
                              }
                              onChange={(e) =>
                                handleExtra4Check(
                                  e.target.checked,
                                  "max_stay_status"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join offer-reservations">
                                Maximum stay
                              </span>
                              <span
                                className={
                                  extra.max_stay_status === true
                                    ? "offer_freeningt_join extra_max_stay_show"
                                    : "offer_freeningt_join extra_max_stay_show d-none"
                                }
                              >
                                <select
                                  className="form-select box_style"
                                  value={extra.max_stay_days}
                                  name="max_stay_days"
                                  onChange={handlechangeSelectStep4}
                                >
                                  {[...Array(30).keys()].map((value) => (
                                    <option key={value + 1} value={value + 1}>
                                      {value + 1}
                                    </option>
                                  ))}
                                </select>
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input extra_advance_days_check"
                              type="checkbox"
                              id="formCheck1"
                              value={extra.days_in_advance_status}
                              checked={
                                extra.days_in_advance_status === true
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                handleExtra4Check(
                                  e.target.checked,
                                  "days_in_advance_status"
                                )
                              }
                            />
                            <label className="offer-step-label">
                              <span className="offer_freeningt_join offer-reservations">
                                Days in advance
                              </span>
                              <span
                                className={
                                  extra.days_in_advance_status === true
                                    ? "offer_freeningt_join extra_advance_days_show"
                                    : "offer_freeningt_join extra_advance_days_show d-none"
                                }
                              >
                                <input
                                  className="form-control extra-input-field box_style_is"
                                  value={extra.days_in_advance}
                                  name="days_in_advance"
                                  onChange={handlechangeStep4}
                                />
                                <label
                                  style={{ color: "red", fontWeight: "500" }}
                                >
                                  {extra1Error.days_in_advance}
                                </label>
                              </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input restr_loyalty_check "
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleExtra4Check(
                                  e.target.checked,
                                  "loyality_club_status"
                                )
                              }
                              value={extra.loyality_club_status}
                              checked={
                                extra.loyality_club_status === true
                                  ? true
                                  : false
                              }
                            />
                            <label className="offer-reservations">
                              {" "}
                              Only for the loyalty club{" "}
                            </label>
                          </div>
                          <div
                            className={
                              extra.loyality_club_status === true
                                ? "restr_loyalty_expand  "
                                : "restr_loyalty_expand d-none"
                            }
                          >
                            <ul
                              className="rest_list_inline grid_addon"
                              style={{ width: "520px" }}
                            >
                              {Loyaltyclublist.map((loyal, index) => {
                                const isChecked =
                                  loyalty_club?.some(
                                    (item) => item === loyal.value
                                  ) || false;
                                return (
                                  <li key={index}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="formCheck1"
                                      checked={isChecked}
                                      onChange={(e) =>
                                        handleloyalchangeExtra4(
                                          index,
                                          e.target.checked,
                                          loyal.value
                                        )
                                      }
                                    />
                                    <label style={{ marginLeft: "8px" }}>
                                      {" "}
                                      {loyal.label}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {/* <div className={extra.loyality_club_status===true?"restr_loyalty_expand":"restr_loyalty_expand d-none"}>
                                  <ul className="rest_list_inline">
                                    {Loyaltyclublist.map((loyal,index)=>{
                                      return(
                                        <li key={index}>
                                        <input className="form-check-input" type="checkbox" id="formCheck1" 
                                        value={loyal.selected} checked={loyal.selected===true?true:false} 
                                        onChange={(e)=>handleloyalchangeExtra4(e.target.checked,index)}/>
                                        <label style={{marginLeft:"8px"}}> {loyal.label}</label>
                                    </li>
                                      )
                                    })}
                              </ul>
                        </div> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="padding-5"></div>

                  <div className="col-md-12">
                    <div
                      className="extra-provide-sec"
                      style={{ marginTop: "12px" }}
                    >
                      <label className="form-check-label" for="formCheck1">
                        Who provides this extra?
                      </label>
                      <br></br>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input provide_hotel_check"
                          type="radio"
                          name="hotel"
                          id="WithoutinlineRadio1"
                          onChange={(e) =>
                            handleExtraToggle("hotel", "provider")
                          }
                          value={extra.provider}
                          checked={extra.provider === "hotel" ? true : false}
                        />
                        My hotel
                      </div>
                      <br></br>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input external_provider_check"
                          type="radio"
                          name="external"
                          id="WithoutinlineRadio1"
                          onChange={(e) =>
                            handleExtraRadio("external", "provider")
                          }
                          value={extra.provider}
                          checked={extra.provider === "external" ? true : false}
                        />
                        An external provider
                      </div>

                      <div
                        className={
                          extra.provider === "external"
                            ? "extra_provider_show"
                            : "extra_provider_show d-none"
                        }
                      >
                        <span>
                          If you want to notify the external provider that a
                          reservation has just been made, please type its email
                          here
                        </span>
                        <input
                          className="form-control extra-input-full"
                          name="notifyEmail"
                          value={extra.notifyEmail}
                          onChange={handlechangeExtra1}
                        />
                        <span className="extra_table_info_note">
                          (The e-mail will only include the reserved extra, the
                          amounts, the name of the client, the booking dates and
                          the reservation code)
                        </span>
                      </div>
                      <label style={{ color: "red", marginLeft: "22px" }}>
                        {extra1Error.notifyEmail}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link className="btn btn-be-form-cancel btn-be-back" to="/add-on">
                    Cancel
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={nextExtrastep1}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </>
          ) : tab2 === "subaddon" ? (
            <>
              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <h6 className="offer-step-desc">
                    {" "}
                    If you wish to create a new sub add-on, please ensure that
                    the button is enabled.
                  </h6>

                  <div style={{ marginTop: "4%" }}>
                    <h6 className="offer-form-title subaddon-tittle">
                      Sub Add-ons
                    </h6>

                    <div
                      className="form-check form-switch form-switch-danger form-check-inline condition-active-status subaddon-adj"
                      dir="ltr"
                      style={{ marginLeft: "10%", bottom: "30px" }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input offer_checkbox1 checkbox-move"
                        id="inlineswitch5"
                        style={{ width: "50px", height: "25px" }}
                        value={extra.sub_addon_status}
                        name="sub_addon_status"
                        checked={extra.sub_addon_status === true ? true : false}
                        onChange={(e) =>
                          handleExtraSubaddonToggle(
                            e.target.checked,
                            "sub_addon_status"
                          )
                        }
                      />
                    </div>
                    {extra.sub_addon_status === true ||
                    extra.sub_addon_status === 1 ? (
                      <>
                        {subaddonlist.map((subad, i) => {
                          const isMatchingLanguagesub =
                            subad?.multi_lang?.findIndex(
                              (language) =>
                                language.lang_code === subad.newlangsub
                            );
                          return (
                            <div key={i}>
                              <div className="subaddon_sep">
                                <h6
                                  className="subaddon_top-25"
                                  style={{ marginTop: "5%" }}
                                >
                                  Sub-Addon
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Sub-addon Here"
                                  value={subad.sub_addon.title}
                                  onChange={(e) => handleChangeSubaddon(i, e)}
                                  name="title"
                                  style={{ textTransform: "capitalize" }}
                                />
                                {formerror.map((err, index) => {
                                  return (
                                    <>
                                      {i === index && err && err.title ? (
                                        <label style={{ color: "red" }}>
                                          {err.title}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="col-lg-6">
                                    <div className="d-flex">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{
                                          width: "130px",
                                          borderBottomRightRadius: "0px",
                                          borderTopRightRadius: "0px",
                                        }}
                                      >
                                        Add/edit in
                                      </label>
                                      <select
                                        className="form-control addeditin"
                                        data-choices
                                        data-choices-search-false
                                        id="idPayment"
                                        style={{
                                          width: "130px",
                                          height: "38px",
                                          marginLeft: "-1px",
                                        }}
                                        value={subad.newlangsub}
                                        onChange={(e) =>
                                          handleLanguageChangesub(
                                            i,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Choose language
                                        </option>

                                        {language.map((lang, inx) => {
                                          return (
                                            <option
                                              key={inx}
                                              value={lang.lang_code}
                                            >
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    className="appendmore"
                                    style={{ marginTop: "7px" }}
                                  ></div>
                                  {subad.newlangsub ? (
                                    subad.multi_lang.length > 0 ? (
                                      <>
                                        {subad.multi_lang.some(
                                          (item) =>
                                            item.lang_code === subad.newlangsub
                                        ) ? (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-6">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Sub Add-on:
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    subad.multi_lang[
                                                      isMatchingLanguagesub
                                                    ]?.title
                                                  }
                                                  onChange={(e) =>
                                                    handleAddonChangesub(
                                                      i,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-6">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Sub Add-on:
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    subad.multi_lang[
                                                      subad.mulit_lang?.length -
                                                        1
                                                    ]?.title
                                                  }
                                                  onChange={(e) =>
                                                    handleAddonChangesub(
                                                      subad.mulit_lang?.length -
                                                        1,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h6 className="offer-form-title">Rates</h6>
                                <ul className="offer-create--rate-list cancellation-table-scroll">
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"free"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type === "free"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "free"
                                          )
                                        }
                                      />
                                      <label>
                                        Free
                                        <span className="extra-label-sub">
                                          {" "}
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"room_once"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "room_once"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "room_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per room / once:
                                        <span className="extra-label-sub">
                                          ex. flowers, chocolate, late checkout,{" "}
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "room_once"
                                          ? "per_room_once_show"
                                          : "per_room_once_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"room_per_night"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "room_per_night"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "room_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        Per room / per night:
                                        <span className="extra-label-sub">
                                          ex. cleaning, crib, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "room_per_night"
                                          ? "per_room_per_night_show"
                                          : "per_room_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "room_per_night"
                                          ? "per_room_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "per_room_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={
                                          subad.sub_addon
                                            .client_preference_status
                                        }
                                        checked={
                                          subad.sub_addon
                                            .client_preference_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            i,
                                            "client_preference_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_person_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"person_once"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "person_once"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "person_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per person / once:
                                        <span className="extra-label-sub">
                                          ex. tickets, tours, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_once"
                                          ? "per_person_once_show"
                                          : "per_person_once_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>

                                    <ul
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_once"
                                          ? "offer-create-lists per_person_once_show"
                                          : "offer-create-lists per_person_once_show d-none"
                                      }
                                    >
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name={`price_age_category${i}`}
                                            value={"person"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "person"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "person"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per person{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name={`price_age_category${i}`}
                                            value={"adult"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "adult"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "adult"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per adult{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name={`price_age_category${i}`}
                                            value={"child"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "child"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "child"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per child{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_person_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"person_per_night"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "person_per_night"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "person_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        Per person / per night:
                                        <span className="extra-label-sub">
                                          ex. VIP treatment, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_per_night"
                                          ? "per_person_per_night_show"
                                          : "per_person_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                    <ul
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_per_night"
                                          ? "offer-create-lists per_person_per_night_show"
                                          : "offer-create-lists per_person_per_night_show d-none"
                                      }
                                    >
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="price_age_category"
                                            value={"person"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "person"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "person"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per person{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="price_age_category"
                                            value={"adult"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "adult"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "adult"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per adult{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="price_age_category"
                                            value={"child"}
                                            checked={
                                              subad.sub_addon
                                                .price_age_category === "child"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                i,
                                                "price_age_category",
                                                "child"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per child{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                    {/* <div className={extraStep1.price_type === 'person_per_night'?"per_person_per_night_show":"per_person_per_night_show d-none"}>
                            </div> */}

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_per_night"
                                          ? "per_person_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "per_person_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={
                                          subad.sub_addon
                                            .client_preference_status
                                        }
                                        checked={
                                          subad.sub_addon
                                            .client_preference_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            i,
                                            "client_preference_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input no_person_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        value={"person_range_once"}
                                        name={`sub_addon_price_type${i}`}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "person_range_once"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "person_range_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per number of persons / once:
                                        <span className="extra-label-sub">
                                          ex. airport shuttle, etc.
                                        </span>
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "person_range_once"
                                          ? "no_person_once_show no_person_once_tabel_sec"
                                          : "no_person_once_show no_person_once_tabel_sec d-none"
                                      }
                                    >
                                      <table className="extra-table-style">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span className="extra-table-label">
                                                Total Price:
                                              </span>
                                            </td>
                                            {[...Array(12)].map((_, index) => {
                                              const price =
                                                subad.sub_addon
                                                  .person_range_price &&
                                                subad.sub_addon
                                                  .person_range_price[index]
                                                  ? subad.sub_addon
                                                      .person_range_price[index]
                                                      .price
                                                  : "";
                                              return (
                                                <td key={index}>
                                                  <input
                                                    className="form-control extra-table-td"
                                                    style={{
                                                      width: "60px",
                                                      textAlign: "center",
                                                    }}
                                                    name="price"
                                                    value={price}
                                                    onChange={(e) =>
                                                      handlePriceChangeStep1(
                                                        i,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </td>
                                              );
                                            })}
                                            <td>{currency.code}</td>
                                          </tr>
                                          <tr>
                                            <td>guests</td>
                                            {[...Array(12)].map((_, index) => {
                                              return (
                                                <td key={index}>{index + 1}</td>
                                              );
                                            })}
                                          </tr>

                                          <tr>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan="14"
                                              className="extra_table_info_note"
                                            >
                                              You may leave prices empty (not
                                              available for that number of
                                              people) or set the price to 0
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input once_per_reservation_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        value={"reserve_once"}
                                        name={`sub_addon_price_type${i}`}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "reserve_once"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "reserve_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Once per reservation:
                                        <span className="extra-label-sub">
                                          ex.personal shopper{" "}
                                        </span>
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "reserve_once"
                                          ? "once_per_reservation_show"
                                          : "once_per_reservation_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input reservation_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`sub_addon_price_type${i}`}
                                        value={"reserve_per_night"}
                                        checked={
                                          subad.sub_addon
                                            .sub_addon_price_type ===
                                          "reserve_per_night"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            i,
                                            "sub_addon_price_type",
                                            "reserve_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        One unit per reservation / per night:
                                        <span className="extra-label-sub">
                                          One unit per reservation / per night
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "reserve_per_night"
                                          ? "reservation_per_night_show"
                                          : "reservation_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              subad.sub_addon.sub_addon_price
                                            }
                                            name="sub_addon_price"
                                            onChange={(e) =>
                                              handlechangeStep1(i, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        subad.sub_addon.sub_addon_price_type ===
                                        "reserve_per_night"
                                          ? "reservation_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "reservation_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={
                                          subad.sub_addon
                                            .client_preference_status
                                        }
                                        checked={
                                          subad.sub_addon
                                            .client_preference_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            i,
                                            "client_preference_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                {formerror.map((err, inde) => {
                                  return (
                                    <>
                                      {i === inde &&
                                      err &&
                                      err.sub_addon_price ? (
                                        <label style={{ color: "red" }}>
                                          {err.sub_addon_price}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                                <span
                                  className={
                                    i === subaddonlist.length - 1
                                      ? ""
                                      : " d-none"
                                  }
                                  style={{ float: "right" }}
                                >
                                  <Link
                                    className="extra-rate-add"
                                    to="#"
                                    onClick={addInputField}
                                  >
                                    +
                                  </Link>
                                </span>
                                <Link
                                  className={
                                    i === 0
                                      ? "remove_more d-none"
                                      : "remove_more"
                                  }
                                  to="#"
                                  onClick={(e) => removeInputFields(i)}
                                  style={{
                                    float: "right",
                                    marginTop: "0px",
                                    marginRight: "10px",
                                  }}
                                >
                                  X
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => setTab2("addon")}
                  >
                    Prev
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={nextExtrastep1sub}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <h6 className="offer-step-desc">
                    When including an existing extra in an offer, the final
                    price typically does not consider the price of the extra
                    separately. Instead, the extra is bundled into the overall
                    offer price.{" "}
                  </h6>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list  cancellation-table-scroll">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_include_check"
                              type="checkbox"
                              id="formCheck1"
                              value={extra.extra_include_status}
                              checked={
                                extra.extra_include_status === true
                                  ? true
                                  : false
                              }
                              name="extra_include_status"
                              onChange={(e) =>
                                handleExtraSubaddonToggle(
                                  e.target.checked,
                                  "extra_include_status"
                                )
                              }
                            />
                            <label>
                              Inclusions
                              <span className="info-tag">
                                i
                                <span className="tooltiptext">
                                  When including an existing extra, the final
                                  price will not consider the price of the extra
                                </span>
                              </span>
                            </label>
                          </div>
                          <div
                            className={
                              extra.extra_include_status === true
                                ? "offer_include_expand"
                                : "offer_include_expand d-none"
                            }
                          >
                            <ul className="rest_list_block label-media">
                              {loyalty.map((loyal, index) => {
                                return (
                                  <li key={index}>
                                    <input
                                      className="form-check-input "
                                      type="checkbox"
                                      id="formCheck1"
                                      value={loyal.selected}
                                      checked={
                                        loyal.selected &&
                                        loyal.selected === true
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleloyalchange(
                                          e.target.checked,
                                          index
                                        )
                                      }
                                    />
                                    <label className="offer_new_lbl label-media">
                                      {loyal.label}
                                    </label>
                                  </li>
                                );
                              })}

                              <li>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck1"
                                  value={otherloyal_status}
                                  checked={
                                    otherloyal_status === true ? true : false
                                  }
                                  onClick={(e) =>
                                    setOtherloyal_status(e.target.checked)
                                  }
                                />
                                <label className="offer_new_lbl">Other</label>
                              </li>
                              <label style={{ color: "red" }}>
                                {extra1Error.loyalty}
                              </label>
                              <div
                                className={
                                  otherloyal_status === true
                                    ? "data offer_step5_dis_in step5-others "
                                    : "d-none"
                                }
                              >
                                <div className="col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-lg-6 ">
                                  <input
                                    type="text"
                                    value={"English"}
                                    className="select_lang_dummy dummy_extra"
                                  />
                                  <br></br>
                                  <label
                                    className="mar-left-red"
                                    style={{ color: "red", marginLeft: "30px" }}
                                  >
                                    {extra1Error.loyalty_name}
                                  </label>
                                  {otherloyal.map((data, indx) => {
                                    return (
                                      <div
                                        className="mar-top-red"
                                        key={indx}
                                        style={{ marginTop: "23px" }}
                                      >
                                        <div
                                          className={
                                            indx === 0
                                              ? ""
                                              : "extrarate_close_d"
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="new_inp_type inp_type_extra new_inp_type_padding  top-mar-create"
                                            name="loyalty_name"
                                            value={data.loyalty_name}
                                            onChange={(e) =>
                                              hangleotherloyal(
                                                indx,
                                                e.target.value,
                                                "loyalty_name"
                                              )
                                            }
                                          />
                                          <Link
                                            className={
                                              indx === 0
                                                ? " d-none"
                                                : " extrarate_remove_more d-none"
                                            }
                                            to="#"
                                            onClick={(e) =>
                                              removeInputFieldinclusion(indx)
                                            }
                                          >
                                            X
                                          </Link>
                                        </div>
                                        <label
                                          className={
                                            indx === 0
                                              ? "new d-flex margin-none  "
                                              : "d-none"
                                          }
                                          style={{ marginLeft: "30px" }}
                                        >
                                          (E.g. A Bottle of cava on arrival){" "}
                                        </label>

                                        <p
                                          className={
                                            indx === otherloyal.length - 1
                                              ? "extrarate-room-link_new new_inclusions"
                                              : "extrarate_close_d d-none"
                                          }
                                        >
                                          <Link
                                            className="add_new_inclusion"
                                            to="#"
                                            onClick={addInputFieldinclusion}
                                            style={{
                                              color: "#5ea3cb",
                                              marginLeft: "25px",
                                            }}
                                          >
                                            Add a new inclusion
                                          </Link>
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div className="col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                  <div
                                    className="input-group choose-lang-wid mar-lef"
                                    style={{
                                      marginLeft: "30px",
                                      width: "265px",
                                    }}
                                  >
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Select Language:
                                    </label>
                                    <select
                                      className="form-control input_inclusion_select"
                                      data-choices
                                      data-choices-search-false
                                      name="choices-single-default"
                                      id="idPayment"
                                      value={loyallang}
                                      onChange={(e) =>
                                        handlelanglo(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Choose Language..
                                      </option>

                                      {alllanguage.map((lang, inx) => {
                                        return (
                                          <option
                                            key={inx}
                                            value={lang.lang_code}
                                          >
                                            {lang.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <br></br>
                                  {loyallang
                                    ? otherloyal?.map((data, indx) => {
                                        const isMatchingLanguage =
                                          data.lang?.findIndex(
                                            (language) =>
                                              language.lang_code === loyallang
                                          );

                                        return (
                                          <div key={indx}>
                                            {data.lang?.some(
                                              (item) =>
                                                item.lang_code === loyallang
                                            ) ? (
                                              <>
                                                
                                                <div
                                                  className={
                                                    indx === 0
                                                      ? ""
                                                      : "extrarate_close_d"
                                                  }
                                                >
                                                  <input
                                                    type="text"
                                                    className={
                                                      indx ===
                                                      otherloyal.length - 1
                                                        ? "new_inp_type bottle-canva-col new_inp_type_padding"
                                                        : "new_inp_type  new_inp_type_padding"
                                                    }
                                                    name="loyalty_lang_name"
                                                    value={
                                                      data?.lang[
                                                        isMatchingLanguage
                                                      ]?.loyalty_lang_name
                                                    }
                                                    onChange={(e) =>
                                                      hangleotherloyallang(
                                                        indx,
                                                        e.target.value,
                                                        "loyalty_lang_name"
                                                      )
                                                    }
                                                  />
                                                  <Link
                                                    className={
                                                      indx === 0
                                                        ? " d-none"
                                                        : " extrarate_remove_more"
                                                    }
                                                    to="#"
                                                    onClick={(e) =>
                                                      removeInputFieldinclusion(
                                                        indx
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </Link>
                                                </div>
                                                <label
                                                  className={
                                                    indx === 0
                                                      ? "new"
                                                      : "d-none"
                                                  }
                                                  style={{ marginLeft: "30px" }}
                                                >
                                                  (E.g. A Bottle of cava on
                                                  arrival){" "}
                                                </label>

                                                <span
                                                  className={
                                                    indx ===
                                                    otherloyal.length - 1
                                                      ? "extrarate-room-link_new"
                                                      : "extrarate_close_d d-none"
                                                  }
                                                ></span>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  className={
                                                    indx === 0
                                                      ? ""
                                                      : "extrarate_close_d"
                                                  }
                                                  style={{ marginTop: "18px" }}
                                                >
                                                  <input
                                                    type="text"
                                                    className={
                                                      indx ===
                                                      otherloyal.length - 1
                                                        ? "new_inp_type  new_inp_type_padding"
                                                        : "new_inp_type  new_inp_type_padding"
                                                    }
                                                    name="loyalty_lang_name"
                                                    value={
                                                      data?.lang[
                                                        data.lang.length - 1
                                                      ]?.loyalty_lang_name
                                                    }
                                                    onChange={(e) =>
                                                      hangleotherloyallang(
                                                        indx,
                                                        e.target.value,
                                                        "loyalty_lang_name"
                                                      )
                                                    }
                                                  />
                                                  <Link
                                                    className={
                                                      indx === 0
                                                        ? " d-none"
                                                        : " extrarate_remove_more"
                                                    }
                                                    to="#"
                                                    onClick={(e) =>
                                                      removeInputFieldinclusion(
                                                        indx
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </Link>
                                                </div>
                                                <label
                                                  className={
                                                    indx === 0
                                                      ? "new"
                                                      : "d-none"
                                                  }
                                                  style={{ marginLeft: "30px" }}
                                                >
                                                  (E.g. A Bottle of cava on
                                                  arrival){" "}
                                                </label>

                                                <span
                                                  className={
                                                    indx ===
                                                    otherloyal.length - 1
                                                      ? "extrarate-room-link_new"
                                                      : "extrarate_close_d d-none"
                                                  }
                                                ></span>
                                              </>
                                            )}
                                          </div>
                                        );
                                      })
                                    : ""}
                                  {/* {loyallang?
                                            otherloyal.map((data, indx) => {
                                              const isMatchingLanguage = data.lang?.findIndex((language) => language.lang_code === loyallang);

                                            return (
                                                <div key={indx}>
                                                     {
                                                    data.lang?.some((item) => item.lang_code === loyallang )?
                                                    <>

                                                    {console.log(isMatchingLanguage)}
                                                    <div className={indx===0 ?"":'extrarate_close_d'}>
                                                    <input type='text' className={indx===otherloyal.length-1?"new_inp_type  new_inp_type_padding":"new_inp_type  new_inp_type_padding"} name='loyalty_lang_name' value={data?.lang[isMatchingLanguage]?.loyalty_lang_name} onChange={(e)=>hangleotherloyallang(indx,e.target.value,"loyalty_lang_name")}/>
                                                    <Link className={indx===0 ?" d-none":" extrarate_remove_more"} to="#" onClick={(e)=>removeInputFieldinclusion(indx)}>X</Link>                                     
                                                    </div>
                                                    <label className={indx===0 ?"new":"d-none"}style={{marginLeft:"30px"}}>(E.g. A Bottle of cava on arrival) </label>

                                                    <span className={indx===otherloyal.length-1?"extrarate-room-link_new":"extrarate_close_d d-none"}>
                                                    </span>
                                                    </>
                                                    :
                                                     <>
                                                     <div className={indx===0 ?"":'extrarate_close_d'}>
                                                     <input type='text' className={indx===otherloyal.length-1?"new_inp_type  new_inp_type_padding":"new_inp_type  new_inp_type_padding"} name='loyalty_lang_name' value={data?.lang[data.lang.length-1]?.loyalty_lang_name} onChange={(e)=>hangleotherloyallang(indx,e.target.value,"loyalty_lang_name")}/>
                                                     <Link className={indx===0 ?" d-none":" extrarate_remove_more"} to="#" onClick={(e)=>removeInputFieldinclusion(indx)}>X</Link>                                     
                                                     </div>
                                                     <label className={indx===0 ?"new":"d-none"}style={{marginLeft:"30px"}}>(E.g. A Bottle of cava on arrival) </label>
 
                                                     <span className={indx===otherloyal.length-1?"extrarate-room-link_new":"extrarate_close_d d-none"}>
                                                     </span>
                                                     </>}
                                                </div>
                                                
                                                
                                                    );
                                                    })
                                                 :""}    */}
                                </div>
                              </div>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    onClick={(e) => setTab2("subaddon")}
                  >
                    Prev
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={nextExtrastep1inc}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
        {/* </>
          :<>
               <div className="offer-content-section">
                   <div class="col-lg-4" style={{marginTop:"20px"}}>
                      <div className="input-group">
                        <label className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                           <div className="col-md-7">
                              <MultiSelect
                                options={language}
                                value={selectedMulti}
                                onChange={setselectedMulti}
                                labelledBy="Select"
                                selectionType="counter"
                                allowCustomValue={true}
                                aria-expanded="true"
                              />
                                  </div>
                                </div>
                                </div>
                                <div className="appendmore" style={{marginTop:"20px"}}></div>
                                {selectedMulti.length > 0
                                  ? selectedMulti.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1">
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                     <div class="col-lg-4">
                                    <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default">Extra Name:</label>
                                        <input type="text" className="form-control" placeholder="" value={selectedMulti[index].name} 
                                        onChange={(e) =>(selectedMulti[index].name =e.target.value)}/>
                                      </div>
                                    </div>
                                          </div>
                                        );
                                      })
                                    : ""}
                                    </div>
                                </>
                                } */}

        {/* <div className="tab_nav_section">
              <div className="tab_nav_section_btns">
           <Link className="btn btn-be-form-cancel btn-be-back" >Cancel</Link>
          <Link className="btn btn-success btn-be-common" to="#" onClick={(e)=>setTab("step2")}>Next</Link>
       </div>
      </div>  */}
      </div>
    </div>
    //  </div>
    // </div>
  );
}

export default Extraeditstep1;
