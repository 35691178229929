import React, { useEffect, useState } from "react";
import { useNavigate, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";
import { Decodetoken } from "../Components/Commoncomponents/Decodetoken";
import Cookies from "js-cookie";
import Config from "../Components/Commoncomponents/Config";
import CryptoJS from "crypto-js";
import Notfound from "../Components/Commoncomponents/Common/Notfound";

const AuthProtected = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tokens = localStorage.getItem("token");
  const refreshtoken = localStorage.getItem("refreshtoken");
  const [user_type, setUser_type] = useState("");

const secretKey ="fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";

useEffect(() => {
  const encryptedString = localStorage.getItem("user_type");
  if (encryptedString) {
    const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
    const decryptedUserType = bytes.toString(CryptoJS.enc.Utf8); 
    setUser_type(decryptedUserType); 
  }
}, []);
  // const { tokens, handleUnauthorized } = Decodetoken();

  // useEffect(() => {
  //   handleUnauthorized();
  // }, [handleUnauthorized]);

  const Verify = async (e) => {
    const res = await fetch(`${Config.apiurl}refresh-token`, {
      method: "POST",
      body: JSON.stringify({
        old_token: `Bearer ${localStorage.getItem("refreshtoken")}`,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    const data1 = await res.json();
    if (res.status === 200) {
      if(data1.status===false){
        localStorage.clear();
        sessionStorage.clear();
        window.location.href=("/");
      }
      else{
      localStorage.setItem("token", data1.token);
      }
    } else {
      console.log("error");
    }
  };

  if (tokens) {
    const toekns = jwt(tokens);
    const expirationTime = toekns.exp * 1000 - 60000;
    const date = Date.now();

    if (date >= expirationTime) {
      if (refreshtoken) {
        const retoekns = jwt(refreshtoken);
        const expirationTimere = retoekns.exp * 1000 - 60000;

        if (date >= expirationTimere) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("user_type");
          localStorage.removeItem("refreshtoken");

          navigate("/");
        } else {
          Verify();
        }
      }
    } else {
      if (
        tokens &&
        (location.pathname === "/" || location.pathname === "/verify")
      ) {
        if(user_type==="admin-user"){
          navigate("/dashboard");
        }
        else{
          navigate("/hotel-dashboard");
        }
      } else if (!tokens && location.pathname !== "/") {
        navigate("/");
      }
    }
  } else {
    navigate("/");
  }

  useEffect(() => {
    if (tokens) {
      setAuthorization(tokens);
      if (
        tokens &&
        (location.pathname === "/" || location.pathname === "/verify")
      ) {
        if(user_type==="admin-user"){
          navigate("/dashboard");
        }
        else{
          navigate("/hotel-dashboard");
        }
      } else if (!tokens && location.pathname !== "/") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [tokens, navigate,user_type,location.pathname]);

  let role = props.route.role;

  if((user_type==='hotel-user' || user_type==='vendor-user') && role==='admin'){
    // navigate('/page-not-found')
    navigate('/hotel-dashboard')

  }

  // useEffect(() => {
  //   if (tokens !== undefined) {
  //     const decodedToken = jwt(tokens);
  //     const expirationTime = (decodedToken.exp * 1000) - 60000;
  //     const date = Date.now();

  //     if (date >= expirationTime) {
  //       Cookies.remove("email");
  //       Cookies.remove("user_type");
  //       navigate("/");
  //     } else {
  //       if (tokens && (location.pathname === '/' || location.pathname === '/verify')) {
  //         navigate('/dashboard');
  //       } else if (!tokens && location.pathname !== '/') {
  //         navigate('/');
  //       }
  //     }
  //   } else {
  //     console.log('Waiting for tokens...');
  //   }
  // }, [tokens, navigate, location]);

  // <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
