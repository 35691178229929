import React, { useCallback, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import { useEffect } from "react";
import Alerts from "../../Alerts";
import Roomheader from "../../Common/Roomheader";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { UserContext } from "../../Contexts/UserContext";
import Toastmodal from "../../Common/Toastmodal";
import { Modal, ModalBody } from "reactstrap";

function Roomcreate() {
  let navigate = useNavigate();
  let { selecthotelid } = useParams();
  const { updateHotel_select, hotel_select } = useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [sortorder, setSortorder] = useState(0);

  const [language, setLanguage] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMulti1, setselectedMulti1] = useState([]);
  const [board, setBoard] = useState([]);
  const [tab, setTab] = useState("EN");
  const [active, setActive] = useState(false);
  const [roomdetails, setRoomdetails] = useState({
    hide_from_dynamic_section: false,
    hidden_room_status: false,
    confidential_room_status: false,
    show_only_occupancy_match_status: false,
    baby_status: false,
    extra_bed_status: false,
    basic_room_status: false,
    upselling_available_status: true,
  });
  const [occupancy, setOccupancy] = useState([]);
  let params = useParams();
  const [showoccupancy, setShowoccupancy] = useState(false);
  // const [id, setId] = useState(params.type_id)
  const [formerror, setError] = useState({});
  const [hotelid, setHotelid] = useState(
    hotel_select ? hotel_select[0]?.value : ""
  );
  const [removeoccupancyid, setRemoveoccupancyid] = useState([]);
  const [bedtype, setBedtype] = useState([]);
  const [bed_rooms, setBed_rooms] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  let handleChangetogghotel = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setRoomdetails({ ...roomdetails, [name]: value });
  };
  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRoomdetails({ ...roomdetails, [name]: value });
  };

  let handleChangeshow = (e) => {
    let value = e.target.checked;
    setShowoccupancy(value);
  };

  const getValue =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setLanguage(data.data);
    } else {
      console.log("error");
    }
  },[]);

  // const getBoard = async (e) => {
  //   const res = await fetch(`${Config.apiurl}boards`, {
  //     method: "POST",
  //   });
  //   const data1 = await res.json();
  //   const data = data1.data;
  //   if (data1.status_code !== "200" || !data) {
  //     console.log("error");
  //   } else {
  //     setBoard(data);
  //   }
  // };
  
  const getBoard =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setBoard(data);
    }
  },[hotelid]);

  const getbedtype =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}bedtypes/list`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setBedtype(data);
    }
  },[]);

  const [inputFields, setInputFields] = useState([{ adult: [], child: [] }]);

  const addInputField = () => {
    setOccupancy([
      ...occupancy,
      {
        adult: "",
        child: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...occupancy];
    rows.splice(index, 1);
    setOccupancy(rows);
    const deletedRow = occupancy[index];

    removeoccupancyid.push(deletedRow.room_occupancy_id);
  };

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }

  const handleYesClick = () => {
    setRoomdetails((prevDetails) => ({ ...prevDetails, confirm_status: 1 }));
    tog_animationFlip(false);
  };

  const handleNoClick = () => {
    setRoomdetails((prevDetails) => ({ ...prevDetails, confirm_status: 0 }));
    tog_animationFlip(false);
  };

  useEffect(() => {
    getValue();
  }, [getValue]);

  useEffect(() => {
    getBoard();
  }, [getBoard]);

  useEffect(() => {
    getbedtype();
  }, [getbedtype]);

  const handleoccu = (e, i) => {
    let name = e.target.name;
    let value = e.target.value;

    let newval = [...occupancy];
    occupancy[i][name] = value;
    setOccupancy(newval);
  };

  let Save = async () => {
    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!roomdetails.room_title) {
      isFormValid = false;
      errors.room_title = "Room name is required!";
    }
    else if (!noSpecialCharRegex.test(roomdetails.room_title)) {
      isFormValid = false;
      errors.room_title = "Room name should not contain this special character";
    }
    else if (roomdetails.room_title.length > 50) {
      isFormValid = false;
      errors.room_title = `Room name cannot be more than 50 characters!`;
    }
    if (!roomdetails.room_inventory) {
      isFormValid = false;
      errors.room_inventory = "Room inventory is required!";
    }
    if (sortorder !== 0) {
      if (!sortorder) {
        isFormValid = false;
        errors.sortorder = "Sort order is required!";
      }
    }
    if (!roomdetails.no_of_rooms) {
      isFormValid = false;

      errors.no_of_rooms = "No of rooms is required!";
    } else {
      let array = [];
      for (let j = 0; j < roomdetails.no_of_rooms; j++) {
        const errors1 = {};
        if (!bed_rooms[j].bed_count) {
          isFormValid = false;
          errors1.bed_count = "Number of bed is required!";
        }
        if (!bed_rooms[j].bed_type) {
          isFormValid = false;
          errors1.bed_type = "Bed type is required!";
        } else if (bed_rooms[j].bed_type === "other") {
          if (!bed_rooms[j].other) {
            isFormValid = false;
            errors1.other = "Bed type is required!";
          }
        }
        if (!bed_rooms[j].bedsize) {
          isFormValid = false;
          errors1.bedsize = "Bed size is required!";
        }
        array.push(errors1);
      }
      errors.roomNo = array;
    }
    if (!roomdetails.min_no_adults) {
      isFormValid = false;

      errors.min_no_adults = "Minimum number of adult is required!";
    }
    if (!roomdetails.max_no_adults) {
      isFormValid = false;

      errors.max_no_adults = "Maximum number of adult is required!";
    }
    if (!roomdetails.max_no_child) {
      isFormValid = false;

    errors.max_no_child = "Maximum number of child is required!";
    }
    if (!roomdetails.max_child_age) {
      isFormValid = false;

    errors.max_child_age = "Maximum child age is required!";
    }
    if (
      roomdetails.max_no_child !== undefined &&
      roomdetails.max_no_child !== "" &&
      roomdetails.max_no_child !== null
    ) {
      if (roomdetails.max_no_child === 0) {
        console.log("");
      }
    } else {
      isFormValid = false;
      errors.max_child = "Maximum child age is required!";
    }

    if (
      roomdetails.max_child_age !== undefined &&
      roomdetails.max_child_age !== "" &&
      roomdetails.max_child_age !== null
    ) {
      if (roomdetails.max_child_age === 0) {
        console.log("");
      }
    } else {
      isFormValid = false;
      errors.max_child_age = "Maximum child age is required!";
    }

    if (roomdetails.extra_bed_status === true) {
      if (!roomdetails.base_occupancy) {
        isFormValid = false;

        errors.base_occupancy = "Base Occupancy required!";
      }
    }
    if (!roomdetails.room_area) {
      isFormValid = false;

      errors.room_area = "Room area is required!";
    }
    if (!roomdetails.is_active) {
      isFormValid = false;

    errors.is_active = "Status is required!";
    }
    if (selectedMulti.length === 0) {
      isFormValid = false;

      errors.selectedMulti = "Hotel board is required!";
    }

    if (!roomdetails.short_description) {
      errors.short_description = "Short description is required!";
    }
    if (!roomdetails.description) {
      errors.description = "Description is required!";
    }
    if (!roomdetails.en_marketing_title) {
      errors.en_marketing_title = "Marketing caption is required!";
    }
    else if (roomdetails.en_marketing_title.length > 100) {
      isFormValid = false;
      errors.en_marketing_title = `Marketing caption cannot be more than 100 characters!`;
    }

    setError(errors);

    if (isFormValid === true) {
      let occu = [];
      let newocc = [];
      for (let i = 0; i < occupancy.length; i++) {
        if (occupancy[i].room_occupancy_id) {
          let multiarray = {};
          multiarray.occ_id = occupancy[i].room_occupancy_id;
          multiarray.adult = occupancy[i].adult;
          multiarray.child = occupancy[i].child;
          occu.push(multiarray);
        } else {
          let multiarray = {};
          multiarray.adult = occupancy[i].adult;
          multiarray.child = occupancy[i].child;
          newocc.push(multiarray);
        }
      }

      let lang = [];
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].code !== "en") {
            let multiarray = {};
            multiarray.lang_code = selectedMulti1[i].code;
            multiarray.room_title = selectedMulti1[i].name;
            multiarray.marketing_title = selectedMulti1[i].caption;
            multiarray.short_description = selectedMulti1[i].short_description;
            multiarray.description = selectedMulti1[i].description;

            lang.push(multiarray);
          }
        }
      }

      let rooms = [];
      for (let i = 0; i < bed_rooms.length; i++) {
        let multiarray = {};
        multiarray.bed_count = bed_rooms[i].bed_count;
        multiarray.bedtype = bed_rooms[i].bed_type;
        multiarray.bedsize = bed_rooms[i].bedsize;

        rooms.push(multiarray);
      }

      let board = [];

      for (let i = 0; i < selectedMulti.length; i++) {
        board.push(selectedMulti[i].value);
      }
      let upsell_status;
      if (roomdetails.basic_room_status === true) {
        upsell_status = false;
      } else {
        upsell_status = roomdetails.upselling_available_status;
      }

      let confirmstatus;
      if (roomdetails.confirm_status) {
        confirmstatus = roomdetails.confirm_status;
      }

      const res = await fetch(`${Config.apiurl}hotel/room-create`, {
        method: "POST",
        body: JSON.stringify({
          hotel: selecthotelid,
          en_room_title: roomdetails.room_title,
          en_marketing_title: roomdetails.en_marketing_title,
          room_inventory: roomdetails.room_inventory,
          no_of_rooms: roomdetails.no_of_rooms,
          min_no_adults: roomdetails.min_no_adults,
          max_no_adults: roomdetails.max_no_adults,
          max_no_child: roomdetails.max_no_child,
          max_child_age: roomdetails.max_child_age,
          baby_status: roomdetails.baby_status,
          room_area: roomdetails.room_area,
          display_board: board,
          extra_bed_status: roomdetails.extra_bed_status === true ? 1 : 0,
          base_occupancy: roomdetails.base_occupancy,
          rooms: rooms,
          en_description: roomdetails.description,
          en_short_description: roomdetails.short_description,
          is_active: roomdetails.is_active,
          url_3d: roomdetails.url_3d,
          url_video: roomdetails.url_video,
          hide_from_dynamic_section: roomdetails.hide_from_dynamic_section,
          hidden_room_status: roomdetails.hidden_room_status,
          confidential_room_status: roomdetails.confidential_room_status,
          show_only_occupancy_match_status:
            roomdetails.show_only_occupancy_match_status,
          multi_lang: lang,
          basic_room_status: roomdetails.basic_room_status,
          upselling_available_status: upsell_status,
          confirm_status: confirmstatus,
          sort_order: sortorder,

          // occupancy:occu,
          // removed_occupancy:removeoccupancyid,
          // new_occupancy:newocc
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setTimeout(() => navigate("/rooms"), 3000);
        } else if (data.status_code === 403) {
          tog_animationFlip();
        } else if (data.reauth === true) {
          handleUnauthorized();
          Save();
        } else {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Save();
      } else {
        console.log("error");
      }
      // const data = await res.json();
      // if (data.status_code === "200") {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(false);
      //   setTimeout(() => navigate("/rooms"), 3000);
      // } else if (data.reauth === true) {
      //   handleUnauthorized();
      //   Save();
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(false);
      // }
    }
  };

  const addInputFieldRoom = (e) => {
    let count = e.target.value;
    let nam = e.target.name;
    const newInputFields = [];
    setRoomdetails({ ...roomdetails, [nam]: count });

    for (let i = 0; i < count; i++) {
      newInputFields.push({
        bed_count: "",
        bed_type: "",
        bedsize: "",
        bedtype: "",
        other: "",
      });
    }

    setBed_rooms(newInputFields);
  };

  function handleChangeesinner(i, e) {
    let val = e.target.value;
    let name = e.target.name;
    let newval = [...bed_rooms];

    bed_rooms[i][name] = val;
    setBed_rooms(newval);
  }

  function handleChangeesinnerbed(i, e) {
    let val = e.target.value;
    let name = e.target.name;

    let newval = [...bed_rooms];
    bed_rooms[i][name] = val;
    bed_rooms[i]["bedsize"] = "";
    setBed_rooms(newval);

    if (val !== "other") {
      getbedtypebasesize(i, val, name);
    }
  }

  const getbedtypebasesize = async (i, val, name) => {
    const res = await fetch(`${Config.apiurl}be/bedsize/${val}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      let newval = [...bed_rooms];
      bed_rooms[i]["bedsize"] = data;
      setBed_rooms(newval);
    }
  };

  // useEffect(() => {
  //   setId(hotel_select?hotel_select[0].value:"");
  // }, [hotel_select]);

  return (
    <div className="main-content">
      <Roomheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Edit Room Details</h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link className="btn  db-other-button" to="/rooms">
                            Room Details
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button className="btn db-save-button" onClick={Save}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {tab === "EN" ? (
                            <>
                              <div className="row g-3">
                                <div className="col-lg-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Room Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="room_title"
                                      value={roomdetails.room_title}
                                      onChange={handlechange}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.room_title}
                                  </label>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Marketing Caption
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="en_marketing_title"
                                      value={roomdetails.en_marketing_title}
                                      onChange={handlechange}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.en_marketing_title}
                                  </label>
                                </div>
                                <div className="col-lg-2">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      Inventory
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="number"
                                      className="form-control"
                                      name="room_inventory"
                                      value={roomdetails.room_inventory}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.room_inventory}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      No.of Rooms
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="number"
                                      className="form-control"
                                      name="no_of_rooms"
                                      value={roomdetails.no_of_rooms}
                                      onChange={addInputFieldRoom}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.no_of_rooms}
                                    </label>
                                  </div>
                                </div>
                                {roomdetails.no_of_rooms > 0
                                  ? bed_rooms.map((ele, ind) => {
                                      return (
                                        <>
                                          <span class="roomtype-info" key={ind}>
                                            <span class="be-form-line-separation">
                                              <span class="room-sec-title">
                                                Room {ind + 1}
                                              </span>
                                            </span>
                                            <div className="row g-3">
                                              <div className="col-lg-3">
                                                <div className="input-group">
                                                  <label
                                                    for="firstNameinput"
                                                    className="input-group-text input-joint-color"
                                                  >
                                                    {" "}
                                                    Number of beds{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="number"
                                                    class="form-control"
                                                    placeholder=""
                                                    value={ele.bed_count}
                                                    onKeyDown={(e) =>
                                                      symbolsArr.includes(
                                                        e.key
                                                      ) && e.preventDefault()
                                                    }
                                                    min={0}
                                                    onChange={(e) =>
                                                      handleChangeesinner(
                                                        ind,
                                                        e
                                                      )
                                                    }
                                                    name="bed_count"
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                  />

                                                  {formerror.roomNo
                                                    ? formerror.roomNo.map(
                                                        (err1, inde) => {
                                                          return (
                                                            <>
                                                              {ind === inde &&
                                                              err1 &&
                                                              err1.bed_count ? (
                                                                <label
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  {
                                                                    err1.bed_count
                                                                  }
                                                                </label>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    : ""}
                                                </div>
                                              </div>

                                              {/* <span class="be_form_room_info_label_25">
                <label for="firstNameinput" class="form-label be_form_label">
                    Number of beds per Room 
                    <span class="form-validation-required">*</span>
                </label>
                    <input type="number" class="form-control" placeholder="" value={ele.bed_count}
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                        min={0}

                    onChange={(e)=>handleChangeesinner(ind,e)}
                    name="bed_count"/>

            
                </span> */}

                                              <div className="col-lg-3">
                                                <div className="input-group">
                                                  <label
                                                    for="firstNameinput"
                                                    className="input-group-text input-joint-color"
                                                  >
                                                    BedType
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </label>
                                                  <select
                                                    class="form-select"
                                                    id="bedtype_select"
                                                    value={ele.bed_type}
                                                    onChange={(e) =>
                                                      handleChangeesinnerbed(
                                                        ind,
                                                        e
                                                      )
                                                    }
                                                    name="bed_type"
                                                  >
                                                    <option value="">
                                                      Choose
                                                    </option>
                                                    {bedtype.map((bed, j) => {
                                                      return (
                                                        <option
                                                          key={j}
                                                          value={bed.value}
                                                        >
                                                          {bed.label}
                                                        </option>
                                                      );
                                                    })}
                                                    {/* <option value="other">Other</option> */}
                                                    {formerror.roomNo
                                                      ? formerror.roomNo.map(
                                                          (err1, inde) => {
                                                            return (
                                                              <>
                                                                {ind === inde &&
                                                                err1 &&
                                                                err1.bed_type ? (
                                                                  <label
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    {
                                                                      err1.bed_type
                                                                    }
                                                                  </label>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            );
                                                          }
                                                        )
                                                      : ""}
                                                  </select>
                                                </div>
                                              </div>

                                              <div className="col-lg-3">
                                                <div className="input-group">
                                                  <label
                                                    for="firstNameinput"
                                                    className="input-group-text input-joint-color"
                                                  >
                                                    Bed Size (Cm)
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder=""
                                                    value={ele.bedsize}
                                                    onChange={(e) =>
                                                      handleChangeesinner(
                                                        ind,
                                                        e
                                                      )
                                                    }
                                                    name="bedsize"
                                                  />
                                                  {formerror.roomNo
                                                    ? formerror.roomNo.map(
                                                        (err1, inde) => {
                                                          return (
                                                            <>
                                                              {ind === inde &&
                                                              err1 &&
                                                              err1.bedsize ? (
                                                                <label
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  {err1.bedsize}
                                                                </label>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    : ""}
                                                </div>
                                              </div>
                                            </div>
                                          </span>

                                          <span class="be-form-line-separation"></span>
                                        </>
                                      );
                                    })
                                  : ""}

                                <div className="row g-3">
                                  <div className="col-lg-3">
                                    <ul className="rate-extra-info">
                                      <li className="label-li">
                                        <label
                                          className="form-label"
                                          style={{ marginLeft: "4px" }}
                                        >
                                          Baby Cot Status
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                      </li>
                                      <li className="text-li">
                                        <span
                                          className="form-check form-switch form-check-inline condition-active-status"
                                          dir="ltr"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="inlineswitch5"
                                            name="baby_status"
                                            value={roomdetails.baby_status}
                                            checked={
                                              roomdetails.baby_status === true
                                                ? true
                                                : false
                                            }
                                            onChange={handleChangetogghotel}
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                          />
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Min.No.of Adult
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="number"
                                        className="form-control"
                                        name="min_no_adults"
                                        value={roomdetails.min_no_adults}
                                        onChange={handlechange}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.min_no_adults}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.No.of Adult
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="number"
                                        className="form-control"
                                        name="max_no_adults"
                                        value={roomdetails.max_no_adults}
                                        onChange={handlechange}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_no_adults}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.No.of Child
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="number"
                                        className="form-control"
                                        name="max_no_child"
                                        value={roomdetails.max_no_child}
                                        onChange={handlechange}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_no_child}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.Age of Child
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="number"
                                        className="form-control"
                                        name="max_child_age"
                                        value={roomdetails.max_child_age}
                                        onChange={handlechange}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_child_age}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-3">
                                <div className="col-lg-3">
                                  <ul className="rate-extra-info">
                                    <li className="label-li">
                                      <label
                                        className="form-label"
                                        style={{ marginLeft: "4px" }}
                                      >
                                        Extra Bed Status
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                    </li>
                                    <li className="text-li">
                                      <span
                                        className="form-check form-switch form-check-inline condition-active-status"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          name="extra_bed_status"
                                          value={roomdetails.extra_bed_status}
                                          checked={
                                            roomdetails.extra_bed_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                {roomdetails.extra_bed_status === true ? (
                                  <div className="col-md-3">
                                    <div className="col-lg-12">
                                      <div className="input-group">
                                        <label
                                          for="firstNameinput"
                                          className="input-group-text input-joint-color"
                                          style={{ width: "142px" }}
                                        >
                                          Base Occupancy
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                          <span
                                            className="info-tag"
                                            style={{ backgroundColor: "black" }}
                                          >
                                            i
                                            <span class="tooltiptext">
                                              Maximum no. of adult without extra
                                              bed
                                            </span>
                                          </span>
                                        </label>
                                        <input
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          type="number"
                                          className="form-control"
                                          name="base_occupancy"
                                          value={roomdetails.base_occupancy}
                                          onChange={handlechange}
                                        />
                                        <label style={{ color: "red" }}>
                                          {formerror.base_occupancy}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      Room Area
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="text"
                                      className="form-control"
                                      name="room_area"
                                      value={roomdetails.room_area}
                                      onChange={handlechange}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                      {formerror.room_area}
                                    </label>
                                </div>

                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Sort Order{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="number"
                                      className="sort-order-field"
                                      value={sortorder}
                                      onChange={(e) =>
                                        setSortorder(e.target.value)
                                      }
                                      min={0}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                      style={{ width: "65px" }}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.sortorder}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-3">
                                <div className="col-lg-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ height: "40px" }}
                                    >
                                      Hotel Boards
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-8">
                                      <MultiSelect
                                        options={board}
                                        value={selectedMulti}
                                        onChange={(val) => {
                                          setselectedMulti(val);
                                        }}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        //closeOnChangedValue={true}
                                        //
                                        aria-expanded="true"
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                        {formerror.selectedMulti}
                                      </label>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={roomdetails.is_active}
                                      onChange={handlechange}
                                      name="is_active"
                                    >
                                      <option value="">Choose...</option>
                                      <option
                                        {...(roomdetails.is_active === true
                                          ? "selected"
                                          : "")}
                                        value={true}
                                      >
                                        Active
                                      </option>
                                      <option
                                        {...(roomdetails.is_active === false
                                          ? "selected"
                                          : "")}
                                        value={false}
                                      >
                                        Inactive
                                      </option>
                                    </select>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.is_active}
                                  </label>
                                </div>

                                <div className="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      3D URL
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="url_3d"
                                        value={roomdetails.url_3d}
                                        onChange={handlechange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Video URL
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="url_video"
                                        value={roomdetails.url_video}
                                        onChange={handlechange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <label
                                    className=" "
                                    id="inputGroup-sizing-default"
                                  >
                                    Short description
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>

                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    value={roomdetails.short_description}
                                    name="short_description"
                                    onChange={handlechange}
                                  ></textarea>
                                  <label style={{ color: "red" }}>
                                    {formerror.short_description}
                                  </label>
                                </div>
                                <div className="col-lg-6">
                                  <label
                                    className=" "
                                    id="inputGroup-sizing-default"
                                  >
                                    Description
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>

                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    value={roomdetails.description}
                                    name="description"
                                    onChange={handlechange}
                                  ></textarea>
                                  <label style={{ color: "red" }}>
                                    {formerror.description}
                                  </label>
                                </div>
                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="basic_room_status"
                                    value={roomdetails.basic_room_status}
                                    checked={
                                      roomdetails.basic_room_status === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">Basic Room</span>
                                  <span style={{ paddingLeft: "3%" }}>
                                    {roomdetails.basic_room_status === true ? (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck1"
                                          name="upselling_available_status"
                                          value={
                                            roomdetails.upselling_available_status
                                          }
                                          checked={
                                            roomdetails.upselling_available_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                          disabled
                                        />
                                        <span
                                          className="room-check"
                                          style={{ color: "#d2d2d2" }}
                                        >
                                          Upselling
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck1"
                                          name="upselling_available_status"
                                          value={
                                            roomdetails.upselling_available_status
                                          }
                                          checked={
                                            roomdetails.upselling_available_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                        />
                                        <span className="room-check">
                                          Upselling
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="hide_from_dynamic_section"
                                    value={
                                      roomdetails.hide_from_dynamic_section
                                    }
                                    checked={
                                      roomdetails.hide_from_dynamic_section ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Hide it at the dynamic website section
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="hidden_room_status"
                                    value={roomdetails.hidden_room_status}
                                    checked={
                                      roomdetails.hidden_room_status === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Hidden room for Metas
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="confidential_room_status"
                                    value={roomdetails.confidential_room_status}
                                    checked={
                                      roomdetails.confidential_room_status ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Confidential: Promotional code required
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="show_only_occupancy_match_status"
                                    value={
                                      roomdetails.show_only_occupancy_match_status
                                    }
                                    checked={
                                      roomdetails.show_only_occupancy_match_status ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Only show when the requested occupancy
                                    matches exactly with an inventory occupancy
                                  </span>
                                </div>

                                {/* <div className="col-md-12">
                                     <input className="form-check-input" type="checkbox" id="formCheck1" name="occupancy" value={showoccupancy}
                                     checked={showoccupancy=== true ? true : false} onChange={handleChangeshow}
                                     />
                                       <span className="room-check">Show Occupancy</span>
                                       <div className={showoccupancy===true?"row":"row d-none"} style={{marginLeft:"20px"}}>
                                       

                                       

                                              <div  className="row mb-2 roomedit_rows" >
                                                <div className="addmore_select-label_roomedit">
                                                No.of Adult
                                                <span className="form-validation-required">*</span>
                                                </div>
                                                <div className=" addmore_select-label_roomedit">
                                                No.of Child 
                                                <span className="form-validation-required">*</span>
                                                </div>
                                            </div>
                                               {occupancy.map((data,index)=>{
                                      return (
                                        <div key={index}>
                                       
                                            <div  className="row mb-2 roomedit_rows" key={index}>
                                                <div className="col-md-1 mr-2">
                                                <input type="number" min={0} className="form-control room_edit_in" name="adult" value={data.adult}  onChange={(e)=>{handleoccu(e,index)}}/>
                                                </div>
                                                <div className="col-md-1">
                                                <input type="number" min={0} className="form-control room_edit_in" name="child" value={data.child}  onChange={(e)=>{handleoccu(e,index)}}/>
                                                </div>
                                                <div className="col-md-1 padding_01">
                                                  <span className={index===occupancy.length-1?"extrarate-room-link":"extrarate-room-link d-none"}>
                                                    <Link className="extra-rate-add" to="#"  onClick={addInputField}>+</Link>
                                                  </span>
                                                  <Link className={index===0?"remove_more d-none":"remove_more"} to="#" onClick={(e)=>removeInputFields(index)}>X</Link><br></br>                                       
                                                </div>
                                            </div>
                                                
                                        </div>
                                                  );
                                                  })}
                                              </div>
                                    
                                     </div> */}
                              </div>
                              <div className="padding-10"></div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "130px" }}
                                  >
                                    Choose Language
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>

                                  <div className="col-md-7">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti1}
                                      onChange={(val) => setselectedMulti1(val)}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appendmore"></div>
                              {selectedMulti1.length > 0
                                ? selectedMulti1.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Room Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={element.name}
                                                name="name"
                                                onChange={(e) =>
                                                  setselectedMulti1(
                                                    (prevsetselectedMulti1) =>
                                                      prevsetselectedMulti1.map(
                                                        (item, i) =>
                                                          i === index
                                                            ? {
                                                                ...item,
                                                                name: e.target
                                                                  .value,
                                                              }
                                                            : item
                                                      )
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Marketing Caption
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={element.caption}
                                                name="caption"
                                                onChange={(e) =>
                                                  setselectedMulti1(
                                                    (prevsetselectedMulti1) =>
                                                      prevsetselectedMulti1.map(
                                                        (item, i) =>
                                                          i === index
                                                            ? {
                                                                ...item,
                                                                caption:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : item
                                                      )
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mt-3">
                                            <label
                                              className=" "
                                              id="inputGroup-sizing-default"
                                            >
                                              Short Description
                                            </label>

                                            <textarea
                                              className="form-control"
                                              rows="2"
                                              value={element.short_description}
                                              name="short_description"
                                              onChange={(e) =>
                                                setselectedMulti1(
                                                  (prevsetselectedMulti1) =>
                                                    prevsetselectedMulti1.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              short_description:
                                                                e.target.value,
                                                            }
                                                          : item
                                                    )
                                                )
                                              }
                                            ></textarea>
                                          </div>
                                          <div className="col-lg-6 mt-3">
                                            <label
                                              className=" "
                                              id="inputGroup-sizing-default"
                                            >
                                              Description
                                            </label>

                                            <textarea
                                              className="form-control"
                                              rows="2"
                                              value={element.description}
                                              name="description"
                                              onChange={(e) =>
                                                setselectedMulti1(
                                                  (prevsetselectedMulti1) =>
                                                    prevsetselectedMulti1.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              description:
                                                                e.target.value,
                                                            }
                                                          : item
                                                    )
                                                )
                                              }
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ height: "150px", width: "540px" }}
        >
          <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
            Basic room is already exist, Do you want to set this room as basic
            room?
          </div>
          <button className="board_create_click" style={{ marginTop: "20px" }}>
            <Link to="#" onClick={() => handleYesClick()}>
              {" "}
              Yes{" "}
            </Link>
          </button>
          <button
            className="board_create_click"
            style={{ marginTop: "20px", marginLeft: "2%" }}
          >
            <Link to="#" onClick={() => handleNoClick()}>
              {" "}
              No{" "}
            </Link>
          </button>
        </ModalBody>
      </Modal>
      <Footer />

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Roomcreate;
