import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Roomheader from "../../Common/Roomheader";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import Footer from "../../Common/Footer";
import Loading from "../../Loading";

function Roomdetails() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  console.log(hotel_selectid);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [selehotel, setSelechotel] = useState(0);
  const [showFullData, setShowFullData] = useState([]);
  const [loader, setLoader] = useState(false);

  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
  }

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleloyaltyToggle = (i, index, isChecked, name) => {
    const updatedValues = [...list];
    updatedValues[i]["rooms"][index][name] = isChecked;
    setList(updatedValues);
    getBasicStatus(i, index);
  };

  const handleloyaltyToggleUpsell = (i, index, isChecked, name) => {
    const updatedValues = [...list];
    updatedValues[i]["rooms"][index][name] = isChecked;
    setList(updatedValues);
    getUpsellStatus(i, index);
  };

  const handleSortOrderChange = (e, i, index) => {
    const updatedBoard = [...list];
    updatedBoard[i].rooms[index].sort_order = parseInt(e.target.value, 10);
    setList(updatedBoard);
    sortOrderChange(i, index);
  };

  const sortOrderChange = async (i, index) => {
    const res = await fetch(`${Config.apiurl}hotel/rooms-order/update`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: list[i].hotel_id,
        room_id: list[i]["rooms"][index].room_id,
        order: list[i]["rooms"][index].sort_order,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("success");
        fetchPost();
      } else if (data.reauth === true) {
        handleUnauthorized();
        sortOrderChange();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      sortOrderChange();
    } else {
      console.log("error");
    }
  };

  const [confirm, setConfirm] = useState("");
  const [loop, setLoop] = useState("");
  const [loopindex, setLoopindex] = useState("");

  const [modal_animationFlipbasic, setmodal_animationFlipbasic] =
    useState(false);
  function tog_animationFlipbasic(i, index) {
    console.log(i);
    console.log(index);
    setLoop(i);
    setLoopindex(index);
    setmodal_animationFlipbasic(!modal_animationFlipbasic);
  }

  const handleYesClick = () => {
    setConfirm(1);
    getBasicStatus(loop, loopindex, 1);
    tog_animationFlipbasic(false);
  };

  const handleNoClick = () => {
    setConfirm(0);
    getBasicStatus(loop, loopindex, 0);
    tog_animationFlipbasic(false);
  };

  const getBasicStatus = async (i, index, yesstatus) => {
    let newConfirm;
    if (yesstatus !== undefined) {
      newConfirm = yesstatus;
    }

    const res = await fetch(`${Config.apiurl}hotel/basic-room/status/update`, {
      method: "POST",
      body: JSON.stringify({
        hotel: list[i].hotel_id,
        room_id: list[i]["rooms"][index].room_id,
        basic_room_status: list[i]["rooms"][index].basic_room,
        confirm_status: newConfirm,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("success");
        fetchPost();
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBasicStatus();
      } else if (data.status_code === 403) {
        tog_animationFlipbasic(i, index);
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBasicStatus();
    } else {
      console.log("error");
    }
  };

  const getUpsellStatus = async (i, index) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room/upselling/status/update`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel: list[i].hotel_id,
          room_id: list[i]["rooms"][index].room_id,
          upselling_available_status:
            list[i]["rooms"][index].upselling_available,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("success");
        fetchPost();
      } else if (data.reauth === true) {
        handleUnauthorized();
        getUpsellStatus();
      } else if (data.status_code === 403) {
        tog_animationFlipbasic(i, index);
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getUpsellStatus();
    } else {
      console.log("error");
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotels/rooms?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.data) {
        return data.data;
      } else if (data?.reauth === true) {
        handleUnauthorized();
        fetchComments(currentPage);
      }
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost =useCallback(async () => {
    const response = await fetch(`${Config.apiurl}hotels/rooms?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data.data) {
        setList(data.data);
        setPaginate(data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      }
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  useEffect(() => {
    fetchPost();
  }, [id,fetchPost]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list]);

  return (
    <>
      <div id="layout-wrapper">
        <Roomheader />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                    <Link
                      type="button"
                      to={`/room-create/${list[selehotel]?.hotel_id}`}
                      className="btn db-save-button "
                    >
                      Create
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card" style={{ marginTop: "5px" }}>
                      <div className="card-body">
                        {loader===false?
                        <Loading/>
                        :
                        <div>
                          {/* <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                          </ul> */}
                          <div className="">
                            {list.map((li, i) => {
                              return (
                                <>
                                  <div
                                    className={
                                      list.length > 1
                                        ? "row hotel_single_list"
                                        : "row"
                                    }
                                    key={i}
                                  >
                                    {list.length > 1 ? (
                                      <div className="hotel_service_display hotel_service_display_12">
                                        <div className="hotel_service_display_14">
                                          <input
                                            type="radio"
                                            id="radio-one"
                                            className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                            value={true}
                                            checked={
                                              i === selehotel ? true : false
                                            }
                                            onChange={(e) => setSelechotel(i)}
                                            // onChange={loyaltycommontogg}
                                            style={{
                                              position: "relative",
                                              top: "8px",
                                            }}
                                          />

                                          <div className="hk_service_all_hotel">
                                            <span className="">
                                              {" "}
                                              {pageindex === 1
                                                ? i + 1
                                                : (pageindex - 1) * 10 + i + 1}
                                              .
                                            </span>
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {li.hotel_name}
                                            </span>
                                          </div>
                                        </div>
                                        {showFullData[i]?.hotel === true ? (
                                          <span
                                            className="db-modify_toggle_btn"
                                            onClick={() =>
                                              handleDropdownClickhotel(i)
                                            }
                                          >
                                            <span
                                              className="bx bxs-chevron-up"
                                              style={{ fontSize: "20px" }}
                                            ></span>
                                          </span>
                                        ) : (
                                          <span
                                            className="db-modify_toggle_btn"
                                            onClick={() =>
                                              handleDropdownClickhotel(i)
                                            }
                                          >
                                            <span
                                              className="bx bxs-chevron-down"
                                              style={{ fontSize: "20px" }}
                                            ></span>
                                          </span>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <table
                                      id="fixed-header orderTable"
                                      className={`table table-nowrap align-middle ${
                                        showFullData[i]?.hotel === true
                                          ? ""
                                          : "d-none"
                                      }`}
                                    >
                                      <thead
                                        className="text-muted table-light"
                                        key={i}
                                      >
                                        <tr className="text-uppercase ">
                                          <th scope="col"></th>
                                          <th className="" data-sort="id">
                                            Sl No
                                          </th>
                                          <th
                                            className=""
                                            data-sort="customer_name"
                                          >
                                            Room Name
                                          </th>
                                          <th>Sort Order</th>
                                          <th
                                            className=""
                                            data-sort="customer_name"
                                          >
                                            Base Room Category
                                          </th>
                                          <th
                                            className=""
                                            data-sort="customer_name"
                                          >
                                            Upselling Status
                                          </th>

                                          <th className="" data-sort="status">
                                            Status
                                          </th>
                                          <th className="" data-sort="city">
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                        {li.rooms.map((user, index) => {
                                          return (
                                            <tr key={index}>
                                              <td></td>
                                              <td className="id">
                                                {pageindex === 1
                                                  ? index + 1
                                                  : (pageindex - 1) * 10 +
                                                    index +
                                                    1}
                                              </td>
                                              <td className="customer_name">
                                                {user.room_name}
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  className="sort-order-field"
                                                  value={user.sort_order}
                                                  onChange={(e) =>
                                                    handleSortOrderChange(
                                                      e,
                                                      i,
                                                      index
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td className="">
                                                <div
                                                  className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                                  dir="ltr"
                                                  style={{ marginLeft: "16%" }}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input offer_checkbox1"
                                                    id="inlineswitch5"
                                                    value={user.basic_room}
                                                    checked={
                                                      user.basic_room === true
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) =>
                                                      handleloyaltyToggle(
                                                        i,
                                                        index,
                                                        e.target.checked,
                                                        "basic_room"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                              <td className="">
                                                <div
                                                  className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                                  dir="ltr"
                                                  style={{ marginLeft: "16%" }}
                                                >
                                                  {user.basic_room === true ? (
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input offer_checkbox1"
                                                      id="inlineswitch5"
                                                      value={
                                                        user.upselling_available
                                                      }
                                                      checked={
                                                        user.upselling_available ===
                                                        true
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        handleloyaltyToggle(
                                                          i,
                                                          index,
                                                          e.target.checked,
                                                          "upselling_available"
                                                        )
                                                      }
                                                      disabled
                                                    />
                                                  ) : (
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input offer_checkbox1"
                                                      id="inlineswitch5"
                                                      value={
                                                        user.upselling_available
                                                      }
                                                      checked={
                                                        user.upselling_available ===
                                                        true
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        handleloyaltyToggleUpsell(
                                                          i,
                                                          index,
                                                          e.target.checked,
                                                          "upselling_available"
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </td>
                                              <td className="status">
                                                {user.status === false ? (
                                                  <span class="badge badge-soft-danger text-uppercase">
                                                    {" "}
                                                    INACTIVE
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-soft-success text-uppercase">
                                                    ACTIVE
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                <ul className="list-inline hstack gap-2 mb-1">
                                                  <li
                                                    className="list-inline-item edit"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-placement="top"
                                                    title="Edit"
                                                  >
                                                    <Link
                                                      to={`/room-edit/${user.room_id}/${li.hotel_id}`}
                                                      state={{}}
                                                      data-bs-toggle="modal"
                                                      className="text-primary d-inline-block edit-item-btn"
                                                    >
                                                      <i className="ri-pencil-fill fs-16"></i>
                                                    </Link>
                                                  </li>
                                                  {/* <li
                                                    className="list-inline-item"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-placement="top"
                                                    title="Remove"
                                                  >
                                                    <Link
                                                      className="text-danger d-inline-block remove-item-btn"
                                                      data-bs-toggle="modal"
                                                      to="#"
                                                      onClick={(e) =>
                                                        tog_animationFlip()
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-5-fill fs-16"></i>
                                                    </Link>
                                                  </li> */}
                                                </ul>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              );
                            })}

                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlipbasic}
        toggle={() => {
          tog_animationFlipbasic();
        }}
        modalClassName="flip"
        centered
        style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ height: "150px", width: "540px" }}
        >
          <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
            Basic room is already exist, Do you want to set this room as basic
            room?
          </div>
          <button className="board_create_click" style={{ marginTop: "20px" }}>
            <Link to="#" onClick={() => handleYesClick()}>
              {" "}
              Yes{" "}
            </Link>
          </button>
          <button
            className="board_create_click"
            style={{ marginTop: "20px", marginLeft: "2%" }}
          >
            <Link to="#" onClick={() => handleNoClick()}>
              {" "}
              No{" "}
            </Link>
          </button>
        </ModalBody>
      </Modal>
      <Footer />
    </>
  );
}

export default Roomdetails;
