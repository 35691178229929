import React from 'react'
import Footer from "./Footer"
function Thanks() {
  return (
    <div className='' style={{height:"100vh",background:"#f3f6f9"}}>
       <div className="thanks_head">
            <div className="thanks_section">
              <img src="/images/logo-white.png" className="img-fluid" alt="" />
            </div>
        </div>
        <div className='thanks_section_img'>
        <img src="/images/thanksimg1.png" alt="" />
        </div>
        <div className="b_widget_thanks-area_container">
         
        <div className="b_widget_thanks-area">
          <div className="b_widget_thanks_head_image">
            <img src="/images/correct.png" alt="" />
          </div>
          <div className="b_widget_thanks_head">Thank you for completing this form</div>
          <div className="b_widget_thanks_content">
          We've received your request and are currently working on it. Rest assured, we'll notify you as soon as it's complete. Should you have any questions or special requests in the meantime, please feel free to reach out to us.
          </div>
          <div className="need_help_div">
                   
                    <span className="reserve2_need_help_mail">
                      <div>
                        <span className="phone_div">Contact</span>
                        <span>
                          <img
                            className="reserve2_icon"
                            src="/images/icon/call.png"
                            alt=""
                          />
                        </span>
                        <span
                          className="phone_div"
                          style={{ marginLeft: "10px" }}
                        >
                          00971503573532
                        </span>
                      </div>
                      <div>
                        <span className="phone_div">Email us</span>
                        <span>
                          <img
                            className="reserve2_icon"
                            src="/images/icon/email.png"
                            alt=""
                          />
                        </span>
                        <span
                          className="phone_div"
                          style={{ marginLeft: "10px" }}
                        >
                          support@hoteldirectrate.com
                        </span>
                      </div>
                    </span>
                  </div>
        </div>
      </div>
            {/* <div class="login-main-container" style={{marginTop:"100px"}}>
                <div class="thankyou-wrapper">
                    <h1><img src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png" alt="thanks" /></h1>
                      <p>for filling the form </p>

                </div>
            </div> */}

            <Footer/>
    </div>
     
  )
}

export default Thanks
