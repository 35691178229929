import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

function Editmodal({
  pagedata,
  modal_backdrop,
  tog_backdrop,
  pageid,
  UpdateData,
  status,
  setStatus,
  setPagedata,
  setPageslu,
  pageslu,
}) {
  return (
    <Modal
      isOpen={modal_backdrop}
      toggle={() => {
        tog_backdrop();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ModalHeader
        className="modal-title modal-header bg-light p-3"
        id="staticBackdropLabel"
        toggle={() => {
          tog_backdrop();
        }}
      ></ModalHeader>
      <ModalBody className="">
        <div className="mt-4">
          <label for="ordername" className="form-label">
            Page Name
          </label>
          <input
            type="text"
            className="form-control"
            value={pagedata}
            id="ordername"
            onChange={(e) => setPagedata(e.target.value)}
          />

          <label for="orderId" className="form-label">
            Page Slug
          </label>
          <input
            type="text"
            className="form-control"
            value={pageslu}
            id="orderId"
            onChange={(e) => setPageslu(e.target.value)}
          />
          <label for="orderstatus" className="form-label mt-2">
            Status
          </label>
          <select
            className="form-control "
            name="status"
            value={status}
             id="orderstatus"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option>Choose...</option>
            <option name="status" value={1}>
              Active
            </option>
            <option name="status" value={0}>
              InActive
            </option>
          </select>

          <div className="modal-footer p-3">
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  tog_backdrop();
                }}
              >
                Close{" "}
              </button>
              {/* <!-- <button type="submit" className="btn btn-success" id="add-btn">Save</button> --> */}
              <button
                type="button"
                className="btn db-save-single-button"
                id="edit-btn"
                onClick={() => UpdateData(pageid)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default Editmodal;
