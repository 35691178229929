import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Common/Footer";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Config from "../../Config";
import axios from "axios";
import DeleteModal from "../../DeleteModal";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Nodatafound from "../../Common/Nodatafound";
import Loading from "../../Loading";

function Pagecontentlist() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let {slug, page_id, page_name } = useParams();
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  const getList =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}page-contents/${slug}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if(data.data.length===0){
          setList([]);
      
        }
        else{
          setList(data.data?.en);
          setLoader(true);
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getList();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getList();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,slug]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Page Content</h4>
                </div>
              </div>
            </div>

            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex res-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Page Contents
                      </h4>
                      <Link className="btn  db-other-button" to="/pages" style={{height:"32px",padding:"5px 15px"}}>
                          Page List
                        </Link>
                      <div className="flex-shrink-0 d-flex justify-content-between">
                      
                        <Link
                          type="button"
                          to={`/page-content-edit/${slug}/${page_id}/${page_name}`}
                          className="btn db-save-button "
                        >
                          Edit
                        </Link>
                        <Link
                          type="button"
                          to={`/page-content/${slug}/${page_id}/${page_name}`}
                          className="btn db-save-button "
                        >
                          Create
                        </Link>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          {loader===false?
                          <Loading/>
                          :
                          <>
                          {list.length===0?
                           <Nodatafound/>
                           :
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th></th>
                                <th className="" data-sort="">
                                  Slug
                                </th>
                                <th className="" data-sort="">
                                  Text
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                             
                              {Object.entries(list)
                                .filter(
                                  ([key]) =>
                                    key !== "page_slug" && key !== "lang_code"
                                )
                                .map(([key, value], index) => (
                                  <tr key={index}>
                                    <td className="id"></td>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                            }
                          </>
                           }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Pagecontentlist;
