import React, { useCallback, useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate } from "react-router-dom";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import Select from "react-select";
import { UserContext } from "../../Contexts/UserContext";

const SingleOptions = [
  { value: "homepage", label: "Home Page" },
  { value: "reservation", label: "Reservation" },
  { value: "other", label: "Other" },
];
const SingleOptionsexist = [
  { value: "homepage", label: "Home Page" },
  { value: "reservation", label: "Reservation" },
  { value: "other", label: "Other" },
];
const usageoption = [
  { value: "limited", label: "Limitted" },
  { value: "unlimited", label: "Unlimitted" },
  { value: "person_limit", label: "Person Limit" },
];

function Poststayemail() {

  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let navigate = useNavigate();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState({ expiry_status: true });
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [rates, setRates] = useState([]);
  const [selectedRates, setSelectedRates] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [Error, setError] = useState({});
  const [tab2, setTab2] = useState("exists");
  const [newLang, setNewLang] = useState("");
  const [multi, setMulti] = useState([]);
  const [isMatchingLanguagenew, setisMatchingLanguagenew] = useState("");
  const [language, setLanguage] = useState([]);
  const [existcode, setExistcode] = useState([]);
  const [selectcode, setSelectCode] = useState([]);
  const [selectcodenew, setSelectCodenew] = useState([]);
  const [selectedOffersnew, setSelectedOffersnew] = useState([]);
  const [selectedRoomsnew, setSelectedRoomsnew] = useState([]);
  const [langall, setLangall] = useState([]);
  const [destinationurl, setDestinationurl] = useState("");
  const [inputEnDestination, setInputEnDestination] = useState("");
  const [newLangexist, setNewLangexist] = useState("");
  const [multiexist, setMultiexist] = useState([]);
  const [isMatchingLanguagenewexist, setisMatchingLanguagenewexist] =
    useState("");
  const [langallexist, setLangallexist] = useState([]);
  const [destinationurlexist, setDestinationurlexist] = useState("");
  const [inputEnDestinationexist, setInputEnDestinationexist] = useState("");
  const [selectedusage, setSelectedusage] = useState([]);
  const [selectedusagenew, setSelectedusagenew] = useState([]);
  const [newOffer, setNewOffer] = useState([]);

  const handleChangeExpiration = (name, value) => {
    setList((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };

  const getlanguage =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlanguage();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlanguage();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getOffer =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offers/confidential`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setOffers(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getOffer();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getOffer();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  // const newOffer = offers.map((offer) => ({
  //   label: offer.title,
  //   value: offer.offer_id,
  // }));

  useEffect(() => {
    for (let i = 0; i < offers.length; i++) {
      newOffer.push({
        label: offers[i].title,
        value: offers[i].offer_id,
      });
    }
  }, [offers,newOffer]);

  const getRoom =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}confidential-rooms`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setRooms(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRoom();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRoom();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  const getActiveCode =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/private-sale/promo-codes`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setExistcode(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getActiveCode();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getActiveCode();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  const getData =useCallback(async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/post-stay/view`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if (data.data.length !== 0) {
          setList(data.data.post_stay);
          setSelectedusagenew(data.data.post_stay.usage);
          setSelectCodenew(data.data.promocode);
          setSelectedOffersnew(data.data.post_stay?.confidential_offers);
          setSelectedRoomsnew(data.data.post_stay?.confidential_rooms);
          setTab2(data.data.post_stay.type);
          if (list.type === "autogenerate") {
            setLangall(data.data?.destination_url);
            if (data.data?.destination_url) {
              setMulti(data.data?.destination_url);
            }
          }
          if (list.type === "exists") {
            setLangallexist(data.data?.destination_url);
            if (data.data?.destination_url) {
              setMultiexist(data.data?.destination_url);
            }
          }
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id,list.type]);

  const handleLanguageChangenew = (selectedOption) => {
    setNewLang(selectedOption);
    const updatedLanguages = [...multi];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
        updatedLanguages.push({
          lang_code: selectedOption,
          destination_url: "",
        });
      }
    } else {
      updatedLanguages.push({ lang_code: selectedOption, destination_url: "" });
    }
    setMulti(updatedLanguages);
  };

  const handleloyallangChange = (Value) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["destination_url"] = Value;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["destination_url"] = Value;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  const handleLanguageChangenewexist = (selectedOption) => {
    setNewLangexist(selectedOption);
    const updatedLanguages = [...multiexist];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
        updatedLanguages.push({
          lang_code: selectedOption,
          destination_url: "",
        });
      }
    } else {
      updatedLanguages.push({ lang_code: selectedOption, destination_url: "" });
    }
    setMultiexist(updatedLanguages);
  };

  const handleloyallangChangeexist = (Value) => {
    const updatedList = [...multiexist];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLangexist
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["destination_url"] = Value;
    } else {
      const newLanguage = { lang_code: newLangexist };
      newLanguage["destination_url"] = Value;
      updatedList.push(newLanguage);
    }
    setMultiexist(updatedList);
  };

  const Add = async () => {
    let isFormValid = true;

    const errors = {};

    if (tab2 === "exists") {
      if (existcode.length > 0) {
        if (selectcode.length === 0) {
          isFormValid = false;
          errors.selectcode = "Code is required!";
        }
      }
      if (!destinationurlexist) {
        isFormValid = false;
        errors.destinationurlexist = "Destination url is required!";
      }
    } else {
      if (!selectedusage.length === 0) {
        isFormValid = false;
        errors.selectedusage = "Usage is required!";
      }
      if (
        selectedusage.value === "limited" ||
        selectedusage.value === "person_limit"
      ) {
        if (!list.usage_limit) {
          isFormValid = false;
          errors.usage_limit = "This field is required!";
        }
      }
      if (list.expiry_status === true) {
        if (!list.expiry_days) {
          isFormValid = false;
          errors.expiry_days = "Expiry day is required!";
        }
      }

      if (!list.discount) {
        isFormValid = false;
        errors.discount = "Discount is required!";
      }
      if (!destinationurl) {
        isFormValid = false;
        errors.destinationurl = "Destination url is required!";
      }
    }
    setError(errors);

    if (isFormValid === true) {
      let existcode;
      if (tab2 === "exists") {
        existcode = selectcode.value;
      }

      let lang = [];
      if (tab2 === "exists") {
        let enExistsInMulti = false;
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].lang_code === "en") {
            enExistsInMulti = true;
          }
        }

        if (enExistsInMulti === false) {
          if (inputEnDestinationexist) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestinationexist;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multiexist[i].lang_code;
            multiarray.url = multiexist[i].destination_url;
            lang.push(multiarray);
          }
        }
      } else {
        let enInMulti = false;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].lang_code === "en") {
            enInMulti = true;
          }
        }

        if (enInMulti === false) {
          if (inputEnDestination) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestination;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multi[i].lang_code;
            multiarray.url = multi[i].destination_url;
            lang.push(multiarray);
          }
        }
      }

      let expiry_status;
      let expiry_days;
      let usage;
      let usage_limit;
      let discount;
      let roomval = [];
      let Offerval = [];

      if (tab2 === "autogenerate") {
        expiry_status = list.expiry_status === true ? 1 : 0;
        expiry_days = list.expiry_days;
        usage = selectedusage.value;
        usage_limit = list.usage_limit;
        discount = list.discount;

        if (selectedRooms.length > 0) {
          for (let i = 0; i < selectedRooms.length; i++) {
            roomval.push(selectedRooms[i].value);
          }
        }

        if (selectedOffers.length > 0) {
          for (let i = 0; i < selectedOffers.length; i++) {
            Offerval.push(selectedOffers[i].value);
          }
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/post-stay/create`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            code_type: tab2,
            promocode: existcode,
            expiry_status: expiry_status,
            expiry_days: expiry_days,
            usage_status: usage,
            usage_limit: usage_limit,
            discount: discount,
            status: list.status === true ? 1 : 0,
            confidential_offers: Offerval,
            confidential_rooms: roomval,
            destination_url_type:
              tab2 === "exists"
                ? destinationurlexist?.value
                : destinationurl?.value,
            other_url: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          // setTimeout(() => navigate("/codes"), 3000);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Add();
      } else {
        console.log("error");
      }
    }
  };

  const Update = async () => {
    let isFormValid = true;

    const errors = {};

    if (tab2 === "exists") {
      if (existcode.length > 0) {
        if (selectcode.length === 0) {
          isFormValid = false;
          errors.selectcode = "Code is required!";
        }
      }
      if (!destinationurlexist) {
        isFormValid = false;
        errors.destinationurlexist = "Destination url is required!";
      }
    } else {
      if (selectedusage.length === 0) {
        isFormValid = false;
        errors.selectedusage = "Usage is required!";
      }
      if (
        selectedusage.value === "limited" ||
        selectedusage.value === "person_limit"
      ) {
        if (!list.usage_limit) {
          isFormValid = false;
          errors.usage_limit = "This field is required!";
        }
      }
      if (list.expiry_status === true) {
        if (!list.expiry_days) {
          isFormValid = false;
          errors.expiry_days = "Expiry day is required!";
        }
      }

      if (!list.discount) {
        isFormValid = false;
        errors.discount = "Discount is required!";
      }
      if (!destinationurl) {
        isFormValid = false;
        errors.destinationurl = "Destination url is required!";
      }
    }
    setError(errors);

    if (isFormValid === true) {
      let existcode;
      if (tab2 === "exists") {
        existcode = selectcode.value;
      }

      let lang = [];
      if (tab2 === "exists") {
        let enExistsInMulti = false;
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].lang_code === "en") {
            enExistsInMulti = true;
          }
        }

        if (enExistsInMulti === false) {
          if (inputEnDestinationexist) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestinationexist;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multiexist[i].lang_code;
            multiarray.url = multiexist[i].destination_url;
            lang.push(multiarray);
          }
        }
      } else {
        let enInMulti = false;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].lang_code === "en") {
            enInMulti = true;
          }
        }

        if (enInMulti === false) {
          if (inputEnDestination) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestination;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multi[i].lang_code;
            multiarray.url = multi[i].destination_url;
            lang.push(multiarray);
          }
        }
      }

      let expiry_status;
      let expiry_days;
      let usage;
      let usage_limit;
      let discount;
      let roomval = [];
      let Offerval = [];

      if (tab2 === "autogenerate") {
        expiry_status = list.expiry_status === true ? 1 : 0;
        usage = selectedusage.value;
        discount = list.discount;

        if (selectedRooms.length > 0) {
          for (let i = 0; i < selectedRooms.length; i++) {
            roomval.push(selectedRooms[i].value);
          }
        }

        if (selectedOffers.length > 0) {
          for (let i = 0; i < selectedOffers.length; i++) {
            Offerval.push(selectedOffers[i].value);
          }
        }
      }

      if (tab2 === "autogenerate") {
        if (list.expiry_status === true) {
          expiry_days = list.expiry_days;
        }
        if (selectedusage.value === "limited") {
          usage_limit = list.usage_limit;
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/post-stay/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            post_stay_id: list.poststay_id,
            code_type: tab2,
            promocode: existcode,
            expiry_status: expiry_status,
            expiry_days: expiry_days,
            usage_status: usage,
            usage_limit: usage_limit,
            discount: discount,
            status: list.status === true ? 1 : 0,
            confidential_offers: Offerval,
            confidential_rooms: roomval,
            destination_url_type:
              tab2 === "exists"
                ? destinationurlexist?.value
                : destinationurl?.value,
            other_url: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {
    if (list.type === "autogenerate") {
      const initialSelectedOption = SingleOptions.find(
        (option) => option.value === list?.destination_url_type
      );
      setDestinationurl(initialSelectedOption);
    } else {
      const initialSelectedOptionexist = SingleOptionsexist.find(
        (option) => option.value === list?.destination_url_type
      );
      setDestinationurlexist(initialSelectedOptionexist);
    }

    const newUsage = usageoption.find(
      (option) => option.value === selectedusagenew
    );
    setSelectedusage(newUsage);
  }, [list?.destination_url_type,selectedusagenew,selectedusage?.length,list.type]);

  useEffect(() => {
    if (selectcodenew) {
      for (let i = 0; i < existcode?.length; i++) {
        if (selectcodenew?.promocode_id === existcode[i]?.value) {
          setSelectCode({
            value: existcode[i].value,
            label: existcode[i].label,
          });
        }
      }
    }
  }, [selectcodenew, existcode]);

  useEffect(() => {
    if (selectedOffersnew) {
      for (let i = 0; i < newOffer.length; i++) {
        if (selectedOffersnew.some((item) => item === newOffer[i].value)) {
          selectedOffers.push({
            value: newOffer[i].value,
            label: newOffer[i].label,
          });
        }
      }
    }
  }, [selectedOffersnew, newOffer,selectedOffers]);

  useEffect(() => {
    if (selectedRoomsnew) {
      for (let i = 0; i < rooms.length; i++) {
        if (selectedRoomsnew.some((item) => item === rooms[i].value)) {
          selectedRooms.push({
            value: rooms[i].value,
            label: rooms[i].label,
          });
        }
      }
    }
  }, [selectedRoomsnew, rooms,selectedRooms]);

  useEffect(() => {
    const isMatching = multi?.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguagenew(isMatching);
  }, [newLang,multi]);

  useEffect(() => {
    let newval;

    langall?.forEach((lang) => {
      if (lang.destination_url) {
        newval = lang.lang_code;
      }
    });
    setNewLang(newval);
  }, [langall]);

  useEffect(() => {
    const langEnEntry = langall?.find((entry) => entry.lang_code === "en");
    const destinationUrlEn = langEnEntry ? langEnEntry.destination_url : "";
    setInputEnDestination(destinationUrlEn);
  }, [langall]);

  useEffect(() => {
    const isMatching = multiexist?.findIndex(
      (language) => language.lang_code === newLangexist
    );
    setisMatchingLanguagenewexist(isMatching);
  }, [newLangexist,multiexist]);

  useEffect(() => {
    let newval;

    langallexist?.forEach((lang) => {
      if (lang.destination_url) {
        newval = lang.lang_code;
      }
    });
    setNewLangexist(newval);
  }, [langallexist]);

  useEffect(() => {
    const langEnEntry = langallexist?.find((entry) => entry.lang_code === "en");
    const destinationUrlEn = langEnEntry ? langEnEntry.destination_url : "";
    setInputEnDestinationexist(destinationUrlEn);
  }, [langallexist]);

  useEffect(() => {
    getOffer();
  }, [getOffer]);

  useEffect(() => {
    getRoom();
  }, [getRoom]);

  useEffect(() => {
    getActiveCode();
  }, [getActiveCode]);

  useEffect(() => {
    getData();
  }, [id,getData]);

  useEffect(() => {
    getlanguage();
  }, [getlanguage]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              <div className="">
                <h4 className="" style={{ marginLeft: "10px" }}>
                  {hotelname}
                </h4>
                <div className="card header">
                  <div
                    className="align-items-center d-flex"
                    style={{
                      borderBottom: "1px solid #d2d2d2",
                      marginTop: "25px",
                    }}
                  >
                    <h4 style={{ marginLeft: "15px" }}>
                      CHECK-OUT EMAIL CONFIGURATION
                    </h4>
                    <div className="flex-grow-1">
                      <div
                        className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                        dir="ltr"
                        style={{ float: "right", bottom: "8px" }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor="inlineswitch5"
                          style={{
                            marginRight: "40px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            color: "black",
                          }}
                        >
                          Active
                        </label>
                        <input
                          type="checkbox"
                          className="form-check-input offer_checkbox1"
                          id="inlineswitch5"
                          value={list?.status}
                          checked={list?.status === true ? true : false}
                          onChange={(e) =>
                            handleloyaltyToggle(e.target.checked, "status")
                          }
                          style={{ width: "50px", height: "25px" }}
                        />
                      </div>
                    </div>
                    <div style={{ float: "right", paddingRight: "15px" }}>
                      {list.poststay_id ? (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px", bottom: "8px" }}
                          onClick={Update}
                        >
                          {" "}
                          Save
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px", bottom: "8px" }}
                          onClick={Add}
                        >
                          {" "}
                          Save
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="col-md-12">
                          <ul
                            className="offer-create-list_rate"
                            style={{ borderBottom: "0px" }}
                          >
                            <li
                              className={
                                tab2 === "exists"
                                  ? "extra-step-list active"
                                  : "extra-step-list"
                              }
                              onClick={(e) => setTab2("exists")}
                            >
                              EXISTING CODE
                            </li>
                            <li
                              className={
                                tab2 === "autogenerate"
                                  ? "extra-step-list active"
                                  : "extra-step-list"
                              }
                              onClick={(e) => setTab2("autogenerate")}
                            >
                              AUTOGENERATED CODE
                            </li>
                          </ul>
                        </div>
                        {tab2 === "exists" ? (
                          <>
                            <div
                              className="col-lg-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Choose an Existing Code
                                </label>
                                <div className="col-md-3">
                                  <Select
                                    placeholder="Select..."
                                    className="form-control multiselect_extra_based"
                                    data-choices
                                    name="choices-single-default"
                                    id="choices-single-default"
                                    options={existcode}
                                    value={selectcode}
                                    onChange={setSelectCode}
                                  ></Select>
                                </div>
                                <label style={{ color: "red" }}>
                                  {Error.selectcode}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-lg-4"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Destination URL
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-3">
                                    <Select
                                      placeholder="Select..."
                                      className="form-control singleselect-url-exist"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={SingleOptionsexist}
                                      value={destinationurlexist}
                                      onChange={setDestinationurlexist}
                                    ></Select>
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {Error.destinationurlexist}
                                </label>
                              </div>

                              {destinationurlexist?.value === "other" ? (
                                <>
                                  <div
                                    className="col-md-6 translation-fill-section"
                                    data-value="1"
                                  >
                                    <div className="col-lg-8">
                                      <div
                                        className="input-group"
                                        style={{
                                          top: "20px",
                                          right: "10px",
                                          width: "405px",
                                        }}
                                      >
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          En
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter the URL"
                                          value={inputEnDestinationexist}
                                          onChange={(e) =>
                                            setInputEnDestinationexist(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="d-flex">
                                      <div className="col-lg-4 d-flex align-items-center">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                          style={{
                                            width: "130px",
                                            borderBottomRightRadius: "0px",
                                            borderTopRightRadius: "0px",
                                          }}
                                        >
                                          Add/edit in
                                        </label>

                                        <select
                                          className="form-control"
                                          data-choices
                                          data-choices-search-false
                                          id="idPayment"
                                          style={{
                                            width: "235px",
                                            height: "38px",
                                            marginLeft: "-1px",
                                          }}
                                          value={newLangexist}
                                          onChange={(e) =>
                                            handleLanguageChangenewexist(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            Choose language
                                          </option>

                                          {language.map((lang, inx) => {
                                            return (
                                              <option
                                                key={inx}
                                                value={lang.code}
                                              >
                                                {lang.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      {newLangexist ? (
                                        multiexist?.length > 0 ? (
                                          <>
                                            {multiexist?.some(
                                              (item) =>
                                                item.lang_code === newLangexist
                                            ) ? (
                                              <div
                                                className="col-md-6 translation-fill-section"
                                                data-value="1"
                                              >
                                                <div className="col-lg-8">
                                                  <div
                                                    className="input-group"
                                                    style={{
                                                      top: "13px",
                                                      right: "5px",
                                                    }}
                                                  >
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {newLangexist}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter the URL"
                                                      value={
                                                        multiexist[
                                                          isMatchingLanguagenewexist
                                                        ]?.destination_url
                                                      }
                                                      onChange={(e) =>
                                                        handleloyallangChangeexist(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="col-md-6 translation-fill-section"
                                                data-value="1"
                                              >
                                                <div className="col-lg-8">
                                                  <div
                                                    className="input-group"
                                                    style={{
                                                      top: "13px",
                                                      right: "5px",
                                                    }}
                                                  >
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {newLangexist}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter the URL"
                                                      value={
                                                        multiexist[
                                                          multiexist?.length - 1
                                                        ]?.destination_url
                                                      }
                                                      onChange={(e) =>
                                                        handleloyallangChangeexist(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : tab2 === "autogenerate" ? (
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div
                              className="col-lg-3"
                              style={{ marginTop: "15px" }}
                            >
                              <span
                                className="input-group"
                                style={{ width: "268px" }}
                              >
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Usage{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </span>
                                <Select
                                  placeholder="Select..."
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  value={selectedusage}
                                  onChange={setSelectedusage}
                                  options={usageoption}
                                ></Select>
                              </span>
                              <label style={{ color: "red" }}>
                                {Error.selectedusage}
                              </label>
                            </div>
                            {selectedusage?.value === "limited" ||
                            selectedusage?.value === "person_limit" ? (
                              <>
                                <div
                                  className="input-group"
                                  style={{ width: "190px", right: "7px" }}
                                >
                                  <input
                                    type="text"
                                    className="new_inp_avail_code form-control"
                                    placeholder="Enter no of usages"
                                    value={list.usage_limit}
                                    onChange={(e) =>
                                      handleloyaltyToggle(
                                        e.target.value,
                                        "usage_limit"
                                      )
                                    }
                                    style={{
                                      width: "175px",
                                      textAlign: "center",
                                      marginBottom: "5px",
                                      top: "15px",
                                      height: "37px",
                                      fontSize: "14px",
                                    }}
                                  />
                                  <label
                                    style={{ color: "red", marginTop: "10px" }}
                                  >
                                    {Error.usage_limit}
                                  </label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            <div
                              className="col-md-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div
                                className="form-check form-check-inline"
                                style={{ float: "left" }}
                              >
                                <label style={{ marginRight: "10px" }}>
                                  Expiration
                                </label>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input per_room_once_check"
                                    type="radio"
                                    id="WithInlineRadioNo"
                                    name="expiry_status"
                                    value={true}
                                    checked={list?.expiry_status === true}
                                    onChange={(e) =>
                                      handleChangeExpiration(
                                        "expiry_status",
                                        true
                                      )
                                    }
                                  />
                                  <label>Yes</label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input per_room_once_check"
                                    type="radio"
                                    id="WithInlineRadioNo"
                                    name="expiry_status"
                                    value={false}
                                    checked={list?.expiry_status === false}
                                    onChange={(e) =>
                                      handleChangeExpiration(
                                        "expiry_status",
                                        false
                                      )
                                    }
                                  />
                                  <label>No</label>
                                </div>
                              </div>
                            </div>
                            {list?.expiry_status === true ? (
                              <>
                                <div
                                  class="col-lg-4"
                                  style={{ marginTop: "15px", right: "5%" }}
                                >
                                  <div
                                    className="input-group"
                                    style={{ width: "350px" }}
                                  >
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Days after the code is sent
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={list?.expiry_days}
                                      onChange={(e) =>
                                        handleloyaltyToggle(
                                          e.target.value,
                                          "expiry_days"
                                        )
                                      }
                                      style={{ textAlign: "center" }}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {Error.expiry_days}
                                  </label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="row">
                              <div
                                class="col-lg-4"
                                style={{ marginTop: "15px" }}
                              >
                                <div
                                  className="input-group"
                                  style={{ width: "350px" }}
                                >
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Extra Discount
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                    {/* <span className="info-tag-p-sale" style={{left:"5px"}}>i<span className="tooltiptext"></span></span> */}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={list?.discount}
                                    onChange={(e) =>
                                      handleloyaltyToggle(
                                        e.target.value,
                                        "discount"
                                      )
                                    }
                                  />
                                  <span
                                    className="input-group-text input-joint-color-loyal"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "60px" }}
                                  >
                                    %
                                  </span>
                                </div>
                                {/* <span className="rate_modify_type_section" style={{right:"10%"}}>
                                  <select id="ForminputState" className="form-select" value={list?.discount_unit_type}
                                   onChange={(e) => handleloyaltyToggle(e.target.value, "discount_unit_type")}>
                                      <option value="currency">AED</option>
                                      <option value="percentage">%</option>
                                  </select>
                              </span> */}
                                <label style={{ color: "red" }}>
                                  {Error.discount}
                                </label>
                              </div>
                              {newOffer.length > 0 ? (
                                <div
                                  className="col-lg-4"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Confidential Offers
                                    </label>
                                    <MultiSelect
                                      options={newOffer}
                                      value={selectedOffers}
                                      onChange={setSelectedOffers}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      className="multiselect-psale-email"
                                      allowCustomValue={true}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {Error.selectedOffers}
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                              {rooms.length > 0 ? (
                                <div
                                  className="col-lg-4"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Confidential Rooms
                                    </label>
                                    <div className="col-md-3">
                                      <MultiSelect
                                        options={rooms}
                                        value={selectedRooms}
                                        onChange={setSelectedRooms}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        className="multiselect-psale-email"
                                        allowCustomValue={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="col-lg-4"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Destination URL
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <div className="col-md-3">
                                  <Select
                                    placeholder="Select..."
                                    className="form-control singleselect-url"
                                    data-choices
                                    name="choices-single-default"
                                    id="choices-single-default"
                                    options={SingleOptions}
                                    value={destinationurl}
                                    onChange={setDestinationurl}
                                  ></Select>
                                </div>
                              </div>
                              <label
                                style={{ color: "red", marginTop: "10px" }}
                              >
                                {Error.destinationurl}
                              </label>
                            </div>

                            {destinationurl?.value === "other" ? (
                              <>
                                <div
                                  className="col-md-6 translation-fill-section"
                                  data-value="1"
                                >
                                  <div className="col-lg-8">
                                    <div
                                      className="input-group"
                                      style={{
                                        top: "10px",
                                        right: "10px",
                                        width: "405px",
                                      }}
                                    >
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        En
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter the URL"
                                        value={inputEnDestination}
                                        onChange={(e) =>
                                          setInputEnDestination(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="d-flex">
                                    <div className="col-lg-4 d-flex align-items-center">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{
                                          width: "130px",
                                          borderBottomRightRadius: "0px",
                                          borderTopRightRadius: "0px",
                                        }}
                                      >
                                        Add/edit in
                                      </label>

                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        id="idPayment"
                                        style={{
                                          width: "218px",
                                          height: "38px",
                                          marginLeft: "-1px",
                                        }}
                                        value={newLang}
                                        onChange={(e) =>
                                          handleLanguageChangenew(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Choose language
                                        </option>

                                        {language.map((lang, inx) => {
                                          return (
                                            <option key={inx} value={lang.code}>
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    {newLang ? (
                                      multi?.length > 0 ? (
                                        <>
                                          {multi?.some(
                                            (item) => item.lang_code === newLang
                                          ) ? (
                                            <div
                                              className="col-md-6 translation-fill-section"
                                              data-value="1"
                                            >
                                              <div className="col-lg-8">
                                                <div
                                                  className="input-group"
                                                  style={{
                                                    top: "13px",
                                                    right: "5px",
                                                  }}
                                                >
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {newLang}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the URL"
                                                    value={
                                                      multi[
                                                        isMatchingLanguagenew
                                                      ]?.destination_url
                                                    }
                                                    onChange={(e) =>
                                                      handleloyallangChange(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="col-md-6 translation-fill-section"
                                              data-value="1"
                                            >
                                              <div className="col-lg-8">
                                                <div
                                                  className="input-group"
                                                  style={{
                                                    top: "13px",
                                                    right: "5px",
                                                  }}
                                                >
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {newLang}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the URL"
                                                    value={
                                                      multi[multi?.length - 1]
                                                        ?.destination_url
                                                    }
                                                    onChange={(e) =>
                                                      handleloyallangChange(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="padding-20"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Poststayemail;
