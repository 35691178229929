import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import Loyaltyheader from "../../Common/Loyaltyheader";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import Loading from "../../Loading";

function Loyaltyclub() {
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [error, setError] = useState([{}]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [loader, setLoader] = useState(false);

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [language, setLanguage] = useState([]);
  const [multi, setMulti] = useState([]);
  const [club, setClub] = useState([]);
  const [status, setStatus] = useState(false);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  // const [club, setClub] = useState({
  // club_name:'',
  // club_logo:'',
  // club_conditionurl:'',
  // club_privacy:'',
  // club_status:false,

  // });

  const getlanguage =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setLanguage(data.data);
    }
  },[]);

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}loyalty-club/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setClub(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setClub(commentsFormServer);
    }
  };

  const fetchPost =useCallback(async () => {
    const response = await fetch(`${Config.apiurl}loyalty-club/list?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await response.json();
    if (data.status_code === "200") {
      setClub(data.data.data);
      // if (data.data.multi_lang) {
      //         setMulti(data.data.multi_lang);
      //       }
      //       if (data.data.status) {
      //         setStatus(data.data.status);
      //       }
      setPaginate(data.data.last_page);
      setLoader(true);
    } else if (response.reauth === true) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  // const getview = async (e) => {
  //   const res = await fetch(`${Config.apiurl}loyalty-club/list`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       hotels: id,
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   });
  //   const data = await res.json();
  //   if (data.status_code !== "200" || !data.data) {
  //     console.log("error");
  //   } else {
  //     setClub(data.data);
  //     if (data.data.multi_lang) {
  //       setMulti(data.data.multi_lang);
  //     }
  //     if (data.data.status) {
  //       setStatus(data.data.status);
  //     }
  //   }
  // };

  // const Export = async (e) => {
  //   try {
  //     const res = await fetch(
  //       `${Config.apiurl}hotel/loyalty/members/list/download`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           hotel_id: id,
  //         }),
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       }
  //     );

  //     if (res.status !== 200) {
  //       throw new Error(`HTTP error! Status: ${res.status}`);
  //     }

  //     // const blob = await res.blob();

  //     // const link = document.createElement('a');
  //     // link.href = window.URL.createObjectURL(blob);
  //     // link.download = 'members_list.xlsx';
  //     // document.body.appendChild(link);

  //     // link.click();

  //     // document.body.removeChild(link);

  //     const contentDisposition = res.headers.get("content-disposition");
  //     console.log(contentDisposition);
  //     const fileNameMatch =
  //       contentDisposition &&
  //       contentDisposition.match(/filename=(["'])(?:(?=(\\?))\2.)*?\1/);

  //     const suggestedFileName = fileNameMatch
  //       ? fileNameMatch[0].substring(10, fileNameMatch[0].length - 1)
  //       : "club_members.xlsx";

  //     const blob = await res.blob();
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(new Blob([blob]));
  //     link.download = suggestedFileName;

  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  function handleChange(index, e) {
    const { name, value } = e.target;
    const updatedClub = [...club];
    updatedClub[index] = {
      ...updatedClub[index],
      loyalty_club: {
        ...updatedClub[index].loyalty_club,
        [name]: value,
      },
    };
    setClub(updatedClub);

    const updatedChangedData = [...changeddata];
    updatedChangedData[index] = updatedClub[index];
    setChangeddata(updatedChangedData);

    // const updatedChangedData = [...changeddata];
    // updatedChangedData[index] = {
    //   ...updatedChangedData[index],
    //   loyalty_club: {
    //     ...updatedChangedData[index]?.loyalty_club,
    //     [name]: value,
    //   },
    // };
    // setChangeddata(updatedChangedData);
  }

  function handleChangeToggle(index, e) {
    const { checked } = e.target;
    const updatedClub = [...club];
    updatedClub[index] = {
      ...updatedClub[index],
      loyalty_club: {
        ...updatedClub[index].loyalty_club,
        status: checked,
      },
    };
    setClub(updatedClub);

    const updatedChangedData = [...changeddata];
    updatedChangedData[index] = updatedClub[index];

    // updatedChangedData[index] = {
    //   ...updatedChangedData[index],
    //   loyalty_club: {
    //     ...updatedChangedData[index]?.loyalty_club,
    //     status: checked,
    //   },
    // };
    setChangeddata(updatedChangedData);
  }


  const handleLanguageChangenew = (index, selectedOption) => {
    const updatedLanguages = [...club];
    if (
      updatedLanguages[index].loyalty_club === null ||
      !updatedLanguages[index].loyalty_club.multi_lang_data
    ) {
      updatedLanguages[index].loyalty_club = {
        ...updatedLanguages[index].loyalty_club,
        newlangsub: selectedOption,
        multi_lang_data: [{ lang_code: selectedOption, title: "" }],
      };
    } else {
      updatedLanguages[index].loyalty_club.newlangsub = selectedOption;
      if (
        !updatedLanguages[index].loyalty_club.multi_lang_data.some(
          (item) => item.lang_code === selectedOption
        )
      ) {
        updatedLanguages[index].loyalty_club.multi_lang_data.push({
          lang_code: selectedOption,
          title: "",
        });
      }
    }
    setClub(updatedLanguages);
    const changedHotel = updatedLanguages[index];
    changeddata[index] = changedHotel;
  };

  const handleloyallangChange = (index, Value) => {
    const updatedList = [...club];
    const matchingIndex = updatedList[
      index
    ].loyalty_club?.multi_lang_data?.findIndex(
      (language) =>
        language.lang_code === updatedList[index].loyalty_club?.newlangsub
    );
    if (matchingIndex !== -1) {
      updatedList[index].loyalty_club.multi_lang_data[matchingIndex]["title"] =
        Value;
    } else {
      const newLanguage = {
        lang_code: updatedList[index].loyalty_club?.newlangsub,
        title: Value,
      };

      updatedList[index].loyalty_club?.multi_lang_data?.push(newLanguage);
    }
    setClub(updatedList);
    // Add the changed hotel to the changeddata variable
    const changedHotel = updatedList[index];
    changeddata[index] = changedHotel;
  };

  console.log(changeddata);

  let Save = async () => {
    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    let arr = [];
    for (let i = 0; i < club; i++) {
      const errors = {};
      if (!club[i].loyalty_club?.program_name) {
        isFormValid = false;
        errors.program_name = "Club name is required!";
      }
      else if (!noSpecialCharRegex.test(club[i].loyalty_club?.program_name)) {
        isFormValid = false;
        errors.program_name = "Club name should not contain this special character";
      }
      else if (club[i].loyalty_club?.program_name.length > 50) {
        isFormValid = false;
        errors.program_name = `Club name cannot be more than 50 characters!`;
      }
      if (!club[i].loyalty_club?.club_condition_url) {
        isFormValid = false;

        errors.club_condition_url = "Club condition url is required!";
      }
      if (!club[i].loyalty_club?.club_policy_url) {
        isFormValid = false;

        errors.club_policy_url = "Privacy policy url is required!";
      }

      arr.push(errors);
    }
    setError(arr);

    if (isFormValid === true) {
      let hotel = [];
      if (changeddata?.length > 0) {
        for (let i = 0; i < changeddata.length; i++) {
          if (changeddata[i] !== undefined) {
            let multiarray = {};
            multiarray.hotel_id = changeddata[i].hotel_id;
            multiarray.loyalty_club_id =
              changeddata[i].loyalty_club.loyalty_club_id;
            multiarray.program_name = changeddata[i].loyalty_club.program_name;
            multiarray.status =
              changeddata[i].loyalty_club.status === true ? 1 : 0;
            multiarray.club_logo_url =
              changeddata[i].loyalty_club.club_logo_url;
            multiarray.club_condition_url =
              changeddata[i].loyalty_club.club_condition_url;
            multiarray.privacy_policy_url =
              changeddata[i].loyalty_club.club_policy_url;

            let lang = [];
            console.log(changeddata[i]);
            if (changeddata[i]?.loyalty_club?.multi_lang_data) {
              for (
                let j = 0;
                j < changeddata[i]?.loyalty_club.multi_lang_data?.length;
                j++
              ) {
                let multiarray1 = {};
                multiarray1.lang_code =
                  changeddata[i].loyalty_club.multi_lang_data[j].lang_code;
                multiarray1.name =
                  changeddata[i].loyalty_club.multi_lang_data[j].title;

                lang.push(multiarray1);
              }
              multiarray.multi_lang = lang;
              hotel.push(multiarray);
            }
          }
        }

        const res = await fetch(`${Config.apiurl}loyalty-club/update`, {
          method: "POST",
          body: JSON.stringify({
            hotels: hotel,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          if (data.status_code === "200" || !data.data) {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("success");
            setToastlarge(true);
            fetchPost();
          } else {
            console.log("error");
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("error");
            setToastlarge(true);
          }
        } else if (res.status === 401) {
          handleUnauthorized();
          Save();
        } else {
          console.log("error");
        }
      } else {
        setToastmodal(true);
        setToastmessage("You have made no changes to save");
        setToasttype("success");
        setToastlarge(true);
      }
    }
  };

  useEffect(() => {
    fetchPost();
  }, [id,fetchPost]);

  useEffect(() => {
    getlanguage();
  }, [getlanguage]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (club.length > 0) {
      const initialState = club.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [club]);

  useEffect(() => {
    let newarray = [];
    let lang;
    const updatedList = [...club];
    for (let i = 0; i < updatedList.length; i++) {
      if (updatedList[i].loyalty_club) {
        for (
          let j = 0;
          j < updatedList[i].loyalty_club?.multi_lang_data?.length;
          j++
        ) {
          let ar = {};
          ar.lang_code =
            updatedList[i].loyalty_club?.multi_lang_data[j]?.lang_code;
          ar.title = updatedList[i].loyalty_club?.multi_lang_data[j]?.title;
          newarray.push(ar);
          lang = updatedList[i].loyalty_club?.multi_lang_data[j]?.lang_code;
        }
        updatedList[i].loyalty_club.newlangsub = lang;
      }
    }
  }, [club]);

  // useEffect(() => {
  //   const isMatching = club?.findIndex((data) =>
  //     data.loyalty_club?.multi_lang_data?.some(
  //       (language) => language.lang_code === newLang
  //     )
  //   );
  //   setisMatchingLanguagenew(isMatching);
  // }, [newLang, club]);

  // console.log(isMatchingLanguagenew);

  // useEffect(() => {
  //   let newval;

  //   club?.forEach((data) =>
  //     data.loyalty_club?.multi_lang_data?.some((lang) => {
  //       if (lang?.title) {
  //         newval = lang?.lang_code;
  //         return true;
  //       }
  //       return false;
  //     })
  //   );
  //   setNewLang(newval);
  // }, [club?.length]);

  return (
    <div>
      <Loyaltyheader />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              <div className="card">
                {loader===false?
                <Loading/>
                :
                <div className="card-body">
                  <div className="live-preview">
                    <div className="page-title-box  align-items-center justify-content-between">
                      <h4 className="db-page-title">Loyalty Club Settings</h4>

                      {/* <div
                        className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                        dir="ltr"
                        style={{ marginTop: "8%", right: "4%" }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor="inlineswitch5"
                          style={{
                            marginRight: "10px",
                            float: "left",
                            marginTop: "5px",
                          }}
                        >
                          Activate
                        </label>

                        <input
                          type="checkbox"
                          className="form-check-input offer_checkbox1"
                          id="inlineswitch5"
                          style={{
                            width: "50px",
                            height: "25px",
                            marginLeft: "0px",
                            paddingLeft: "12px",
                          }}
                          value={status}
                          checked={status === true ? true : false}
                          onChange={(e) => setStatus(e.target.checked)}
                        />
                      </div> */}
                    </div>
                    <div
                      className="flex-shrink-0 d-flex justify-content-between"
                      style={{ float: "right" }}
                    >
                      {/* <Link type="button" to="#" className="btn db-save-button-loyalty" style={{height:"30px"}} >Create New Loyalty</Link> */}
                      <Link
                        type="button"
                        to="#"
                        className="btn db-save-button"
                        style={{ height: "30px" }}
                        onClick={Save}
                      >
                        Save
                      </Link>
                    </div>
                    <div className="row" style={{ display: "contents" }}>
                      {club.map((data, i) => {
                        const isMatchingLanguagesub =
                          data.loyalty_club?.multi_lang_data?.findIndex(
                            (language) =>
                              language.lang_code ===
                              data.loyalty_club?.newlangsub
                          );

                        return (
                          <>
                            <div
                              className={
                                club.length > 1
                                  ? "row hotel_single_list"
                                  : "row"
                              }
                              key={i}
                            >
                              {club.length > 1 ? (
                                <div className="hotel_service_display hotel_service_display_12">
                                  <div className="hotel_service_display_14">
                                    <div className="hk_service_all_hotel">
                                      <span className="">
                                        {" "}
                                        {pageindex === 1
                                          ? i + 1
                                          : (pageindex - 1) * 10 + i + 1}
                                        .
                                      </span>
                                      <span style={{ marginLeft: "10px" }}>
                                        {data.hotel_name}
                                      </span>
                                    </div>
                                  </div>
                                  {showFullData[i]?.hotel === true ? (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-up"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  ) : (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-down"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className={`row ${
                                  showFullData[i]?.hotel === true
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                <div
                                  className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                  dir="ltr"
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineswitch5"
                                    style={{
                                      marginRight: "10px",
                                      float: "left",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Activate
                                  </label>

                                  <input
                                    type="checkbox"
                                    className="form-check-input offer_checkbox1"
                                    id="inlineswitch5"
                                    style={{
                                      width: "50px",
                                      height: "25px",
                                      marginLeft: "0px",
                                      paddingLeft: "12px",
                                    }}
                                    value={data.loyalty_club?.status}
                                    checked={
                                      data.loyalty_club?.status === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeToggle(i, e)}
                                  />
                                </div>
                                <div className="be_loyalty_level">
                                  <h5 className="be_loyalty_level_title">
                                    Single hotel club settings
                                  </h5>
                                  <span className="padding-5"></span>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Club Name{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <div class="info-tag-new">i</div>
                                        <span className="tooltiptext-new">
                                          Name of the club
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="program_name"
                                        value={data.loyalty_club?.program_name}
                                        onChange={(e) => handleChange(i, e)}
                                      />
                                      {/* <label style={{ color: "red" }}>
                                        {error.program_name}
                                      </label> */}
                                      {error.map((err, index) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.program_name ? (
                                              <label style={{ color: "red" }}>
                                                {err.program_name}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>

                                    <div className="col-md-6">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Club logo URL{" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="club_logo_url"
                                        value={data.loyalty_club?.club_logo_url}
                                        onChange={(e) => handleChange(i, e)}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Club conditions URL{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="club_condition_url"
                                        value={
                                          data.loyalty_club?.club_condition_url
                                        }
                                        onChange={(e) => handleChange(i, e)}
                                      />
                                      {error.map((err, index) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.club_condition_url ? (
                                              <label style={{ color: "red" }}>
                                                {err.club_condition_url}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>

                                    <div className="col-md-6">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Privacy Policy URL{" "}
                                        {/* <span className="form-validation-required">
                                          *
                                        </span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="club_policy_url"
                                        value={
                                          data.loyalty_club?.club_policy_url
                                        }
                                        onChange={(e) => handleChange(i, e)}
                                      />
                                      {error.map((err, index) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.club_policy_url ? (
                                              <label style={{ color: "red" }}>
                                                {err.club_policy_url}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>

                                    <div
                                      className="col-md-6"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ float: "left" }}
                                      >
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                          style={{
                                            width: "130px",
                                            borderBottomRightRadius: "0px",
                                            borderTopRightRadius: "0px",
                                          }}
                                        >
                                          Add/edit in
                                        </label>
                                        <select
                                          className="form-control"
                                          data-choices
                                          data-choices-search-false
                                          id="idPayment"
                                          style={{
                                            width: "225px",
                                            height: "38px",
                                            marginLeft: "-1px",
                                          }}
                                          value={data.loyalty_club?.newlangsub}
                                          onChange={(e) =>
                                            handleLanguageChangenew(
                                              i,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option>Choose language</option>

                                          {language.map((lang, inx) => {
                                            return (
                                              <option
                                                key={inx}
                                                value={lang.code}
                                              >
                                                {lang.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="input-group input_loyal_club" >
                                            <label className="input-group-text input-joint-color" >Export Members</label>
                                            <button className="input_loyal_club_btn" onClick={Export}>Download</button>
                                                    
                                    </div>
                                </div> */}
                                    {data.loyalty_club?.newlangsub ? (
                                      <>
                                        {data.loyalty_club?.multi_lang_data
                                          ?.length > 0 ? (
                                          <>
                                            {data.loyalty_club?.multi_lang_data?.some(
                                              (item) =>
                                                item.lang_code ===
                                                data.loyalty_club?.newlangsub
                                            ) ? (
                                              <div
                                                className="col-md-6 translation-fill-section"
                                                data-value="1"
                                                style={{ marginTop: "20px" }}
                                              >
                                                <div className="col-md-12">
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Club Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder=""
                                                      value={
                                                        data.loyalty_club
                                                          ?.multi_lang_data[
                                                          isMatchingLanguagesub
                                                        ]?.title
                                                      }
                                                      onChange={(e) =>
                                                        handleloyallangChange(
                                                          i,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="col-md-6 translation-fill-section mt-3"
                                                data-value="1"
                                              >
                                                <div className="col-md-12">
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Club Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder=""
                                                      value={
                                                        data.loyalty_club
                                                          ?.multi_lang_data[
                                                          data.loyalty_club
                                                            ?.multi_lang_data
                                                            ?.length - 1
                                                        ]?.title
                                                      }
                                                      onChange={(e) =>
                                                        handleloyallangChange(
                                                          data.loyalty_club
                                                            ?.multi_lang_data
                                                            ?.length - 1,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {paginate > 1 ? (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginate}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Loyaltyclub;
