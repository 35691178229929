import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Notfound from "../Common/Notfound";
import Config from "../Config";
import Loading from "../Loading";
import "./be_form.css";
import HotelFreequency from "./HotelFreequency";
import Hotelinformation from "./Hotelinformation";
import Loyalty from "./Loyalty";
import Roominformaton from "./Roominformaton";
import Taxdetails from "./Taxdetails";
import moment, { months } from "moment";
import Alerts from "../Alerts";
import { Helmet } from "react-helmet";
import Toastmodal from "../Common/Toastmodal";

const time = [
  { value: "00:00", label: "12 am" },
  { value: "01:00", label: "1 am" },
  { value: "02:00", label: "2 am" },
  { value: "03:00", label: "3 am" },
  { value: "04:00", label: "4 am" },
  { value: "05:00", label: "5 am" },
  { value: "06:00", label: "6 am" },
  { value: "07:00", label: "7 am" },
  { value: "08:00", label: "8 am" },
  { value: "09:00", label: "9 am" },
  { value: "10:00", label: "10 am" },
  { value: "11:00", label: "11 am" },
  { value: "12:00", label: "12 pm" },
  { value: "13:00", label: "1 pm" },
  { value: "14:00", label: "2 pm" },
  { value: "15:00", label: "3 pm" },
  { value: "16:00", label: "4 pm" },
  { value: "17:00", label: "5 pm" },
  { value: "18:00", label: "6 pm" },
  { value: "19:00", label: "7 pm" },
  { value: "20:00", label: "8 pm" },
  { value: "21:00", label: "9 pm" },
  { value: "22:00", label: "10 pm" },
  { value: "23:00", label: "11 pm" },
];

function Bookingengineform() {
  
  let navigate = useNavigate();

  const [tab, setTab] = useState("Hotel-info");
  let { string, id } = useParams();
  const [country, setCountry] = useState([]);
  const [hoteltype, setHoteltype] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [hotelinfo, setHotelinfo] = useState([]);
  const [roomtype, setRoomtype] = useState([]);
  const [loyaltyold, setLoyaltyold] = useState([]);
  const [basiclevel, setBasiclevel] = useState([]);
  const [roominfo, setRoominfo] = useState([
    {
      language: "",
      name: "",
      facility: [],
      inputFieldsroom_nos: [],
      sort_order: 0,
      extra_bed_status: false,
      baby_status: false,
    },
  ]);
  const [roomimage, setRoomimage] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [hkfreequency, setHkfreequency] = useState([]);
  const [tax, setTax] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const [loader, setLoader] = useState(true);
  const [be_lang, setBe_lang] = useState([]);
  const [property, setProperty] = useState([]);
  const [star, setStar] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [creditcard, setCreditcard] = useState([]);
  const identitystring = string;
  const hotelsid = id;
  const [selectedCard, setselectedCard] = useState([]);
  const [selectedBookinglang, setSelectedBookinglang] = useState([]);

  const [selectedExtanet, setSelectedExtranet] = useState([]);
  const [hotelimgsize, setHotelimagesize] = useState([]);
  const [hotellogosize, setHotellogosize] = useState([]);
  const [hotelroomsize, setHotelroomsize] = useState([]);
  const [prev, setPrev] = useState(false);
  const [roomprev, setRoomprev] = useState(false);
  const [hkprev, setHkprev] = useState(false);
  const [taxprev, setTaxprev] = useState(false);
  const [loyalprev, setLoyalprev] = useState(false);
  const [loyaltylogo, setLoyaltylogo] = useState("");
  const [loyaltyimages, setLoyaltyimages] = useState([]);
  const [defaultimage, setDefaultimage] = useState([]);

  const [pms, setPMS] = useState([]);
  const [channel, setChannel] = useState([]);
  const [selectedcountry, setSelectedcountry] = useState([]);
  const [facility, setFacility] = useState([]);
  const [roominfos, setRoominfos] = useState([
    {
      room_images: [],
    },
  ]);
  const [propertytypehotel, setPropertytypehotel] = useState([]);
  const [hoteldetails, setHoteldetails] = useState([]);
  const [mobile, setMobile] = useState("");
  const [imgname, setImgname] = useState("");
  const [selectedfacility, setselectedfacility] = useState([]);
  const [service, setService] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [ser_length, setser_length] = useState("");
  const [taxstatus, setTaxstatus] = useState(true);
  const [bedtype, setBedtype] = useState([]);
  const [countrytime, setCountrytime] = useState([]);
  const [calling, setCalling] = useState([]);
  const [benifit, setBenifit] = useState([]);
  const [count, setCount] = useState(false);
  const [count1, setCount1] = useState(true);
  const [count2, setCount2] = useState(true);
  const [count3, setCount3] = useState(true);
  const [count4, setCount4] = useState(true);
  const [oldlogo, setOldlogo] = useState([]);
  const [roomimgcount, setRoomimgcount] = useState(true);
  const [edit, setEdit] = useState(false);
  const [lval, setlval] = useState([]);
  const [roomtypelist, setRoomtypelist] = useState([]);
  const [loaderspin, setLoaderspin] = useState(false);
  const [newhotellogo, setNewhotellogo] = useState({
    hotel_logo: "",
  });

  const [hotelinfoupdatestatus, setHotelinfoupdatestatus] = useState(false);
  const [roominfoupdatestatus, setRoominfoupdatestatus] = useState(false);
  const [hkupdatestatus, setHkupdatestatus] = useState(false);
  const [taxupdatestatus, setTaxupdatestatus] = useState(false);
  const [loyalupdatestatus, setLoyalupdatestatus] = useState(false);

  const [hotel_info_status, setHotel_info_status] = useState(false);
  const [room_info_status, setRoom_info_status] = useState(false);
  const [hk_info_status, setHk_info_status] = useState(false);
  const [tax_info_status, setTax_info_status] = useState(false);
  const [loyal_info_status, setLoyal_info_status] = useState(false);
  const review_submit = localStorage.getItem("review_submit");

  const [oldlist, setoldlist] = useState([]);
  const [oldimage, setOldImage] = useState([]);
  const [remove, setRemovemage] = useState();

  const [oldlisthotel, setoldlisthotel] = useState([]);
  const [oldimagehotel, setOldImagehotel] = useState([]);
  const [removehotel, setRemovemagehotel] = useState();

  const [oldlistroom, setoldlistroom] = useState([]);
  const [oldimageroom, setOldImageroom] = useState([]);
  const [removeroomid, setRemoveroomid] = useState([]);
  const [removeloyalid, setRemoveloayalid] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [selectedcategory, setSelectedcategory] = useState([]);
  const [selectedchannelmanager, setSelectedchannelmanager] = useState([]);
  const [selectedcurrency, setSelectedcurrency] = useState([]);
  const [selectedhotelimage, setSelectedhotelimage] = useState([]);
  const [selectedoptionalimage, setSelectedoptionalimage] = useState([]);
  const [selectedtimezone, setSelectedtimezone] = useState([]);
  const [selectedpms, setSelectedPMS] = useState([]);
  const [hotelimage, setHotelimage] = useState([]);
  const [optionalimage, setOptionalimage] = useState([]);
  const [checkintime, setCheckintime] = useState("");
  const [checkouttime, setCheckouttime] = useState("");
  const [checkintimenew, setCheckintimenew] = useState("");
  const [checkouttimenew, setCheckouttimenew] = useState("");

  const [removeRoom, setRemoveRoom] = useState([]);

  const renderedItems = [];

  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState([{}]);
  const [formerror4, setError4] = useState([{}]);
  const [formerror5, setError5] = useState([{}]);
  const [formerror6, setError6] = useState([{}]);
  const [formerror7, setError7] = useState([{}]);
  const [formerror8, setError8] = useState([{}]);
  const [formerror9, setError9] = useState({});
  const [formerror10, setError10] = useState([{}]);
  const [formerror11, setError11] = useState([{}]);
  const [formerrorall, setErrorall] = useState({});

  const [reviewhotel, setReviewhotel] = useState(true);
  const [reviewroom, setReviewroom] = useState(true);
  const [reviewhk, setReviewhk] = useState(true);
  const [reviewtax, setReviewtax] = useState(true);
  const [reviewloyal, setReviewloyal] = useState(true);

  const [hotelbutton, setHotelbutton] = useState(false);
  const [roombutton, setRoombutton] = useState(false);
  const [hkbutton, setHkbutton] = useState(false);
  const [taxbutton, setTaxbutton] = useState(false);
  const [loyalbutton, setLoyalbutton] = useState(false);
  const [submitbutton, setSubmitbutton] = useState(false);

  const yesorno = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const [formerror, setError] = useState({});

  const getall = async (e) => {
    const res = await fetch(`${Config.apiurl}be-form/all`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountry(data.countries);
      setTimezone(data.timezones);
      setBe_lang(data.language);
      setProperty(data.property_type);
      setStar(data.categories);
      setCurrency(data.currencies);
      setCreditcard(data.credit_cards);
      setPMS(data.pms);
      setChannel(data.channel_managers);
      setFacility(data.facilities);
      setService(data.services);
      setFrequency(data.frequencies);
      setBedtype(data.bedtypes);
      setBenifit(data.loyalty_benefits);
      setBasiclevel(data.loyalty_levels);
      setDefaultimage(data?.default_image);
    }
  };
  const getcountrybasetime = async (val) => {
    const res = await fetch(`${Config.apiurl}country/time-zones/${val.value}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountrytime(data);
    }
    const res1 = await fetch(
      `${Config.apiurl}country/calling-code/${val.value}`,
      {
        method: "POST",
      }
    );
    const data2 = await res1.json();
    const data3 = data2.data;

    if (data2.status_code !== "200" || !data3) {
      console.log("error");
    } else {
      setCalling(data3);
    }
    setSelectedtimezone(data[0]);
    setSelectedcountry(val);
    setHoteldetails({ ...hoteldetails, calling_code: data3[0] });
  };

  const getRoomtype =useCallback(async () => {
    const res = await fetch(
      `${Config.apiurl}temporary-hotel/roomtypes/${hotelsid}`,
      {
        method: "POST",
      }
    );
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  },[hotelsid]);

  const gethotelinfo =useCallback(async (e) => {
    if (prev === false) {
      const res = await fetch(
        `${Config.apiurl}temporary-hotel/registration/${identitystring}/${hotelsid}`,
        {
          method: "GET",
        }
      );
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
        setLoader(false);
        setInvalid(true);
      } else {
        setLoader(false);

        setHotelinfo(data.group);
        setHoteltype(data.type);
        setHoteldetails(data.hotel_info[0].basic_info);
        setSelectedcountry(data.hotel_info[0].country);
        setPropertytypehotel(data.hotel_info[0].property_type);
        setSelectedBookinglang(data.hotel_info[0].be_language);
        setSelectedcategory(data.hotel_info[0].category);
        setSelectedchannelmanager(data.hotel_info[0].channel_manager);
        setselectedCard(data.hotel_info[0].credit_cards);
        setSelectedcurrency(data.hotel_info[0].currency);
        setSelectedExtranet(data.hotel_info[0].extranet_languages);
        setHkfreequency(data.hotel_info[0].hk_frequency);
        setSelectedhotelimage(data.hotel_info[0].hotel_images);
        setLoyalty(data.hotel_info[0].loyalty_program);
        setSelectedoptionalimage(data.hotel_info[0].optional_images);
        setSelectedPMS(data.hotel_info[0].pms);
        setRoominfo(data.hotel_info[0].room_info);
        setTax(data.hotel_info[0].tax_info);
        setSelectedtimezone(data.hotel_info[0].timezone);
        setOldlogo(data.hotel_info[0].basic_info.hotel_logo);
        setHotel_info_status(data.hotel_info[0].basic_info.hotel_info_status);
        setRoom_info_status(data.hotel_info[0].basic_info.room_info_status);
        setHk_info_status(data.hotel_info[0].basic_info.hk_info_status);
        setTax_info_status(data.hotel_info[0].basic_info.tax_info_status);
        setLoyal_info_status(data.hotel_info[0].basic_info.loyalty_info_status);
        setLoyaltyold(data.hotel_info[0].loyalty_program);
        setCheckintimenew(data.hotel_info[0].basic_info.checkin_time);
        setCheckouttimenew(data.hotel_info[0].basic_info.checkout_time);
        setHotelbutton(false);
        setRoombutton(false);
        setHkbutton(false);
        setTaxbutton(false);
        setLoyalbutton(false);
      }
    }
  },[identitystring,hotelsid,prev]);

  const [selectedhotel, setSelectedhotel] = useState("");
  const [other, setOther] = useState(false);

  const handlechange = (e) => {
    setHotelbutton(false);
    setHotelinfoupdatestatus(true);
    let name = e.target.name;
    let value = e.target.value;
    setHoteldetails({ ...hoteldetails, [name]: value });
  };
  const handleInputsphone = (value) => {
    setHoteldetails({ ...hoteldetails, phone_no: value });
  };

  function handleChangeselect(selectedOption, nameval) {
    let name = nameval;
    let value = selectedOption;
    setHoteldetails({ ...hoteldetails, [name]: value });
  }

  const onChange = (e) => {
    const file = e.target.files;
    //         const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const img = new Image();
    //     img.onload = handleImageLoad;
    //     img.src = e.target.result;
    //   };

    //   reader.readAsDataURL(file);
    // console.log(e);
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    setHotelimage(e.target.files);
  };

  const onChangelogo = (e) => {
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    const file = e.target.files[0];

    //     const reader = new FileReader();
    // reader.onload = (e) => {
    // const img = new Image();
    // img.onload = handleImageLoadlogo;
    // img.src = e.target.result;
    // };

    // reader.readAsDataURL(file);
    // console.log(e);
    let name = e.target.name;
    setNewhotellogo({ [name]: file });
    // setHoteldetails({ ...hoteldetails, [name]: e.target.files[0] });
  };

  const onChangehotelroom = (e) => {
    setHotelbutton(false);

    const file = e.target.files;
    setHotelinfoupdatestatus(true);
    setOptionalimage(e.target.files);
  };

  const removeImage = (id) => {
    // delete oldimage[id];
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    oldlist.push(id);
    setSelectedoptionalimage((oldState) =>
      oldState.filter((item) => item !== id)
    );
    const res = oldimage.map((el1) => ({
      name: el1,
      match: selectedoptionalimage.some((el2) => el2 === el1),
    }));
    setRemovemage(res);
  };

  const removeImagehotel = (id) => {
    // delete oldimage[id];
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    oldlisthotel.push(id);
    setSelectedhotelimage((oldState) => oldState.filter((item) => item !== id));
    const res = oldimagehotel.map((el1) => ({
      name: el1,
      match: selectedhotelimage.some((el2) => el2 === el1),
    }));
    setRemovemagehotel(res);
  };

  function handleChangeselectcountry(selectedOption) {
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    let value = selectedOption;
    getcountrybasetime(selectedOption);
    //  setHoteldetails({ ...hoteldetails, [name]: value });
  }

  let handleChangetaxselect = (i, selectedOption, nameval) => {
    let name = nameval;
    let value = selectedOption;
    let newval = [...inputFieldstax];
    inputFieldstax[i][name] = value;
    setInputFieldstax(newval);
  };
  const addInputField = () => {
    setRoombutton(false);
    setRoominfoupdatestatus(true);

    setRoominfo([
      ...roominfo,
      {
        room_beds: [],
        room_detail: {
          baby_status: false,
          extra_bed_status: false,
          room_id: "",
        },
        sort_order: 0,
        room_facilities: [],
        room_images: [],
        removed_room_img: [],
        room_imagesnew: [],
      },
    ]);
    setRoominfos([
      ...roominfos,
      {
        room_images: [],
      },
    ]);
  };
  const removeInputFields = (index) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    const deletedRow = roominfo[index];
    // setRemoveroomid(deletedRow.room_detail.room_id)
    removeroomid.push(deletedRow.room_detail.room_id);

    const rows = [...roominfo];
    rows.splice(index, 1);
    setRoominfo(rows);

    const rows1 = [...roominfos];
    rows1.splice(index, 1);
    setRoominfos(rows1);
  };

  let handleChangees = (i, data, name) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];
    roominfo[i]["room_detail"][name] = data;
    setRoominfo(newval);
  };
  let handleChangeesroomfac = (i, data, name) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];
    roominfo[i][name] = data;
    setRoominfo(newval);
  };

  const onFileChange = (i, data, name) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];
    roominfo[i][name] = data.target.files;

    setRoominfo(newval);
  };

  const removeImageRoom = (id, i) => {
    // delete oldimage[id];
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];

    let newva = [...removeRoom];
    let news = newva[i] ? [...newva[i].remove] : [];

    news.push(id);
    // newva[i] = news;
    newva[i] = {
      remove: news,
    };
    setRemoveRoom(newva);
    const updatedRoominfo = [...roominfo];
    updatedRoominfo[i] = {
      ...roominfo[i],
      room_images: roominfo[i].room_images.filter((item) => item !== id),
    };

    setRoominfo(updatedRoominfo);
  };

  const [otherhk, setOtherhk] = useState([{}]);
  const [inputFieldshk, setInputFieldshk] = useState([{}]);

  const addInputFieldHK = () => {
    setHkbutton(false);
    setHkupdatestatus(true);
    setHkfreequency([
      ...hkfreequency,
      {
        service: [],
        frequency: [],
      },
    ]);
  };

  const removeInputFieldsHK = (index) => {
    setHkupdatestatus(true);
    setHkbutton(false);

    const rows = [...hkfreequency];
    rows.splice(index, 1);
    setHkfreequency(rows);
  };

  let handleChangehk = (i, e) => {
    setHkbutton(false);

    setHkupdatestatus(true);

    let newval = [...hkfreequency];
    hkfreequency[i]["service"][e.target.name] = e.target.value;
    setHkfreequency(newval);
  };
  let handleChangehkother = (i, e) => {
    setHkbutton(false);

    setHkupdatestatus(true);

    let newval = [...hkfreequency];
    hkfreequency[i][e.target.name] = e.target.value;
    setHkfreequency(newval);
  };

  const [inputFieldstax, setInputFieldstax] = useState([
    {
      amount_type: "percentage",
      increment_type: "",
      service_charge_applicable: false,
      service_charge_status: false,
    },
  ]);
  const addInputFieldtax = () => {
    setTaxbutton(false);
    setTaxupdatestatus(true);

    setTax([
      ...tax,
      {
        amount_type: "percentage",
        increment_type: "",
        room_type: [],
        service_charge_applicable: false,
        service_charge_status: false,
      },
    ]);
  };
  const removeInputFieldstax = (index) => {
    setTaxbutton(false);

    setTaxupdatestatus(true);

    const rows = [...tax];
    rows.splice(index, 1);
    setTax(rows);
  };

  let handleChangetax = (i, e) => {
    setTaxbutton(false);

    setTaxupdatestatus(true);

    let newval = [...tax];
    tax[i][e.target.name] = e.target.value;
    setTax(newval);
  };

  let handleChangetaxtogg = (i, e) => {
    setTaxbutton(false);

    setTaxupdatestatus(true);

    let newval = [...tax];
    tax[i][e.target.name] = e.target.checked;
    setTax(newval);
  };

  let handleChangeesroomtypetax = (i, data, name) => {
    setTaxbutton(false);

    setTaxupdatestatus(true);

    let newval = [...tax];
    tax[i][name] = data;
    setTax(newval);
  };

  useEffect(() => {
    getall();
  },[]);

  useEffect(() => {
    gethotelinfo();
  },[gethotelinfo]);

  useEffect(() => {
    getRoomtype();
  },[getRoomtype]);
  
  useEffect(() => {
      // getall();
      // gethotelinfo();
      // getRoomtype();

      if (selectedfacility.length > 0) {
        for (let i = 0; i < selectedfacility.length; i++) {
          if (selectedfacility[i].value === "other") {
            setOther(!other);
          }
        }
      } else {
        setOther(false);
      }
      setser_length(service.length);
    },
    [identitystring, service.length,selectedfacility,roominfo.length,hoteldetails.length,other]);

  function handleChangeesinner(index, i, val, name) {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];
    roominfo[index]["room_beds"][i][name] = val;
    setRoominfo(newval);
  }
  function handleChangeesinnerbed(index, i, val, name) {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let newval = [...roominfo];
    roominfo[index]["room_beds"][i][name] = val;
    roominfo[index]["room_beds"][i]["bedsize"] = "";
    setRoominfo(newval);

    if (val !== other) {
      getbedtypebasesize(index, i, val, name);
    }
  }

  const getbedtypebasesize = async (index, i, val, name) => {
    const res = await fetch(`${Config.apiurl}be/bedsize/${val}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      let newval = [...roominfo];
      roominfo[index]["room_beds"][i]["bedsize"] = data;
      setRoominfo(newval);
    }
  };
  let handleChangetogghotel = (e) => {
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    let value = e.target.checked;
    let name = e.target.name;
    setHoteldetails({ ...hoteldetails, [name]: value });
  };

  console.log(roominfo);
  let handleChangetoggroom = (i, e) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    let value = e.target.checked;
    let name = e.target.name;
    let newval = [...roominfo];
    roominfo[i]["room_detail"][name] = value;
    setRoominfo(newval);
  };
  let handleChangetogghk = (i, val, name) => {
    setHkbutton(false);

    setHkupdatestatus(true);

    let value = val;
    let newval = [...hkfreequency];
    hkfreequency[i][name] = value;
    setHkfreequency(newval);
  };
  const addInputFieldRoom = (inds, count, nam) => {
    setRoombutton(false);

    setRoominfoupdatestatus(true);

    const newInputFields = [];
    let newval = [...roominfo];

    for (let i = 0; i < count; i++) {
      newInputFields.push({
        bed_count: "",
        bed_type: "",
        bedsize: "",
        bedtype: "",
        other: "",
      });
    }
    roominfo[inds]["room_detail"][nam] = count;
    roominfo[inds]["room_beds"] = newInputFields;
    setRoominfo(newval);
  };

  useEffect(() => {
    if (checkintimenew) {
      const checkin = time.find((option) => option.value === checkintimenew);
      if (checkin) {
        setCheckintime(checkin);
      }
    }

    if (checkouttimenew) {
      const checkout = time.find((option) => option.value === checkouttimenew);
      if (checkout) {
        setCheckouttime(checkout);
      }
    }
  }, [checkintimenew, checkouttimenew]);

  let hotelinfosavenext = () => {
    setPrev(false);

    setLoaderspin(true);

    setHotelbutton(true);
    let isFormValid = true;

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_]+$/;
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const errors = {};
    if (!hoteldetails.hotel_name) {
      isFormValid = false;

      errors.hotel_name = "Hotel name is required!";
    }
    else if (!noSpecialCharRegex.test(hoteldetails.hotel_name)) {
      isFormValid = false;
      errors.hotel_name = "Hotel name should not contain this special character";
    }
    else if (hoteldetails.hotel_name.length > 100) {
      isFormValid = false;
      errors.hotel_name = `Hotel name cannot be more than 100 characters!`;
    }

    if (!hoteldetails.corporate_name) {
      isFormValid = false;

      errors.corporate_name = "Corporate name is required!";
    }
    else if (!noSpecialCharRegex.test(hoteldetails.corporate_name)) {
      isFormValid = false;
      errors.corporate_name = "Corporate name should not contain this special character";
    }
    else if (hoteldetails.corporate_name.length > 100) {
      isFormValid = false;
      errors.corporate_name = `Corporate name cannot be more than 100 characters!`;
    }

    if (!hoteldetails.calling_code) {
      isFormValid = false;

      errors.calling_code = "calling code is required!";
    }
    if (!hoteldetails.phone_no) {
      isFormValid = false;

      errors.phone_no = "Phone Number is required!";
    }
    if (!hoteldetails.reservation_email) {
      isFormValid = false;

      errors.reservation_email = "Reservation email is required!";
    } else if (!regex.test(hoteldetails.reservation_email)) {
      isFormValid = false;

      errors.reservation_email = "This is not a valid email format!";
    }
    if (!checkintime) {
      isFormValid = false;

      errors.checkintime = "Checkin is required!";
    }
    if (!checkouttime) {
      isFormValid = false;

      errors.checkouttime = "Checkout time is required!";
    }
    if (!hoteldetails.extranet_admin_email) {
      isFormValid = false;

      errors.extranet_admin_email = "Extranet admin email is required!";
    } else if (!regex.test(hoteldetails.extranet_admin_email)) {
      isFormValid = false;

      errors.extranet_admin_email = "This is not a valid email format!";
    }
    if (!hoteldetails.address) {
      isFormValid = false;

      errors.address = "Hotel address is required!";
    }

    if (!hoteldetails.min_room_rate) {
      isFormValid = false;

      errors.min_room_rate = "Minimum room rate is required!";
    }
    if (!hoteldetails.max_room_rate) {
      isFormValid = false;

      errors.max_room_rate = "Maximum room rate is required!";
    }
    if (!hoteldetails.no_of_rooms) {
      isFormValid = false;

      errors.no_of_rooms = "Number of rooms is required!";
    }
    if (!hoteldetails.website_url) {
      isFormValid = false;

      errors.website_url = "Website Url is required!";
    }
    else if (!urlRegex.test(hoteldetails.website_url)) {
      isFormValid = false;
      errors.website_url = "Please enter a valid URL (e.g., www.example.com or https://example.com)";
    }
    // if (!hoteldetails.extranet_login_nos) {
    //   isFormValid = false;

    // errors.extranet_login_nos = "Extranet login numbers required!";
    // }

    if (
      newhotellogo === null ||
      newhotellogo === undefined ||
      !newhotellogo ||
      newhotellogo === "" ||
      !newhotellogo.hotel_logo ||
      newhotellogo.hotel_logo === ""
    ) {
      if (!oldlogo) {
        isFormValid = false;

        errors.hotel_logo = "Hotel logo is required!";
      }
    } else {
      if (
        newhotellogo.hotel_logo.type === "image/jpeg" ||
        newhotellogo.hotel_logo.type === "image/jpg" ||
        newhotellogo.hotel_logo.type === "image/png" ||
        newhotellogo.hotel_logo.type === "image/webp" ||
        newhotellogo.hotel_logo.type === "image/avif"
      ) {
        console.log("");
        // if (newhotellogo.hotel_logo.size > 1024 * 1000) {
        //   isFormValid = false;

        //   errors.hotel_logo =
        //     "maximum file size 1 mb...please upload an image less than 1 mb";
        // }
      } else {
        isFormValid = false;

        errors.hotel_logo = "Only support jpg, jpeg, png,webp image!";
      }
    }
    setError1(errors);
    const errors1 = {};
    if (selectedExtanet.length === 0) {
      isFormValid = false;

      errors1.selectedExtanet = "Extranet language is required!";
    } else {
      if (selectedExtanet.length > 4) {
        isFormValid = false;

        errors1.selectedExtanet = "Unable to choose more than 4 languages!";
      }
    }
    setError2(errors1);

    const errors2 = {};
    if (selectedBookinglang.length === 0) {
      isFormValid = false;

      errors2.selectedBookinglang = "Booking engine language is required!";
    }
    if ( selectedcategory.length === 0) {
      isFormValid = false;

      errors2.selectedcategory = "Category  is required!";
    }

    if (selectedcountry.length === 0) {
      isFormValid = false;

      errors2.selectedcountry = "Country is required!";
    }

    if (selectedcurrency.length === 0) {
      isFormValid = false;

      errors2.selectedcurrency = "currency is required!";
    }
    if (selectedtimezone.length === 0) {
      isFormValid = false;

      errors2.selectedtimezone = "Timezone is required!";
    }
    if (optionalimage.length === 0) {
      if (selectedoptionalimage.length === 0) {
        isFormValid = false;

        errors2.optionalimage = "Optional image is required!";
      }
    } else {
      for (var i = 0; i < optionalimage.length; i++) {
        if (
          optionalimage[i].type === "image/jpeg" ||
          optionalimage[i].type === "image/jpg" ||
          optionalimage[i].type === "image/png" ||
          optionalimage[i].type === "image/webp" ||
          optionalimage[i].type === "image/avif"
        ) {
          console.log("");
         
        } else {
          isFormValid = false;

          errors2.optionalimage = "Only support jpg, jpeg, png, webp image!";
        }
      }
    }
    if ( hotelimage.length === 0) {
      if (selectedhotelimage.length === 0) {
        isFormValid = false;

        errors2.hotelimage = "Hotel image is required!";
      }
    } else {
      for (var k = 0; k < hotelimage.length; k++) {
        if (
          hotelimage[k].type === "image/jpeg" ||
          hotelimage[k].type === "image/jpg" ||
          hotelimage[k].type === "image/png" ||
          hotelimage[k].type === "image/webp" ||
          hotelimage[k].type === "image/avif"
        ) {
         console.log("");
         
        } else {
          isFormValid = false;

          errors2.hotelimage = "Only support jpg, jpeg, png, webp image!";
        }
      }
      // setErrorall(errors5);
    }
    if (propertytypehotel.length === 0) {
      isFormValid = false;

      errors2.propertytypehotel = "Property type is required!";
    }
    setError3(errors2);

    // const errors3 = {};
    // if (propertytypehotel.length === 0) {
    //   isFormValid = false;

    //   errors3.propertytypehotel = "Property type is required!";
    // }
    // setError4(errors3);

    const errors4 = {};
    if (hoteldetails.credit_card_status === true) {
      if (selectedCard.length === 0) {
        isFormValid = false;

        errors4.selectedCard = "Card is required!";
      }
    }
      if (hoteldetails.channel_manager_status === true) {
        if (selectedchannelmanager.length === 0) {
          isFormValid = false;
  
          errors4.channel_manager_name = "Channel manager name is required!";
        }
      }
      if (hoteldetails.pms_status === true) {
        if (selectedpms.length === 0) {
          isFormValid = false;
  
          errors4.pms_name = "PMS name is required!";
        }
      }
      setError5(errors4);
    const errors5 = {};
    // if (hoteldetails.channel_manager_status === true) {
    //   if (selectedchannelmanager.length === 0) {
    //     isFormValid = false;

    //     errors5.channel_manager_name = "Channel manager name is required!";
    //   }
    // }
    // if (hoteldetails.pms_status === true) {
    //   if (selectedpms.length === 0) {
    //     isFormValid = false;

    //     errors5.pms_name = "PMS name is required!";
    //   }
    // }
    // if ( selectedcategory.length === 0) {
    //   isFormValid = false;

    //   errors5.selectedcategory = "Category  is required!";
    // }

    // if (selectedcountry.length === 0) {
    //   isFormValid = false;

    //   errors5.selectedcountry = "Country is required!";
    // }

    // if (selectedcurrency.length === 0) {
    //   isFormValid = false;

    //   errors5.selectedcurrency = "currency is required!";
    // }
    // if (selectedtimezone.length === 0) {
    //   isFormValid = false;

    //   errors5.selectedtimezone = "Timezone is required!";
    // }
    // if (optionalimage.length === 0) {
    //   if (selectedoptionalimage.length === 0) {
    //     isFormValid = false;

    //     errors5.optionalimage = "Optional image is required!";
    //   }
    // } else {
    //   for (var i = 0; i < optionalimage.length; i++) {
    //     if (
    //       optionalimage[i].type === "image/jpeg" ||
    //       optionalimage[i].type === "image/jpg" ||
    //       optionalimage[i].type === "image/png" ||
    //       optionalimage[i].type === "image/webp" ||
    //       optionalimage[i].type === "image/avif"
    //     ) {
    //       console.log("");
    //       // if (optionalimage[i].size > 1024 * 1000) {
    //       //   isFormValid = false;

    //       //   errors5.optionalimage =
    //       //     "maximum file size 1 mb...please upload an image less than 1 mb";
    //       // }
    //     } else {
    //       isFormValid = false;

    //       errors5.optionalimage = "Only support jpg, jpeg, png, webp image!";
    //     }
    //   }
    // }
    // if ( hotelimage.length === 0) {
    //   if (selectedhotelimage.length === 0) {
    //     isFormValid = false;

    //     errors5.hotelimage = "Hotel image is required!";
    //   }
    // } else {
    //   for (var k = 0; k < hotelimage.length; k++) {
    //     if (
    //       hotelimage[k].type === "image/jpeg" ||
    //       hotelimage[k].type === "image/jpg" ||
    //       hotelimage[k].type === "image/png" ||
    //       hotelimage[k].type === "image/webp" ||
    //       hotelimage[k].type === "image/avif"
    //     ) {
    //       // if (hotelimage[i].size > 1024 * 1000) {
    //       //   isFormValid = false;

    //       //   errors5.hotelimage =
    //       //     "maximum file size 1 mb...please upload an image less than 1 mb";
    //       // }
    //       console.log("");
    //     } else {
    //       isFormValid = false;

    //       errors5.hotelimage = "Only support jpg, jpeg, png, webp image!";
    //     }
    //   }
    //   setErrorall(errors5);
    // }

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_name", hoteldetails.hotel_name);
      formData.append("corporate_name", hoteldetails.corporate_name);
      formData.append("country", selectedcountry.value);
      formData.append("timezone", selectedtimezone.value);
      formData.append("calling_code", hoteldetails.calling_code);
      formData.append("phone_number", hoteldetails.phone_no);
      formData.append("reservation_email", hoteldetails.reservation_email);
      formData.append("check_in_time", checkintime.value);
      formData.append("check_out_time", checkouttime.value);
      formData.append("address", hoteldetails.address);
      for (let i = 0; i < selectedExtanet.length; i++) {
        formData.append(`extranet_language[${i}]`, selectedExtanet[i].value);
      }
      for (let i = 0; i < selectedBookinglang.length; i++) {
        formData.append(
          `booking_engine_language[${i}]`,
          selectedBookinglang[i].value
        );
      }
      if (hoteldetails.licence_number) {
        formData.append("licence_number", hoteldetails.licence_number);
      } else {
        formData.append("licence_number", "");
      }
      if (hoteldetails.vat_registration_number) {
        formData.append(
          "vat_registration_number",
          hoteldetails.vat_registration_number
        );
      } else {
        formData.append("vat_registration_number", "");
      }
      formData.append("property_type", propertytypehotel.value);
      formData.append("category", selectedcategory.value);
      formData.append("default_currency", selectedcurrency.value);
      formData.append("min_room_rate", hoteldetails.min_room_rate);
      formData.append("max_room_rate", hoteldetails.max_room_rate);
      formData.append("no_of_rooms", hoteldetails.no_of_rooms);
      formData.append("website_url", hoteldetails.website_url);
      formData.append(
        "extranet_admin_email",
        hoteldetails.extranet_admin_email
      );

      // formData.append("extranet_login_nos", hoteldetails.extranet_login_nos);

      for (let i = 0; i < hotelimage.length; i++) {
        formData.append(`hotel_image[${i}]`, hotelimage[i]);
      }
      for (let i = 0; i < optionalimage.length; i++) {
        formData.append(`optional_images[${i}]`, optionalimage[i]);
      }
      if (newhotellogo.hotel_logo) {
        formData.append("logo", newhotellogo.hotel_logo);
      }
      // formData.append("logo", hoteldetails.hotel_logo);
      formData.append(
        "channel_manager_status",
        hoteldetails.channel_manager_status
      );
      if (hoteldetails.channel_manager_status === true) {
        formData.append("channel_manager", selectedchannelmanager.value);
      }
      formData.append("pms_status", hoteldetails.pms_status);
      if (hoteldetails.pms_status === true) {
        formData.append("pms", selectedpms.value);
      }
      formData.append("credit_card_status", hoteldetails.credit_card_status);
      if (hoteldetails.credit_card_status === true) {
        for (let i = 0; i < selectedCard.length; i++) {
          formData.append(`credit_cards[${i}]`, selectedCard[i].value);
        }
      }

      if (oldlist.length > 0) {
        for (var j = 0; j < oldlist.length; j++) {
          formData.append(`removed_images[optional_images][${j}]`, oldlist[j]);
        }
      }
      if (oldlisthotel.length > 0) {
        for (var y = 0; y < oldlisthotel.length; y++) {
          formData.append(`removed_images[hotel_image][${y}]`, oldlisthotel[y]);
        }
      }
      axios
        .post(
          `${Config.apiurl}temporary-hotel/update-details/hotel_info/${hotelsid}`,
          formData,
          {}
        )
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            console.log("error");
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("sucess");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setReviewhotel(false);

            setHoteldetails([]);
            setSelectedcountry([]);
            setSelectedtimezone([]);
            setSelectedExtranet([]);
            setSelectedBookinglang([]);
            setPropertytypehotel([]);
            setSelectedcategory([]);
            setSelectedcurrency([]);
            setSelectedchannelmanager([]);
            setSelectedPMS([]);
            setselectedCard([]);
            setHotelimage([]);
            setRemovemage([]);
            setRemovemagehotel([]);
            setOptionalimage([]);
            setNewhotellogo([]);

            gethotelinfo();

            setTimeout(() => setTab("Room-info"), 3000);

            // setPrev(true)
          }
        });
    } else {
      setToastmodal(true);
      setToastmessage("Validation error occur");
      setToasttype("error");
      setToastlarge(false);
      window.scrollTo(0, 0);
    }
  };

  //validation hotel information
  function roomprevtab() {
    if (roominfoupdatestatus === true) {
      setRoomprev(true);
    } else {
      //  setPrev(true)
      setTab("Hotel-info");
      setHotelinfoupdatestatus(false);
    }
  }

  function hkprevtab() {
    if (hkupdatestatus === true) {
      setHkprev(true);
    } else {
      //  setPrev(true)
      setTab("Room-info");
      setHkupdatestatus(false);
    }
  }

  function taxprevtab() {
    if (taxupdatestatus === true) {
      setTaxprev(true);
    } else {
      setTab("Freequency");
      setTaxupdatestatus(false);
    }
  }

  function loyalprevtab() {
    if (loyalupdatestatus === true) {
      setLoyalprev(true);
    } else {
      //  setPrev(true)
      setTab("Tax");
      setLoyalupdatestatus(false);
    }
  }
  //validate room information

  let roominfonext = (tag) => {
    setPrev(false);

    setLoaderspin(true);

    setRoombutton(true);
    let isFormValid = true;

    let arr = [];
    for (let i = 0; i < roominfo.length; i++) {
      const errors = {};
      if (!roominfo[i].room_detail.room_title) {
        isFormValid = false;
        errors.room_name = "Room name is required!";
      }
      if (roominfo[i].room_facilities.length === 0) {
        isFormValid = false;
        errors.facility = "Facility is required!";
      } else {
        for (let k = 0; k < roominfo[i].room_facilities.length; k++) {
          if (roominfo[i].room_facilities[k].value === "other") {
            if (!roominfo[i].room_detail.otherfacilitry) {
              isFormValid = false;
              errors.otherfacilitry = "Facility name is required!";
            }
          }
        }
      }
      if (!roominfo[i].room_detail.room_inventory) {
        isFormValid = false;
        errors.room_inventory = "Room inventory is required!";
      }
      if (!roominfo[i].room_detail.min_no_adults) {
        isFormValid = false;
        errors.minimum_adult = "Minimum adult is required!";
      }
      if (!roominfo[i].room_detail.max_no_adults) {
        isFormValid = false;
        errors.max_adult = "Maximum adult is required!";
      }
      if (
        roominfo[i].room_detail.max_no_child !== undefined &&
        roominfo[i].room_detail.max_no_child !== "" &&
        roominfo[i].room_detail.max_no_child !== null
      ) {
        if (roominfo[i].room_detail.max_no_child === 0) {
          console.log("");
        }
      } else {
        isFormValid = false;
        errors.max_child = "Maximum child is required!";
      }

      if (
        roominfo[i].room_detail.max_child_age !== undefined &&
        roominfo[i].room_detail.max_child_age !== "" &&
        roominfo[i].room_detail.max_child_age !== null
      ) {
        if (roominfo[i].room_detail.max_child_age === 0) {
          console.log("");
        }
      } else {
        isFormValid = false;
        errors.max_child_age = "Maximum child is required!";
      }
      // if (roominfo[i].room_detail.max_no_child) {
      //   console.log();
      // }
      // else{
      //   console.log(roominfo[i].room_detail.max_no_child);
      //   isFormValid = false;
      //   errors.max_child = "Maximum child is required!";

      // }
      // if (roominfo[i].room_detail.max_child_age!== undefined) {
      //   console.log();
      // }
      // else{
      //   isFormValid = false;
      //   errors.max_child_age = "Maximum child age is required!";

      // }
      if (!roominfo[i].room_detail.room_area) {
        isFormValid = false;
        errors.room_area = "Room area is required!";
      }
      if (roominfo[i].room_detail.extra_bed_status === true) {
        if (!roominfo[i].room_detail.extra_bed_charge) {
          isFormValid = false;
          errors.extra_bed_charge = "Bed charge is required!";
        }
      }
      if (!roominfo[i].room_detail.no_of_rooms) {
        isFormValid = false;
        errors.room_nos = "Room number is required!";
      } else {
        let array = [];
        for (let j = 0; j < roominfo[i].room_detail.no_of_rooms; j++) {
          const errors1 = {};
          if (!roominfo[i].room_beds[j].bed_count) {
            isFormValid = false;
            errors1.no_of_beds_per_room = "Number of bed is required!";
          }
          if (!roominfo[i].room_beds[j].bed_type) {
            isFormValid = false;
            errors1.bed_type = "Bed type is required!";
          } else if (roominfo[i].room_beds[j].bed_type === "other") {
            if (!roominfo[i].room_beds[j].other) {
              isFormValid = false;
              errors1.other = "Bed type is required!";
            }
          }
          if (!roominfo[i].room_beds[j].bedsize) {
            isFormValid = false;
            errors1.bed_size = "Bed size is required!";
          }
          array.push(errors1);
        }
        errors.roomNo = array;
      }
      if (!roominfo[i].room_detail.description) {
        isFormValid = false;
        errors.room_des = "Room description is required!";
      }

      arr.push(errors);
    }
    setError6(arr);

    let arra = [];
    for (let i = 0; i < roominfo.length; i++) {
      const errors = {};
      if (
        !roominfo[i].room_imagesnew ||
        roominfo[i].room_imagesnew.length === 0
      ) {
        if (!roominfo[i].room_images || roominfo[i].room_images.length === 0) {
          isFormValid = false;
          errors.room_image = "Room images is required!";
        } else {
          if (roominfo[i].room_images.length < 5) {
            isFormValid = false;
            errors.room_image = "At least 5 Room images is required!";
          }
        }
      } else {
        let newimg = roominfo[i].room_imagesnew.length;
        let old = roominfo[i].room_images.length;
        let total = newimg + old;

        if (total < 5) {
          isFormValid = false;
          errors.room_image = "At least 5 Room images is required!";
        } else {
          for (var j = 0; j < roominfo[i].room_imagesnew.length; j++) {
            if (
              roominfo[i].room_imagesnew[j].type === "image/jpeg" ||
              roominfo[i].room_imagesnew[j].type === "image/jpg" ||
              roominfo[i].room_imagesnew[j].type === "image/png" ||
              roominfo[i].room_imagesnew[j].type === "image/webp" ||
              roominfo[i].room_imagesnew[j].type === "image/avif"
            ) {
              console.log("");
              // if ( roominfo[i].room_imagesnew[j].size > 1024 * 1000) {
              //   isFormValid = false;
              //   errors.room_image =
              //     "maximum file size 1 mb...please upload an image less than 1 mb";
              // }
            } else {
              isFormValid = false;
              errors.room_image = "Only support jpg, jpeg, png, webp image!";
            }
          }
        }
      }
      arra.push(errors);
    }
    setError11(arra);

    if (isFormValid === true) {
      const formData = new FormData();
      for (let i = 0; i < roominfo.length; i++) {
        if (i === 0) {
          formData.append(`room_info[${i}][basic_room_status]`, 1);
        } else {
          formData.append(`room_info[${i}][basic_room_status]`, 0);
        }
        formData.append(
          `room_info[${i}][title]`,
          roominfo[i].room_detail.room_title
        );
        if (roominfo[i].room_detail.room_id) {
          formData.append(
            `room_info[${i}][room_id]`,
            roominfo[i].room_detail.room_id
          );
        } else {
          formData.append(`room_info[${i}][room_id]`, "");
        }
        for (let j = 0; j < roominfo[i].room_facilities.length; j++) {
          formData.append(
            `room_info[${i}][facility][${j}]`,
            roominfo[i].room_facilities[j].value
          );
          if (roominfo[i].room_facilities[j].value === "other") {
            formData.append(
              `room_info[${i}][other_facilities]`,
              roominfo[i].room_detail.otherfacilitry
            );
          }
        }
        formData.append(
          `room_info[${i}][room_inventory]`,
          roominfo[i].room_detail.room_inventory
        );
        formData.append(
          `room_info[${i}][min_no_of_adult]`,
          roominfo[i].room_detail.min_no_adults
        );
        formData.append(
          `room_info[${i}][max_no_adult]`,
          roominfo[i].room_detail.max_no_adults
        );
        formData.append(
          `room_info[${i}][max_no_child]`,
          roominfo[i].room_detail.max_no_child
        );
        formData.append(
          `room_info[${i}][max_child_age]`,
          roominfo[i].room_detail.max_child_age
        );
        formData.append(
          `room_info[${i}][baby_status]`,
          roominfo[i].room_detail.baby_status
        );
        formData.append(
          `room_info[${i}][description]`,
          roominfo[i].room_detail.description
        );
        formData.append(
          `room_info[${i}][room_area]`,
          roominfo[i].room_detail.room_area
        );
        if (roominfo[i].room_detail.extra_bed_status === true) {
          formData.append(`room_info[${i}][extra_bed_status]`, 1);
        } else {
          formData.append(`room_info[${i}][extra_bed_status]`, 0);
        }
        if (roominfo[i].room_detail.extra_bed_status === true) {
          formData.append(
            `room_info[${i}][extra_bed_rate]`,
            roominfo[i].room_detail.extra_bed_charge
          );
        }

        formData.append(
          `room_info[${i}][nos_of_rooms]`,
          roominfo[i].room_detail.no_of_rooms
        );
        for (let j = 0; j < roominfo[i].room_beds.length; j++) {
          formData.append(
            `room_info[${i}][room][${j}][bed_count]`,
            roominfo[i].room_beds[j].bed_count
          );
          formData.append(
            `room_info[${i}][room][${j}][bed_type]`,
            roominfo[i].room_beds[j].bed_type
          );
          if (roominfo[i].room_beds[j].bed_type === "other") {
            formData.append(
              `room_info[${i}][room][${j}][other_type]`,
              roominfo[i].room_beds[j].other
            );
          }
          formData.append(
            `room_info[${i}][room][${j}][bedsize]`,
            roominfo[i].room_beds[j].bedsize
          );
        }

        if (roominfo[i].room_imagesnew) {
          for (let j = 0; j < roominfo[i].room_imagesnew.length; j++) {
            formData.append(
              `room_info[${i}][image][${j}]`,
              roominfo[i].room_imagesnew[j]
            );
          }
        }
      }
      if (removeRoom.length > 0) {
        for (let i = 0; i < removeRoom.length; i++) {
          // if(removeRoom[i]!==null||removeRoom[i]!==""||removeRoom[i]!==undefined||removeRoom[i] ||removeRoom[i].remove.length>0 ||removeRoom[i].remove)
          if (
            removeRoom[i] &&
            removeRoom[i].remove &&
            removeRoom[i].remove.length > 0
          ) {
            for (let j = 0; j < removeRoom[i].remove.length; j++) {
              formData.append(
                `room_info[${i}][removed_images][${j}]`,
                removeRoom[i].remove[j]
              );
            }
          }
        }
      }
      if (removeroomid.length > 0) {
        for (let j = 0; j < removeroomid.length; j++) {
          formData.append(`removed_rooms[${j}]`, removeroomid[j]);
        }
      }

      axios
        .post(
          `${Config.apiurl}temporary-hotel/update-details/room_info/${hotelsid}`,
          formData,
          {
            headers: {
              "Content-type": "multipart/formdata",
            },
          }
        )
        .then((res) => {
          setLoaderspin(false);

          if (res.status_code !== "200" || !res) {
            console.log("error");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("sucess");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setRoominfo([]);
            setRemoveRoom([]);
            gethotelinfo();

            if (tag !== "room") {
              setTimeout(() => setTab("Freequency"), 3000);

              setRemoveroomid([]);
              setRemoveRoom([]);
              setReviewroom(false);

              setRoominfoupdatestatus(false);
              getRoomtype();

              //setPrev(true)
            }
          }
        });
    } else {
      setLoaderspin(false);
      // document.getElementById("scroller").scroll(0,0)
      // const contentContainer = document.getElementById('contentContainer');
      // contentContainer.scrollTo(0, 0);
      setToastmodal(true);
      setToastmessage("Validation error occur");
      setToasttype("error");
      setToastlarge(false);
      window.scrollTo(0, 0);
    }
  };

  //validate room information

  //validate hotel frequency

  let hfnext = (tag) => {
    setPrev(false);

    setLoaderspin(true);

    setHkbutton(true);
    let isFormValid = true;
    let arr = [];
    for (let i = 0; i < hkfreequency.length; i++) {
      const errors = {};
      if (!hkfreequency[i].service || !hkfreequency[i].service.label) {
        isFormValid = false;
        errors.service_name = "Service name is required!";
      }
      if (
        (!hkfreequency[i].frequency && !hkfreequency[i].frequency_id) ||
        hkfreequency[i].frequency.length === 0
      ) {
        isFormValid = false;
        errors.frequency = "Frequency is required!";
      } else if (hkfreequency[i].frequency.value === "other") {
        if (!hkfreequency[i].other_value) {
          isFormValid = false;
          errors.other_value = "Frequency name is required!";
        }
      }
      arr.push(errors);
    }
    setError7(arr);
    if (isFormValid === true) {
      let newnum = 0;
      const formData = new FormData();
      for (let i = 0; i < hkfreequency.length; i++) {
        if (hkfreequency[i].service.value) {
          formData.append(
            `hk_frequency[${i}][service]`,
            hkfreequency[i].service.value
          );
          if (hkfreequency[i].frequency.value) {
            formData.append(
              `hk_frequency[${i}][frequency]`,
              hkfreequency[i].frequency.value
            );
          } else if (hkfreequency[i].frequency === "not_available") {
            formData.append(`hk_frequency[${i}][frequency]`, "not_available");
          }
          if (hkfreequency[i].frequency.value === "other") {
            formData.append(
              `hk_frequency[${i}][other_frequency]`,
              hkfreequency[i].other_value
            );
          }
        } else {
          formData.append(
            `new_service[${newnum}][service]`,
            hkfreequency[i].service.label
          );
          if (hkfreequency[i].frequency.value) {
            formData.append(
              `new_service[${newnum}][frequency]`,
              hkfreequency[i].frequency.value
            );
          } else if (hkfreequency[i].frequency === "not_available") {
            formData.append(`hk_frequency[${i}][frequency]`, "not_available");
          }
          if (hkfreequency[i].frequency.value === "other") {
            formData.append(
              `new_service[${newnum}][other_frequency]`,
              hkfreequency[i].other_value
            );
          }
          newnum = newnum + 1;
        }
      }
      axios
        .post(
          `${Config.apiurl}temporary-hotel/update-details/hk_frequency/${hotelsid}`,
          formData,
          {}
        )
        .then((res) => {
          setLoaderspin(false);

          if (res.status_code !== "200" || !res) {
            console.log("error");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("sucess");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setHkfreequency([]);
            gethotelinfo();

            if (tag !== "hk") {
              setTimeout(() => setTab("Tax"), 3000);
              setHkupdatestatus(false);
              setReviewhk(false);

              // setPrev(true)
            }
          }
        });
    } else {
      setLoaderspin(false);

      window.scrollTo(0, 0);
      setToastmodal(true);
      setToastmessage("Validation error occur");
      setToasttype("error");
      setToastlarge(false);
    }
  };

  //validate hotel frequency

  let handleChangetoggtax = (e) => {
    setTaxbutton(false);

    setTaxupdatestatus(true);

    // let value = e.target.checked;
    let value1 = e.target.value;
    let name = e.target.name;

    if (value1 === "false") {
      const value = false;
      setHoteldetails({ ...hoteldetails, [name]: value });
    } else if (value1 === "true") {
      const value = true;
      setHoteldetails({ ...hoteldetails, [name]: value });
    }
  };

  let handleChangetoggloyalcheck = (e) => {
    setLoyalbutton(false);
    setLoyalupdatestatus(true);
    // let value = e.target.checked;
    let value1 = e.target.value;
    let name = e.target.name;

    if (value1 === "false") {
      const value = false;
      setHoteldetails({ ...hoteldetails, [name]: value });
    } else if (value1 === "true") {
      const value = true;
      setHoteldetails({ ...hoteldetails, [name]: value });
    }
  };

  //validate tax details

  let taxnext = (tag) => {
    setPrev(false);

    setLoaderspin(true);

    setTaxbutton(true);
    // let isFormValid = true;

    // let arr = [];
    // for (let i = 0; i < tax.length; i++) {
    //   const errors = {};
    //   if (!tax[i].title) {
    //     isFormValid = false;

    //     errors.tax_name = "Tax name is required!";
    //   }
    //   if (!tax[i].value || !tax[i].amount_type) {
    //     isFormValid = false;

    //     errors.tax_dis = "Discount is required!";
    //   }
    //   if (!tax[i].increment_type) {
    //     isFormValid = false;

    //     errors.increment_type = "This field is required!";
    //   }
    //   if (!tax[i].increment_frequency) {
    //     isFormValid = false;

    //     errors.increment_frequency = "Frequency is required!";
    //   }
    //   if (!tax[i].message) {
    //     isFormValid = false;

    //     errors.tax_comment = "Comment is required!";
    //   }
    //   if (!tax[i].room_type || tax[i].room_type.length === 0) {
    //     isFormValid = false;
    //     errors.roomtype = "Room type is required!";
    //   }
    //   arr.push(errors);
    // }
    // setError8(arr);

    // if (isFormValid === true) {
      const formData = new FormData();

      formData.append("tax_include_status", hoteldetails.tax_include_status);

      for (let i = 0; i < tax.length; i++) {
        formData.append(`tax[${i}][title]`, tax[i].title);
        formData.append(`tax[${i}][discount]`, tax[i].value);
        formData.append(`tax[${i}][amount_type]`, tax[i].amount_type);
        formData.append(`tax[${i}][increment_type]`, tax[i].increment_type);
        formData.append(
          `tax[${i}][increment_frequency]`,
          tax[i].increment_frequency
        );
        formData.append(
          `tax[${i}][service_charge_status]`,
          tax[i].service_charge_status
        );
        formData.append(
          `tax[${i}][service_charge_applicable]`,
          tax[i].service_charge_applicable
        );
        formData.append(`tax[${i}][message]`, tax[i].message);
        for (let j = 0; j < tax[i].room_type.length; j++) {
          formData.append(
            `tax[${i}][room_type][${j}]`,
            tax[i].room_type[j].value
          );
        }
      }
      axios
        .post(
          `${Config.apiurl}temporary-hotel/update-details/tax_info/${hotelsid}`,
          formData,
          {}
        )
        .then((res) => {
          setLoaderspin(false);

          if (res.status_code !== "200" || !res) {
            console.log("error");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("sucess");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTax([]);
            gethotelinfo();
            if (tag !== "tax") {
              setTimeout(() => setTab("Loyalty"), 3000);
              setTaxupdatestatus(false);
              setReviewtax(false);

              // setPrev(true)
            }
          }
        });
    // } else {
    //   setLoaderspin(false);

    //   window.scrollTo(0, 0);
    //   setToastmodal(true);
    //   setToastmessage("Validation error occur");
    //   setToasttype("error");
    //   setToastlarge(false);
    // }
  };
  //validate tax details

  //loyalty full

  const [checke, setChecked] = useState("no");
  const [taxchecke, setTaxChecked] = useState("no");

  const [display, setDisplay] = useState("no");
  const [selectedMulti, setselectedMulti] = useState([]);

  const [inputFieldsloyalty, setInputFieldsloyalty] = useState([
    {
      language: "",
      name: "",
      loyalty: "",
      benefit: [],
      orand: "or",
      expdate: false,
      expiry_status: false,
    },
    {
      language: "",
      name: "",
      loyalty: "",
      benefit: [],
      expdate: false,
      orand: "or",
      expiry_status: false,
    },
    {
      language: "",
      name: "",
      loyalty: "",
      benefit: [],
      expdate: false,
      orand: "or",
      expiry_status: false,
    },
    {
      language: "",
      name: "",
      loyalty: "",
      benefit: [],
      expdate: false,
      orand: "or",
      expiry_status: false,
    },
  ]);

  const addInputFieldloyal = () => {
    setLoyalbutton(false);
    setLoyalupdatestatus(true);

    const updatedLoyaltyStructure = {
      ...loyalty,
    };

    const newLoyaltyLevel = {
      name: "New Loyalty Level",
      condition: "or",
      expiry_status: false,
      benefits: [],
    };

    updatedLoyaltyStructure.loyalty_levels.push(newLoyaltyLevel);

    setLoyalty(updatedLoyaltyStructure);
  };

  const removeInputFieldsloyalty = (index) => {
    setLoyalbutton(false);
    setLoyalupdatestatus(true);

    const deletedRow = loyalty.loyalty_levels[index];
    removeloyalid.push(deletedRow.level_id);

    const updatedLevels = loyalty.loyalty_levels.filter((_, i) => i !== index);
    setLoyalty((prevLoyalty) => ({
      ...prevLoyalty,
      loyalty_levels: updatedLevels,
    }));
  };
  useEffect(() => {
    if (selectedMulti.length > 0) {
      for (let i = 0; i < selectedMulti.length; i++) {
        if (selectedMulti[i].value === "other") {
          setOther(!other);
        }
      }
    } else {
      setOther(false);
    }

    if (
      hoteldetails.hotel_form_status === 3 &&
      ((hoteldetails.tab_list.includes("hotel_info") && !reviewhotel) ||
        (!hoteldetails.tab_list.includes("hotel_info") && reviewhotel)) &&
      ((hoteldetails.tab_list.includes("room_info") && !reviewroom) ||
        (!hoteldetails.tab_list.includes("room_info") && reviewroom)) &&
      ((hoteldetails.tab_list.includes("hk_frequency") && !reviewhk) ||
        (!hoteldetails.tab_list.includes("hk_frequency") && reviewhk)) &&
      ((hoteldetails.tab_list.includes("tax") && !reviewtax) ||
        (!hoteldetails.tab_list.includes("tax") && reviewtax)) &&
      ((hoteldetails.tab_list.includes("loyalty") && !reviewloyal) ||
        (!hoteldetails.tab_list.includes("loyalty") && reviewloyal))
    ) {
      // Set data in localStorage here
      localStorage.setItem("review_submit", "true");

      // ... Other JSX code
    }
  }, [selectedMulti,hoteldetails,reviewhotel,reviewroom,reviewhk,reviewtax,reviewloyal,other]);

  let handleChangeloyaltylevel = async (i, e) => {
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = {
        ...newLoyaltyLevels[i],
        [e.target.name]: e.target.value,
        minimum_reservation: "",
        condition: "or",
        minimum_room_nights: "",
        period: "",
        discount_percentage: "",
        amount_spend: "",
      }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });

    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    const res1 = await fetch(`${Config.apiurl}basic-levels/${e.target.value}`, {
      method: "POST",
    });
    const data2 = await res1.json();
    const data3 = data2.data;

    if (data2.status_code !== "200" || !data3) {
      console.log("error");
    } else {
      const value = e.target.value;
      setLoyalty((prevState) => {
        const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
        newLoyaltyLevels[i] = {
          ...newLoyaltyLevels[i],
          [e.target.name]: value,
          name: data3.name,
          minimum_reservation: data3.minimum_reservation,
          condition: data3.condition,
          minimum_room_nights: data3.minimum_room_nights,
          period: data3.period,
          discount_percentage: data3.discount_percentage,
          amount_spend: data3.amount_spend,
        }; // Update the specific object in the array
        return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
      });
    }
  };
  let handleChangeloyalty = (i, e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);
    const value = e.target.value;
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = { ...newLoyaltyLevels[i], [e.target.name]: value }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };

  let handleChangeloyaltyfile = (i, e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    const value = e.target.files[0];
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = { ...newLoyaltyLevels[i], [e.target.name]: value }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };

  let handleloyaltybasiclogo = (e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    let newval = { ...loyalty }; // Create a shallow copy of the loyalty object

    let name = e.target.name;
    let value = e.target.files[0];
    setLoyaltylogo(e.target.files[0]);
  };

  let handleChangetoggloyaldisplay = (e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    // let value = e.target.checked;
    let value1 = e.target.value;
    let name = e.target.name;

    let newval = { ...loyalty }; // Create a shallow copy of the loyalty object

    if (value1 === "false") {
      const value = false;
      newval.loyalty_program[name] = value; // Update the specific property inside loyalty_program

      setLoyalty(newval);
    } else if (value1 === "true") {
      const value = true;
      newval.loyalty_program[name] = value; // Update the specific property inside loyalty_program

      setLoyalty(newval);
    }
  };

  let handleloyalty = (e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    let newval = { ...loyalty }; // Create a shallow copy of the loyalty object

    let name = e.target.name;
    let value = e.target.value;
    newval.loyalty_program[name] = value; // Update the specific property inside loyalty_program

    setLoyalty(newval);
  };
  let handleChangeeslo = (i, data) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    const value = data;
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = { ...newLoyaltyLevels[i], benefits: value }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };
  let handleChangetoggexp = (i, e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);

    console.log(e);

    const value = e.target.checked;
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = { ...newLoyaltyLevels[i], [e.target.name]: value }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };

  console.log("booking form changes");
  let handleflatdate = (val, name, i) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);
    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[i] = { ...newLoyaltyLevels[i], [name]: val }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };

  let handleChangetogg = (index, e) => {
    setLoyalbutton(false);

    setLoyalupdatestatus(true);
    const name = e.target.name;
    const val = e.target.checked;

    setLoyalty((prevState) => {
      const newLoyaltyLevels = [...prevState.loyalty_levels]; // Create a shallow copy of the loyalty_levels array
      newLoyaltyLevels[index] = { ...newLoyaltyLevels[index], [name]: val }; // Update the specific object in the array
      return { ...prevState, loyalty_levels: newLoyaltyLevels }; // Return the updated loyalty object
    });
  };

  let loyaltynext = (tag) => {
    setPrev(false);

    setLoaderspin(true);

    setLoyalbutton(true);
    let isFormValid = true;
    if (hoteldetails.loyalty_program_status === true) {
      const errors = {};
      if (!loyalty.loyalty_program.program_name) {
        isFormValid = false;
        errors.loyalty_program = "This field is required!";
      }
      if (!loyalty.loyalty_program.discount_percentage) {
        isFormValid = false;

        errors.loyalty_discount = "Discount percentage is required!";
      } else if (loyalty.loyalty_program.discount_percentage === "other") {
        if (!loyalty.loyalty_program.loyalty_other_discount) {
          isFormValid = false;

          errors.other_discount_percentage = "Discount percentage is required!";
        }
      }
      setError9(errors);
    }

    let arr = [];
    if (hoteldetails.loyalty_program_status === true) {
      for (let i = 0; i < loyalty.loyalty_levels.length; i++) {
        const errors = {};
        if (!loyalty.loyalty_levels[i].name) {
          isFormValid = false;
          errors.loyalty = "Loyalty name is required!";
        } else if (loyalty.loyalty_levels[i].name === "other") {
          if (!loyalty.loyalty_levels[i].other) {
            isFormValid = false;

            errors.loyaltyname = "Loyalty name is required!";
          }
        }

        if (!loyalty.loyalty_levels[i].minimum_reservation) {
          isFormValid = false;

          errors.minimum_reservation = "Minimum reservation is required!";
        }
        if (!loyalty.loyalty_levels[i].minimum_room_nights) {
          isFormValid = false;

          errors.minimum_room_nights = "Minimum room nights is required!";
        }
        if (loyalty.loyalty_levels[i].expiry_status === true) {
          if (!loyalty.loyalty_levels[i].period) {
            isFormValid = false;

            errors.period = "Period is required!";
          }
        }

        if (!loyalty.loyalty_levels[i].amount_spend) {
          isFormValid = false;

          errors.amount_spend = "Amount send is required!";
        }

        if (loyalty.loyalty_levels[i].benefits.length > 0) {
          for (let k = 0; k < loyalty.loyalty_levels[i].benefits.length; k++) {
            if (loyalty.loyalty_levels[i].benefits[k].value === "other") {
              if (!loyalty.loyalty_levels[i].mul_other) {
                isFormValid = false;

                errors.mul_other = "Benefit name is required!";
              }
            }
          }
        }
        arr.push(errors);
      }
      setError10(arr);
    }

    if (hoteldetails.loyalty_program_status === false || isFormValid === true) {
      const formData = new FormData();

      if (hoteldetails.loyalty_program_status === false) {
        formData.append(`hotel_loyalty_status`, 0);
        if (loyalty.loyalty_program.loyalty_id) {
          formData.append(`loyalty_id`, loyalty.loyalty_program.loyalty_id);
        } else {
          formData.append(`loyalty_id`, "");
        }
      } else if (hoteldetails.loyalty_program_status === true) {
        formData.append(`hotel_loyalty_status`, 1);

        if (loyalty.loyalty_program.loyalty_id) {
          formData.append(`loyalty_id`, loyalty.loyalty_program.loyalty_id);
        } else {
          formData.append(`loyalty_id`, "");
        }

        formData.append(
          `loyalty_program_name`,
          loyalty.loyalty_program.program_name
        );
        formData.append(
          `loyalty_discount_percentage`,
          loyalty.loyalty_program.discount_percentage
        );
        if (loyalty.loyalty_program.discount_percentage === "other") {
          formData.append(
            `loyalty_other_discount`,
            loyalty.loyalty_program.loyalty_other_discount
          );
        }
        if (loyalty.loyalty_program.club_logo_url) {
          formData.append(
            `club_logo_url`,
            loyalty.loyalty_program.club_logo_url
          );
        }
        if (loyalty.loyalty_program.club_condition_url) {
          formData.append(
            `club_condition_url`,
            loyalty.loyalty_program.club_condition_url
          );
        }
        if (loyalty.loyalty_program.club_policy_url) {
          formData.append(
            `club_policy_url`,
            loyalty.loyalty_program.club_policy_url
          );
        }
        formData.append(
          `basic_level_name`,
          loyalty.loyalty_program.basic_level_name
        );
        if (logoFile) {
          if (logoFile instanceof File) {
            formData.append("basic_level_logo", logoFile);
          } else {
            formData.append(" basic_level_choosen_image", logoFile);
          }
        }
        for (let i = 0; i < loyalty.loyalty_levels.length; i++) {
          formData.append(
            `loyalty_level[${i}][name]`,
            loyalty.loyalty_levels[i].name
          );
          if (loyalty.loyalty_levels[i].level_id) {
            formData.append(
              `loyalty_level[${i}][level_id]`,
              loyalty.loyalty_levels[i].level_id
            );
          } else {
            formData.append(`loyalty_level[${i}][level_id]`, "");
          }

          if (loyalty.loyalty_levels[i].name === "other") {
            formData.append(
              `loyalty_level[${i}][new_name]`,
              loyalty.loyalty_levels[i].other
            );
          }
          if (loyalty.loyalty_levels[i].logo) {
            if (loyalty.loyalty_levels[i].logo instanceof File) {
              formData.append(
                `loyalty_level[${i}][logo]`,
                loyalty.loyalty_levels[i].logo
              );
            } else {
              formData.append(
                `loyalty_level[${i}][choosen_image]`,
                loyalty.loyalty_levels[i].logo
              );
            }
          }
          if (loyalty.loyalty_levels[i].minimum_reservation) {
            formData.append(
              `loyalty_level[${i}][reservations]`,
              loyalty.loyalty_levels[i].minimum_reservation
            );
          } else {
            formData.append(`loyalty_level[${i}][reservations]`, 0);
          }
          formData.append(
            `loyalty_level[${i}][condition]`,
            loyalty.loyalty_levels[i].condition
          );
          if (loyalty.loyalty_levels[i].minimum_room_nights) {
            formData.append(
              `loyalty_level[${i}][room_nights]`,
              loyalty.loyalty_levels[i].minimum_room_nights
            );
          } else {
            formData.append(`loyalty_level[${i}][room_nights]`, 0);
          }
          if (loyalty.loyalty_levels[i].expiry_status === true) {
            formData.append(`loyalty_level[${i}][expiry_status]`, 1);
          } else {
            formData.append(`loyalty_level[${i}][expiry_status]`, 0);
          }
          if (loyalty.loyalty_levels[i].expiry_status === true) {
            formData.append(
              `loyalty_level[${i}][period]`,
              loyalty.loyalty_levels[i].period
            );
          }
          formData.append(
            `loyalty_level[${i}][amount_spend]`,
            loyalty.loyalty_levels[i].amount_spend
          );

          if (loyalty.loyalty_levels[i].discount_percentage) {
            formData.append(
              `loyalty_level[${i}][discount_percentage]`,
              loyalty.loyalty_levels[i].discount_percentage
            );
          } else {
            formData.append(`loyalty_level[${i}][discount_percentage]`, 0);
          }

          if (loyalty.loyalty_levels[i].benefits.length > 0) {
            let count = 0;
            for (
              let j = 0;
              j < loyalty.loyalty_levels[i].benefits.length;
              j++
            ) {
              formData.append(
                `loyalty_level[${i}][benefit][${count}]`,
                loyalty.loyalty_levels[i].benefits[count].value
              );
              if (loyalty.loyalty_levels[i].benefits[count].value === "other") {
                formData.append(
                  `loyalty_level[${i}][other_benefit]`,
                  loyalty.loyalty_levels[i].mul_other
                );
              }
              count += 1;
            }
          }
        }
        if (loyalty.loyalty_program.rate_display_status === false) {
          formData.append(`loyalty_rate_display_status`, 0);
        }
        if (loyalty.loyalty_program.rate_display_status === true) {
          formData.append(`loyalty_rate_display_status`, 1);
        }
        if (removeloyalid.length > 0) {
          for (let j = 0; j < removeloyalid.length; j++) {
            if (removeloyalid[j] !== undefined) {
              formData.append(`removed_loyalty_levels[${j}]`, removeloyalid[j]);
            }
          }
        }
      }

      axios
        .post(
          `${Config.apiurl}temporary-hotel/update-details/loyalty_info/${hotelsid}`,
          formData,
          {}
        )
        .then((res) => {
          setLoaderspin(false);

          if (res.status_code !== "200" || !res) {
            console.log("error");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("sucess");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setLoyalty([]);
            setLoyaltylogo([]);
            gethotelinfo();

            if (tag === "loyalty") {
              // setTimeout(() =>  setTab("Hotel-info"), 3000);
              console.log("loyal");
            } else {
              setLoyalupdatestatus(false);
              setReviewloyal(false);
              setRemoveloayalid([]);

              // setPrev(true)
            }
          }
        });
    } else {
      setLoaderspin(false);

      window.scrollTo(0, 0);
      setToastmodal(true);
      setToastmessage("Validation error occur");
      setToasttype("error");
      setToastlarge(false);
    }
  };

  //loyalty full

  function ResetHotelinformation() {
    setHoteldetails({
      hotel_name: "",
      corporate_name: "",
      country: "",
      timezone: "",
      calling_code: "",
      phone_no: "",
      reservation_email: "",
      checkin_time: "",
      checkout_time: "",
      address: "",
      licence_number: "",
      vat_registration_number: "",
      category: "",
      default_currency: "",
      min_room_rate: "",
      max_room_rate: "",
      no_of_rooms: "",
      website_url: "",
      hotel_image: [],
      room_image: "",
      hotel_logo: "",
      channel_manager_status: false,
      channel_manager_name: "",
      pms_status: false,
      pms_name: "",
      credit_card_status: false,
    });
    setSelectedExtranet([]);
    setSelectedBookinglang([]);
    setPropertytypehotel([]);
    setselectedCard([]);
  }
  function Prvfunction(tag) {
    if (tag === "room") {
      roominfonext(tag);
      gethotelinfo();

      setTimeout(() => setTab("Hotel-info"), 3000);
      setRoomprev(!roomprev);
      setReviewroom(false);
      setRemoveroomid([]);
      setRemoveRoom([]);
    } else if (tag === "hk") {
      hfnext(tag);
      gethotelinfo();

      setTimeout(() => setTab("Room-info"), 3000);
      setHkprev(!hkprev);

      setReviewhk(false);
    } else if (tag === "tax") {
      taxnext(tag);
      gethotelinfo();

      setTimeout(() => setTab("Freequency"), 3000);
      setTaxprev(!taxprev);

      setReviewtax(false);
    } else if (tag === "loyalty") {
      loyaltynext(tag);
      gethotelinfo();

      setTimeout(() => setTab("Tax"), 3000);
      setLoyalprev(!loyalprev);

      setReviewloyal(false);
    }
  }

  function closetab(tag) {
    if (tag === "room") {
      setRoomprev(!roomprev);
      gethotelinfo();
      setTimeout(() => setTab("Hotel-info"), 3000);

      setRoominfoupdatestatus(false);
      setRoombutton(false);
    }
    if (tag === "hk") {
      setHkprev(!hkprev);
      gethotelinfo();
      setTimeout(() => setTab("Room-info"), 3000);
      setHkupdatestatus(false);
    }
    if (tag === "tax") {
      setTaxprev(!taxprev);
      gethotelinfo();
      setTimeout(() => setTab("Freequency"), 3000);
      setTaxupdatestatus(false);
    }
    if (tag === "loyalty") {
      setLoyalprev(!loyalprev);
      gethotelinfo();
      setTimeout(() => setTab("Tax"), 3000);
      setLoyalupdatestatus(false);
    }
  }
  const submitconfirm = async (e) => {
    setSubmitbutton(true);
    axios
      .post(
        `${Config.apiurl}temporary-hotel/submit-confirmation/${hotelsid}`,
        {}
      )
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          console.log("error");

          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          window.scrollTo(0, 0);

          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(false);
          localStorage.removeItem("review_submit");
          setTimeout(() => navigate("/thanks"), 3000);
        }
      });
  };

  const [popupid, setPopupId] = useState("");
  const [popupcreate, setPopupcreate] = useState(false);
  const [logoFile, setLogoFile] = useState("");
  const [tabimgcreate, setTabimagecreate] = useState("upload");
  const [popuploop, setPopuploop] = useState(false);
  const [tabimg, setTabimage] = useState("upload");

  const handleImageClickcreate = (imageSrc) => {
    setLogoFile(imageSrc);
    setPopupcreate(false);
  };

  const onFileChangecreate = (e) => {
    setPopupcreate(false);
    setLogoFile(e.target.files[0]);
    e.target.value = null;
  };

  const handleImageClickloop = (imgsrc, index) => {
    setLoyalty((prevLoyalty) => {
      const updatedLoyalty = { ...prevLoyalty };
      updatedLoyalty.loyalty_levels[index] = {
        ...updatedLoyalty.loyalty_levels[index],
        logo: imgsrc,
      };
      setPopuploop(false);
      return updatedLoyalty;
    });
  };

  const onFileChangeloyalty = (index, event) => {
    setPopuploop(false);
    const selectedFile = event.target.files[0];

    setLoyalty((prevCommonLevel) => {
      const updatedCommonLevel = { ...prevCommonLevel };
      updatedCommonLevel.loyalty_levels[index] = {
        ...updatedCommonLevel.loyalty_levels[index],
        logo: selectedFile,
      };

      return updatedCommonLevel;
    });
    event.target.value = null;
  };
  console.log(loyalty);

  return (
    <>
      <Helmet>
        <title>Directorate - Booking Engine Form</title>
      </Helmet>

      {loader === true ? (
        <Loading />
      ) : (
        <div id="layout-wrapper">
          {invalid === true ? (
            <Notfound />
          ) : (
            <div className="booking-engine-form-main-temp-hotel">
              <div className="be-hotel-form-section">
                <div className="be_login-section">
                  <img
                    src="/images/logo-white.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                {hoteltype === "group" ? (
                  <div className="be_login-section_top">
                    <h6 className="">{hoteldetails.hotel_name}</h6>
                  </div>
                ) : (
                  <>
                    <div className="be_login-section_top">
                      <h6 className="">{hoteldetails.hotel_name}</h6>
                    </div>
                    <div className="be_login-section_top ">
                      <h6 className="" style={{ color: "transparent" }}>
                        {hoteldetails.hotel_name}
                      </h6>
                    </div>
                  </>
                )}
                {hoteltype === "group" ? (
                  <div className="be_login-section_top">
                    <h6 className="">{hotelinfo.name}</h6>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="be-title-section">
                {/* {alert ? (
            <Alerts type={alerttype} message={alertmessage} open={open} />
          ) : (
            ""
          )} */}

                <div className="be-title-inner-section_grop mb-3  res-header">
                  <div className="col-md-6 ">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Booking Engine Form
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4
                      className="card-title mb-0 flex-grow-1"
                      style={{ textAlign: "end" }}
                    >
                      {hoteldetails.registration_email}
                    </h4>
                  </div>
                </div>

                {hoteldetails.hotel_form_status === 3 ? (
                  <div className="booking_engine_form_notes">
                    <textarea className="form-control" disabled>
                      {hoteldetails.notes}
                    </textarea>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="be-form-part-section">
                <div className="row project-wrapper">
                  <div className="row dashboard-top-tab-style">
                    <div className="col-xxl-12 dashboard-main-style">
                      <div className="card">
                        <div className="tabs-link-be-form tab-var">
                          {hoteldetails.hotel_form_status === 3 ? (
                            <div className="tab mar">
                              <div className="tabs-container josein">
                                <button
                                  className={`tablinks tab-frek ${
                                    tab === "Hotel-info" &&
                                    hoteldetails.tab_list.includes("hotel_info")
                                      ? "active styred"
                                      : tab === "Hotel-info"
                                        ? "active"
                                        : hoteldetails.tab_list.includes(
                                              "hotel_info"
                                            )
                                          ? "styred"
                                          : ""
                                  }`}
                                  id="tabBasicInfo"
                                  onClick={() => setTab("Hotel-info")}
                                >
                                  Hotel Information
                                </button>
                                <button
                                  className={`tablinks tab-frek ${
                                    tab === "Room-info" &&
                                    hoteldetails.tab_list.includes("room_info")
                                      ? "active styred"
                                      : tab === "Room-info"
                                        ? "active"
                                        : hoteldetails.tab_list.includes(
                                              "room_info"
                                            )
                                          ? "styred"
                                          : ""
                                  }`}
                                  id="tabRoomInfo"
                                  onClick={() => setTab("Room-info")}
                                >
                                  Room Information
                                </button>
                                <button
                                  className={`tablinks tab-frek ${
                                    tab === "Freequency" &&
                                    hoteldetails.tab_list.includes(
                                      "hk_frequency"
                                    )
                                      ? "active styred"
                                      : tab === "Freequency"
                                        ? "active"
                                        : hoteldetails.tab_list.includes(
                                              "hk_frequency"
                                            )
                                          ? "styred"
                                          : ""
                                  }`}
                                  id="tabFrequencyInfo"
                                  onClick={() => setTab("Freequency")}
                                >
                                  HK Frequency
                                </button>
                                <button
                                  className={`tablinks tab-frek ${
                                    tab === "Tax" &&
                                    hoteldetails.tab_list.includes("tax")
                                      ? "active styred"
                                      : tab === "Tax"
                                        ? "active"
                                        : hoteldetails.tab_list.includes("tax")
                                          ? "styred"
                                          : ""
                                  }`}
                                  id="tabTaxInfo"
                                  onClick={() => setTab("Tax")}
                                >
                                  Tax Details
                                </button>
                                <button
                                  className={`tablinks tab-frek ${
                                    tab === "Loyalty" &&
                                    hoteldetails.tab_list.includes("loyalty")
                                      ? "active styred"
                                      : tab === "Loyalty"
                                        ? "active"
                                        : hoteldetails.tab_list.includes(
                                              "loyalty"
                                            )
                                          ? "styred"
                                          : ""
                                  }`}
                                  id="tabLoyaltyInfo"
                                  onClick={() => setTab("Loyalty")}
                                >
                                  Loyalty
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="tab">
                              <button
                                className={
                                  tab === "Hotel-info"
                                    ? "tablinks active"
                                    : "tablinks"
                                }
                                id="tabBasicInfo"
                                //  onClick={(e) => setTab("Hotel-info")}
                              >
                                Hotel Information
                              </button>
                              <button
                                className={
                                  tab === "Room-info"
                                    ? "tablinks active"
                                    : "tablinks"
                                }
                                id="tabRoomInfo"
                                // onClick={(e) => setTab("Room-info")}
                              >
                                Room Information
                              </button>
                              <button
                                className={
                                  tab === "Freequency"
                                    ? "tablinks active"
                                    : "tablinks"
                                }
                                id="tabFrequencyInfo"
                                // onClick={(e) => setTab("Freequency")}
                              >
                                HK Frequency
                              </button>
                              <button
                                className={
                                  tab === "Tax" ? "tablinks active" : "tablinks"
                                }
                                id="tabTaxInfo"
                                // onClick={(e) => setTab("Tax")}
                              >
                                Tax Details
                              </button>
                              <button
                                className={
                                  tab === "Loyalty"
                                    ? "tablinks active"
                                    : "tablinks"
                                }
                                id="tabLoyaltyInfo"
                                // onClick={(e) => setTab("Loyalty")}
                              >
                                Loyalty
                              </button>
                            </div>
                          )}
                        </div>
                        <div id="contentContainer">
                          {tab === "Hotel-info" ? (
                            <Hotelinformation
                              setTab={setTab}
                              country={country}
                              timezone={timezone}
                              be_lang={be_lang}
                              property={property}
                              star={star}
                              currency={currency}
                              creditcard={creditcard}
                              selectedCard={selectedCard}
                              setselectedCard={setselectedCard}
                              hoteldetails={hoteldetails}
                              setHoteldetails={setHoteldetails}
                              handlechange={handlechange}
                              handleInputsphone={handleInputsphone}
                              handleChangeselect={handleChangeselect}
                              onChange={onChange}
                              pms={pms}
                              channel={channel}
                              setPMS={setPMS}
                              selectedBookinglang={selectedBookinglang}
                              selectedExtanet={selectedExtanet}
                              setSelectedBookinglang={setSelectedBookinglang}
                              setSelectedExtranet={setSelectedExtranet}
                              onChangelogo={onChangelogo}
                              onChangehotelroom={onChangehotelroom}
                              handleChangeselectcountry={
                                handleChangeselectcountry
                              }
                              formerror={formerror}
                              countrytime={countrytime}
                              calling={calling}
                              yesorno={yesorno}
                              propertytypehotel={propertytypehotel}
                              setPropertytypehotel={setPropertytypehotel}
                              handleChangetogghotel={handleChangetogghotel}
                              // hotelinfonext={hotelinfonext}
                              formerror1={formerror1}
                              formerror2={formerror2}
                              formerror3={formerror3}
                              formerror4={formerror4}
                              formerror5={formerror5}
                              ResetHotelinformation={ResetHotelinformation}
                              setSelectedPMS={setSelectedPMS}
                              selectedpms={selectedpms}
                              setSelectedchannelmanager={
                                setSelectedchannelmanager
                              }
                              selectedchannelmanager={selectedchannelmanager}
                              setSelectedcategory={setSelectedcategory}
                              selectedcategory={selectedcategory}
                              setSelectedcountry={setSelectedcountry}
                              selectedcountry={selectedcountry}
                              setSelectedcurrency={setSelectedcurrency}
                              selectedcurrency={selectedcurrency}
                              setSelectedtimezone={setSelectedtimezone}
                              selectedtimezone={selectedtimezone}
                              formerrorall={formerrorall}
                              selectedoptionalimage={selectedoptionalimage}
                              selectedhotelimage={selectedhotelimage}
                              removeImage={removeImage}
                              removeImagehotel={removeImagehotel}
                              oldlogo={oldlogo}
                              setHotelinfoupdatestatus={
                                setHotelinfoupdatestatus
                              }
                              hotelinfoupdatestatus={hotelinfoupdatestatus}
                              hotelinfosavenext={hotelinfosavenext}
                              hotel_info_status={hotel_info_status}
                              hotelbutton={hotelbutton}
                              setHotelbutton={setHotelbutton}
                              loaderspin={loaderspin}
                              time={time}
                              checkintime={checkintime}
                              setCheckintime={setCheckintime}
                              checkouttime={checkouttime}
                              setCheckouttime={setCheckouttime}
                            />
                          ) : tab === "Room-info" ? (
                            <Roominformaton
                              setTab={setTab}
                              facility={facility}
                              handleChangees={handleChangees}
                              addInputField={addInputField}
                              removeInputFields={removeInputFields}
                              onFileChange={onFileChange}
                              bedtype={bedtype}
                              handleChangeesinner={handleChangeesinner}
                              handleChangetoggroom={handleChangetoggroom}
                              addInputFieldRoom={addInputFieldRoom}
                              roomprevtab={roomprevtab}
                              roominfonext={roominfonext}
                              formerror6={formerror6}
                              roominfo={roominfo}
                              setRoominfo={setRoominfo}
                              handleChangeesroomfac={handleChangeesroomfac}
                              roominfoupdatestatus={roominfoupdatestatus}
                              room_info_status={room_info_status}
                              roominfos={roominfos}
                              setRoominfos={setRoominfos}
                              formerror11={formerror11}
                              removeImageRoom={removeImageRoom}
                              roomprev={roomprev}
                              setRoomprev={setRoomprev}
                              Prvfunction={Prvfunction}
                              closetab={closetab}
                              handleChangeesinnerbed={handleChangeesinnerbed}
                              roombutton={roombutton}
                              loaderspin={loaderspin}
                            />
                          ) : tab === "Freequency" ? (
                            <HotelFreequency
                              setTab={setTab}
                              service={service}
                              frequency={frequency}
                              otherhk={otherhk}
                              addInputFieldHK={addInputFieldHK}
                              removeInputFieldsHK={removeInputFieldsHK}
                              handleChangehk={handleChangehk}
                              inputFieldshk={inputFieldshk}
                              ser_length={ser_length}
                              handleChangetogghk={handleChangetogghk}
                              hfnext={hfnext}
                              formerror7={formerror7}
                              hkfreequency={hkfreequency}
                              setHkfreequency={setHkfreequency}
                              hkupdatestatus={hkupdatestatus}
                              hk_info_status={hk_info_status}
                              handleChangehkother={handleChangehkother}
                              hkprevtab={hkprevtab}
                              hkprev={hkprev}
                              setHkprev={setHkprev}
                              Prvfunction={Prvfunction}
                              closetab={closetab}
                              edit={edit}
                              setEdit={setEdit}
                              hkbutton={hkbutton}
                              loaderspin={loaderspin}
                            />
                          ) : tab === "Tax" ? (
                            <Taxdetails
                              setTab={setTab}
                              addInputFieldtax={addInputFieldtax}
                              removeInputFieldstax={removeInputFieldstax}
                              handleChangetax={handleChangetax}
                              handleChangetaxselect={handleChangetaxselect}
                              handleChangetoggtax={handleChangetoggtax}
                              taxnext={taxnext}
                              formerror8={formerror8}
                              tax={tax}
                              setTax={setTax}
                              hoteldetails={hoteldetails}
                              selectedcurrency={selectedcurrency}
                              taxupdatestatus={taxupdatestatus}
                              tax_info_status={tax_info_status}
                              taxprevtab={taxprevtab}
                              taxprev={taxprev}
                              setTaxprev={setTaxprev}
                              Prvfunction={Prvfunction}
                              closetab={closetab}
                              roomtype={roomtype}
                              roomtypelist={roomtypelist}
                              setRoomtypelist={setRoomtypelist}
                              handleChangeesroomtypetax={
                                handleChangeesroomtypetax
                              }
                              taxchecke={taxchecke}
                              setTaxChecked={setTaxChecked}
                              setTaxupdatestatus={setTaxupdatestatus}
                              taxbutton={taxbutton}
                              loaderspin={loaderspin}
                              handleChangetaxtogg={handleChangetaxtogg}
                            />
                          ) : tab === "Loyalty" ? (
                            <Loyalty
                              setTab={setTab}
                              frequency={frequency}
                              benifit={benifit}
                              checke={checke}
                              setChecked={setChecked}
                              inputFieldsloyalty={inputFieldsloyalty}
                              removeInputFieldsloyalty={
                                removeInputFieldsloyalty
                              }
                              handleChangeloyalty={handleChangeloyalty}
                              handleChangetoggexp={handleChangetoggexp}
                              handleChangeeslo={handleChangeeslo}
                              display={display}
                              setDisplay={setDisplay}
                              loyalty={loyalty}
                              setLoyalty={setLoyalty}
                              handleloyalty={handleloyalty}
                              handleflatdate={handleflatdate}
                              loyaltynext={loyaltynext}
                              handleChangeloyaltyfile={handleChangeloyaltyfile}
                              hoteldetails={hoteldetails}
                              handleloyaltybasiclogo={handleloyaltybasiclogo}
                              formerror9={formerror9}
                              formerror10={formerror10}
                              loyaltyold={loyaltyold}
                              loyalprev={loyalprev}
                              setLoyalprev={setLoyalprev}
                              Prvfunction={Prvfunction}
                              closetab={closetab}
                              loyalupdatestatus={loyalupdatestatus}
                              setLoyalupdatestatus={setLoyalupdatestatus}
                              loyal_info_status={loyal_info_status}
                              loyalprevtab={loyalprevtab}
                              submitconfirm={submitconfirm}
                              handleChangetoggloyalcheck={
                                handleChangetoggloyalcheck
                              }
                              handleChangetoggloyaldisplay={
                                handleChangetoggloyaldisplay
                              }
                              loyaltyimages={loyaltyimages}
                              setLoyaltyimages={setLoyaltyimages}
                              loyalbutton={loyalbutton}
                              submitbutton={submitbutton}
                              basiclevel={basiclevel}
                              handleChangeloyaltylevel={
                                handleChangeloyaltylevel
                              }
                              addInputFieldloyal={addInputFieldloyal}
                              loaderspin={loaderspin}
                              handleChangetogg={handleChangetogg}
                              defaultimage={defaultimage}
                              setHoteldetails={setHoteldetails}
                              popupid={popupid}
                              setPopupId={setPopupId}
                              popupcreate={popupcreate}
                              setPopupcreate={setPopupcreate}
                              logoFile={logoFile}
                              setLogoFile={setLogoFile}
                              tabimgcreate={tabimgcreate}
                              setTabimagecreate={setTabimagecreate}
                              popuploop={popuploop}
                              setPopuploop={setPopuploop}
                              tabimg={tabimg}
                              setTabimage={setTabimage}
                              handleImageClickcreate={handleImageClickcreate}
                              onFileChangecreate={onFileChangecreate}
                              handleImageClickloop={handleImageClickloop}
                              onFileChangeloyalty={onFileChangeloyalty}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {hoteldetails.hotel_form_status === 3 ? (
                        (((hoteldetails.tab_list.includes("hotel_info") &&
                          reviewhotel === false) ||
                          (!hoteldetails.tab_list.includes("hotel_info") &&
                            reviewhotel === true)) &&
                          ((hoteldetails.tab_list.includes("room_info") &&
                            reviewroom === false) ||
                            (!hoteldetails.tab_list.includes("room_info") &&
                              reviewroom === true)) &&
                          ((hoteldetails.tab_list.includes("hk_frequency") &&
                            reviewhk === false) ||
                            (!hoteldetails.tab_list.includes("hk_frequency") &&
                              reviewhk === true)) &&
                          ((hoteldetails.tab_list.includes("tax") &&
                            reviewtax === false) ||
                            (!hoteldetails.tab_list.includes("tax") &&
                              reviewtax === true)) &&
                          ((hoteldetails.tab_list.includes("loyalty") &&
                            reviewloyal === false) ||
                            (!hoteldetails.tab_list.includes("loyalty") &&
                              reviewloyal === true))) ||
                        review_submit === "true" ? (
                          <div className="loyalty_submit_confirm">
                            <h4>
                              You filled all information,Do you want to submit?
                            </h4>
                            <button
                              className="btn btn_loyal_confirm"
                              onClick={submitconfirm}
                            >
                              Submit
                            </button>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {toastmodal === true ? (
            <Toastmodal
              setToast={setToastmodal}
              message={toastmessage}
              type={toasttype}
              large={toastlarge}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default Bookingengineform;
