import React, { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import { useState, useEffect, useRef } from "react";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import moment from "moment";
import axios from "axios";
import Toastmodal from "../../Common/Toastmodal";
import Offerstep6sugg from "./Offersuggestions/Offerstep6sugg";
import Offerstep5sugg from "./Offersuggestions/Offerstep5sugg";
import Offerstep4sugg from "./Offersuggestions/Offerstep4sugg";
import Offerstep3sugg from "./Offersuggestions/Offerstep3sugg";
import Offerstep2sugg from "./Offersuggestions/Offerstep2sugg";
import Offerstep1sugg from "./Offersuggestions/Offerstep1sugg";

function Offersuggestion() {
  let navigate = useNavigate();
  let params = useParams();

  const [id, setId] = useState(params.hotel_id);
  const [offerid, setOfferid] = useState(params.offerid);
  const [tab, setTab] = useState("step1");
  const [offer1valid, setOffer1valid] = useState(false);
  const [offer2valid, setOffer2valid] = useState(false);
  const [offer3valid, setOffer3valid] = useState(false);

  const [offer4valid, setOffer4valid] = useState(false);
  const [offer5valid, setOffer5valid] = useState(false);

  const [offer6valid, setOffer6valid] = useState(false);
  const [errorroom, setErrorroom] = useState([{}]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [optionimage, setOptionimage] = useState([]);
  const [multicountri3, setMulticountry3] = useState([]);
  const [multicountri4, setMulticountry4] = useState([]);
  const [otherboard, setOtherboard] = useState([]);
  const [selectedotherboard, setSelectedotherboard] = useState([]);

  // offer1 page
  const [offer1error, setOffer1error] = useState({});
  const [offer1Check, setOffer1check] = useState(true);
  const [offername, setOffername] = useState("");
  const [activestaydate, setActivestaydate] = useState(false);
  const [activestaydateopen, setActivestaydateopen] = useState(false);

  const [activecheckindate, setActivecheckindate] = useState(false);
  const [activecheckindateopen, setActivecheckindateopen] = useState(false);

  const [activecheckoutdate, setActivecheckoutdate] = useState(false);
  const [activecheckoutdateopen, setActivecheckoutdateopen] = useState(false);

  const [activereservedate, setActivereservedate] = useState(false);
  const [activereservedateopen, setActivereservedateopen] = useState(false);

  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");

  const [datefromcheckin, setDatefromcheckin] = useState("");
  const [datetocheckin, setDatetocheckin] = useState("");

  const [datefromcheckout, setDatefromcheckout] = useState("");
  const [datetocheckout, setDatetocheckout] = useState("");

  const [datefromreserve, setDatefromreserve] = useState("");
  const [datetoreserve, setDatetoreserve] = useState("");

  const fpstay = useRef(null);
  const fpcheckin = useRef(null);
  const fpcheckout = useRef(null);
  const fpreserve = useRef(null);

  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [selectedWeekdayscheckin, setSelectedWeekdayscheckin] = useState([]);
  const [selectedWeekdayscheckout, setSelectedWeekdayscheckout] = useState([]);
  const [selectedWeekdaysreserve, setSelectedWeekdaysreserve] = useState([]);

  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const [clickedDayscheckin, setClickedDayscheckin] = useState([]);
  const [clickedDayscheckout, setClickedDayscheckout] = useState([]);
  const [clickedDaysreserve, setClickedDaysreserve] = useState([]);

  const [openclosestay, setOpenclosestay] = useState("open");
  const [openclosecheckin, setOpenclosecheckin] = useState("open");
  const [openclosecheckout, setOpenclosecheckout] = useState("open");
  const [openclosereserve, setOpenclosereserve] = useState("open");

  const [language, setLanguage] = useState([]);

  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const [selectedDatescheckin, setSelectedDatescheckin] = useState([]);
  const [selectedDatescheckout, setSelectedDatescheckout] = useState([]);
  const [selectedDatesreserve, setSelectedDatesreserve] = useState([]);

  const [selectedMulti, setselectedMulti] = useState([]);

  // offer2 page
  const [imagechoose, setImagechoose] = useState("");
  const [offer2error, setOffer2error] = useState({});

  const [offer2room, setOffer2room] = useState([]);
  const [boardoffer2, setBoardoffer2] = useState([]);
  const [ratelistoffer2, setRatelistoffer2] = useState([]);
  const [activeroom, setActiveroom] = useState(false);
  const [activeboard, setActiveboard] = useState(false);
  const [activerate, setActiverate] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [availableboard, setAvailbleboard] = useState([]);
  const [selectedroomocc, setSelectedroomocc] = useState([]);
  //offer3 page
  const [offer3fields, setOffer3fields] = useState({
    price_type: "same",
    fixed_week_price_status: false,
    discount_unit_price: "percentage",
    discount_value: "",
    increment_status: false,
    increment_type: "per_person",
    increment_frequency: "once",
    increment_room_value: "",
    increment_per_adult: "",
    increment_per_child: "",
    increment_unit: "currency",
    free_night_status: false,
    free_night_type: "last_night",
    free_booking_night1: "",
    free_booking_night2: "",

    // booking_time_discount_status:false,
    booking_at_night_status: false,
    // booking_time_discount:'',
    booking_night_discount: "",
    booking_night_unit_value: "percentage",
    // booking_hours_status:false,
    // booking_weekend_discount_status:false,
    hours_discount_from: "",
    hours_discount_to: "",
    booking_weekend_discount_status: false,
    booking_weekend_discount: "",
    booking_weekend_unit_value: "percentage",
    booking_weekend_week_from: "",
    booking_weekend_week_to: "",
    booking_weekend_time_from: "",
    booking_weekend_time_to: "",
    long_stay_discount_status: false,
    long_stay_discount0_night: "1",
    long_stay_discount0_percentage: "",
    long_stay_discount0_unit: "percentage",
    long_stay_discount1_night: "",
    long_stay_discount1_percentage: "",
    long_stay_discount1_unit: "percentage",
    long_stay_discount2_night: "",
    long_stay_discount2_percentage: "",
    long_stay_discount2_unit: "percentage",

    long_stay_discount3_night: "",
    long_stay_discount3_percentage: "",
    long_stay_discount3_unit: "percentage",

    early_booking_discount_status: false,
    early_booking_discount0_days: "",
    early_booking_discount0_percentage: "",
    early_booking_discount0_unit: "percentage",
    early_booking_discount1_days: "",
    early_booking_discount1_percentage: "",
    early_booking_discount1_unit: "percentage",

    early_booking_discount2_days: "",
    early_booking_discount2_percentage: "",
    early_booking_discount2_unit: "percentage",

    early_booking_discount3_days: "",
    early_booking_discount3_percentage: "",
    early_booking_discount3_unit: "percentage",

    country_discount_status: false,
    device_discount_status: false,
    device_discount_type: "desktop",
    device_discount_percentage: "",
    device_discount_unit_value: "currency",
    last_minute_discount_status: false,
    last_minute_discount0_from: "12",
    last_minute_discount0_discount: "",
    last_minute_discount0_unit: "percentage",
    last_minute_discount1_from: "",
    last_minute_discount1_discount: "",
    last_minute_discount1_unit: "percentage",

    last_minute_discount2_from: "",
    last_minute_discount2_discount: "",
    last_minute_discount2_unit: "percentage",

    last_minute_discount3_from: "",
    last_minute_discount3_discount: "",
    last_minute_discount3_unit: "percentage",
  });
  const [exceptionstaydate, setExceptionstaydate] = useState(false);
  const [exceptionnight, setExceptionnight] = useState(false);
  const [countryOffer3, setCountryOffer3] = useState([]);
  const [inputFieldsexception, setInputFieldsexception] = useState([
    { fromdate: [], todate: [], discount: "", unit_value: "percentage" },
  ]);
  const [inputFieldsextra, setInputFieldsextra] = useState([
    {
      datefrom: [],
      dateto: [],
      discounts: "",
      discount_unit_price: "percentage",
    },
  ]);
  const [showAlloffer3country, setShowAlloffer3country] = useState(false);
  const [board_apply_status,setBoard_apply_status]=useState("yes");

  const visibleItemsoffer3 =
    showAlloffer3country === true ? countryOffer3 : countryOffer3.slice(0, 20);
  const [offer3error, setOffer3error] = useState({});
  const [tabroom, setTabroom] = useState([]);
  const [tabroomweek, setTabroomweek] = useState([]);
  const [ratetab, setRatetab] = useState("rate");
  const [offer3validrate, setOffer3validrate] = useState(false);
  const [offer3validncrement, setOffer3validncrement] = useState(false);
  const [offer3validfreenight, setOffer3validfreenight] = useState(false);
  const [offer3validextra, setOffer3validextrae] = useState(false);
  const [offer3validcountry, setOffer3validcountry] = useState(false);
  const [offer3validdivice, setOffer3validdivice] = useState(false);

  // offer4 page
  const [offer4fields, setOffer4fields] = useState({
    promotional_code: false,
    loyalty_club: false,
    advanced_booking: false,
    different_payment: false,
    minimum_stay: false,
    maximum_stay: false,
    minimum_room: false,
    close_offer: false,
    maximum_rooms: false,
    last_minute: false,
    display_night: false,
    apply_country: false,
    only_device: false,
    ad_booking_days: "",
    cancel_policy: "",
    minimum_stay_count: "2",
    minimum_stay_night: false,
    maximum_stay_count: "2",
    max_stay_all_night_offered: "offer",
    min_rooms_per_booking: "2",
    offer_close_for_room: "2",
    max_room_per_night: "1",
    offer_last_minute_hours: "12",
    offer_display_hours_status: false,
    offer_display_from: "0",
    offer_display_to: "12",
    offer_display_weekend_status: false,
    offer_display_weekend_week_from: "",
    offer_display_weekend_time_from: "",
    offer_display_weekend_week_to: "",
    offer_display_weekend_time_to: "",
    offer_available_device_type: "",
  });

  const [Loyaltyclublist, setLoyaltyclublist] = useState([]);
  const [legend, setLegend] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const visibleItems =
    showAll === true ? countrylist : countrylist.slice(0, 20);

  const [offer4error, setOffer4error] = useState({});

  //offer4 page

  //offer5 page
  const [offer_include_status, setOffer_include_status] = useState(false);
  const [loyalty, setLoyalty] = useState([]);
  const [otherloyal_status, setOtherloyal_status] = useState(false);
  const [otherloyal, setOtherloyal] = useState([
    {
      loyalty_name: "",
      lang: [],
    },
  ]);
  const [alllanguage, setAlllanguage] = useState([]);
  const [loyallang, setLoyallang] = useState("");
  const [offer5error, setOffer5error] = useState({});

  //offer5 page

  //offer6 page

  const [offer6fields, setOffer6fields] = useState({
    description: "",
    price_description: "",
    hide_offer_status: false,
    show_offer_discount: false,
    hide_offer_in_search: false,
  });
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState([]);

  const [selectedMulti6, setselectedMulti6] = useState([]);

  // offer1 page

  const getall =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offer/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data[0].optional_language);
      setOffer2room(data.data[0].rooms_occupancies);
      setBoardoffer2(data.data[0].hotel_board);
      setRatelistoffer2(data.data[0].rate_lists);
      setLegend(data.data[0].policy_data);
      setCountrylist(data.data[0].countries);
      setCountryOffer3(data.data[0].countries);
      setLoyalty(data.data[0].inclusions);
      setAlllanguage(data.data[0].optional_language);
      setCurrency(data.data[0].currency);
      setLoyaltyclublist(data.data[0].loyalty_clubs);
      setOptionimage(data.data[0].option_images);
    }
  },[id]);

  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    }
  };

  const handleApplyToCalendarcheckin = () => {
    if (openclosecheckin === "open") {
      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    } else {
      if (fpcheckin.current) {
        fpcheckin.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0 &&
        clickedDayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    }
  };

  const handleApplyToCalendarcheckout = () => {
    if (openclosecheckout === "open") {
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckout(dates);
      }
    } else {
      if (fpcheckout.current) {
        fpcheckout.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0 &&
        clickedDayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckout(dates);
      }
    }
  };

  const handleApplyToCalendareserve = () => {
    if (openclosereserve === "open") {
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesreserve(dates);
      }
    } else {
      if (fpreserve.current) {
        fpreserve.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0 &&
        clickedDaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesreserve(dates);
      }
    }
  };

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const toggleSelectedDaycheckin = (dayIndex) => {
    if (selectedWeekdayscheckin.includes(dayIndex)) {
      if (clickedDayscheckin.includes(dayIndex)) {
        setClickedDayscheckin((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckin((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDaycheckout = (dayIndex) => {
    if (selectedWeekdayscheckout.includes(dayIndex)) {
      if (clickedDayscheckout.includes(dayIndex)) {
        setClickedDayscheckout((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckout((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDayreserve = (dayIndex) => {
    if (selectedWeekdaysreserve.includes(dayIndex)) {
      if (clickedDaysreserve.includes(dayIndex)) {
        setClickedDaysreserve((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysreserve((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };

  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };

  const handleOpenclosecheckin = (e) => {
    setActivecheckindateopen(false);
  };

  const handleOpenclosecheckout = (e) => {
    setActivecheckoutdateopen(false);
  };

  const handleOpenclosereserve = (e) => {
    setActivereservedateopen(false);
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };

  const handleCalendarChangeFromcheckin = (selectedDates) => {
    setDatefromcheckin(selectedDates[0]);
  };

  const handleCalendarChangeTocheckin = (selectedDates) => {
    setDatetocheckin(selectedDates[0]);
  };

  const handleCalendarChangeFromcheckout = (selectedDates) => {
    setDatefromcheckout(selectedDates[0]);
  };

  const handleCalendarChangeTocheckout = (selectedDates) => {
    setDatetocheckout(selectedDates[0]);
  };

  const handleCalendarChangeFromreserve = (selectedDates) => {
    setDatefromreserve(selectedDates[0]);
  };

  const handleCalendarChangeToreserve = (selectedDates) => {
    setDatetoreserve(selectedDates[0]);
  };

  const nextoffterstep1 = () => {
    let isFormValid = true;
    const errors = {};

    if (!offername) {
      isFormValid = false;

      errors.offername = "Please provide the offer name!";
    }

    setOffer1error(errors);

    if (isFormValid === true) {
      setTab("step2");
      setOffer1valid(true);
    }
  };

  // offer1 page

  //offer2 page

  const handleChangeroomoffer2 = (isChecked) => {
    setActiveroom(isChecked);
    const updatedList = [...offer2room];
    const updatedAvailableBoard = [...availableboard];
    const updatedroomocc = [...selectedroomocc];
    const updatedother = [...selectedotherboard];
    const updatedallother = [...otherboard];
    if (isChecked === true) {
      setActiveboard([]);

      updatedList.forEach((newone) => {
        newone.display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            // If the item doesn't exist in availableboard, add it with its count
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }
        });
        if (updatedroomocc.length > 0) {
          if (!updatedroomocc.some((item) => item.value === newone.value))
            updatedroomocc.push({
              key: newone.key,
              label: newone.label,
              value: newone.value,
            });
        } else {
          updatedroomocc.push({
            key: newone.key,
            label: newone.label,
            value: newone.value,
          });
        }
      });
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (!updatedother[i]) {
          updatedother[i] = { selectedboard: [] };
        }
        if (!updatedallother[i]) {
          updatedallother[i] = { otherboard: [] };
        }
      }
      setSelectedotherboard(updatedother);
      setOtherboard(updatedallother);
      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
    } else {
      setAvailbleboard([]);
      setSelectedroomocc([]);
      updatedList.forEach((room) => {
        room.based_on = "";
      });
    }

    updatedList.forEach((room) => {
      room.selected = isChecked;
    });
    setOffer2room(updatedList);
  };

  const handlesingleroomselect = (i, isChecked) => {
    const updatedList = [...offer2room];

    const updatedroomocc = [...selectedroomocc];
    updatedList[i]["selected"] = isChecked;
    if (isChecked === true) {
      //count add to list
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            // If the item doesn't exist in availableboard, add it with its count
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        const newAvailableBoard = updatedList[i].display_board.map(
          (newItem) => ({
            id: newItem,
            count: 1,
          })
        );
        setAvailbleboard(newAvailableBoard);
      }

      //room add to new list

      updatedroomocc.push({
        key: updatedList[i].key,
        label: updatedList[i].label,
        value: updatedList[i].value,
      });
      setSelectedroomocc(updatedroomocc);

      let updatedother = [...selectedotherboard];
      const updatedallother = [...otherboard];
      if (!updatedother[i]) {
        updatedother[i] = { selectedboard: [] };
      }
      if (!updatedallother[i]) {
        updatedallother[i] = { otherboard: [] };
      }
      setSelectedotherboard(updatedother);
      setOtherboard(updatedallother);
    } else {
      //count minus
      updatedList[i]["based_on"] = "";
      updatedList[i]["all_label"] = false;
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );

          if (existingItem) {
            // Decrease the count when isChecked is false
            existingItem.count -= 1;

            // If count is zero or less, remove the item
            if (existingItem.count <= 0) {
              updatedAvailableBoard.splice(
                updatedAvailableBoard.indexOf(existingItem),
                1
              );
            }
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        // Handle the case where availableboard is empty
        console.error("Availableboard is empty");
      }

      const updatedroomoccFiltered = updatedroomocc.filter((item) => {
        return item.key !== updatedList[i].key;
      });

      setSelectedroomocc(updatedroomoccFiltered);
    }

    updatedList[i].occupancies.forEach((occ) => {
      occ.selected = false;
    });

    setOffer2room(updatedList);

    if (isChecked === false) {
      setActiveroom(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveroom(true);
      }
    }
  };

  const handlesingleoccupancyselect = (index, indx, isChecked) => {
    const updatedList = [...offer2room];

    const updatedroomocc = [...selectedroomocc];
    if (isChecked === true) {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          updatedroomocc[i].occupancies.push(
            updatedList[index].occupancies[indx]
          );
          break; // Exit the loop after finding and updating the matching room
        }
      }
      //room select after board select

      //   if( boardoffer2.find(item => item.selected === true)){
      //   updatedList.forEach(newone=>{
      //     boardoffer2.forEach(newItem => {
      //     for (let j = 0; j < updatedroomocc.length; j++) {
      //       if (newone.value === updatedroomocc[j].value) {

      //         if (newone.display_board.includes(newItem.value)) {
      //           for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
      //             if (!updatedroomocc[j].occupancies[k].boards) {
      //               updatedroomocc[j].occupancies[k].boards = [];
      //             }
      //             updatedroomocc[j].occupancies[k].boards.push({
      //               hotel_board: newItem.hotel_borad,
      //               label: newItem.label,
      //               value:newItem.value
      //             });

      //           }
      //         }
      //       }
      //     }
      //     })
      //   })
      // }

      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.based_on === "per_person") {
                  if (
                    newone.display_board.includes(newItem.value) &&
                    newItem.selected === true
                  ) {
                    for (
                      let k = 0;
                      k < updatedroomocc[j].occupancies.length;
                      k++
                    ) {
                      if (!updatedroomocc[j].occupancies[k].boards) {
                        updatedroomocc[j].occupancies[k].boards = [];
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      } else {
                        if (
                          !updatedroomocc[j].occupancies[k].boards.some(
                            (item) => item.hotel_board === newItem.hotel_borad
                          )
                        ) {
                          updatedroomocc[j].occupancies[k].boards.push({
                            hotel_board: newItem.hotel_borad,
                            label: newItem.label,
                            value: newItem.value,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = updatedroomocc[i].occupancies.filter(
            (item) =>
              item.occupancy_id !==
              updatedList[index].occupancies[indx].occupancy_id
          );
          break;
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    updatedList[index]["occupancies"][indx]["selected"] = isChecked;

    if (isChecked === false) {
      updatedList[index]["all_label"] = isChecked;
    } else {
      const hasSelectedFalse = updatedList[index].occupancies.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        updatedList[index]["all_label"] = isChecked;
      }
    }
    setOffer2room(updatedList);
  };

  const handleChangeboardoffer2 = (isChecked) => {
    setActiveboard(isChecked);
    const updatedList = [...boardoffer2];

    const updatedListroom = [...offer2room];
    const updatedroomocc = [...selectedroomocc];

    updatedList.forEach((room) => {
      if (availableboard.some((item) => item.id === room.value)) {
        room.selected = isChecked;
      }
    });

    setBoardoffer2(updatedList);

    if (isChecked === true) {
      updatedListroom.forEach((newone) => {
        updatedList.forEach((newItem) => {
          for (let j = 0; j < updatedroomocc.length; j++) {
            if (newone.value === updatedroomocc[j].value) {
              if (newone.display_board.includes(newItem.value)) {
                for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                  if (!updatedroomocc[j].occupancies[k].boards) {
                    updatedroomocc[j].occupancies[k].boards = [];
                  }
                  updatedroomocc[j].occupancies[k].boards.push({
                    hotel_board: newItem.hotel_borad,
                    label: newItem.label,
                    value: newItem.value,
                  });
                }
              }
            }
          }
        });
      });

      setSelectedroomocc(updatedroomocc);
    } else {
      updatedListroom.forEach((newone) => {
        updatedList.forEach((newItem) => {
          for (let j = 0; j < updatedroomocc.length; j++) {
            if (newone.value === updatedroomocc[j].value) {
              if (newone.display_board.includes(newItem.value)) {
                for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                  updatedroomocc[j].occupancies[k].boards = [];
                }
              }
            }
          }
        });
      });

      setSelectedroomocc(updatedroomocc);
    }
  };

  const handlesingleboardselect = (i, isChecked) => {
    const updatedList = [...boardoffer2];
    const rooms = [...offer2room];
    const updatedroomocc = [...selectedroomocc];

    updatedList[i]["selected"] = isChecked;
    setBoardoffer2(updatedList);

    if (isChecked === false) {
      setActiveboard(isChecked);

      //board added to new list
      for (let l = 0; l < rooms.length; l++) {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (rooms[l].value === updatedroomocc[j].value) {
            for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
              if (updatedroomocc[j].occupancies[k].boards) {
                updatedroomocc[j].occupancies[k].boards = updatedroomocc[
                  j
                ].occupancies[k].boards.filter((board) => {
                  return board.value !== updatedList[i].value;
                });
              }
            }
          }
        }
      }

      setSelectedroomocc(updatedroomocc);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveboard(true);
      }
      for (let l = 0; l < rooms.length; l++) {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (rooms[l].value === updatedroomocc[j].value) {
            if (rooms[l].display_board.includes(updatedList[i].value)) {
              for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                if (!updatedroomocc[j].occupancies[k].boards) {
                  updatedroomocc[j].occupancies[k].boards = [];
                }
                updatedroomocc[j].occupancies[k].boards.push({
                  hotel_board: updatedList[i].hotel_borad,
                  label: updatedList[i].label,
                  value: updatedList[i].value,
                });

                // Add updatedList[i].board to updatedroomocc

                // updatedroomocc[j].boards.push({ hotel_borad: updatedList[i].hotel_borad, label: updatedList[i].label ,value:updatedList[i].value });
              }
            }
          }
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
  };

  const handleChangerateoffer2 = (isChecked) => {
    setActiverate(isChecked);
    const updatedList = [...ratelistoffer2];
    updatedList.forEach((room) => {
      room.selected = isChecked;
    });
    setRatelistoffer2(updatedList);
  };

  const handlesinglerateselect = (i, isChecked) => {
    const updatedList = [...ratelistoffer2];
    updatedList[i]["selected"] = isChecked;
    setRatelistoffer2(updatedList);

    if (isChecked === false) {
      setActiverate(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiverate(true);
      }
    }
  };

  const handlebasedon = (index, val) => {
    const updatedroomocc = [...selectedroomocc];
    const updatedList = [...offer2room];

    updatedList[index]["based_on"] = val;
    if (val === "per_room") {
      updatedList[index]["all_label"] = false;
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["based_on"] = val;

          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }

          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            if (
              !updatedroomocc[i].occupancies.some(
                (item) =>
                  item.occupancy_id ===
                  updatedList[index].occupancies[j].occupancy_id
              )
            ) {
              updatedroomocc[i].occupancies.push(
                updatedList[index].occupancies[j]
              );
            }
          }
        }
      }
      //room select after board select
      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (
                  newone.display_board.includes(newItem.value) &&
                  newItem.selected === true
                ) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                      updatedroomocc[j].occupancies[k].boards.push({
                        hotel_board: newItem.hotel_borad,
                        label: newItem.label,
                        value: newItem.value,
                      });
                    } else {
                      if (
                        !updatedroomocc[j].occupancies[k].boards.some(
                          (item) => item.hotel_board === newItem.hotel_borad
                        )
                      ) {
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["based_on"] = val;

          updatedroomocc[i].occupancies = [];
        }
      }
    }
    setOffer2room(updatedList);
  };
  const handleChangeroomoffer2all_label = (index, isChecked) => {
    const updatedList = [...offer2room];
    const updatedroomocc = [...selectedroomocc];
    let updatedother = [...selectedotherboard];

    updatedList[index]["all_label"] = isChecked;

    if (isChecked === true) {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = true;
      });
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          // updatedroomocc[i].occupancies.push(newone.occupancies);
          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            if (updatedList[index].occupancies[j].selected === true) {
              if (
                !updatedroomocc[i].occupancies.some(
                  (item5) =>
                    item5.occupancy_id ===
                    updatedList[index].occupancies[j].occupancy_id
                )
              ) {
                updatedroomocc[i].occupancies.push(
                  updatedList[index].occupancies[j]
                );
              }
            }
            // if (!updatedList[index].occupancies
            //   .filter(item => item.selected === true)
            //   .every(item => updatedroomocc[i].occupancies
            //     .some(item5 => item5.occupancy_id === item.occupancy_id )
            //   )){
            // updatedroomocc[i].occupancies.push(updatedList[index].occupancies[j]);
            //   }
          }
          // break; // Exit the loop after finding and updating the matching room
        }
      }

      //room select after board select
      // if( boardoffer2.find(item => item.selected === true)){
      //   updatedList.forEach(newone=>{
      //     boardoffer2.forEach(newItem => {
      //     for (let j = 0; j < updatedroomocc.length; j++) {
      //       if (newone.value === updatedroomocc[j].value) {

      //         if (newone.display_board.includes(newItem.value)) {
      //           for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
      //             if (!updatedroomocc[j].occupancies[k].boards) {
      //               updatedroomocc[j].occupancies[k].boards = [];
      //             }
      //             updatedroomocc[j].occupancies[k].boards.push({
      //               hotel_board: newItem.hotel_borad,
      //               label: newItem.label,
      //               value:newItem.value
      //             });

      //           }
      //         }
      //       }
      //     }
      //     })
      //   })
      // }

      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.based_on === "per_person") {
                  if (
                    newone.display_board.includes(newItem.value) &&
                    newItem.selected === true
                  ) {
                    for (
                      let k = 0;
                      k < updatedroomocc[j].occupancies.length;
                      k++
                    ) {
                      if (!updatedroomocc[j].occupancies[k].boards) {
                        updatedroomocc[j].occupancies[k].boards = [];
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      } else {
                        if (
                          !updatedroomocc[j].occupancies[k].boards.some(
                            (item) => item.hotel_board === newItem.hotel_borad
                          )
                        ) {
                          updatedroomocc[j].occupancies[k].boards.push({
                            hotel_board: newItem.hotel_borad,
                            label: newItem.label,
                            value: newItem.value,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = [];
        }
      }
    }
    setOffer2room(updatedList);

    setSelectedroomocc(updatedroomocc);
  };

  const nextoffterstep2 = (icon) => {
    let isFormValid = false;
    let isFormValid1 = false;
    let isFormValid2 = false;
    let isFormValid3 = true;

    let arr = [];
    const errors = {};

    const hasSelectedFalse = offer2room.some((item) => item.selected === true);
    if (hasSelectedFalse) {
      isFormValid = true;
    }

    const hasSelectedboard = boardoffer2.filter(
      (item6) => item6.selected === true
    );

    for (let i = 0; i < offer2room.length; i++) {
      const errors1 = {};
      if (offer2room[i].selected === true) {
        // for(let j=0;j<offer2room[i].occupancies.length;j++){
        //   if(offer2room[i].based_on==='per_person'){
        //     if(offer2room[i].occupancies[j].selected===true){
        //       isFormValid = true;

        //    }
        //    else{
        //     isFormValid = false;
        //    }
        //   if(!updatedroomocc[j].occupancies[k].boards.includes(newItem.value)){

        //   }

        //   }
        //   else if(offer2room[i].based_on==='per_room'){
        //     isFormValid = true;
        //   }

        //   else{
        //     isFormValid = false;
        //    }

        // }
        const isMatchFound =
          offer2room[i].display_board.filter((item6) =>
            hasSelectedboard.some((item) => item.value === item6)
          ).length > 0;
        if (offer2room[i].based_on === "") {
          isFormValid = false;
        } else {
          if (isMatchFound === true) {
            if (offer2room[i].based_on === "per_person") {
              const hasSelectedFalse = offer2room[i].occupancies.some(
                (item) => item.selected === true
              );
              if (hasSelectedFalse) {
                isFormValid = true;
              } else {
                isFormValid = false;
              }
            } else if (offer2room[i].based_on === "per_room") {
              isFormValid = true;
            } else {
              isFormValid = false;
            }
          } else {
            isFormValid3 = false;
            errors1.room_name = "This room not included the selected board !";
          }
        }
      }
      arr.push(errors1);
    }
    setErrorroom(arr);

    if (isFormValid === false) {
      errors.room = "Please select one room and occupancy!";
    }

    // for(let i=0;i<boardoffer2.length;i++){
    //   if(boardoffer2[i].selected===true){

    //          isFormValid1 = true;
    //     }
    //   }

    const hasSelectedFalse1 = boardoffer2.some(
      (item) => item.selected === true
    );
    if (hasSelectedFalse1) {
      isFormValid1 = true;
    }
    if (isFormValid1 === false) {
      errors.board = "Please select one board.";
    }

    // for(let i=0;i<ratelistoffer2.length;i++){
    //   if(ratelistoffer2[i].selected===true){

    //          isFormValid2 = true;
    //     }
    //   }

    const hasSelectedFalse3 = ratelistoffer2.some(
      (item) => item.selected === true
    );
    if (hasSelectedFalse3) {
      isFormValid2 = true;
    }

    if (isFormValid2 === false) {
      errors.rate = "Please select one Rate.";
    }

    setOffer2error(errors);

    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true &&
      isFormValid3 === true
    ) {
      if (icon === "prev") {
        setTab("step1");
        setOffer2valid(true);
      } else {
        setTab("step3");
        setOffer2valid(true);
      }
      const updatedBoard = [...otherboard];
      for (let j = 0; j < selectedroomocc.length; j++) {
        for (let i = 0; i < boardoffer2.length; i++) {
          if (boardoffer2[i].selected === false || !boardoffer2[i].selected) {
            if (updatedBoard[j].otherboard.length > 0) {
              if (
                !updatedBoard[j].otherboard.some(
                  (item) => item.value === boardoffer2[i].value
                )
              ) {
                updatedBoard[j].otherboard.push({
                  label: boardoffer2[i].label,
                  value: boardoffer2[i].value,
                  lang_code: boardoffer2[i].lang_code,
                  hotel_borad: boardoffer2[i].hotel_borad,
                });
              }
            } else {
              updatedBoard[j].otherboard.push({
                label: boardoffer2[i].label,
                value: boardoffer2[i].value,
                lang_code: boardoffer2[i].lang_code,
                hotel_borad: boardoffer2[i].hotel_borad,
              });
            }
          } else if (boardoffer2[i].selected === true) {
            for (let k = 0; k < offer2room.length; k++) {
              if (offer2room[k].value === selectedroomocc[j].value) {
                // Found the matching element
                const matchingElement = offer2room[k];
                if (
                  !matchingElement.display_board.some(
                    (item) => item === boardoffer2[i].value
                  )
                ) {
                  if (
                    !updatedBoard[j].otherboard.some(
                      (item) => item.value === boardoffer2[i].value
                    )
                  ) {
                    updatedBoard[j].otherboard.push({
                      label: boardoffer2[i].label,
                      value: boardoffer2[i].value,
                      lang_code: boardoffer2[i].lang_code,
                      hotel_borad: boardoffer2[i].hotel_borad,
                    });
                  }
                }
              }
            }
          }
        }
      }
      setOtherboard(updatedBoard);
    }
  };

  //offer2 page

  //offer3 page

  const handleOptionChangeoffer3price = (val, name) => {
    // if(name==='last_night'){
    //   offer3fields.free_booking_night2='';
    // }
    // else if(name==='cheapest_night'){
    //   offer3fields.free_booking_night2='';

    // }
    // setOffer3fields({...offer3fields,[name]:val});
    setOffer3fields((prevOffer3fields) => {
      const updatedFields = { ...prevOffer3fields };

      if (name === "free_night_type" && val === "last_night") {
        updatedFields.free_booking_night2 = "";
      } else if (name === "free_night_type" && val === "cheapest_night") {
        updatedFields.free_booking_night1 = "";
      }

      return { ...updatedFields, [name]: val };
    });
  };
  const handleoffer3checkbox = (isChecked, name) => {
    setOffer3fields({ ...offer3fields, [name]: isChecked });
  };

  const handleoffer3weekcheck = (isChecked, name) => {
    const updatedroomocc = [...selectedroomocc];

    if (isChecked === true) {
      for (let j = 0; j < updatedroomocc.length; j++) {
        for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
          for (
            let l = 0;
            l < updatedroomocc[j].occupancies[k].boards.length;
            l++
          ) {
            updatedroomocc[j].occupancies[k].boards[l].rate = "";
          }

          // Add updatedList[i].board to updatedroomocc

          // updatedroomocc[j].boards.push({ hotel_borad: updatedList[i].hotel_borad, label: updatedList[i].label ,value:updatedList[i].value });
        }
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let j = 0; j < updatedroomocc.length; j++) {
        for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
          for (
            let l = 0;
            l < updatedroomocc[j].occupancies[k].boards.length;
            l++
          ) {
            updatedroomocc[j].occupancies[k].boards[l].monday = "";
            updatedroomocc[j].occupancies[k].boards[l].tuesday = "";
            updatedroomocc[j].occupancies[k].boards[l].wednesday = "";
            updatedroomocc[j].occupancies[k].boards[l].thursday = "";
            updatedroomocc[j].occupancies[k].boards[l].friday = "";
            updatedroomocc[j].occupancies[k].boards[l].saturday = "";
            updatedroomocc[j].occupancies[k].boards[l].sunday = "";
          }
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    setOffer3fields({ ...offer3fields, [name]: isChecked });
  };

  const addInputFieldoffer3 = () => {
    setInputFieldsexception([
      ...inputFieldsexception,
      {
        fromdate: [],
        todate: [],
        discount: "",
        unit_value: "percentage",
      },
    ]);
  };
  const removeInputFieldsoffer3 = (index) => {
    const rows = [...inputFieldsexception];
    rows.splice(index, 1);
    setInputFieldsexception(rows);
  };

  let handleChangedatefrom = (i, data) => {
    // setDatefrom(data);
    let newval = [...inputFieldsexception];
    inputFieldsexception[i]["fromdate"] = data;
    setInputFieldsexception(newval);
  };

  let handleChangetodate = (i, data) => {
    // setDateto(data);
    let newval = [...inputFieldsexception];
    inputFieldsexception[i]["todate"] = data;
    setInputFieldsexception(newval);
  };
  let handleChangediscount = (index, val, name) => {
    let newInputFields = [...inputFieldsexception];
    newInputFields[index][name] = val;
    setInputFieldsexception(newInputFields);
  };

  const handleloyalchangeoffer3country = (isChecked, index) => {
    const updatedList = [...countryOffer3];
    updatedList[index]["selected"] = isChecked;
    setCountryOffer3(updatedList);
  };
  const addInputFieldextra = () => {
    setInputFieldsextra([
      ...inputFieldsextra,
      {
        datefrom: [],
        dateto: [],
        discounts: "",
        discount_unit_price: "percentage",
      },
    ]);
  };
  const removeInputFieldsextra = (index) => {
    const rows = [...inputFieldsextra];
    rows.splice(index, 1);
    setInputFieldsextra(rows);
  };

  let handleChangedatefromextra = (i, data) => {
    let newval = [...inputFieldsextra];
    inputFieldsextra[i]["datefrom"] = data;
    setInputFieldsextra(newval);
  };

  let handleChangetodateextra = (i, data) => {
    let newval = [...inputFieldsextra];
    inputFieldsextra[i]["dateto"] = data;
    setInputFieldsextra(newval);
  };

  let handleChangediscountsextra = (index, event) => {
    const value = event.target.value;
    const name = event.target.name;
    let newInputFieldsextra = [...inputFieldsextra];
    newInputFieldsextra[index][name] = value;
    setInputFieldsextra(newInputFieldsextra);
  };

  const handleweekinput = (i, ind, index, e) => {
    const updatedList = [...selectedroomocc];
    updatedList[i]["occupancies"][ind]["boards"][index][e.target.name] =
      e.target.value;
    setSelectedroomocc(updatedList);
  };
  const handleweekinputextend = (i, ind, index, e) => {
    const updatedList = [...selectedroomocc];
    for (let j = 0; j < updatedList[i].occupancies.length; j++) {
      for (let k = 0; k < updatedList[i].occupancies[j].boards.length; k++) {
        updatedList[i].occupancies[j].boards[index][e.target.name] =
          e.target.value;
      }
    }
    setSelectedroomocc(updatedList);
  };

  const handleoffer3change = (e) => {
    if (e.target.name === "increment_type") {
      setOffer3fields({
        ...offer3fields,
        [e.target.name]: e.target.value,
        increment_room_value: "",
        increment_per_adult: "",
        increment_per_child: "",
      });
    } else {
      setOffer3fields({ ...offer3fields, [e.target.name]: e.target.value });
    }
  };

  const handleInputChangeconutryvalue = (i, val, name) => {
    const updatedList = [...multicountri3];
    updatedList[i][name] = val;
    setMulticountry3(updatedList);
  };

  let handleClickroom = (i) => {
    let newval = [...tabroom];
    tabroom[i]["click"] = !tabroom[i]["click"];
    setTabroom(newval);
  };

  let handleTab = (val, i) => {
    let newval = [...tabroom];
    tabroom[i]["tab"] = val;
    setTabroom(newval);
  };

  let handleClickroomweek = (i) => {
    let newval = [...tabroomweek];
    tabroomweek[i]["click"] = !tabroomweek[i]["click"];
    setTabroomweek(newval);
  };

  let handleTabweek = (val, i) => {
    let newval = [...tabroomweek];
    tabroomweek[i]["tab"] = val;
    setTabroomweek(newval);
  };

  let handleotherboard = (ind, val) => {
    let updatedList = [...selectedroomocc];
    for (let i = 0; i < val.length; i++) {
      for (let k = 0; k < updatedList[ind].occupancies.length; k++) {
        if (
          !updatedList[ind].occupancies[k].boards.some(
            (item) => item.value === val[i].value
          )
        ) {
          updatedList[ind].occupancies[k].boards.push({
            hotel_board: val[i].hotel_borad,
            label: val[i].label,
            value: val[i].value,
            rate: "",
          });
        }
      }
    }
    for (let k = 0; k < updatedList[ind]?.occupancies?.length; k++) {
      for (
        let l = 0;
        l < updatedList[ind]?.occupancies[k]?.boards?.length;
        l++
      ) {
        const board = updatedList[ind].occupancies[k].boards[l];
        const isBoardInSelectedOtherBoard = val.some(
          (item) => item.value === board.value
        );
        const isBoardInBoardOffer2 = boardoffer2.some(
          (item) =>
            item.value === board.value &&
            (item.selected === false || !item.selected)
        );
        if (!isBoardInSelectedOtherBoard && isBoardInBoardOffer2) {
          updatedList[ind].occupancies[k].boards.splice(l, 1);
          l--;
        }
      }
    }
    setSelectedroomocc(updatedList);
    let updatedother = [...selectedotherboard];
    if (!updatedother[ind]) {
      updatedother[ind] = { selectedboard: [] };
    }
    updatedother[ind].selectedboard = val;
    setSelectedotherboard(updatedother);
  };

  const nextoffterstep3rate = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer3fields.price_type === "fixed") {
      if (offer3fields.fixed_week_price_status === true) {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              if (
                !selectedroomocc[i].occupancies[j].boards[k].monday &&
                !selectedroomocc[i].occupancies[j].boards[k].tuesday &&
                !selectedroomocc[i].occupancies[j].boards[k].wednesday &&
                !selectedroomocc[i].occupancies[j].boards[k].thursday &&
                !selectedroomocc[i].occupancies[j].boards[k].friday &&
                !selectedroomocc[i].occupancies[j].boards[k].saturday &&
                !selectedroomocc[i].occupancies[j].boards[k].sunday
              ) {
                isFormValid = false;
                errors.fixed_week_price =
                  "Price for each day of the week is required.";
              }
            }
          }
        }
      } else {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              if (!selectedroomocc[i].occupancies[j].boards[k].rate) {
                isFormValid = false;
                errors.fixed_price =
                  "Please choose a fixed price for the offer";
              }
            }
          }
        }
      }
    }
    if (offer3fields.price_type === "discount") {
      if (!offer3fields.discount_value) {
        isFormValid = false;
        errors.discount_value = "Please provide a value";
      }
    }
    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setTab("step2");
        setOffer3validrate(true);
      } else {
        setRatetab("increment");
        setOffer3validrate(true);
      }
    }
  };

  const nextoffterstep3increment = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer3fields.increment_status === true) {
      if (offer3fields.increment_type === "per_person") {
        if (
          !offer3fields.increment_per_adult &&
          !offer3fields.increment_per_child
        ) {
          isFormValid = false;
          errors.increment_type =
            "If selecting the increment option, please enter a positive value ";
        }
      } else {
        if (!offer3fields.increment_room_value) {
          isFormValid = false;
          errors.increment_type =
            "If selecting the increment option, please specify a positive value ";
        }
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("rate");
        setOffer3validncrement(true);
      } else {
        setRatetab("free_nights");
        setOffer3validncrement(true);
      }
    }
  };

  const nextoffterstep3freenight = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer3fields.free_night_status === true) {
      if (offer3fields.free_night_type === "last_night") {
        if (!offer3fields.free_booking_night1) {
          isFormValid = false;
          errors.free_booking_night1 = "Please select a value.";
        }
      } else if (offer3fields.free_night_type === "cheapest_night") {
        if (!offer3fields.free_booking_night2) {
          isFormValid = false;
          errors.free_booking_night2 = "Please select a value.";
        }
      }
    }
    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("increment");
        setOffer3validfreenight(true);
      } else {
        setRatetab("extra_discount");
        setOffer3validfreenight(true);
      }
    }
  };

  const nextoffterstep3extra = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer3fields.booking_at_night_status === true) {
      if (!offer3fields.booking_night_discount) {
        isFormValid = false;
        errors.booking_time_discount = "Please enter a valid discount.";
      }
      if (
        !offer3fields.hours_discount_from ||
        !offer3fields.hours_discount_to
      ) {
        isFormValid = false;
        errors.hours_discount_to = "Please input the hours.";
      }
    }

    if (offer3fields.booking_weekend_discount_status === true) {
      if (!offer3fields.booking_weekend_discount) {
        isFormValid = false;
        errors.booking_weekend_discount = "Please enter a valid discount.";
      }
      if (
        !offer3fields.booking_weekend_week_from ||
        !offer3fields.booking_weekend_time_from ||
        !offer3fields.booking_weekend_week_to ||
        !offer3fields.booking_weekend_time_to
      ) {
        isFormValid = false;
        errors.booking_weekend_week_from = "Please input all the fields.";
      }
    }
    if (offer3fields.long_stay_discount_status === true) {
      if (
        !offer3fields.long_stay_discount0_percentage ||
        !offer3fields.long_stay_discount0_night
      ) {
        isFormValid = false;
        errors.long_stay_discount0_percentage =
          "Please input a discount for a long stay.";
      }
    }
    if (offer3fields.long_stay_discount_status === true) {
      if (
        (offer3fields?.long_stay_discount0_night &&
          offer3fields?.long_stay_discount1_night &&
          parseInt(offer3fields?.long_stay_discount0_night) >
            parseInt(offer3fields?.long_stay_discount1_night)) ||
        (offer3fields?.long_stay_discount1_night &&
          offer3fields?.long_stay_discount2_night &&
          parseInt(offer3fields?.long_stay_discount1_night) >
            parseInt(offer3fields?.long_stay_discount2_night)) ||
        (offer3fields?.long_stay_discount2_night &&
          offer3fields?.long_stay_discount3_night &&
          parseInt(offer3fields?.long_stay_discount2_night) >
            parseInt(offer3fields?.long_stay_discount3_night))
      ) {
        isFormValid = false;
        errors.long_stay_discount1_night =
          "Sequence discounts: short to long stays.";
      }
    }
    if (offer3fields.early_booking_discount_status === true) {
      if (
        !offer3fields.early_booking_discount0_days ||
        !offer3fields.early_booking_discount0_percentage
      ) {
        isFormValid = false;
        errors.early_booking_discount_status =
          "A discount for early booking must be inserted";
      }
    }

    if (offer3fields.last_minute_discount_status === true) {
      if (!offer3fields.last_minute_discount0_discount) {
        isFormValid = false;
        errors.last_minute_discount0_discount =
          "Please input a discount for last-minute bookings";
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("free_nights");
        setOffer3validextrae(true);
      } else {
        setRatetab("country");
        setOffer3validextrae(true);
      }
    }
  };

  const nextoffterstep3country = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer3fields.country_discount_status === true) {
      // const hasSelectedFalse = countryOffer3.some((item) => item.selected === true );

      if (multicountri3.length === 0) {
        isFormValid = false;
        errors.country_discount = "You must select at least one area.";
      } else {
        const hasSelectedFalse = multicountri3.some((item) => {
          if (!item.discount) {
            isFormValid = false;
            errors.country_discount = "Please specify a value.";

            return true;
          }
          return false;
        });
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("extra_discount");
        setOffer3validcountry(true);
      } else {
        setRatetab("device_discount");
        setOffer3validcountry(true);
      }
    }
  };

  const nextoffterstep3device = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer3fields.device_discount_status === true) {
      if (!offer3fields.device_discount_percentage) {
        isFormValid = false;
        errors.device_discount_percentage = "Please specify a value";
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("country");
        setOffer3validdivice(true);
      } else {
        setTab("step4");
        setOffer3valid(true);
      }
    }
  };
  //offer3 page

  //offer4 page

  const handleoffer4checkbox = (isChecked, name) => {
    setOffer4fields({ ...offer4fields, [name]: isChecked });
  };

  const handleloyalchangeoffer4 = (isChecked, index) => {
    const updatedList = [...Loyaltyclublist];
    updatedList[index]["selected"] = isChecked;
    setLoyaltyclublist(updatedList);
  };

  const handlechangeoffer4 = (e) => {
    setOffer4fields({ ...offer4fields, [e.target.name]: e.target.value });
  };

  const handleOptionChangeoffer4Cancelpolicy = (val, name) => {
    setOffer4fields({ ...offer4fields, [name]: val });
  };

  const handleloyalchangeoffer4country = (isChecked, index) => {
    const updatedList = [...countrylist];
    updatedList[index]["offercountry"] = isChecked;
    setCountrylist(updatedList);
  };

  const nextoffterstep4 = (icon) => {
    let isFormValid = true;

    const errors = {};
    // if(offer4fields.promotional_code===false && offer4fields.loyalty_club===false && offer4fields.advanced_booking===false && offer4fields.different_payment===false && offer4fields.minimum_stay===false && offer4fields.maximum_stay===false&& offer4fields.minimum_room===false&& offer4fields.close_offer===false && offer4fields.maximum_rooms===false && offer4fields.last_minute===false && offer4fields.display_night===false &&offer4fields.apply_country===false && offer4fields.only_device===false){
    //   isFormValid=false;
    //   errors.offer4 = "select one Field";
    // }

    if (offer4fields.loyalty_club === true) {
      const hasSelectedFalse = Loyaltyclublist.some(
        (item) => item.selected === true
      );
      if (!hasSelectedFalse) {
        isFormValid = false;
        errors.loyalty_club = "Please choose one field";
      }
    }

    if (offer4fields.advanced_booking === true) {
      if (!offer4fields.ad_booking_days) {
        isFormValid = false;
        errors.ad_booking_days = "Please choose one field";
      }
    }

    if (offer4fields.different_payment === true) {
      if (!offer4fields.cancel_policy) {
        isFormValid = false;
        errors.cancel_policy = "Please choose one field";
      }
    }

    if (offer4fields.display_night === true) {
      if (
        offer4fields.offer_display_hours_status === false &&
        offer4fields.offer_display_weekend_status === false
      ) {
        isFormValid = false;
        errors.offer_display_hours_status = "Please choose one field";
      } else if (offer4fields.offer_display_weekend_status === true) {
        if (
          !offer4fields.offer_display_weekend_week_from ||
          !offer4fields.offer_display_weekend_time_from ||
          !offer4fields.offer_display_weekend_week_to ||
          !offer4fields.offer_display_weekend_time_to
        ) {
          isFormValid = false;
          errors.offer_display_hours_status = "Please choose one field";
        }
      }
    }

    if (offer4fields.apply_country === true) {
      // const hasSelectedFalse = countrylist.some((item) => item.offercountry === true );
      if (multicountri4.length === 0) {
        isFormValid = false;
        errors.apply_country = "Please select one country";
      }
    }

    if (offer4fields.only_device === true) {
      if (!offer4fields.offer_available_device_type) {
        isFormValid = false;
        errors.only_device = "Please choose one field";
      }
    }
    setOffer4error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setTab("step3");
        setOffer4valid(true);
      } else {
        setTab("step5");
        setOffer4valid(true);
      }
    }
  };

  //offer4 page

  //offer5 page

  const addInputField = () => {
    setOtherloyal([
      ...otherloyal,
      {
        loyalty_name: "",
        lang: [],
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...otherloyal];
    rows.splice(index, 1);
    setOtherloyal(rows);
  };

  const handleloyalchange = (isChecked, index) => {
    const updatedList = [...loyalty];
    updatedList[index]["selected"] = isChecked;
    setLoyalty(updatedList);
  };

  const hangleotherloyal = (index, val, name) => {
    const updatedList = [...otherloyal];
    updatedList[index][name] = val;
    setOtherloyal(updatedList);
  };

  const handlelanglo = (val) => {
    setLoyallang(val);
    const updatedList = [...otherloyal];
    for (let i = 0; i < updatedList.length; i++) {
      if (!updatedList[i].lang.some((item) => item.lang_code === val)) {
        updatedList[i].lang.push({ lang_code: val, loyalty_lang_name: "" });
      }
    }
    setOtherloyal(updatedList);
  };

  const hangleotherloyallang = (index, val, name) => {
    const updatedList = [...otherloyal];
    //   const matchingIndex = updatedList[index].findIndex((item) =>
    //   item.lang.some((language) => language.lang_code === loyallang)
    // );
    const matchingIndex = updatedList[index].lang.findIndex(
      (language) => language.lang_code === loyallang
    );
    if (matchingIndex !== -1) {
      updatedList[index]["lang"][matchingIndex][name] = val;
    } else {
      const newLanguage = { lang_code: loyallang };
      newLanguage[name] = val;
      updatedList[index].lang.push(newLanguage);
    }
    setOtherloyal(updatedList);
  };
  const nextoffterstep5 = (icon) => {
    if (icon === "prev") {
      if (offer_include_status === false) {
        setTab("step4");
        setOffer5valid(true);
      } else {
        let isFormValid = true;

        const errors = {};

        const hasSelectedFalse = loyalty.some((item) => item.selected === true);
        if (!hasSelectedFalse && otherloyal_status === false) {
          isFormValid = false;
          errors.loyalty = "Please choose one field";
        }

        if (otherloyal_status === true) {
          const hasSelectedFalse = otherloyal.some(
            (item) => item.loyalty_name !== ""
          );
          if (!hasSelectedFalse) {
            isFormValid = false;
            errors.loyalty_name = "Please enter at least one name.";
          }
        }

        setOffer5error(errors);

        if (isFormValid === true) {
          if (icon === "prev") {
            setTab("step4");
            setOffer5valid(true);
          } else {
            setTab("step6");
            setOffer5valid(true);
          }
        }
      }
    } else {
      if (offer_include_status === false) {
        setTab("step6");
        setOffer5valid(true);
      } else {
        let isFormValid = true;

        const errors = {};

        const hasSelectedFalse = loyalty.some((item) => item.selected === true);
        if (!hasSelectedFalse && otherloyal_status === false) {
          isFormValid = false;
          errors.loyalty = "select one Field";
        }

        if (otherloyal_status === true) {
          const hasSelectedFalse = otherloyal.some(
            (item) => item.loyalty_name !== ""
          );
          if (!hasSelectedFalse) {
            isFormValid = false;
            errors.loyalty_name = "Enter atleast one name";
          }
        }

        setOffer5error(errors);

        if (isFormValid === true) {
          if (icon === "prev") {
            setTab("step4");
            setOffer5valid(true);
          } else {
            setTab("step6");
            setOffer5valid(true);
          }
        }
      }
    }
  };

  //offer5 page

  //offer6 page

  const handlechangeoffer6 = (e) => {
    setOffer6fields({ ...offer6fields, [e.target.name]: e.target.value });
  };

  const handleoffer6checkbox = (isChecked, name) => {
    setOffer6fields({ ...offer6fields, [name]: isChecked });
  };

  const onFileChangepdf = (e) => {
    setPdf(e.target.files[0]);
  };
  //offer6 page

  const elementsNotInSelectedMulti = selectedMulti6.filter(
    (item6) => !selectedMulti.some((item) => item.code === item6.code)
  );
  const Save = async () => {
    const formData = new FormData();
    //step1
    formData.append("hotel_id", id);
    formData.append("title", offername);
    formData.append("description", offer6fields.description);
    formData.append("price_description", offer6fields.price_description);
    let mulcount = 0;
    let mulc;

    for (let i = 0; i < selectedMulti.length; i++) {
      const newi = selectedMulti6.findIndex(
        (item) => item.code === selectedMulti[i].code
      );
      if (selectedMulti6.some((item) => item.code === selectedMulti[i].code)) {
        if (
          selectedMulti[i].name ||
          selectedMulti6[newi].description ||
          selectedMulti6[newi].price_description
        ) {
          formData.append(
            `multi_lang[${mulcount}][lang_code]`,
            selectedMulti[i].code
          );
          if (selectedMulti[i].name) {
            formData.append(
              `multi_lang[${mulcount}][title]`,
              selectedMulti[i].name
            );
          } else {
            formData.append(`multi_lang[${mulcount}][title]`, "");
          }

          if (selectedMulti6[newi].description) {
            formData.append(
              `multi_lang[${mulcount}][description]`,
              selectedMulti6[newi].description
            );
          } else {
            formData.append(`multi_lang[${mulcount}][description]`, "");
          }

          if (selectedMulti6[newi].price_description) {
            formData.append(
              `multi_lang[${mulcount}][price_description]`,
              selectedMulti6[newi].price_description
            );
          } else {
            formData.append(`multi_lang[${mulcount}][price_description]`, "");
          }

          mulcount += 1;
        }
      } else if (
        !selectedMulti6.some((item) => item.code === selectedMulti[i].code)
      ) {
        if (selectedMulti[i].name) {
          formData.append(
            `multi_lang[${mulcount}][lang_code]`,
            selectedMulti[i].code
          );

          formData.append(
            `multi_lang[${mulcount}][title]`,
            selectedMulti[i].name
          );
        }
        mulcount += 1;
      }

      mulc = mulcount;
    }

    for (let j = 0; j < elementsNotInSelectedMulti.length; j++) {
      if (
        elementsNotInSelectedMulti[j].description ||
        elementsNotInSelectedMulti[j].price_description
      ) {
        formData.append(
          `multi_lang[${mulc}][lang_code]`,
          elementsNotInSelectedMulti[j].code
        );

        if (elementsNotInSelectedMulti[j].description) {
          formData.append(
            `multi_lang[${mulc}][description]`,
            elementsNotInSelectedMulti[j].description
          );
        } else {
          formData.append(`multi_lang[${mulc}][description]`, "");
        }

        if (elementsNotInSelectedMulti[j].price_description) {
          formData.append(
            `multi_lang[${mulc}][price_description]`,
            elementsNotInSelectedMulti[j].price_description
          );
        } else {
          formData.append(`multi_lang[${mulc}][price_description]`, "");
        }
      }
      mulc += 1;
    }

    if (offer1Check === true) {
      formData.append("status", 1);
    } else {
      formData.append("status", 0);
    }

    //step2

    if (offer1Check === true) {
      formData.append("d_stay", activestaydate);
      if (activestaydate === true) {
        formData.append("d_stay", 1);
      } else {
        formData.append("d_stay", 0);
      }
      if (activestaydate === true) {
        for (let i = 0; i < selectedDatesstay.length; i++) {
          const formattedDate = moment(selectedDatesstay[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`stay_dates[${i}]`, formattedDate);
        }
      }
      if (activecheckindate === true) {
        formData.append("d_checkin", 1);
      } else {
        formData.append("d_checkin", 0);
      }
      if (activecheckindate === true) {
        for (let i = 0; i < selectedDatescheckin.length; i++) {
          const formattedDate = moment(selectedDatescheckin[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`checkin_dates[${i}]`, formattedDate);
        }
      }
      if (activecheckoutdate === true) {
        formData.append("d_checkout", 1);
      } else {
        formData.append("d_checkout", 0);
      }
      if (activecheckoutdate === true) {
        for (let i = 0; i < selectedDatescheckout.length; i++) {
          const formattedDate = moment(selectedDatescheckout[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`checkout_dates[${i}]`, formattedDate);
        }
      }
      if (activereservedate === true) {
        formData.append("d_reserve", 1);
      } else {
        formData.append("d_reserve", 0);
      }
      if (activereservedate === true) {
        for (let i = 0; i < selectedDatesreserve.length; i++) {
          const formattedDate = moment(selectedDatesreserve[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`reserve_dates[${i}]`, formattedDate);
        }
      }
    }

    // for(let i=0;i<offer2room.length;i++){
    //   if(offer2room[i].selected===true){
    //     formData.append(`rooms[${i}][room]`,offer2room[i].value );
    //     for(let j=0;j<offer2room[i].occupancies.length;j++){
    //       if(offer2room[i].occupancies[j].selected===true){
    //         formData.append(`rooms[${i}][occupancy][${j}][occupancy_id]`,offer2room[i].occupancies[j].occupancy_id );

    //       }
    //     }
    //   }

    // }

    for (let i = 0; i < selectedroomocc.length; i++) {
      formData.append(`rooms[${i}][room]`, selectedroomocc[i].value);
      for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
        formData.append(
          `rooms[${i}][occupancy][${j}][occupancy_id]`,
          selectedroomocc[i].occupancies[j].occupancy_id
        );
      }
      formData.append(`rooms[${i}][type]`, selectedroomocc[i].based_on);
    }
    let count = 0;
    for (let i = 0; i < boardoffer2.length; i++) {
      if (boardoffer2[i].selected === true) {
        formData.append(`boards[${count}]`, boardoffer2[i].value);
        count += 1;
      }
    }

    let count1 = 0;

    for (let i = 0; i < ratelistoffer2.length; i++) {
      if (ratelistoffer2[i].selected === true) {
        formData.append(`rates[${count1}]`, ratelistoffer2[i].value);
        count1 += 1;
      }
    }
    //step3

    formData.append("price_type", offer3fields.price_type);
    if(board_apply_status==="yes"){
      formData.append("board_apply_status", 1);  
    }
    else if(board_apply_status==="no"){
      formData.append("board_apply_status", 0);  

    }
    if (offer3fields.price_type === "same") {
      formData.append("fixed_week_price_status", 0);
    } else if (offer3fields.price_type === "fixed") {
      
      if (offer3fields.fixed_week_price_status === true) {
        formData.append("fixed_week_price_status", 1);
      } else {
        formData.append("fixed_week_price_status", 0);
      }
      if (offer3fields.fixed_week_price_status === true) {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][board_id]`,
                selectedroomocc[i].occupancies[j].boards[k].value
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][monday]`,
                selectedroomocc[i].occupancies[j].boards[k].monday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][tuesday]`,
                selectedroomocc[i].occupancies[j].boards[k].tuesday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][wednesday]`,
                selectedroomocc[i].occupancies[j].boards[k].wednesday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][thursday]`,
                selectedroomocc[i].occupancies[j].boards[k].thursday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][friday]`,
                selectedroomocc[i].occupancies[j].boards[k].friday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][saturday]`,
                selectedroomocc[i].occupancies[j].boards[k].saturday
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][sunday]`,
                selectedroomocc[i].occupancies[j].boards[k].sunday
              );
            }
          }
        }
      } else {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][board_id]`,
                selectedroomocc[i].occupancies[j].boards[k].value
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][rate]`,
                selectedroomocc[i].occupancies[j].boards[k].rate
              );
            }
          }
        }
      }
    } else if (offer3fields.price_type === "discount") {
      formData.append("discount_value", offer3fields.discount_value);
      formData.append("discount_unit_price", offer3fields.discount_unit_price);
      let count = 0;
      for (let i = 0; i < inputFieldsexception.length; i++) {
        if (
          inputFieldsexception[i].fromdate.length > 0 &&
          inputFieldsexception[i].todate.length > 0 &&
          inputFieldsexception[i].discount
        ) {
          formData.append(
            `offer_discount[${count}][from]`,
            moment(inputFieldsexception[i].fromdate[0]).format("DD-MM-YYYY")
          );
          formData.append(
            `offer_discount[${count}][until]`,
            moment(inputFieldsexception[i].todate[0]).format("DD-MM-YYYY")
          );
          formData.append(
            `offer_discount[${count}][discount]`,
            inputFieldsexception[i].discount
          );
          formData.append(
            `offer_discount[${count}][unit_value]`,
            inputFieldsexception[i].unit_value
          );

          formData.append("fixed_week_price_status", 0);
          count += 1;
        }
      }
    }
    if (offer3fields.increment_status === true) {
      formData.append("increment_status", 1);
      formData.append("increment_type", offer3fields.increment_type);
      if (offer3fields.increment_type === "per_room") {
        formData.append(
          "increment_room_value",
          offer3fields.increment_room_value
        );
      } else {
        formData.append(
          "increment_per_adult",
          offer3fields.increment_per_adult
        );
        formData.append(
          "increment_per_child",
          offer3fields.increment_per_child
        );
      }
      formData.append("increment_frequency", offer3fields.increment_frequency);
      formData.append("increment_unit", offer3fields.increment_unit);
    } else {
      formData.append("increment_status", 0);
    }

    if (offer3fields.free_night_status === true) {
      formData.append("free_night_status", 1);
      formData.append("free_night_type", offer3fields.free_night_type);
      if (offer3fields.free_night_type === "last_night") {
        formData.append("free_booking_night", offer3fields.free_booking_night1);
      } else {
        formData.append("free_booking_night", offer3fields.free_booking_night2);
      }
    } else {
      formData.append("free_night_status", 0);
    }
    if (offer3fields.booking_at_night_status === true) {
      formData.append("booking_at_night_status", 1);

      formData.append(
        "booking_night_discount",
        offer3fields.booking_night_discount
      );
      formData.append("booking_hours_status", 1);
      formData.append("hours_discount_from", offer3fields.hours_discount_from);

      formData.append("hours_discount_to", offer3fields.hours_discount_to);

      formData.append(
        "booking_night_unit_value",
        offer3fields.booking_night_unit_value
      );
    } else {
      formData.append("booking_at_night_status", 0);
    }

    if (offer3fields.booking_weekend_discount_status === true) {
      formData.append("booking_weekend_discount_status", 1);
      formData.append(
        "booking_weekend_week_from",
        offer3fields.booking_weekend_week_from
      );
      formData.append(
        "booking_weekend_time_from",
        offer3fields.booking_weekend_time_from
      );

      formData.append(
        "booking_weekend_week_to",
        offer3fields.booking_weekend_week_to
      );
      formData.append(
        "booking_weekend_time_to",
        offer3fields.booking_weekend_time_to
      );
      formData.append(
        "booking_weekend_discount",
        offer3fields.booking_weekend_discount
      );
      formData.append(
        "booking_weekend_unit_value",
        offer3fields.booking_weekend_unit_value
      );

      let count = 0;
      for (let i = 0; i < inputFieldsextra.length; i++) {
        if (
          inputFieldsextra[i].datefrom.length > 0 &&
          inputFieldsextra[i].dateto.length > 0 &&
          inputFieldsextra[i].discounts
        ) {
          formData.append(
            `extra_discount_exception[${count}][from]`,
            moment(inputFieldsextra[i].datefrom[0]).format("DD-MM-YYYY")
          );
          formData.append(
            `extra_discount_exception[${count}][until]`,
            moment(inputFieldsextra[i].dateto[0]).format("DD-MM-YYYY")
          );
          formData.append(
            `extra_discount_exception[${count}][discount]`,
            inputFieldsextra[i].discounts
          );
          formData.append(
            `extra_discount_exception[${count}][unit_value]`,
            inputFieldsextra[i].discount_unit_price
          );

          count += 1;
        }
      }
    } else {
      formData.append("booking_weekend_discount_status", 0);
    }

    if (offer3fields.long_stay_discount_status === true) {
      formData.append("long_stay_discount_status", 1);
      formData.append(
        "long_stay_discount[0][night]",
        offer3fields.long_stay_discount0_night
      );
      formData.append(
        "long_stay_discount[0][discount]",
        offer3fields.long_stay_discount0_percentage
      );
      formData.append(
        "long_stay_discount[0][unit_value]",
        offer3fields.long_stay_discount0_unit
      );

      if (
        offer3fields.long_stay_discount1_night &&
        offer3fields.long_stay_discount1_percentage
      ) {
        formData.append(
          "long_stay_discount[1][night]",
          offer3fields.long_stay_discount1_night
        );
        formData.append(
          "long_stay_discount[1][discount]",
          offer3fields.long_stay_discount1_percentage
        );
        formData.append(
          "long_stay_discount[1][unit_value]",
          offer3fields.long_stay_discount1_unit
        );
      }
      if (
        offer3fields.long_stay_discount2_night &&
        offer3fields.long_stay_discount2_percentage
      ) {
        formData.append(
          "long_stay_discount[2][night]",
          offer3fields.long_stay_discount2_night
        );
        formData.append(
          "long_stay_discount[2][discount]",
          offer3fields.long_stay_discount2_percentage
        );
        formData.append(
          "long_stay_discount[2][unit_value]",
          offer3fields.long_stay_discount2_unit
        );
      }
      if (
        offer3fields.long_stay_discount3_night &&
        offer3fields.long_stay_discount3_percentage
      ) {
        formData.append(
          "long_stay_discount[3][night]",
          offer3fields.long_stay_discount3_night
        );
        formData.append(
          "long_stay_discount[3][discount]",
          offer3fields.long_stay_discount3_percentage
        );
        formData.append(
          "long_stay_discount[3][unit_value]",
          offer3fields.long_stay_discount3_unit
        );
      }
    } else {
      formData.append("long_stay_discount_status", 0);
    }

    if (offer3fields.early_booking_discount_status === true) {
      formData.append("early_booking_discount_status", 1);
      formData.append(
        "early_booking_discount[0][days]",
        offer3fields.early_booking_discount0_days
      );
      formData.append(
        "early_booking_discount[0][discount]",
        offer3fields.early_booking_discount0_percentage
      );
      formData.append(
        "early_booking_discount[0][unit_value]",
        offer3fields.early_booking_discount0_unit
      );

      if (
        offer3fields.early_booking_discount1_days &&
        offer3fields.early_booking_discount1_percentage
      ) {
        formData.append(
          "early_booking_discount[1][days]",
          offer3fields.early_booking_discount1_days
        );
        formData.append(
          "early_booking_discount[1][discount]",
          offer3fields.early_booking_discount1_percentage
        );
        formData.append(
          "early_booking_discount[1][unit_value]",
          offer3fields.early_booking_discount1_unit
        );
      }
      if (
        offer3fields.early_booking_discount2_days &&
        offer3fields.early_booking_discount2_percentage
      ) {
        formData.append(
          "early_booking_discount[2][days]",
          offer3fields.early_booking_discount2_days
        );
        formData.append(
          "early_booking_discount[2][discount]",
          offer3fields.early_booking_discount2_percentage
        );
        formData.append(
          "early_booking_discount[2][unit_value]",
          offer3fields.early_booking_discount2_unit
        );
      }
      if (
        offer3fields.early_booking_discount3_days &&
        offer3fields.early_booking_discount3_percentage
      ) {
        formData.append(
          "early_booking_discount[3][days]",
          offer3fields.early_booking_discount3_days
        );
        formData.append(
          "early_booking_discount[3][discount]",
          offer3fields.early_booking_discount3_percentage
        );
        formData.append(
          "early_booking_discount[3][unit_value]",
          offer3fields.early_booking_discount3_unit
        );
      }
    } else {
      formData.append("early_booking_discount_status", 0);
    }

    if (offer3fields.country_discount_status === true) {
      formData.append("country_discount_status", 1);
      let count = 0;
      for (let i = 0; i < multicountri3.length; i++) {
        if (multicountri3[i].discount) {
          formData.append(
            `discount_country[${count}][country]`,
            multicountri3[i].value
          );
          formData.append(
            `discount_country[${count}][discount]`,
            multicountri3[i].discount
          );
          if (!multicountri3[i].country_unit_value) {
            formData.append(
              `discount_country[${count}][unit_value]`,
              "currency"
            );
          } else {
            formData.append(
              `discount_country[${count}][unit_value]`,
              multicountri3[i].country_unit_value
            );
          }
          count += 1;
        }
      }
    } else {
      formData.append("country_discount_status", 0);
    }
    if (offer3fields.device_discount_status === true) {
      formData.append("device_discount_status", 1);
      formData.append(
        "device_discount_type",
        offer3fields.device_discount_type
      );
      formData.append(
        "device_discount",
        offer3fields.device_discount_percentage
      );
      formData.append(
        "device_discount_unit_value",
        offer3fields.device_discount_unit_value
      );
    } else {
      formData.append("device_discount_status", 0);
    }

    if (offer3fields.last_minute_discount_status === true) {
      formData.append("last_minute_discount_status", 1);
      formData.append(
        "last_minute_discount[0][from]",
        offer3fields.last_minute_discount0_from
      );
      formData.append(
        "last_minute_discount[0][discount]",
        offer3fields.last_minute_discount0_discount
      );
      formData.append(
        "last_minute_discount[0][unit_value]",
        offer3fields.last_minute_discount0_unit
      );

      if (
        offer3fields.last_minute_discount1_from &&
        offer3fields.last_minute_discount1_discount
      ) {
        formData.append(
          "last_minute_discount[1][from]",
          offer3fields.last_minute_discount1_from
        );
        formData.append(
          "last_minute_discount[1][discount]",
          offer3fields.last_minute_discount1_discount
        );
        formData.append(
          "last_minute_discount[1][unit_value]",
          offer3fields.last_minute_discount1_unit
        );
      }
      if (
        offer3fields.last_minute_discount2_from &&
        offer3fields.last_minute_discount2_discount
      ) {
        formData.append(
          "last_minute_discount[2][from]",
          offer3fields.last_minute_discount2_from
        );
        formData.append(
          "last_minute_discount[2][discount]",
          offer3fields.last_minute_discount2_discount
        );
        formData.append(
          "last_minute_discount[2][unit_value]",
          offer3fields.last_minute_discount2_unit
        );
      }
      if (
        offer3fields.last_minute_discount3_from &&
        offer3fields.last_minute_discount3_discount
      ) {
        formData.append(
          "last_minute_discount[3][from]",
          offer3fields.last_minute_discount3_from
        );
        formData.append(
          "last_minute_discount[3][discount]",
          offer3fields.last_minute_discount3_discount
        );
        formData.append(
          "last_minute_discount[3][unit_value]",
          offer3fields.last_minute_discount3_unit
        );
      }
    } else {
      formData.append("last_minute_discount_status", 0);
    }

    //step4
    if (offer4fields.promotional_code === true) {
      formData.append("promocode_available_status", 1);
    } else {
      formData.append("promocode_available_status", 0);
    }

    if (offer4fields.loyalty_club === true) {
      formData.append("loyality_club_status", 1);
      let count = 0;
      for (let i = 0; i < Loyaltyclublist.length; i++) {
        if (Loyaltyclublist[i].selected === true) {
          formData.append(`loyality_clubs[${count}]`, Loyaltyclublist[i].value);
          count += 1;
        }
      }
    } else {
      formData.append("loyality_club_status", 0);
    }

    if (offer4fields.advanced_booking === true) {
      formData.append("advance_booking_status", 1);
      formData.append("advance_booking_days", offer4fields.ad_booking_days);
    } else {
      formData.append("advance_booking_status", 0);
    }
    if (offer4fields.different_payment === true) {
      formData.append("cancelation_policy_status", 1);
      formData.append("cancelation_policy", offer4fields.cancel_policy);
    } else {
      formData.append("cancelation_policy_status", 0);
    }
    if (offer4fields.minimum_stay === true) {
      formData.append("offer_min_stay_status", 1);
      formData.append("offer_min_stay_days", offer4fields.minimum_stay_count);
      if (offer4fields.minimum_stay_night === true) {
        formData.append("all_min_night_consider_status", 1);
      } else {
        formData.append("all_min_night_consider_status", 0);
      }
    } else {
      formData.append("offer_min_stay_status", 0);
    }

    if (offer4fields.maximum_stay === true) {
      formData.append("offer_max_stay_status", 1);
      formData.append("offer_max_stay_days", offer4fields.maximum_stay_count);
      formData.append(
        "max_stay_all_night_offered",
        offer4fields.max_stay_all_night_offered
      );
    } else {
      formData.append("offer_max_stay_status", 0);
    }

    if (offer4fields.minimum_room === true) {
      formData.append("min_rooms_per_booking_status", 1);
      formData.append(
        "min_rooms_per_booking",
        offer4fields.min_rooms_per_booking
      );
    } else {
      formData.append("min_rooms_per_booking_status", 0);
    }
    if (offer4fields.close_offer === true) {
      formData.append("offer_close_for_min_rooms_status", 1);
      formData.append(
        "offer_close_for_room",
        offer4fields.offer_close_for_room
      );
    } else {
      formData.append("offer_close_for_min_rooms_status", 0);
    }

    if (offer4fields.maximum_rooms === true) {
      formData.append("max_room_per_night_status", 1);
      formData.append("max_room_per_night", offer4fields.max_room_per_night);
    } else {
      formData.append("max_room_per_night_status", 0);
    }
    if (offer4fields.last_minute === true) {
      formData.append("offer_last_minute_status", 1);
      formData.append(
        "offer_last_minute_hours",
        offer4fields.offer_last_minute_hours
      );
    } else {
      formData.append("offer_last_minute_status", 0);
    }
    if (offer4fields.display_night === true) {
      formData.append("offer_display_time_status", 1);
      if (offer4fields.offer_display_hours_status === true) {
        formData.append("offer_display_hours_status", 1);
        formData.append("offer_display_from", offer4fields.offer_display_from);

        formData.append("offer_display_to", offer4fields.offer_display_to);
      } else {
        formData.append("offer_display_hours_status", 0);
      }
      if (offer4fields.offer_display_weekend_status === true) {
        formData.append("offer_display_weekend_status", 1);
        formData.append(
          "offer_display_weekend_week_from",
          offer4fields.offer_display_weekend_week_from
        );
        formData.append(
          "offer_display_weekend_time_from",
          offer4fields.offer_display_weekend_time_from
        );
        formData.append(
          "offer_display_weekend_week_to",
          offer4fields.offer_display_weekend_week_to
        );
        formData.append(
          "offer_display_weekend_time_to",
          offer4fields.offer_display_weekend_time_to
        );
      } else {
        formData.append("offer_display_weekend_status", 0);
      }
    } else {
      formData.append("offer_display_time_status", 0);
    }

    if (offer4fields.apply_country === true) {
      formData.append("offer_available_country_status", 1);
      // let count=0;
      for (let i = 0; i < multicountri4.length; i++) {
        formData.append(`offer_country[${i}]`, multicountri4[i].value);
      }
    } else {
      formData.append("offer_available_country_status", 0);
    }

    if (offer4fields.only_device === true) {
      formData.append("offer_available_device_status", 1);
      formData.append(
        "offer_available_device_type",
        offer4fields.offer_available_device_type
      );
    } else {
      formData.append("offer_available_device_status", 0);
    }
    //step 5
    if (offer_include_status === true) {
      formData.append("offer_include_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty.length; i++) {
        if (loyalty[i].selected && loyalty[i].selected === true) {
          formData.append(`offer_include_list[${count}]`, loyalty[i].value);
          count += 1;
        }
      }

      if (otherloyal_status === true) {
        formData.append("offer_include_list_other_status", 1);
        // formData.append("offer_include_list_other_language_code", loyallang);
        for (let i = 0; i < otherloyal.length; i++) {
          if (otherloyal[i].loyalty_name) {
            formData.append(
              `offer_include_other[${i}][title]`,
              otherloyal[i].loyalty_name
            );
          }
          for (let j = 0; j < otherloyal[i].lang.length; j++) {
            if (
              otherloyal[i].lang[j].lang_code &&
              otherloyal[i].lang[j].loyalty_lang_name
            ) {
              formData.append(
                `offer_include_other[${i}][multi_lang][${j}][lang_code]`,
                otherloyal[i].lang[j].lang_code
              );
              formData.append(
                `offer_include_other[${i}][multi_lang][${j}][title]`,
                otherloyal[i].lang[j].loyalty_lang_name
              );
            }
          }
        }
      } else {
        formData.append("offer_include_list_other_status", 0);
      }
    } else {
      formData.append("offer_include_status", 0);
    }

    //step6

    if (offer6fields.hide_offer_status === true) {
      formData.append("hide_offer_status", 1);
    } else {
      formData.append("hide_offer_status", 0);
    }
    if (offer6fields.show_offer_discount === true) {
      formData.append("show_offer_discount", 1);
    } else {
      formData.append("show_offer_discount", 0);
    }
    if (offer6fields.hide_offer_in_search) {
      formData.append("hide_offer_in_search", 1);
    } else {
      formData.append("hide_offer_in_search", 0);
    }
    if (image instanceof File) {
      formData.append("image", image);
    } else {
      formData.append("choosen_image", image);
    }
    if (pdf instanceof File) {
      formData.append("pdf_attachment", pdf);
    }

    axios.post(`${Config.apiurl}hotel/offer/add`, formData, {}).then((res) => {
      if (res.status_code !== "200" || !res) {
        setToastmodal(true);
        setToastmessage(res.message);
        setToasttype("error");
        setToastlarge(true);
      } else {
        if (res.message === "Offer Already Exist!") {
          setToastmodal(true);
          setToastmessage("Offer already exist please change offer name");
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(false);
          setTimeout(() => navigate("/offers"), 3000);
        }
      }
    });
  };

  useEffect(() => {
    getall();
  }, [getall]);

  useEffect(() => {
    if (offerid === "valentine") {
      setOffer3fields({
        price_type: "discount",
        fixed_week_price_status: false,
        discount_unit_price: "percentage",
        discount_value: "5",
        increment_status: false,
        increment_type: "per_person",
        increment_frequency: "once",
        increment_room_value: "",
        increment_per_adult: "",
        increment_per_child: "",
        increment_unit: "currency",
        free_night_status: false,
        free_night_type: "last_night",
        free_booking_night1: "",
        free_booking_night2: "",

        // booking_time_discount_status:false,
        booking_at_night_status: false,
        // booking_time_discount:'',
        booking_night_discount: "",
        booking_night_unit_value: "percentage",
        // booking_hours_status:false,
        // booking_weekend_discount_status:false,
        hours_discount_from: "",
        hours_discount_to: "",
        booking_weekend_discount_status: false,
        booking_weekend_discount: "",
        booking_weekend_unit_value: "percentage",
        booking_weekend_week_from: "",
        booking_weekend_week_to: "",
        booking_weekend_time_from: "",
        booking_weekend_time_to: "",
        long_stay_discount_status: false,
        long_stay_discount0_night: "1",
        long_stay_discount0_percentage: "",
        long_stay_discount0_unit: "percentage",
        long_stay_discount1_night: "",
        long_stay_discount1_percentage: "",
        long_stay_discount1_unit: "percentage",
        long_stay_discount2_night: "",
        long_stay_discount2_percentage: "",
        long_stay_discount2_unit: "percentage",

        long_stay_discount3_night: "",
        long_stay_discount3_percentage: "",
        long_stay_discount3_unit: "percentage",

        early_booking_discount_status: false,
        early_booking_discount0_days: "",
        early_booking_discount0_percentage: "",
        early_booking_discount0_unit: "percentage",
        early_booking_discount1_days: "",
        early_booking_discount1_percentage: "",
        early_booking_discount1_unit: "percentage",

        early_booking_discount2_days: "",
        early_booking_discount2_percentage: "",
        early_booking_discount2_unit: "percentage",

        early_booking_discount3_days: "",
        early_booking_discount3_percentage: "",
        early_booking_discount3_unit: "percentage",

        country_discount_status: false,
        device_discount_status: false,
        device_discount_type: "desktop",
        device_discount_percentage: "",
        device_discount_unit_value: "currency",
        last_minute_discount_status: false,
        last_minute_discount0_from: "12",
        last_minute_discount0_discount: "",
        last_minute_discount0_unit: "percentage",
        last_minute_discount1_from: "",
        last_minute_discount1_discount: "",
        last_minute_discount1_unit: "percentage",

        last_minute_discount2_from: "",
        last_minute_discount2_discount: "",
        last_minute_discount2_unit: "percentage",

        last_minute_discount3_from: "",
        last_minute_discount3_discount: "",
        last_minute_discount3_unit: "percentage",
      });
      setOffername("valentines day offer");
    }
    if (offerid === "3-night") {
      setOffer3fields({
        price_type: "same",
        fixed_week_price_status: false,
        discount_unit_price: "percentage",
        discount_value: "",
        increment_status: false,
        increment_type: "per_person",
        increment_frequency: "once",
        increment_room_value: "",
        increment_per_adult: "",
        increment_per_child: "",
        increment_unit: "currency",
        free_night_status: true,
        free_night_type: "last_night",
        free_booking_night1: "3",
        free_booking_night2: "",

        // booking_time_discount_status:false,
        booking_at_night_status: false,
        // booking_time_discount:'',
        booking_night_discount: "",
        booking_night_unit_value: "percentage",
        // booking_hours_status:false,
        // booking_weekend_discount_status:false,
        hours_discount_from: "",
        hours_discount_to: "",
        booking_weekend_discount_status: false,
        booking_weekend_discount: "",
        booking_weekend_unit_value: "percentage",
        booking_weekend_week_from: "",
        booking_weekend_week_to: "",
        booking_weekend_time_from: "",
        booking_weekend_time_to: "",
        long_stay_discount_status: false,
        long_stay_discount0_night: "1",
        long_stay_discount0_percentage: "",
        long_stay_discount0_unit: "percentage",
        long_stay_discount1_night: "",
        long_stay_discount1_percentage: "",
        long_stay_discount1_unit: "percentage",
        long_stay_discount2_night: "",
        long_stay_discount2_percentage: "",
        long_stay_discount2_unit: "percentage",

        long_stay_discount3_night: "",
        long_stay_discount3_percentage: "",
        long_stay_discount3_unit: "percentage",

        early_booking_discount_status: false,
        early_booking_discount0_days: "",
        early_booking_discount0_percentage: "",
        early_booking_discount0_unit: "percentage",
        early_booking_discount1_days: "",
        early_booking_discount1_percentage: "",
        early_booking_discount1_unit: "percentage",

        early_booking_discount2_days: "",
        early_booking_discount2_percentage: "",
        early_booking_discount2_unit: "percentage",

        early_booking_discount3_days: "",
        early_booking_discount3_percentage: "",
        early_booking_discount3_unit: "percentage",

        country_discount_status: false,
        device_discount_status: false,
        device_discount_type: "desktop",
        device_discount_percentage: "",
        device_discount_unit_value: "currency",
        last_minute_discount_status: false,
        last_minute_discount0_from: "12",
        last_minute_discount0_discount: "",
        last_minute_discount0_unit: "percentage",
        last_minute_discount1_from: "",
        last_minute_discount1_discount: "",
        last_minute_discount1_unit: "percentage",

        last_minute_discount2_from: "",
        last_minute_discount2_discount: "",
        last_minute_discount2_unit: "percentage",

        last_minute_discount3_from: "",
        last_minute_discount3_discount: "",
        last_minute_discount3_unit: "percentage",
      });
      setOffername("3 night offer");
    }
    if (offerid === "eid") {
      setOffer3fields({
        price_type: "discount",
        fixed_week_price_status: false,
        discount_unit_price: "percentage",
        discount_value: "7",
        increment_status: false,
        increment_type: "per_person",
        increment_frequency: "once",
        increment_room_value: "",
        increment_per_adult: "",
        increment_per_child: "",
        increment_unit: "currency",
        free_night_status: false,
        free_night_type: "last_night",
        free_booking_night1: "",
        free_booking_night2: "",

        // booking_time_discount_status:false,
        booking_at_night_status: false,
        // booking_time_discount:'',
        booking_night_discount: "",
        booking_night_unit_value: "percentage",
        // booking_hours_status:false,
        // booking_weekend_discount_status:false,
        hours_discount_from: "",
        hours_discount_to: "",
        booking_weekend_discount_status: false,
        booking_weekend_discount: "",
        booking_weekend_unit_value: "percentage",
        booking_weekend_week_from: "",
        booking_weekend_week_to: "",
        booking_weekend_time_from: "",
        booking_weekend_time_to: "",
        long_stay_discount_status: false,
        long_stay_discount0_night: "1",
        long_stay_discount0_percentage: "",
        long_stay_discount0_unit: "percentage",
        long_stay_discount1_night: "",
        long_stay_discount1_percentage: "",
        long_stay_discount1_unit: "percentage",
        long_stay_discount2_night: "",
        long_stay_discount2_percentage: "",
        long_stay_discount2_unit: "percentage",

        long_stay_discount3_night: "",
        long_stay_discount3_percentage: "",
        long_stay_discount3_unit: "percentage",

        early_booking_discount_status: false,
        early_booking_discount0_days: "",
        early_booking_discount0_percentage: "",
        early_booking_discount0_unit: "percentage",
        early_booking_discount1_days: "",
        early_booking_discount1_percentage: "",
        early_booking_discount1_unit: "percentage",

        early_booking_discount2_days: "",
        early_booking_discount2_percentage: "",
        early_booking_discount2_unit: "percentage",

        early_booking_discount3_days: "",
        early_booking_discount3_percentage: "",
        early_booking_discount3_unit: "percentage",

        country_discount_status: false,
        device_discount_status: false,
        device_discount_type: "desktop",
        device_discount_percentage: "",
        device_discount_unit_value: "currency",
        last_minute_discount_status: false,
        last_minute_discount0_from: "12",
        last_minute_discount0_discount: "",
        last_minute_discount0_unit: "percentage",
        last_minute_discount1_from: "",
        last_minute_discount1_discount: "",
        last_minute_discount1_unit: "percentage",

        last_minute_discount2_from: "",
        last_minute_discount2_discount: "",
        last_minute_discount2_unit: "percentage",

        last_minute_discount3_from: "",
        last_minute_discount3_discount: "",
        last_minute_discount3_unit: "percentage",
      });
      setOffername("eid official website offer");
    }
  }, [offerid]);

  useEffect(() => {
    const updatedList = [...Loyaltyclublist];

    updatedList.forEach((loyal) => {
      loyal.selected = true;
    });
    setLoyaltyclublist(updatedList);
  }, [Loyaltyclublist]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckin,
      datetocheckin
    ) => {
      const weekdays = getWeekdays(datefromcheckin, datetocheckin);
      setSelectedWeekdayscheckin(weekdays);
      setClickedDayscheckin([]);
    };

    if (datefromcheckin && datetocheckin) {
      getWeekdaysBetweenSelectedDates(datefromcheckin, datetocheckin);
    } else {
      setSelectedWeekdayscheckin([]);
    }
  }, [datefromcheckin, datetocheckin]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckout,
      datetocheckout
    ) => {
      const weekdays = getWeekdays(datefromcheckout, datetocheckout);
      setSelectedWeekdayscheckout(weekdays);
      setClickedDayscheckout([]);
    };

    if (datefromcheckout && datetocheckout) {
      getWeekdaysBetweenSelectedDates(datefromcheckout, datetocheckout);
    } else {
      setSelectedWeekdayscheckout([]);
    }
  }, [datefromcheckout, datetocheckout]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromreserve,
      datetoreserve
    ) => {
      const weekdays = getWeekdays(datefromreserve, datetoreserve);
      setSelectedWeekdaysreserve(weekdays);
      setClickedDaysreserve([]);
    };

    if (datefromreserve && datetoreserve) {
      getWeekdaysBetweenSelectedDates(datefromreserve, datetoreserve);
    } else {
      setSelectedWeekdaysreserve([]);
    }
  }, [datefromreserve, datetoreserve]);

  useEffect(() => {
    for (let i = 0; i < selectedroomocc.length; i++) {
      let ar = {};
      ar.tab = "default";
      ar.click = i === 0;
      tabroom.push(ar);
    }
  }, [selectedroomocc.length,tabroom]);

  useEffect(() => {
    for (let i = 0; i < selectedroomocc.length; i++) {
      let ar = {};
      ar.tab = "default";
      ar.click = i === 0;
      tabroomweek.push(ar);
    }
  }, [selectedroomocc.length,tabroomweek]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div className="offer-heading heading-new-in-offer offer-heading-local">
                  <h6 className="offer-list-head">Create New Offers</h6>
                </div>
              </div>
              <div className="col-md-1 create-offer-steps steps-create-flex">
                <ul
                  className="offer-create-list offer-create-media"
                  style={{ display: "contents" }}
                >
                  {offer1valid === true ? (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new_1 step-buttons active"
                          : "offer-list_new_1 step-buttons"
                      }
                      onClick={(e) => setTab("step1")}
                    >
                      Step 1
                      <div className="offer_all" style={{ fontSize: "12px" }}>
                        Availability
                      </div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new_1 step-buttons active"
                          : "offer-list_new_1 step-buttons"
                      }
                    >
                      Step 1
                      <div className="offer_all" style={{ fontSize: "12px" }}>
                        Availability
                      </div>
                    </li>
                  )}
                  {offer1valid === true ? (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new_1 step-buttons active"
                          : "offer-list_new_1 step-buttons"
                      }
                      onClick={(e) => setTab("step2")}
                    >
                      Step 2
                      <div className="offer_all" style={{ fontSize: "12px" }}>
                        Room Types
                      </div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new_1 step-buttons active"
                          : "offer-list_new_1 step-buttons"
                      }
                    >
                      Step 2
                      <div className="offer_all" style={{ fontSize: "12px" }}>
                        Room Types
                      </div>
                    </li>
                  )}
                  {/* {offer1valid===true && offer2valid===true? */}
                  <li
                    className={
                      tab === "step3"
                        ? "offer-list_new_1 step-buttons active"
                        : "offer-list_new_1 step-buttons"
                    }
                    onClick={(e) => setTab("step3")}
                  >
                    Step 3
                    <div className="offer_all" style={{ fontSize: "12px" }}>
                      Rates
                    </div>
                  </li>
                  {/* :
                              <li className={tab==="step3"?"offer-list_new_1 active":"offer-list_new_1"}>Step 3
                                <div style={{fontSize:"12px"}}>Rates</div>
                              </li>
                            }  */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true? */}
                  <li
                    className={
                      tab === "step4"
                        ? "offer-list_new_1 step-buttons active"
                        : "offer-list_new_1 step-buttons"
                    }
                    onClick={(e) => setTab("step4")}
                  >
                    Step 4
                    <div className="offer_all" style={{ fontSize: "12px" }}>
                      Rules
                    </div>
                  </li>
                  {/* :
                                <li className={tab==="step4"?"offer-list_new_1 active":"offer-list_new_1"} >Step 4
                                  <div style={{fontSize:"12px"}}>Rules</div>
                                </li>
                              } */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true && offer4valid===true? */}
                  <li
                    className={
                      tab === "step5"
                        ? "offer-list_new_1 step-buttons active"
                        : "offer-list_new_1 step-buttons"
                    }
                    onClick={(e) => setTab("step5")}
                  >
                    Step 5
                    <div className="offer_all" style={{ fontSize: "12px" }}>
                      Inclusions
                    </div>
                  </li>
                  {/* :
                              <li className={tab==="step5"?"offer-list_new_1 active":"offer-list_new_1"} >Step 5
                                <div style={{fontSize:"12px"}}>Inclusions</div>
                              </li>

                            }  */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true && offer4valid===true && offer5valid===true? */}

                  <li
                    className={
                      tab === "step6"
                        ? "offer-list_new_1 step-buttons active"
                        : "offer-list_new_1 step-buttons"
                    }
                    onClick={(e) => setTab("step6")}
                  >
                    Step 6
                    <div className="offer_all" style={{ fontSize: "12px" }}>
                      Definition
                    </div>
                  </li>
                  {/* :
                              <li className={tab==="step6"?"offer-list_new_1 active":"offer-list_new_1"}>Step 6
                                <div style={{fontSize:"12px"}}>Definition</div>
                              </li>
                              } */}
                </ul>
              </div>
              {tab === "step1" ? (
                <Offerstep1sugg
                  setTab={setTab}
                  activestaydate={activestaydate}
                  setActivestaydate={setActivestaydate}
                  activestaydateopen={activestaydateopen}
                  setActivestaydateopen={setActivestaydateopen}
                  activecheckindate={activecheckindate}
                  setActivecheckindate={setActivecheckindate}
                  activecheckindateopen={activecheckindateopen}
                  setActivecheckindateopen={setActivecheckindateopen}
                  activecheckoutdate={activecheckoutdate}
                  setActivecheckoutdate={setActivecheckoutdate}
                  activecheckoutdateopen={activecheckoutdateopen}
                  setActivecheckoutdateopen={setActivecheckoutdateopen}
                  activereservedate={activereservedate}
                  setActivereservedate={setActivereservedate}
                  activereservedateopen={activereservedateopen}
                  setActivereservedateopen={setActivereservedateopen}
                  datefromstay={datefromstay}
                  datetostay={datetostay}
                  datefromcheckin={datefromcheckin}
                  datetocheckin={datetocheckin}
                  datefromcheckout={datefromcheckout}
                  datetocheckout={datetocheckout}
                  datefromreserve={datefromreserve}
                  datetoreserve={datetoreserve}
                  fpstay={fpstay}
                  fpcheckin={fpcheckin}
                  fpcheckout={fpcheckout}
                  fpreserve={fpreserve}
                  selectedWeekdaysstay={selectedWeekdaysstay}
                  selectedWeekdayscheckin={selectedWeekdayscheckin}
                  selectedWeekdayscheckout={selectedWeekdayscheckout}
                  selectedWeekdaysreserve={selectedWeekdaysreserve}
                  clickedDaysstay={clickedDaysstay}
                  clickedDayscheckin={clickedDayscheckin}
                  clickedDayscheckout={clickedDayscheckout}
                  clickedDaysreserve={clickedDaysreserve}
                  openclosestay={openclosestay}
                  setOpenclosestay={setOpenclosestay}
                  openclosecheckin={openclosecheckin}
                  setOpenclosecheckin={setOpenclosecheckin}
                  openclosecheckout={openclosecheckout}
                  setOpenclosecheckout={setOpenclosecheckout}
                  openclosereserve={openclosereserve}
                  setOpenclosereserve={setOpenclosereserve}
                  language={language}
                  selectedDatesstay={selectedDatesstay}
                  setSelectedDatesstay={setSelectedDatesstay}
                  selectedDatescheckin={selectedDatescheckin}
                  setSelectedDatescheckin={setSelectedDatescheckin}
                  selectedDatescheckout={selectedDatescheckout}
                  setSelectedDatescheckout={setSelectedDatescheckout}
                  selectedDatesreserve={selectedDatesreserve}
                  setSelectedDatesreserve={setSelectedDatesreserve}
                  handleApplyToCalendarstay={handleApplyToCalendarstay}
                  handleApplyToCalendarcheckin={handleApplyToCalendarcheckin}
                  handleApplyToCalendarcheckout={handleApplyToCalendarcheckout}
                  handleApplyToCalendareserve={handleApplyToCalendareserve}
                  toggleSelectedDaystay={toggleSelectedDaystay}
                  toggleSelectedDaycheckin={toggleSelectedDaycheckin}
                  toggleSelectedDaycheckout={toggleSelectedDaycheckout}
                  toggleSelectedDayreserve={toggleSelectedDayreserve}
                  handleOpenclosestay={handleOpenclosestay}
                  handleOpenclosecheckin={handleOpenclosecheckin}
                  handleOpenclosecheckout={handleOpenclosecheckout}
                  handleOpenclosereserve={handleOpenclosereserve}
                  handleCalendarChangeFrom={handleCalendarChangeFrom}
                  handleCalendarChangeTo={handleCalendarChangeTo}
                  handleCalendarChangeFromcheckin={
                    handleCalendarChangeFromcheckin
                  }
                  handleCalendarChangeTocheckin={handleCalendarChangeTocheckin}
                  handleCalendarChangeFromcheckout={
                    handleCalendarChangeFromcheckout
                  }
                  handleCalendarChangeTocheckout={
                    handleCalendarChangeTocheckout
                  }
                  handleCalendarChangeFromreserve={
                    handleCalendarChangeFromreserve
                  }
                  handleCalendarChangeToreserve={handleCalendarChangeToreserve}
                  offer1Check={offer1Check}
                  setOffer1check={setOffer1check}
                  selectedMulti={selectedMulti}
                  setselectedMulti={setselectedMulti}
                  offername={offername}
                  setOffername={setOffername}
                  nextoffterstep1={nextoffterstep1}
                  offer1error={offer1error}
                />
              ) : tab === "step2" ? (
                <Offerstep2sugg
                  setTab={setTab}
                  offer2room={offer2room}
                  boardoffer2={boardoffer2}
                  ratelistoffer2={ratelistoffer2}
                  activeroom={activeroom}
                  activeboard={activeboard}
                  activerate={activerate}
                  handleChangeroomoffer2={handleChangeroomoffer2}
                  handlesingleroomselect={handlesingleroomselect}
                  handlesingleoccupancyselect={handlesingleoccupancyselect}
                  handleChangeboardoffer2={handleChangeboardoffer2}
                  handlesingleboardselect={handlesingleboardselect}
                  handleChangerateoffer2={handleChangerateoffer2}
                  handlesinglerateselect={handlesinglerateselect}
                  nextoffterstep2={nextoffterstep2}
                  offer2error={offer2error}
                  availableboard={availableboard}
                  handlebasedon={handlebasedon}
                  handleChangeroomoffer2all_label={
                    handleChangeroomoffer2all_label
                  }
                  errorroom={errorroom}
                />
              ) : tab === "step3" ? (
                <Offerstep3sugg
                  setTab={setTab}
                  selectedroomocc={selectedroomocc}
                  handleweekinput={handleweekinput}
                  currency={currency}
                  offer3fields={offer3fields}
                  handleOptionChangeoffer3price={handleOptionChangeoffer3price}
                  handleoffer3checkbox={handleoffer3checkbox}
                  handleoffer3change={handleoffer3change}
                  exceptionstaydate={exceptionstaydate}
                  setExceptionstaydate={setExceptionstaydate}
                  inputFieldsexception={inputFieldsexception}
                  setInputFieldsexception={setInputFieldsexception}
                  addInputFieldoffer3={addInputFieldoffer3}
                  removeInputFieldsoffer3={removeInputFieldsoffer3}
                  handleChangedatefrom={handleChangedatefrom}
                  handleChangetodate={handleChangetodate}
                  handleChangediscount={handleChangediscount}
                  exceptionnight={exceptionnight}
                  setExceptionnight={setExceptionnight}
                  inputFieldsextra={inputFieldsextra}
                  addInputFieldextra={addInputFieldextra}
                  removeInputFieldsextra={removeInputFieldsextra}
                  handleChangedatefromextra={handleChangedatefromextra}
                  handleChangetodateextra={handleChangetodateextra}
                  handleChangediscountsextra={handleChangediscountsextra}
                  countryOffer3={countryOffer3}
                  visibleItemsoffer3={visibleItemsoffer3}
                  handleloyalchangeoffer3country={
                    handleloyalchangeoffer3country
                  }
                  showAlloffer3country={showAlloffer3country}
                  setShowAlloffer3country={setShowAlloffer3country}
                  handleInputChangeconutryvalue={handleInputChangeconutryvalue}
                  // nextoffterstep3={nextoffterstep3}
                  nextoffterstep3rate={nextoffterstep3rate}
                  nextoffterstep3increment={nextoffterstep3increment}
                  nextoffterstep3freenight={nextoffterstep3freenight}
                  nextoffterstep3extra={nextoffterstep3extra}
                  nextoffterstep3country={nextoffterstep3country}
                  nextoffterstep3device={nextoffterstep3device}
                  offer3error={offer3error}
                  handleoffer3weekcheck={handleoffer3weekcheck}
                  tabroom={tabroom}
                  tabroomweek={tabroomweek}
                  handleTabweek={handleTabweek}
                  handleTab={handleTab}
                  handleClickroomweek={handleClickroomweek}
                  handleClickroom={handleClickroom}
                  handleweekinputextend={handleweekinputextend}
                  ratetab={ratetab}
                  setRatetab={setRatetab}
                  offer3validrate={offer3validrate}
                  offer3validncrement={offer3validncrement}
                  offer3validfreenight={offer3validfreenight}
                  offer3validextra={offer3validextra}
                  offer3validcountry={offer3validcountry}
                  offer3validdivice={offer3validdivice}
                  multicountri3={multicountri3}
                  setMulticountry3={setMulticountry3}
                  otherboard={otherboard}
                  selectedotherboard={selectedotherboard}
                  setSelectedotherboard={setSelectedotherboard}
                  handleotherboard={handleotherboard}
                  board_apply_status={board_apply_status}
                  setBoard_apply_status={setBoard_apply_status}
                />
              ) : tab === "step4" ? (
                <Offerstep4sugg
                  setTab={setTab}
                  offer4fields={offer4fields}
                  handleoffer4checkbox={handleoffer4checkbox}
                  Loyaltyclublist={Loyaltyclublist}
                  handleloyalchangeoffer4={handleloyalchangeoffer4}
                  handlechangeoffer4={handlechangeoffer4}
                  legend={legend}
                  handleOptionChangeoffer4Cancelpolicy={
                    handleOptionChangeoffer4Cancelpolicy
                  }
                  showAll={showAll}
                  setShowAll={setShowAll}
                  visibleItems={visibleItems}
                  handleloyalchangeoffer4country={
                    handleloyalchangeoffer4country
                  }
                  countrylist={countrylist}
                  nextoffterstep4={nextoffterstep4}
                  offer4error={offer4error}
                  multicountri4={multicountri4}
                  setMulticountry4={setMulticountry4}
                />
              ) : tab === "step5" ? (
                <Offerstep5sugg
                  setTab={setTab}
                  offer_include_status={offer_include_status}
                  setOffer_include_status={setOffer_include_status}
                  loyalty={loyalty}
                  otherloyal_status={otherloyal_status}
                  setOtherloyal_status={setOtherloyal_status}
                  otherloyal={otherloyal}
                  addInputField={addInputField}
                  removeInputFields={removeInputFields}
                  alllanguage={alllanguage}
                  loyallang={loyallang}
                  setLoyallang={setLoyallang}
                  hangleotherloyal={hangleotherloyal}
                  nextoffterstep5={nextoffterstep5}
                  handleloyalchange={handleloyalchange}
                  offer5error={offer5error}
                  hangleotherloyallang={hangleotherloyallang}
                  handlelanglo={handlelanglo}
                />
              ) : tab === "step6" ? (
                <Offerstep6sugg
                  setTab={setTab}
                  offer6fields={offer6fields}
                  language={language}
                  selectedMulti6={selectedMulti6}
                  setselectedMulti6={setselectedMulti6}
                  handlechangeoffer6={handlechangeoffer6}
                  // onFileChange={onFileChange}
                  handleoffer6checkbox={handleoffer6checkbox}
                  onFileChangepdf={onFileChangepdf}
                  Save={Save}
                  setImage={setImage}
                  image={image}
                  imagechoose={imagechoose}
                  setImagechoose={setImagechoose}
                  optionimage={optionimage}
                  currency={currency}
                  pdf={pdf}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Offersuggestion;
