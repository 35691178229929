import React, { useCallback, useContext, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Reservationheader from "../../Common/Reservationheader";
import { Link, useParams } from "react-router-dom";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import Toastmodal from "../../Common/Toastmodal";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Loading from "../../Loading";

function Reservationedit() {
  
  let {reservation_id} = useParams();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [roomtypes, setRoomtypes] = useState([]);
  const [boards, setBoards] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  const [sendEmail,setSendEmail] = useState(true);
  const [sendEmail1,setSendEmail1] = useState(true);
  const [list,setList] = useState([]);
  const [roomdata,setRoomdata] = useState([]);
  const [rooms,setRooms] = useState([]);
  const [loader, setLoader] = useState(false);
  const [extras,setExtras] = useState([]);
  const [checkin, setCheckin] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [totalRate, setTotalRate] = useState(0);
  const [roomdatanew,setRoomdatanew] = useState([]);
  const [changedData,setChangedData] = useState([]);
  const [appliedRates, setAppliedRates] = useState([]);
  const [appliedPromocodeRates, setAppliedPromocodeRates] = useState([]);
  const [appliedLoyaltyRates, setAppliedLoyaltyRates] = useState([]);
  const [roomtotalrate, setRoomtotalrate] = useState([]);

  const CheckinChange = (selectedDates) => {
    setCheckin(selectedDates[0]);
  };

  const CheckoutChange = (selectedDates) => {
    setCheckout(selectedDates[0]);
  };

  const handleRoomChange = (index, selectedOption) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].room = selectedOption;
    setRooms(updatedRooms);
  };

  const handleBoardChange = (index, selectedOption) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].board = selectedOption;
    setRooms(updatedRooms);
  };

  const handleOccupancyChange = (index, selectedOption) => {
    const updatedRooms = [...rooms];
    const selectedOccupancy = roomdata
      .flatMap(room => room.occupancies)
      .find(occupancy => occupancy.ocuupancy_id === selectedOption.value);

    updatedRooms[index].occupancy = selectedOccupancy;
    setRooms(updatedRooms);
  };

  const handleRateChange = (index, event) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].room_total_without_tax = event.target.value;
    setRooms(updatedRooms);
  };

  const handleRoomCountChange = (index, event) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].room_count = event.target.value;
    setRooms(updatedRooms);
  };

  const handleExtraRateChange = (index, event) => {
    const updatedRooms = [...extras];
    updatedRooms[index].addon_rate = event.target.value;
    setExtras(updatedRooms);
  };


  const handleDeleteChange = (index) => {
    const updatedExtras = extras.map((extra, i) => {
      if (i === index) {
        return { ...extra, delete_status: !extra.delete_status };
      }
      return extra;
    });
    const filteredExtras = updatedExtras.filter(extra => !extra.delete_status);
    setExtras(filteredExtras);
  };

  const handleExtraRateChangeSub = (i,index, event) => {
    const updatedRooms = [...extras];
    updatedRooms[i].sub_addon_array[index].sub_addon_rate = event.target.value;
    setExtras(updatedRooms);
  };

  const handleDeleteChangeSub = (extraIndex, subIndex) => {
    const updatedExtras = extras.map((extra, i) => {
      if (i === extraIndex) {
        const updatedSubAddons = extra.sub_addon_array.map((subAddon, j) => {
          if (j === subIndex) {
            return { ...subAddon, delete_status: !subAddon.delete_status };
          }
          return subAddon;
        });
  
        const filteredSubAddons = updatedSubAddons.filter(subAddon => !subAddon.delete_status);
        return { ...extra, sub_addon_array: filteredSubAddons };
      }
      return extra;
    });
  
    setExtras(updatedExtras);
  };

  const handleChange = (name, value) => {
    const updatedList = {...list};
    updatedList.reservation[name] = value;
    setList(updatedList);
    setChangedData(updatedList.reservation);
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const getView =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}reservation/modify/view`, {
      method: "POST",
      body: JSON.stringify({
        reservation_id:reservation_id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data);
        setRoomdata(data.data.room_data);
        setRooms(data.data.reservation.room_types);
        setExtras(data.data.reservation.extras);
        setCheckin(data.data.reservation.check_in);
        setCheckout(data.data.reservation.check_out);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getView();
      } else {
        setLoader(true);
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getView();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,reservation_id]);

  const Search =useCallback(async () => {

      const res = await fetch(`${Config.apiurl}reservation/modify/search`,{
          method: "POST",
          body: JSON.stringify({
            reservation_id:reservation_id,
            hotel_id:list.hotel_id,
            check_in:moment(checkin).format('DD-MM-YYYY'),
            check_out:moment(checkout).format('DD-MM-YYYY')
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
         console.log("error");
        } else if (data.reauth === true) {
          handleUnauthorized();
          Search();
        } else {
          setRoomdatanew(data.data);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Search();
      } else {
        console.log("error");
      }
  },[handleUnauthorized,reservation_id,list.hotel_id,checkin,checkout]);

  const Save = async () => {
   
    let room=[];
    for (let i = 0; i < rooms.length; i++) {
      let multiarray = {};
      multiarray.room_id = rooms[i].room.value;
      multiarray.room_count = rooms[i].room_count;
      multiarray.occupancy =  rooms[i].occupancy.ocuupancy_id;
      multiarray.rate_id = rooms[i].ratelist.value;
      multiarray.board = rooms[i].board.value;
      multiarray.offer_apply_status = rooms[i].offer_apply_status;
      multiarray.offer_id = rooms[i].offer_id;
      multiarray.offer_discount = rooms[i].offer_discount;
      multiarray.offer_discount_type = rooms[i].offer_discount_type;
      multiarray.promocode_applied_status = rooms[i].promocode_status;
      multiarray.loyalty_program_applied_status = rooms[i].loyalty_program_status;
      multiarray.rate = rooms[i].room_total_without_tax;
      multiarray.type = rooms[i].room_type;
      room.push(multiarray);
    }

    let extra=[];
  if(extras.length>0){
  for (let i = 0; i < extras.length; i++) {
    let subaddon = [];
    for (let j = 0; j < extras[i]?.sub_addon_array?.length; j++) {
    let multiarray = {};

    multiarray.id = extras[i].sub_addon_array[j].id;
    if(extras[i].sub_addon_array[j].no_of_rooms){
      multiarray.room_count = extras[i].sub_addon_array[j].no_of_rooms;
    }
    if(extras[i].sub_addon_array[j].no_of_nights){
      multiarray.no_of_nights = extras[i].sub_addon_array[j].no_of_nights;
    }
    if(extras[i].sub_addon_array[j].person_count){
      multiarray.no_of_person = extras[i].sub_addon_array[j].person_count;
    }
    multiarray.sub_addon_price = extras[i].sub_addon_array[j].sub_addon_rate;

    subaddon.push(multiarray);
  }

    let multiarray1 = {};

    multiarray1.id = extras[i].value;
    if(extras[i].no_of_rooms){
      multiarray1.room_count = extras[i].no_of_rooms;
    }
    if(extras[i].no_of_nights){
       multiarray1.no_of_nights =  extras[i].no_of_nights;
    }
    if(extras[i].person_count){
      multiarray1.no_of_person =  extras[i].person_count;
    }
    multiarray1.addon_rate = extras[i].addon_rate;
    if(subaddon.length > 0){
      multiarray1.sub_addon = subaddon;
    }

    extra.push(multiarray1);
   }
  }

      const res = await fetch(`${Config.apiurl}reservation/modify/update`,{
          method: "POST",
          body: JSON.stringify({
            reservation_id: reservation_id,
            promocode_status:list.reservation.promocode_status===true?1:0,
            loyalty_program_status:list.reservation.loyalty_program_status===true?1:0,
            loyalty_program_id:list.reservation.loyalty_program_id,
            check_in:moment(checkin).format('DD-MM-YYYY'),
            check_out:moment(checkout).format('DD-MM-YYYY'),
            promocode:list.reservation.promocode,
            roomtype:room,
            first_name:changedData.customer_firstname,
            last_name:changedData.customer_lastname,
            email:changedData.customer_mail,
            calling_code:changedData.customer_calling_code,
            phone_number:changedData.customer_phone_number,
            // promocode_status:changedData.promocode_status===true?1:0,
            // promocode:changedData.promocode,
            // loyalty_program_status:changedData.loyalty_program_status===true?1:0,
            // loyalty_program_id:changedData.loyalty_program_id,
            send_customer_confirmation:sendEmail,
            send_copy_to_admin:sendEmail1,
            extras:extra
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          Save();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Save();
      } else {
        console.log("error");
      }
  };

  // function processDBValue(dbValue) {
  //   if (dbValue === null || dbValue === undefined) {
  //     return "";
  //   }
  //   dbValue = dbValue.toString();
  //   const numericValue = parseFloat(dbValue.replace(/,/g, ""));
  //   const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;
  //   return result.endsWith(".00") ? result.slice(0, -3) : result;
  // }
  
  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
  
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));
    
    if (!isNaN(numericValue)) {
      if (!dbValue.includes('.')) {
        dbValue += ".00";
      }
    }
  
    return dbValue;
  }
  
  
  useEffect(() => {
    const roomNewValues = new Set(roomdatanew.map(room => room.value));

    const filteredRooms = roomdata.filter(room => roomNewValues.has(room.value));

    const rooms = filteredRooms.map(room => ({
      value: room.value,
      label: room.label,
      key: room.key,
      occupancies: room.occupancies.map(occupancy => ({
        ocuupancy_id: occupancy.ocuupancy_id,
        adult: occupancy.adult,
        child: occupancy.child,
        rate_list: occupancy?.rate_list?.map(rate => ({
          value: rate.value,
          label: rate.label,
          board: rate.board.map(board => ({
            value: board.value,
            label: board.label,
            adult_price:board.adult_price,
            child_price:board.child_price,
            // board_include:board.board_include
          }))
        }))
      }))
    }));

    const uniqueBoards = new Map();
    filteredRooms.forEach(room => {
      room.occupancies.forEach(occupancy => {
        occupancy.rate_list?.forEach(rate => {
          rate.board.forEach(board => {
            if (!uniqueBoards.has(board.value)) {
              uniqueBoards.set(board.value, { value: board.value, label: board.label ,
                adult_price:board.adult_price,child_price:board.child_price});
            }
          });
        });
      });
    });

    const boards = Array.from(uniqueBoards.values());

    const uniqueOccupancies = new Map();
    filteredRooms.forEach(room => {
      room.occupancies.forEach(occupancy => {
        if (!uniqueOccupancies.has(occupancy.ocuupancy_id)) {
          uniqueOccupancies.set(occupancy.ocuupancy_id, {
            value: occupancy.ocuupancy_id,
            label: `${occupancy.adult} Adult, ${occupancy.child} Child`,
          });
        }
      });
    });

    const occupancies = Array.from(uniqueOccupancies.values());

    setRoomtypes(rooms);
    setBoards(boards);
    setOccupancy(occupancies);
  }, [roomdata, roomdatanew]);

  useEffect(() => {
    const rates = [];
    const promocodeRates = [];
    const loyaltyRates = [];
    const totalRoomRates = [];
    let totalRate = 0;

    rooms.forEach(room => {
        let baseRate = parseFloat(room.room_total_without_tax) || 0;
        let offerAppliedRate = baseRate;
        let promoAppliedRate = baseRate;
        let loyaltyAppliedRate = baseRate;

        if (room.offer_apply_status === 1) {
            let offerDiscountValue = 0;
            if (room.offer_discount_type === 'percentage') {
                offerDiscountValue = (baseRate * room.offer_discount) / 100;
            } else if (room.offer_discount_type === 'currency') {
                offerDiscountValue = room.offer_discount;
            }
            offerAppliedRate -= offerDiscountValue;
        }

        rates.push(offerAppliedRate);

        if (list?.reservation?.promocode_status === true) {
            let promoDiscountValue = 0;
            if (list?.reservation?.promocode_discount_type === 'percentage') {
                promoDiscountValue = (offerAppliedRate * list?.reservation?.promocode_discount_value) / 100;
            } else if (list?.reservation?.promocode_discount_type === 'currency') {
                promoDiscountValue = list?.reservation?.promocode_discount_value;
            }
            promoAppliedRate = offerAppliedRate - promoDiscountValue;
        } else {
            promoAppliedRate = offerAppliedRate;
        }

        promocodeRates.push(promoAppliedRate);

        if (list?.reservation?.loyalty_program_status === true) {
            let loyaltyDiscountValue = 0;
            if (list?.reservation?.loyalty_discount_type === 'percentage') {
                loyaltyDiscountValue = (promoAppliedRate * list?.reservation?.loyalty_discount_value) / 100;
            } else if (list?.reservation?.loyalty_discount_type === 'currency') {
                loyaltyDiscountValue = list?.reservation?.loyalty_discount_value;
            }
            loyaltyAppliedRate = promoAppliedRate - loyaltyDiscountValue;
        } else {
            loyaltyAppliedRate = promoAppliedRate;
        }

        loyaltyRates.push(loyaltyAppliedRate);

        let boardAdditionalCost = 0;
        if (room.board_include) {
            const adultPrice = room.board.adult_price || 0;
            const childPrice = room.board.child_price || 0;
            const adultCount = room.occupancy?.adult || 0;
            const childCount = room.occupancy?.child || 0;

            boardAdditionalCost = (adultPrice * adultCount) + (childPrice * childCount);
        }

        const roomCount = room.room_count || 0;
        const totalRate = (loyaltyAppliedRate + boardAdditionalCost) * roomCount;
        totalRoomRates.push(totalRate);
    });

    const extrasTotal = extras.reduce((acc, extra) => {
        let extraRate = parseFloat(extra.addon_rate) || 0;
        const subAddonsTotal = extra.sub_addon_array?.reduce((subAcc, sub) => {
            return sub.sub_addon_rate > 0 ? subAcc + parseFloat(sub.sub_addon_rate) : subAcc;
        }, 0) || 0;
        return acc + extraRate + subAddonsTotal;
    }, 0);

    totalRate = totalRoomRates.reduce((acc, rate) => acc + rate, 0) + extrasTotal;

    setAppliedRates(rates);
    setAppliedPromocodeRates(promocodeRates);
    setAppliedLoyaltyRates(loyaltyRates);
    setRoomtotalrate(totalRoomRates);
    setTotalRate(totalRate); 

}, [rooms, list?.reservation?.promocode_status, list?.reservation?.promocode_discount_value, list?.reservation?.promocode_discount_type, list?.reservation?.loyalty_program_status, list?.reservation?.loyalty_discount_value, list?.reservation?.loyalty_discount_type, list?.hotel_currency?.code, extras]);
 
// useEffect(() => {
  //   const rates = rooms.map(room => {
  //     let baseRate = parseFloat(room.room_total_without_tax) || 0;
  
  //     if (room.offer_apply_status === 1) {
  //       let discountValue = 0;
  //       if (room.offer_discount_type === 'percentage') {
  //         discountValue = (baseRate * room.offer_discount) / 100;
  //       } else if (room.offer_discount_type === 'currency') {
  //         discountValue = room.offer_discount;
  //       }
  //       baseRate -= discountValue;
  //     }
  //   });
  
  //   setAppliedRates(rates); 
  // }, [rooms]);


  // const calculateTotalRate = () => {
  //   let total = 0;

  //   rooms.forEach(room => {
  //     let roomRate = parseFloat(room.room_total_without_tax) || 0;

  //     if (room.board && room.board_include===true) {
  //       roomRate += parseFloat(room.board.adult_price) || 0;
  //       roomRate += parseFloat(room.board.child_price) || 0;
  //     }

  //     let discountValue = 0;

  //     if (room.offer_apply_status === 1) {
  //       discountValue = room.offer_discount_type === 'percentage'
  //         ? (roomRate * room.offer_discount) / 100
  //         : room.offer_discount;
  //     }

  //     if (list?.reservation?.promocode_status && list?.reservation?.promocode_discount_value) {
  //       const promoDiscount = list.reservation.promocode_discount_type === 'percentage'
  //         ? (roomRate * list.reservation.promocode_discount_value) / 100
  //         : list.reservation.promocode_discount_value;
  //       discountValue += promoDiscount;
  //     }

  //     if (list?.reservation?.loyalty_program_status && list?.reservation?.loyalty_discount_value) {
  //       const loyaltyDiscount = list.reservation.loyalty_discount_type === 'percentage'
  //         ? (roomRate * list.reservation.loyalty_discount_value) / 100
  //         : list.reservation.loyalty_discount_value;
  //       discountValue += loyaltyDiscount;
  //     }

  //     total += roomRate - discountValue;
  //   });

  //   extras.forEach(extra => {
  //     if (!extra.delete_status) {
  //       total += parseFloat(extra.addon_rate) || 0;
  //     }
  //   });

  //   setTotalRate(total);
  // };

  // useEffect(() => {
  //   calculateTotalRate();
  // }, [rooms, list.reservation, extras]);


  useEffect(() => {
    getView();
  }, [getView]);

  useEffect(() => {
    if (checkin && checkout) {
      Search();
    }
  }, [checkin, checkout,Search]);

  const offersDisplayed = new Set();

  return (
    <div>
      <Reservationheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              {loader===false?
               <Loading/>
               :
              <div className="card">
                <div className="card-body">
                  <div className="live-preview">
                    <div className="page-title-box  align-items-center justify-content-between">
                      <h4 className="db-page-title">Reservation Edit</h4>
                    </div>
                     <div className="flex-shrink-0 d-flex justify-content-between"
                        style={{ float: "right" }}>
                        <Link type="button" to="#" className="btn db-save-button" onClick={Save}
                          style={{ height: "30px" }}>Save
                        </Link>
                     </div>
                      <div className="row" style={{ display: "contents" }}>
                        <div className="be_loyalty_level">
                          <span className="padding-5"></span>
                           <div className="row">
                            <div className="col-md-6">
                              <label for="firstNameinput" className="form-label be_form_label">
                               Reservation ID <span className="form-validation-required">*</span>
                              </label>
                              <input  type="text" className="form-control" placeholder=""
                                value={list?.reservation?.reservation_key} disabled/>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                               Hotel<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={list?.hotel_name} disabled/>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                             First Name<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder="" name="customer_firstname"
                               value={list?.reservation?.customer_firstname}
                               onChange={(e) => handleChange('customer_firstname' ,e.target.value)}/>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                             Last Name<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder="" name="customer_lastname"
                               value={list?.reservation?.customer_lastname}
                               onChange={(e) => handleChange('customer_lastname' ,e.target.value)}/>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Email<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder="" name="customer_mail"
                               value={list?.reservation?.customer_mail}
                               onChange={(e) => handleChange('customer_mail' ,e.target.value)}/>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Phone Number<span className="form-validation-required">*</span>
                             </label>
                             <div className="input-group" data-input-flag>
                                  {list.reservation?.customer_calling_code === null ? (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <select
                                        value={list.reservation?.customer_calling_code}
                                        name="customer_calling_code"
                                        className="calling-select"
                                      >
                                      <option value={list.reservation?.customer_calling_code}>
                                            {list.reservation?.customer_calling_code}
                                        </option>
                                      </select>
                                    </button>
                                  ) : (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {list.reservation?.customer_calling_code}
                                    </button>
                                  )}
                                  <input
                                    type="number"
                                    class="form-control rounded-end flag-input"
                                    placeholder="Enter number" name="customer_phone_number"
                                    value={list?.reservation?.customer_phone_number}
                                    onChange={(e) => handleChange('customer_phone_number' ,e.target.value)}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }

                                  />
                                </div>
                            </div>
                            
                            {/* {list?.reservation?.promocode_status===true?
                            <>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Promocode<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.promocode} disabled/>
                            </div>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Promocode Discount<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.promocode_discount_value}
                              style={{width:"40%"}} disabled/>
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list?.reservation?.promocode_discount_type==="percentage"?
                                  <>
                                 %
                                </>
                                :
                                <>
                                {list?.hotel_currency?.code}
                                </>
                                }
                              </span>
                             </span>
                            </div>
                            </>
                            :""}

                          {list?.reservation?.loyalty_program_status===true?
                            <>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Loyalty Program<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.loyalty_program_title} disabled/>
                            </div>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Loyalty Discount<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.loyalty_discount_value}
                              style={{width:"40%"}} disabled/>
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list?.reservation?.loyalty_discount_type==="percentage"?
                                  <>
                                 %
                                </>
                                :
                                <>
                                {list?.hotel_currency?.code}
                                </>
                                }
                              </span>
                             </span>
                            </div>
                            </>
                            :""} */}

                           <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Checkin Date<span className="form-validation-required">*</span>
                             </label>
                             <Flatpickr
                              id="flatpickr-checkin"
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="Checkin date"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={checkin ? new Date(checkin) : ''}
                              onChange={CheckinChange}
                            />
                             <span className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                onClick={() =>handleCalendarIconClick("checkin")}
                                style={{top:"33px"}} ></span>
                            </div>

                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Checkout Date<span className="form-validation-required">*</span>
                             </label>
                             <Flatpickr
                              id="flatpickr-checkout"
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="Checkout date"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={checkout ? new Date(checkout) : ''}
                              onChange={CheckoutChange}
                            />
                             <span className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                onClick={() =>handleCalendarIconClick("checkout")}
                               style={{top:"33px"}}></span>
                            </div>
                          </div>

                        {rooms?.map((room, i) => {
                            return (
                          <div className="row" key={i} style={{marginTop:"5%"}}>
                            <div className="col-md-6">
                            <label for="firstNameinput" className="form-label be_form_label">
                              Room<span className="form-validation-required">*</span>
                             </label>
                             <Select
                                className="form-control"
                                options={roomtypes}
                                value={room.room}
                                // value={roomtypes.find(option => option.value === room.room) || null}
                                onChange={selectedOption => handleRoomChange(i, selectedOption)}
                              />
                            </div>
                            <div className="col-md-6">
                            <label for="firstNameinput" className="form-label be_form_label">
                              Boards<span className="form-validation-required">*</span>
                             </label>
                             <Select
                                className="form-control"
                                options={boards}
                                value={room.board}
                                onChange={selectedOption => handleBoardChange(i, selectedOption)}
                              />
                            </div>
                            <div className="col-md-6">
                            <label for="firstNameinput" className="form-label be_form_label">
                              Occupancy<span className="form-validation-required">*</span>
                             </label>
                             <Select
                                className="form-control"
                                options={occupancy}
                                value={{ value: room.occupancy?.ocuupancy_id, label: `${room?.occupancy?.adult} Adult, ${room?.occupancy?.child} Child` }}
                                onChange={selectedOption => handleOccupancyChange(i, selectedOption)}
                              />
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Rate<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={processDBValue(room.room_total_without_tax)}
                               onChange={event => handleRateChange(i, event)}/>
                             <span
                                className="input-group-text input-joint-color-loyal"
                                id="inputGroup-sizing-default" >
                                {list?.hotel_currency?.code}
                              </span>
                             </span>
                            </div>
                           
                            {room.offer_apply_status===1?
                            <>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Offer Name<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={room.title} disabled/>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Offer Discount<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={room.offer_discount}
                               disabled/>
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {room.offer_discount_type==="percentage"?
                                  <>
                                 %
                                </>
                                :
                                <>
                                {list?.hotel_currency?.code}
                                </>
                                }
                              </span>
                             </span>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Offer Applied Rate<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={(processDBValue(appliedRates[i]))} disabled
                               />
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list.hotel_currency.code}
                              </span>
                             </span>
                            </div>
                           
                            </>
                            :""}
                             {list?.reservation?.promocode_status===true?
                            <>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Promocode<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.promocode} disabled/>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Promocode Discount<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.promocode_discount_value}
                              disabled/>
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list?.reservation?.promocode_discount_type==="percentage"?
                                  <>
                                 %
                                </>
                                :
                                <>
                                {list?.hotel_currency?.code}
                                </>
                                }
                              </span>
                             </span>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                             Promocode Applied Rate<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={(processDBValue(appliedPromocodeRates[i]))} disabled
                               />
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list.hotel_currency.code}
                              </span>
                             </span>
                            </div>
                            </>
                            :""}

                          {list?.reservation?.loyalty_program_status===true?
                            <>
                            <div className="col-md-6">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Loyalty Program<span className="form-validation-required">*</span>
                             </label>
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.loyalty_program_title} disabled/>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Loyalty Discount<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={list?.reservation?.loyalty_discount_value}
                              disabled/>
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list?.reservation?.loyalty_discount_type==="percentage"?
                                  <>
                                 %
                                </>
                                :
                                <>
                                {list?.hotel_currency?.code}
                                </>
                                }
                              </span>
                             </span>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Loyalty Applied Rate<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={(processDBValue(appliedLoyaltyRates[i]))} disabled
                               />
                             <span
                                className="input-group-text"
                                id="inputGroup-sizing-default" >
                                {list.hotel_currency.code}
                              </span>
                             </span>
                            </div>
                            </>
                            :""}
                             <div className="col-md-2">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Room Count<span className="form-validation-required">*</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={room.room_count}
                               onChange={event => handleRoomCountChange(i, event)}
                               />
                             </span>
                            </div>
                            <div className="col-md-3">
                             <label for="firstNameinput" className="form-label be_form_label">
                              Total Rate 
                              <span className="form-validation-required">*</span>
                              <span class="info-tag-new">i</span>
                              <span class="tooltiptext-new">Board rate also applied</span>
                             </label>
                             <span className="d-flex">
                             <input type="text" className="form-control" placeholder=""
                               value={processDBValue(roomtotalrate[i])}
                              />
                             <span
                                className="input-group-text input-joint-color-loyal"
                                id="inputGroup-sizing-default" >
                                {list?.hotel_currency?.code}
                              </span>
                             </span>
                            </div>
                          </div>
                            );
                          })}

                          {extras.length>0?
                          <div className="row" style={{marginTop:"5%"}}>
                          <label for="firstNameinput" className="form-label be_form_label">
                              Add-on
                             </label>
                            {extras?.map((data, ind) => {
                              return (
                                <>
                              <div className="col-md-12 d-flex" style={{marginTop:"1%"}}>
                                <span style={{minWidth:"130px",fontWeight:"500"}}>{data.label}</span>
                                <span style={{marginLeft:"10px"}}>
                                <input type="text" className="form-control" placeholder=""
                                  value={data.addon_rate} onChange={event => handleExtraRateChange(ind, event)}/></span>
                                <span
                                  className="input-group-text input-joint-color-loyal"
                                  id="inputGroup-sizing-default">
                                  {list?.hotel_currency?.code}
                                  </span>
                                  <span style={{ marginLeft: "10%",position:"relative",bottom:"5px" }}>
                                  <span className="remove_loyalty" onClick={() => handleDeleteChange(ind)}>Delete</span>
                                  {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`deleteCheckbox-${ind}`}
                                    value={data.delete_status}
                                    checked={data.delete_status}
                                    onChange={() => handleDeleteChange(ind)}
                                    
                                  />
                                <span style={{marginLeft:"10px"}}>Delete</span> */}
                                </span>
                              </div>
                          {data.sub_addon_array?.length>0?
                             <div className="reserve_subaddon_div">
                        <div style={{color:"#43aeb3",fontSize:"13px"}}>Sub Add-on</div>
                            {data.sub_addon_array?.map((sub, index) => {
                            return (
                              <div className="d-flex" key={index} style={{marginTop:"2%"}}>
                              <div style={{fontSize:"12px",minWidth:"100px"}}>{sub.title}</div>
                              {sub?.sub_addon_rate > 1?
                              <>
                              <span style={{marginLeft:"10px"}}>
                                <input type="text" className="form-control" placeholder=""
                                  value={sub.sub_addon_rate} onChange={event => handleExtraRateChangeSub(ind,index, event)}
                                   style={{height:"25px",fontSize:"12px",width:"51%"}} />
                                  </span>
                                  <span
                                  className="input-group-text input-joint-color-loyal"
                                  id="inputGroup-sizing-default" style={{height:"25px",fontSize:"12px",position:"relative",right:"21%"}}>
                                  {list?.hotel_currency?.code}
                                  </span>
                                 </>
                                 :
                                 <>
                                 <span style={{marginLeft:"10px"}}>
                               <input type="text" className="form-control" placeholder=""
                                  value="Free" style={{height:"25px",fontSize:"12px",width:"51%"}} />
                                 </span>
                                
                                 </>
                                  }
                                  <span className="reserve_subaddon_delete_button" onClick={() => handleDeleteChangeSub(ind,index)}>X</span>
                              </div>
                              );
                            })}
                              </div>
                              :""}
                              </>
                              );
                            })}
                          </div>
                          :""}

                        {/* <div className="applied_offers_reserve_div">
                          {list.reservation.loyalty_program_status===true?
                          <div className="applied_offer_sub">With the loyalty program you saved -
                          <span>
                            {list.reservation.loyalty_discount_type === "percentage" ? 
                              ` ${list.reservation.loyalty_discount_value}%` : 
                              ` ${list.reservation.loyalty_discount_value} ${list.hotel_currency.code}`}
                          </span>                          
                          </div>
                          :""}

                           {list.reservation.promocode_status===true?
                          <div className="applied_offer_sub">For the promocode you saved -
                          <span>
                            {list.reservation.promocode_discount_type === "percentage" ? 
                              ` ${list.reservation.promocode_discount_value}%` : 
                              ` ${list.reservation.promocode_discount_value} ${list.hotel_currency.code}`}
                          </span>                          
                          </div>
                          :""}

                           {rooms?.map((data, ind) => {
                              const offerKey = `${data.offer_discount_type}-${data.offer_discount}-${list.hotel_currency.code}`;

                              if (!offersDisplayed.has(offerKey)) {
                                offersDisplayed.add(offerKey);

                                return (
                                  <>
                          {data.offer_apply_status===1?
                            <div className="applied_offer_sub" key={ind}>
                              With the offer you saved -
                           
                                  <span>
                                    {data.offer_discount_type === "percentage" 
                                      ? ` ${data.offer_discount}%` 
                                      : ` ${data.offer_discount} ${list.hotel_currency.code}`}
                                  </span>
                               
                          </div>
                          :""}
                          </>
                           );
                          }

                          return null;
                        })}
                          {extras.length>0?
                          <div className="applied_offer_sub">The add-on rate and board rate have also been added</div>
                          :
                          <div className="applied_offer_sub">The board rate has also been added</div>
                          }
                        </div> */}

                        <div className="d-flex justify-content-center">
                        <div className="reserve_edit_total_div">
                            <span className="grand_total_div">TOTAL</span>
                            <span className="grand_total_div_sub">
                              {processDBValue(totalRate)}  {list?.hotel_currency?.code}
                              </span>
                          </div>
                          </div>

                          <div className="col-md-12" style={{marginTop:"4%"}}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck1"
                                value={sendEmail}
                                checked={sendEmail===true?true:false}
                                onChange={()=>setSendEmail(!sendEmail)}
                              />
                              
                              <span  style={{marginLeft:"10px"}}>
                               Send email to the guest with the modified reservation
                              </span>
                          </div>

                          <div className="col-md-12" style={{marginTop:"1%"}}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck1"
                                value={sendEmail1}
                                checked={sendEmail1===true?true:false}
                                onChange={()=>setSendEmail1(!sendEmail1)}
                              />
                              
                              <span  style={{marginLeft:"10px"}}>
                               Send me a copy of what guest receives
                              </span>
                             
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
               }
            </div>
          </div>
         </div>
        {toastmodal === true ? (
        <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
        />
        ) : (
        ""
        )}
        <Footer />
      </div>
    </div>
  );
}

export default Reservationedit;
