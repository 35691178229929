import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./be_form.css";

import moment from 'moment'
import Config from "../../../Config";
import Loading from "../../../Loading";
import Notfound from "../../../Common/Notfound";
import Alerts from "../../../Alerts";
import TempHotelinformation from "./TempHotelinformation";
import TempRoominformaton from "./TempRoominformaton";
import TempHotelFreequency from "./TempHotelFreequency";
import TempTaxdetails from "./TempTaxdetails";
import TempLoyalty from "./TempLoyalty";
import Footer from "../../../Common/Footer";
import Commonheader from "../../../Common/Commonheader";

function Temporaryhotelview() {
    const location = useLocation();
    const navigate = useNavigate();
    const params=useParams();
        const [tab, setTab] = useState("Hotel-info");
        const [hotelinfo,setHotelinfo]=useState([]);
        const [invalid,setInvalid]=useState(false);
        const hotelsid=params.hotelid;
        const [propertytypehotel, setPropertytypehotel] = useState([]);
        const [hoteldetails,setHoteldetails]=useState([]);
      const[loader,setLoader]=useState(true);
      const [country,setCountry]=useState([]);
      const[timezone,setTimezone]=useState([]);
      const[extra,setExtra]=useState([]);
      const[be_lang,setBe_lang]=useState([]);
      const[category,setCategory]=useState([]);
      const[currency,setCurrency]=useState([]);
      const[channel,setChannel]=useState([]);
      const[pms,setPms]=useState([]);
      const[credit,setCredit]=useState([]);
      const[hotelimg,setHotelimg]=useState([]);
      const[roomimg,setRoomimg]=useState([]);

      const[roominfo,setRoominfo]=useState([]);
      const[hk,setHk]=useState([]);
      const[tax,setTax]=useState([]);
      const[loyal,setLoyal]=useState([]);
      const [review,setReview]=useState([]);
      const [reviewstatus,setReviewstatus]=useState(false);
      const[reviewtab,setReviewtab]=useState([]);

      const [reviewbutton,setReviewbutton]=useState(false);
      const [approvebutton,setApprovebutton]=useState(false);
      const [fullapprovebutton,setFullapprovebutton]=useState(false);

      const [alertmessage, setAlertmessage] = useState("");
      const [alerttype, setAlerttype] = useState("");
      const [open, setOpen] = useState(false);
      const [alert, setAlert] = useState(false);
      const[fullapprove,setFullapprove]=useState(false);
      const[approvehotelid,setApprovehotelid]=useState("");
  
        const gethotelinfo =useCallback(async (e) => {
      
          const res = await fetch(`${Config.apiurl}temporary-hotel/view/${hotelsid}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            
          });
          const data1 = await res.json();
          const data = data1.data;
          if (data1.status_code !== "200" || !data) {
            console.log("error");
            setLoader(false);
            setInvalid(true);
          } else {
            setLoader(false);
            setHoteldetails(data[0].basic_info);
            setCountry(data[0].country);
            setTimezone(data[0].timezone);
            setExtra(data[0].extranet_languages);
            setBe_lang(data[0].be_language);
            setPropertytypehotel(data[0].property_type);
            setCategory(data[0].category);
            setCurrency(data[0].currency);
            setChannel(data[0].channel_manager);
            setPms(data[0].pms);
            setCredit(data[0].credit_cards);
            setHotelimg(data[0].hotel_images);
            setRoomimg(data[0].optional_images);
            setRoominfo(data[0].room_info);
            setHk(data[0].hk_frequency);
            setTax(data[0].tax_info);
            setLoyal(data[0].loyalty_program);
         }
        
        },[hotelsid]);
        

      useEffect(() => {
        gethotelinfo();
      }, [gethotelinfo]);
     
      const sendApprove= async () => {
        setApprovebutton(true);
        const res = await fetch(`${Config.apiurl}temporary-hotel/acepted/${hotelsid}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
         console.log(data);
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              window.scrollTo(0, 0); 

              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setFullapprove(true);
              setApprovehotelid(data.data)
            //  setTimeout(() => navigate("/temporary-property"), 3000);

            }
       
      };

      const Reviewfn= async () => {
        setReviewbutton(true);
      let reviewarray=[];
        if(reviewtab.length>0){
          for(let i=0;i<reviewtab.length;i++){
            reviewarray.push(reviewtab[i].value);
          }
        }
       
        const res = await fetch(`${Config.apiurl}temporary-hotel/review-submit/${hotelsid}`, {
          method: "POST",
          body: JSON.stringify({
            tab:reviewarray,
            note: review,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",

  
          },
        });
        const data = await res.json();
         
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              window.scrollTo(0, 0); 
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              gethotelinfo();
              setReviewstatus(false);
              setReview("");
              setTimeout(() => navigate("/temporary-property"), 3000);
            }
       
      };

      const hoteluserassign= async (room,hotel) => {
        setApprovebutton(true);
        const res = await fetch(`${Config.apiurl}hotel/user/assign`, {
          method: "POST",
          body: JSON.stringify({
            hotel_id: hotel,
            user_id:room
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",

          },
        });
        const data = await res.json();
         console.log(data);
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              window.scrollTo(0, 0); 

              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setFullapprove(false);
              setApprovehotelid(data.data)
             setTimeout(() => navigate("/temporary-property"), 3000);

            }
       
      };
       

      const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/temporary-property');
  };
        return (
          <>
          {loader===true?
            <Loading/>
            :
          <div id="layout-wrapper">
               <Commonheader/>
            {invalid===true?
            <Notfound/>
            :

            <div className="booking_engine_view_style">
            <div className="col-md-12 ">
              
            
         
                  
               
              <div className="be-form-part-section container">
                <div className="row project-wrapper">
                  <div className="row dashboard-top-tab-style">
                    <div className="col-xxl-12 dashboard-main-style  dashboard-main-style_view">
                      <div className="card">
                      <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                   
                    </h4>
                    <div className="flex-shrink-0">
                      <button
                       type="button"
                        className="btn db-other-button "
                        onClick={navigateHome}
                       
                        style={{ marginRight: "5px" }}
                      >
                      Hotel Creation
                      </button>
                     
                     
                    </div>

                  
                  </div>
                  {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
                        <div className="tabs-link-be-form">
                          <div className="tab">
                            <button
                            type="button"
                              className={
                                tab === "Hotel-info" ? "tablinks active" : "tablinks"
                              }
                              id="tabBasicInfo"
                                onClick={(e) => setTab("Hotel-info")}
                            >
                              Hotel Information
                            </button>
                            <button
                            type="button"
                              className={
                                tab === "Room-info" ? "tablinks active" : "tablinks"
                              }
                              id="tabRoomInfo"
                                onClick={(e) => setTab("Room-info")}
                            >
                              Room Information
                            </button>
                            <button
                            type="button"
                              className={
                                tab === "Freequency" ? "tablinks active" : "tablinks"
                              }
                              id="tabFrequencyInfo"
                               onClick={(e) => setTab("Freequency")}
                            >
                              HK Frequency
                            </button>
                            <button
                            type="button"
                              className={
                                tab === "Tax" ? "tablinks active" : "tablinks"
                              }
                              id="tabTaxInfo"
                                onClick={(e) => setTab("Tax")}
                            >
                              Tax Details
                            </button>
                            <button
                            type="button"
                              className={
                                tab === "Loyalty" ? "tablinks active" : "tablinks"
                              }
                              id="tabLoyaltyInfo"
                                 onClick={(e) => setTab("Loyalty")}
                            >
                              Loyalty
                            </button>
                          </div>
                        </div>
                        {tab === "Hotel-info" ? (
                          <TempHotelinformation setTab={setTab} 
                          
                               hoteldetails={hoteldetails}
                               country={country}
                               timezone={timezone}
                               extra={extra}
                               be_lang={be_lang}
                               propertytypehotel={propertytypehotel}
                               category={category}
                               currency={currency}
                               channel={channel}
                               pms={pms}
                               credit={credit}
                               hotelimg={hotelimg}
                               roomimg={roomimg}
                                />
                        ) : tab === "Room-info" ? (
                          <TempRoominformaton setTab={setTab} 
                         roominfo={roominfo}
                            />
                        ) : tab === "Freequency" ? (
                          <TempHotelFreequency setTab={setTab}
                         hk={hk}
                          />
                        ) : tab === "Tax" ? (
                          <TempTaxdetails setTab={setTab}
                          hoteldetails={hoteldetails}
                         tax={tax}
                         currency={currency}
                          />
                        ) : tab === "Loyalty" ? (
                          <TempLoyalty setTab={setTab} 
                          loyal={loyal}
                          hoteldetails={hoteldetails}
                          sendApprove={sendApprove}
                         review={review}
                         setReview={setReview}
                          reviewstatus={reviewstatus}
                          setReviewstatus={setReviewstatus}
                          Reviewfn={Reviewfn}
                          reviewtab={reviewtab}
                          setReviewtab={setReviewtab}
                          reviewbutton={reviewbutton}
                          approvebutton={approvebutton}
                          fullapprove={fullapprove}
                          setFullapprove={setFullapprove}
                          fullapprovebutton={fullapprovebutton}
                          approvehotelid={approvehotelid}
                          hoteluserassign={hoteluserassign}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
      }
       <Footer />
          </div>
      }
      </>
        );
      }

export default Temporaryhotelview
