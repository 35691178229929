import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Commonheader from '../../Common/Commonheader';
import { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Config from '../../Config';
import Alerts from '../../Alerts';
import ReactPaginate from 'react-paginate';
import Footer from '../../Common/Footer';

function Tax() {

  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [id,setId] =useState(localStorage.getItem("selected-hotel-id"));
  const [frequency, setFrequency] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [roomtype,setRoomtype]=useState([]);
  const hotelsid=localStorage.getItem("selected-hotel-id");
  console.log(hotelsid);

    const [inputFieldstax, setInputFieldstax] = useState([
        {
         
          tax_dis_val:"percentage",
          tax_appli:"perperson"
        },
      ]);
      const addInputFieldtax = () => {
        setInputFieldstax([
          ...inputFieldstax,
          {
          
            tax_dis_val:"percentage",
            tax_appli:"perperson"
          },
        ]);
      };
      const removeInputFieldstax = (index) => {
        const rows = [...inputFieldstax];
        rows.splice(index, 1);
        setInputFieldstax(rows);
      };
      let handleChangetax = (i, e) => {
        let newval = [...inputFieldstax];
        inputFieldstax[i][e.target.name] = e.target.value;
        setInputFieldstax(newval);
      
      };
      
      const fetchComments = async (currentPage) => {
        const res = await fetch(
          `${Config.apiurl}hotel-tax/list?page=${currentPage}`,
          {
            method: "POST",
            headers: { 
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
    
        const data = await res.json();
        return data.data.data;
      };
    
    
      const handlePageClick = async (data) => {
        if(data===1){
          setPage(data);
          setPageindex(1);
          const commentsFormServer = await fetchComments(data);
          setList(commentsFormServer);
        
        }
        else{
          let currentPage = data.selected + 1;
          setPageindex(currentPage);
          setPage(data);
          const commentsFormServer = await fetchComments(currentPage);
          setList(commentsFormServer);
        }
      };
    
      const fetchPost =useCallback(async () => {
        const response = await fetch(
           `${Config.apiurl}hotel-tax/list?page=1`,{
            method:'POST',
            body: JSON.stringify({
                hotels: [id]
              }),
            headers:{
              Authorization:`Bearer ${localStorage.getItem('token')}`,
              "Content-type": "application/json; charset=UTF-8",
            }
           }
        );
           if(response.status===200)
           { 
            const data = await response.json();
            console.log(data);
    
            setList(data.data.data);
            setPaginate(data.data.last_page);
          }
           else{
               console.log("error");
            }
     },[id]);

     useEffect(() => {
      
      fetchPost();
      }, [fetchPost]);

  return (
    <div className="main-content">
    <Commonheader/>
<div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Taxes</h4>
                </div>
                {/* <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                  <Link type="button" to="#" className="btn db-save-button " style={{marginBottom:"5px"}}>Update</Link>
                    </div> */}
            </div>
        </div>
        {alert ? 
        <Alerts type={alerttype} message={alertmessage} open={open} />
      : 
        ""}
        <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                        <div className="card-body">
                            <div className="live-preview">
                                <form action="#">
                                <table className="table table-nowrap align-middle" id="orderTable">
                                {list.map((li,i)=>{
                        return(
                          <>
                          {list.length>1?
                          <div>{li.hotel_name}</div>
                          :""}
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                               
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                Title
                                </th>
                                <th className="" data-sort="customer_name">
                                 Taxes
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            
                            <tbody className="list form-check-all">
                              {li.taxes.map((user, index) => {
                                return (
                                  <tr key={index}>
                                 
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.tax_title}
                                    </td>
                                    <td className="customer_name">
                                      {user.value}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="/tax-edit"
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn" >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            </>
                        )})}
                          </table>
                  {paginate > 1 ?
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginate}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                        "pagination justify-content-end"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                    :""}
                                </form>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</div>

<Footer/>
</div>

  )
}

export default Tax