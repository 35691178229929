import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alerts from "../../../Alerts";
import Commonheader from "../../../Common/Commonheader";
import Config from "../../../Config";
import Loading from "../../../Loading";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import axios from "axios";
import Footer from "../../../Common/Footer";
import { UserContext } from "../../../Contexts/UserContext";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import { FaArrowTurnDown, FaArrowTurnUp } from "react-icons/fa6";
import Toastmodal from "../../../Common/Toastmodal";

function Cancellationpolicylist() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [policy, setPolicy] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [pageid, setPageid] = useState("");
  const [lanid, setLanid] = useState("");
  const [error, setError] = useState([{}]);
  const [selectedLang, setSelectedLang] = useState("en");
  const [showFullData, setShowFullData] = useState([]);
  const [delete_id, setDelete_id] = useState("");
  const [changeddata, setChangeddata] = useState([]);

  const getinfo =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotels/cancel-contracts/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();

      if (data.status_code === "200") {
        setPolicy(data.data);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getinfo();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    }
  },[handleUnauthorized,id]);

  const getfulllanguage =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setBe_languagefull(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getfulllanguage();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getfulllanguage();
    }
  },[handleUnauthorized]);

  function tog_animationFlip(hotel_i, c_id, lan) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(c_id);
    setLanid(lan);
    setDelete_id(hotel_i);
  }
  const DeleteData = async (delete_id, c_id, lan) => {
    const formData = new FormData();
    formData.append("hotel_id", delete_id);
    formData.append("hotel_contract_id", c_id);
    formData.append("lang_code", lan);
    axios
      .post(`${Config.apiurl}hotel/cancel-contracts/delete`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.status_code === "200") {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(false);
          setmodal_animationFlip(false);
          getinfo();
          setPageid("");
          setLanid("");
          setTimeout(() => setToastmodal(false), 4000);
        } else if (res.reauth === true) {
          handleUnauthorized();
          DeleteData(delete_id, c_id, lan);
        } else {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(false);
          setmodal_animationFlip(false);
          setPageid("");
          setLanid("");
          setTimeout(() => setToastmodal(false), 4000);
        }
      });
  };
  let handleChangees = (i, index, e) => {
    // let newval = [...policy];
    // policy[i].hotel_contracts[index][e.target.name] = e.target.value;
    // policy[i].hotelflag=true;
    // policy[i].hotel_contracts[index].policyflag=
    // setPolicy(newval)

    let newval = [...policy];
    let newChange = [...changeddata];
    if (
      !newChange[i] ||
      !newChange[i].hotel_contracts ||
      !newChange[i].hotel_contracts[index]
    ) {
      newChange[i] = newChange[i] || { hotel_contracts: [] };
      newChange[i].hotel_contracts[index] = {};
    }
    newChange[i].hotel_contracts[index] = policy[i].hotel_contracts[index];
    newChange[i].hotel_id = policy[i].hotel_id;

    newChange[i].hotel_contracts[index][e.target.name] = e.target.value;
    // policy[i].hotelflag=true;
    // policy[i].hotel_contracts[index].policyflag=
    setChangeddata(newChange);
  };
  let Update = async () => {
    let isFormValid = true;
    let arrs = [];

    for (let i = 0; i < policy.length; i++) {
      const err = {};
      const arrays = [];
      for (let j = 0; j < policy[i].hotel_contracts.length; j++) {
        const errors = {};

        if (!policy[i].hotel_contracts[j].description) {
          isFormValid = false;
          errors.description = "Cancellation message is required!";
        }
        arrays.push(errors);
      }
      err.ar = arrays;
      arrs.push(err);
    }
    setError(arrs);

    let arr = [];
    if (changeddata?.length > 0) {
      for (let i = 0; i < changeddata.length; i++) {
        if (changeddata[i] !== undefined) {
          let arry = {};
          if (changeddata[i]?.hotel_contracts) {
            arry.hotel_contract = Object.values(
              changeddata[i]?.hotel_contracts
            );
            arry.hotel = changeddata[i].hotel_id;
            arr.push(arry);
          }
        }
      }
      let lang = [];

      for (let i = 0; i < arr.length; i++) {
        let multiarray = {};
        let mularr = [];
        multiarray.hotel_id = arr[i].hotel;
        for (let j = 0; j < arr[i].hotel_contract.length; j++) {
          let multiarray1 = {};
          multiarray1.lang_code = arr[i].hotel_contract[j].lang_code;
          multiarray1.hotel_contract_id =
            arr[i].hotel_contract[j].hotel_contract_id;
          multiarray1.contract_id = arr[i].hotel_contract[j].contract_id;
          multiarray1.contract_code = arr[i].hotel_contract[j].contract_code;
          multiarray1.description = arr[i].hotel_contract[j].description;
          multiarray1.contract_type = arr[i].hotel_contract[j].contract_type;

          mularr.push(multiarray1);
        }
        multiarray.policy_contracts = mularr;
        lang.push(multiarray);
      }
      if (isFormValid === true) {
        const res = await fetch(`${Config.apiurl}cancel-contracts/update`, {
          method: "POST",
          body: JSON.stringify({
            hotels: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          if (data.status_code === "200") {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("success");
            setToastlarge(false);
            getinfo();
            setTimeout(() => setToastmodal(false), 4000);
          } else if (data.reauth === true) {
            handleUnauthorized();
            Update();
          } else {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("error");
            setToastlarge(false);
            setTimeout(() => setToastmodal(false), 4000);
          }
        } else if (res.status === 401) {
          handleUnauthorized();
          Update();
        }
      }
    } else {
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };

  console.log(changeddata);
  const handleDropdownClick = (i, index) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    const policylist = [...policy];
    const contract_id = policylist[i].hotel_contracts[index].hotel_contract_id;
    const indicesWithContractIdOne = policylist[i].hotel_contracts
      .map((item, index) =>
        item.hotel_contract_id === contract_id ? index : null
      )
      .filter((index) => index !== null);

    for (let j = 0; j < indicesWithContractIdOne.length; j++) {
      updatedShowFullData[i].hotel_contracts[indicesWithContractIdOne[j]] =
        !updatedShowFullData[i].hotel_contracts[indicesWithContractIdOne[j]];
    }
    setShowFullData(updatedShowFullData);
  };

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  useEffect(() => {
    getfulllanguage();
  }, [getfulllanguage]);

  useEffect(() => {
    getinfo();
  }, [id,getinfo]);

  // useEffect(() => {
  // let array=[];
  //   for(let i=0;i<policy.length;i++){
  //     let ar={}
  //     if(i===0){
  //     ar.hotel=true;
  //     }
  //     else{
  //       ar.hotel=false;
  //     }
  //     setShowFullData(array);
  //   // (Array(policy[i].length).fill(false));
  //   }
  // }, [policy.length]);

  useEffect(() => {
    if (policy.length > 0) {
      const initialState = policy.map((item, index) => ({
        hotel: index === 0,
        // hotel_contracts: item.hotel_contracts.map(contract => ({ showFullData: false }))
        hotel_contracts: Array(item.hotel_contracts.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [policy]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  return (
    <div className="page-content">
      <Commonheader />
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="offer-list-main">
            <div className="offer-list-top">
              <div className="offer-list-title">
                <h6 className="offer-list-head">Hotel Cancellation Policies</h6>
              </div>
              <div className="offer-list-btn">
                <Link
                  type="button"
                  className="btn db-save-button"
                  to="/cancellation-policies-defaultlist"
                >
                  <span className="ri-checkbox-line"></span>{" "}
                  <span className="">Choose From General Policies</span>
                </Link>
              </div>
            </div>
            {loading === false ? (
              <Loading />
            ) : (
              <>
                {policy.length > 0 ? (
                  <>
                    {policy.map((polic, i) => {
                      return (
                        <div
                          className={
                            policy.length > 1 ? "row hotel_single_list" : "row"
                          }
                          key={i}
                        >
                          {policy.length > 1 ? (
                            <div className="hotel_service_display hotel_service_display_12">
                              <div className="hk_service_all_hotel">
                                {polic.hotel_name}
                              </div>
                              {showFullData[i]?.hotel === true ? (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-up"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              ) : (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-down"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              showFullData[i]?.hotel === true ? "" : "d-none"
                            }
                          >
                            <div className="col-md-12">
                              <div className="card offer-lists">
                                <div className="policy-list-head">
                                  <div className="policy-list-lang">
                                    Language
                                  </div>
                                  <div className="policy-list-id">
                                    Policy ID
                                  </div>
                                  <div className="policy-list-type"></div>
                                  <div className="policy-list-text">
                                    Cancellation message
                                  </div>
                                </div>
                              </div>
                            </div>
                            {polic.hotel_contracts.length > 0 ? (
                              polic.hotel_contracts.map((poli, index) => {
                                return (
                                  <div key={index}>
                                    {!showFullData[i]?.hotel_contracts[
                                      index
                                    ] ? (
                                      poli.lang_code === "en" ? (
                                        <div className="col-md-12">
                                          <div className="card offer-lists ">
                                            <div className="policy-list ">
                                              <div className="policy-list-lang cp-margin-auto col-md-1 col-lg-1 col-xl-1">
                                                <select
                                                  className="form-select offer-create-select"
                                                  style={{ width: "75px" }}
                                                  value={poli.lang_code}
                                                >
                                                  {be_languagefull.map(
                                                    (lan, ind) => {
                                                      return (
                                                        <option
                                                          key={ind}
                                                          value={lan.code}
                                                          name="lan"
                                                        >
                                                          {lan.code}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                              <div className="policy-list-id cp-margin-auto col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                                <input
                                                  type="text"
                                                  className="form-control offer-step-textfield flatpickr-input active"
                                                  value={poli.contract_code}
                                                />
                                              </div>
                                              <div className="policy-list-id cp-margin-auto col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                                <p
                                                  style={{
                                                    background: `${poli.color_code}`,
                                                  }}
                                                  className="contact_list_type"
                                                >
                                                  {poli.contract_type}
                                                </p>
                                              </div>
                                              <div className="policy-list-text col-md-8 col-lg-8 col-xl-8 col-sm-8">
                                                <div className="row">
                                                  <div className="col-md-11 col-lg-11 col-xl-11 col-sm-11">
                                                    <textarea
                                                      className="form-control"
                                                      value={poli.description}
                                                      name="description"
                                                      onChange={(e) =>
                                                        handleChangees(
                                                          i,
                                                          index,
                                                          e
                                                        )
                                                      }
                                                    ></textarea>

                                                    {error?.map((err, ind) => {
                                                      return (
                                                        <>
                                                          {err?.ar?.map(
                                                            (er, inx) => {
                                                              return (
                                                                <>
                                                                  {ind === i &&
                                                                  inx ===
                                                                    index &&
                                                                  er &&
                                                                  er?.description ? (
                                                                    <label
                                                                      style={{
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                    >
                                                                      {
                                                                        er.description
                                                                      }
                                                                    </label>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                  <div className="col-md-1 col-lg-1 col-xl-1 col-sm-1 expand_cancel">
                                                    <button
                                                      className="cp-delete-icon cancel_updwn_btn"
                                                      type="button"
                                                      onClick={() =>
                                                        handleDropdownClick(
                                                          i,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <FaArrowTurnDown />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="cp-item-delete col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                                <Link
                                                  className="cp-delete-icon"
                                                  to="#"
                                                  title="Delete"
                                                  onClick={() =>
                                                    tog_animationFlip(
                                                      polic.hotel_id,
                                                      poli.hotel_contract_id,
                                                      poli.lang_code
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="images/icon/delete.png"
                                                    alt=""
                                                    style={{ width: "20px" }}
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <div className="col-md-12">
                                        <div className="card offer-lists ">
                                          <div className="policy-list ">
                                            <div className="policy-list-lang cp-margin-auto col-md-1 col-lg-1 col-xl-1">
                                              <select
                                                className="form-select offer-create-select"
                                                style={{ width: "75px" }}
                                                value={poli.lang_code}
                                              >
                                                {be_languagefull.map(
                                                  (lan, ind) => (
                                                    <option
                                                      key={ind}
                                                      value={lan.code}
                                                      name="lan"
                                                    >
                                                      {lan.code}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                            <div className="policy-list-id cp-margin-auto col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                              <input
                                                type="text"
                                                className="form-control offer-step-textfield flatpickr-input active"
                                                value={poli.contract_code}
                                              />
                                            </div>
                                            <div className="policy-list-id cp-margin-auto col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                              <p
                                                style={{
                                                  background: `${poli.color_code}`,
                                                }}
                                                className="contact_list_type"
                                              >
                                                {poli.contract_type}
                                              </p>
                                            </div>
                                            <div className="policy-list-text col-md-8 col-lg-8 col-xl-8 col-sm-8">
                                              <div className="row">
                                                <div className="col-md-11 col-lg-11 col-xl-11 col-sm-11">
                                                  <textarea
                                                    className="form-control"
                                                    value={poli.description}
                                                    name="description"
                                                    onChange={(e) =>
                                                      handleChangees(
                                                        i,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  ></textarea>

                                                  {error.map((err, ind) => {
                                                    return (
                                                      <>
                                                        {ind === i &&
                                                        err &&
                                                        err.description ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {err.description}
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                {poli.lang_code === "en" ? (
                                                  <div className="col-md-1 col-lg-1 col-xl-1 col-sm-1 expand_cancel">
                                                    <button
                                                      className="cp-delete-icon cancel_updwn_btn"
                                                      type="button"
                                                      onClick={() =>
                                                        handleDropdownClick(
                                                          i,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <FaArrowTurnUp />
                                                    </button>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="cp-item-delete col-md-1 col-lg-1 col-xl-1 col-sm-1">
                                              <Link
                                                className="cp-delete-icon"
                                                to="#"
                                                title="Delete"
                                                onClick={() =>
                                                  tog_animationFlip(
                                                    polic.hotel_id,
                                                    poli.hotel_contract_id,
                                                    poli.lang_code
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/icon/delete.png"
                                                  alt=""
                                                  style={{ width: "20px" }}
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <center className="mt-4">No data found</center>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="cancel_modify_section">
                      <button className="cancel_modify btn" onClick={Update}>
                        Modify
                      </button>
                    </div>
                  </>
                ) : (
                  <center className="mt-4">No data found</center>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {modal_animationFlip ? (
        <Modal
          id="flipModal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody className="modal-body p-5 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#405189,secondary:#f06548"
              style={{ width: "90px", height: "90px" }}
            ></lord-icon>

            <div className="mt-4 text-center">
              <h4>You are about to delete a Hotel Cancel Contract ?</h4>
              <p className="text-muted fs-15 mb-4">
                Deleting your Hotel Cancel Contract will remove all of your
                information from our database.
              </p>
            </div>

            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip();
              }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
            <Button
              color="danger"
              onClick={(e) => DeleteData(delete_id, pageid, lanid)}
            >
              {" "}
              Yes, Delete It{" "}
            </Button>
          </ModalBody>
        </Modal>
      ) : (
        ""
      )}

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
}

export default Cancellationpolicylist;
