import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

function Overviews({setIp,setCountrycode,ip,setSelectedcurrency,setCurrencycode}) {
  const currrency_data=JSON.parse(sessionStorage.getItem('dash_select_currency'));
  const currrency_data_code=sessionStorage.getItem('dash_select_currency_code')


 
  useEffect(() => {
    if(currrency_data){

      setSelectedcurrency(currrency_data);
      setCurrencycode(currrency_data_code);
    }
    else{
    const fetchData = async () => {
      try {
   
        const response = await fetch(process.env.REACT_APP_WIDGET_IP);
     
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
  
        setIp(data.ip);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }
  }, [
  ]);

  useEffect(() => {
    if(currrency_data){

      setSelectedcurrency(currrency_data);
      setCurrencycode(currrency_data_code);
    }
    else{
    const fetchLocationInfo = async () => {
      if (ip) {
        try {
          const response = await fetch(
            `https://widgets.thedirectrate.com/api/ipaddress?ip=${ip}`
          );

          const data = await response.json();
          setCountrycode(data.country_code);
        } catch (error) {
          console.error("Error fetching location information:", error);
        }
      }
    };
    fetchLocationInfo();
  }
  }, [ip
  ]);

  return (
    <div className="dashboard-top-tab-main">
      <ul className="nav nav-pills nav-justified mb-3" role="tablist">
        <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link "
            activeClassName="active"
            to="/dashboard"
          >
            <span className="tab-icons-style">
              <i className="ri-bank-card-fill"></i>
            </span>
            <span className="tab-content-style">Overview</span>
          </NavLink>
        </li>
        <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/dbcomparison"
          >
            <span className="tab-icons-style">
              <i className="bx bx-hive"></i>
            </span>
            <span className="tab-content-style">Comparison</span>
          </NavLink>
        </li>
        {/* <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link"
            activeClassName="nav link active"
            to="/statistics"
          >
            <span className="tab-icons-style">
              <i className="ri-bar-chart-fill"></i>
            </span>
            <span className="tab-content-style">Statistics</span>
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

export default Overviews;
