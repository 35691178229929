import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Commonheader from "../Common/Commonheader";
import { useAuthHandling } from "../Common/useAuthHandling";
import Commonimagepath from "../Commonimagepath";
import Config from "../Config";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Toastmodal from "../Common/Toastmodal";
import Footer from "../Common/Footer";
import axios from "axios";
import { Modal, ModalBody } from "reactstrap";
import { UserContext } from "../Contexts/UserContext";

function Profile() {
  let navigate = useNavigate();
  const {
    updateHotel_select,
    hotel_select,
    hotel_selectid,
    hotel_selectname,
    updateImage,
    profileimage_context,
    updateUsername,
    username_context,
  } = useContext(UserContext);

  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const fileInputRef = useRef(null);
  const [list, setList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countrydata, setCountrydata] = useState([]);
  const [selectlang, setSelectlang] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectcountrynew, setSelectcountrynew] = useState([]);
  const [selectlangnew, setSelectlangnew] = useState([]);
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [tab, setTab] = useState("personal");
  const [selectpreferredlang, setSelectpreferredlang] = useState([]);
  const [selectextranetlang, setSelectextranetlang] = useState([]);
  const [extranetnew, setExtranetnew] = useState([]);
  const [newLang, setNewLang] = useState("");
  const [isMatchingLanguagenew, setisMatchingLanguagenew] = useState("");
  const [multi, setMulti] = useState([]);
  const [langall, setLangall] = useState([]);
  const [id, setId] = useState(localStorage.getItem("selected-hotel-id"));
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState({});
  const [role, setRole] = useState("");
  const [listnew, setListnew] = useState([]);
  const [countrylabel, setCountrylabel] = useState([]);
  const [preferredlanglabel, setPreferredlanglabel] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }

  const handleConditionChange = (name, value) => {
    const updatedValues = { ...list, [name]: value };
    setList(updatedValues);
  };

  const handlePasswordChange = (name, value) => {
    const updatedValues = { ...password, [name]: value };
    setPassword(updatedValues);
  };

  const handleLanguageChangenew = (selectedOption) => {
    setNewLang(selectedOption);
    const updatedLanguages = [...multi];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
        updatedLanguages.push({
          lang_code: selectedOption,
          name: "",
          surname: "",
        });
      }
    } else {
      updatedLanguages.push({
        lang_code: selectedOption,
        name: "",
        surname: "",
      });
    }
    setMulti(updatedLanguages);
  };

  const handleloyallangChange = (Value, field) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex][field] = Value;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage[field] = Value;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  const labels = selectextranetlang.map((lang) => lang.label);
  const extranetlabel = labels.join(", ");

  const getcountry =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCountry(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getcountry();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcountry();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getlanguage =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlanguage();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlanguage();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getData =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if (data.data.length !== 0) {
          setList(data?.data?.user);
          setLogoFile(data?.data?.user?.profile_image);
          setExtranetnew(data?.data?.optonal_languages);
          setSelectcountrynew(data?.data?.user.country);
          setSelectlangnew(data?.data?.user.language);
          setMulti(data?.data?.multi_lang);
          setLangall(data?.data?.multi_lang);
          setRole(data.data.role);
          setListnew(data?.data?.user);
          setCountrylabel(data.data?.user.country);
          setPreferredlanglabel(data?.data?.user.language);
          if (data?.data?.user?.profile_image) {
            updateImage(data?.data?.user?.profile_image);
          }
          if (data?.data?.user.name) {
            updateUsername(data?.data?.user.name);
          }
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,updateImage,updateUsername]);

  const Update = async () => {
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const textOnlyRegex = /^[A-Za-z\s]+$/;
    const errors = {};

    if (!list.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    else if (!textOnlyRegex.test(list.name)) {
      isFormValid = false;
      errors.name = "Name should only contain letters and spaces!";
    }
    else if (list.name.length > 50) {
      isFormValid = false;
      errors.name = `Name cannot be more than 50 characters!`;
    }
    if (!list.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    else if (!textOnlyRegex.test(list.surname)) {
      isFormValid = false;
      errors.surname = "Surname should only contain letters and spaces!";
    }
    else if (list.surname.length > 50) {
      isFormValid = false;
      errors.surname = `Surname cannot be more than 50 characters!`;
    }
    if (!list.email) {
      isFormValid = false;
      errors.email = "Email is required!";
    } else if (!regex.test(list.email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if (countrydata.length === 0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (selectpreferredlang.length === 0) {
      isFormValid = false;
      errors.selectpreferredlang = "Preferred language is required!";
    }
    if (selectextranetlang.length === 0) {
      isFormValid = false;
      errors.selectextranetlang = "Extranet language is required!";
    }

    setError(errors);

    if (isFormValid === true) {
      let newOp_lang = [];
      if (selectextranetlang.length > 0) {
        for (let i = 0; i < selectextranetlang.length; i++) {
          newOp_lang.push(selectextranetlang[i].value);
        }
      }

      let lang = [];
      for (let i = 0; i < multi.length; i++) {
        let multiarray = {};
        multiarray.lang_code = multi[i].lang_code;

        let langarray = [];
        let multilangarray = {};
        multilangarray.name = multi[i].name;
        multilangarray.surname = multi[i].surname;

        langarray.push(multilangarray);

        multiarray.fields = langarray;

        lang.push(multiarray);
      }

      const res = await fetch(`${Config.apiurl}profile/update`, {
        method: "PATCH",
        body: JSON.stringify({
          en_name: list.name,
          en_surname: list.surname,
          email: list.email,
          is_active: list.is_active,
          role: role,
          default_language: selectpreferredlang.value,
          optional_language: newOp_lang,
          country: countrydata.value,
          multi_lang: lang,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200" || !data.data) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getData();
        } else if (data.reauth === true) {
          handleUnauthorized();
          Update();
        } else {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  const ChangePassword = async () => {
    let isFormValid = true;
    const regex1 = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`¿¡!#$%^&*€£@+÷=\-\\';,/{}|":<>?._])./;

    const errors = {};

    if (!password.old_password) {
      isFormValid = false;
      errors.old_password = "Old password is required!";
    }  
    if (!password.new_password) {
      isFormValid = false;
      errors.new_password = "New password is required!";
    }
    else if (!regex1.test(password.new_password)) {
      isFormValid = false;
      errors.new_password = "Password must contain at least one number, one lowercase letter, one uppercase letter and one special character!";
    }
    else if (password.new_password.length < 8) {
      isFormValid = false;
      errors.new_password = "The password must be at least 8 characters!";
    }
    if (!password.confirm_password) {
      isFormValid = false;
      errors.confirm_password = "Confirm password is required!";
    } else if (password.new_password !== password.confirm_password) {
      isFormValid = false;
      errors.confirm_password = "The provided passwords do not match!";
    }

    setError(errors);

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}profile/change-password`, {
        method: "POST",
        body: JSON.stringify({
          old_password: password.old_password,
          password: password.new_password,
          password_confirmation: password.confirm_password,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200" ) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);

        } 
        else if (data.reauth === true) {
          handleUnauthorized();
          ChangePassword();
        }
        else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        ChangePassword();
      } else {
        console.log("error");
      }
    }
  };

  let handleImageChange = (e) => {
    setLogoFile(e.target.files[0]);
    // const ImageUpload = async () => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    axios
      .post(`${Config.apiurl}profile/image/update`, formData, {})
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          console.log("success");
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(true);
          getData();
          // if (fileInputRef.current) {
          //   fileInputRef.current.value = ""; // Clear the file input value
          // }
        }
      });
    // };
  };

  const RemoveImage = async () => {
    const res = await fetch(`${Config.apiurl}profile/image/remove`, {
      method: "POST",

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    console.log(res);
    if (res.status !== 200 || !res) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    } 
    else {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlip();
      getData();
    }
  };

  useEffect(() => {
    if (selectcountrynew) {
      for (let i = 0; i < country?.length; i++) {
        if (selectcountrynew === country[i]?.value) {
          setCountrydata({ label: country[i].label, value: country[i].value });
        }
      }
    }
  }, [selectcountrynew, country]);

  useEffect(() => {
    if (selectlangnew) {
      for (let i = 0; i < language?.length; i++) {
        if (selectlangnew === language[i]?.value) {
          setSelectpreferredlang({
            label: language[i].label,
            value: language[i].value,
          });
        }
      }
    }
  }, [selectlangnew, language]);

  useEffect(() => {
    if (extranetnew) {
      for (let i = 0; i < language?.length; i++) {
        for (let j = 0; j < extranetnew?.length; j++) {
          if (extranetnew[j] === language[i]?.value) {
            selectextranetlang.push({
              label: language[i].label,
              value: language[i].value,
            });
          }
        }
      }
    }
  }, [extranetnew, language,selectextranetlang]);

  useEffect(() => {
    if (countrylabel) {
      for (let i = 0; i < country?.length; i++) {
        if (countrylabel === country[i]?.value) {
          setCountrylabel(country[i].label);
        }
      }
    }
  }, [countrylabel, country]);

  useEffect(() => {
    if (preferredlanglabel) {
      for (let i = 0; i < language?.length; i++) {
        if (preferredlanglabel === language[i]?.value) {
          setPreferredlanglabel(language[i].label);
        }
      }
    }
  }, [preferredlanglabel, language]);

  useEffect(() => {
    getcountry();
  }, [getcountry]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getlanguage();
  }, [getlanguage]);

  useEffect(() => {
    const isMatching = multi?.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguagenew(isMatching);
  }, [newLang,multi]);

  useEffect(() => {
    let newval;

    langall?.forEach((lang) => {
      if (lang.name) {
        newval = lang.lang_code;
      }
    });
    setNewLang(newval);
  }, [langall]);

  return (
    <div>
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card-header align-items-center d-flex">
              <p
                className="card-title flex-grow-1"
                style={{ fontWeight: "500" }}
              >
                Profile
              </p>
            </div>

            {/* <div className="position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg profile-setting-img">
                  <img src="/images/users/avatar-1.jpg" className="profile-wid-img" alt=""/>
                  <div className="overlay-content">
                      <div className="text-end p-3">
                          <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                              <input id="profile-foreground-img-file-input" type="file" className="profile-foreground-img-file-input"/>
                              <label for="profile-foreground-img-file-input" className="profile-photo-edit btn btn-light">
                                  <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}

            <div className="row">
              <div className="col-xxl-3" style={{ width: "28%" }}>
                <div className="card">
                  <div
                    className="card-body p-4"
                    style={{ borderBottom: "1px solid #008080" }}
                  >
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                        {/* <img
                            src="/images/users/avatar-1.jpg"
                            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                            alt="user-profile-image"
                          /> */}
                        {logoFile ? (
                          <img
                            src={`${Commonimagepath.apiurl}${logoFile}`}
                            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                            alt=""
                          />
                        ) : (
                          <img
                            src="images/icon/profile_user.png"
                            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                            alt=""
                          />
                        )}
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <label
                            for="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="ri-camera-fill"></i>
                              <input
                                type="file"
                                id="profile-img-file-input"
                                className="profile-img-file-input"
                                name="profile_image"
                                onChange={handleImageChange}
                                accept=".jpg, .jpeg, .webp, .png"
                                // ref={fileInputRef}
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                      <img
                        src="images/icon/bin.png"
                        className="profiledelete"
                        alt=""
                        style={{
                          marginTop: "25%",
                          paddingLeft: "3px",
                          height: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => tog_animationFlip()}
                      />

                      <h5 className="fs-16 mb-1">
                        {listnew.name}
                        <span style={{ marginLeft: "5px" }}>
                          {listnew.surname}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <p
                      className="card-title mb-3"
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        borderBottom: "2px solid #008080",
                        width: "34%",
                        fontSize: "14px",
                      }}
                    >
                      More Information
                    </p>
                    <table className="table  table-borderless mb-0">
                      <tbody>
                        <tr>
                          <th className="" scope="row">
                            E-mail :
                          </th>
                          <td className="text-muted">{listnew.email}</td>
                        </tr>
                        <tr>
                          <th className="" scope="row">
                            User Type :
                          </th>
                          <td className="text-muted">{listnew.user_type}</td>
                        </tr>
                        <tr>
                          <th className="" scope="row">
                            Country :
                          </th>
                          <td className="text-muted">{countrylabel}</td>
                        </tr>
                        <tr>
                          <th className="adjpro" scope="row">
                            Preferred Language :
                          </th>
                          <td className="text-muted">{preferredlanglabel}</td>
                        </tr>
                        <tr>
                          <th className="adjpro" scope="row">
                            Extranet Languages :
                          </th>
                          <td className="text-muted">{extranetlabel}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8">
                <div className="card">
                  <div className="card-header" style={{ borderBottom: "0px" }}>
                    <ul
                      className="nav nav-pil modify-tabs-profile nav-success mb-3"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className={
                            tab === "personal" ? "nav-link active" : "nav-link"
                          }
                          data-bs-toggle="tab"
                          to="#"
                          role="tab"
                          onClick={(e) => setTab("personal")}
                        >
                          Personal Details{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            tab === "password" ? "nav-link active" : "nav-link"
                          }
                          data-bs-toggle="tab"
                          to="#"
                          role="tab"
                          onClick={(e) => setTab("password")}
                        >
                          Change Password
                        </Link>
                      </li>
                    </ul>
                    {/* <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                            <li className="nav-item">
                                <Link className={tab==="personal"?"nav-link active":"nav-link"} data-bs-toggle="tab" to="#" role="tab" onClick={()=>setTab("personal")}>
                                   Personal Details
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={tab==="password"?"nav-link active":"nav-link"} data-bs-toggle="tab" to="#" role="tab" onClick={()=>setTab("password")}>
                                 Change Password
                                </Link>
                            </li>
                        </ul> */}
                  </div>
                  <div className="card-body p-4">
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="personalDetails"
                        role="tabpanel"
                      >
                        <form action="#">
                          {tab === "personal" ? (
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="name"
                                      value={list.name}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.name}
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Surname
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="surname"
                                      value={list.surname}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "surname",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.surname}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="col-lg-6">
                                  <div
                                    className="d-flex"
                                    style={{ float: "left" }}
                                  >
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                      style={{
                                        width: "130px",
                                        borderBottomRightRadius: "0px",
                                        borderTopRightRadius: "0px",
                                      }}
                                    >
                                      Add/edit in
                                    </label>
                                    <select
                                      className="form-control editlanguage"
                                      data-choices
                                      data-choices-search-false
                                      id="idPayment"
                                      style={{
                                        width: "228px",
                                        height: "38px",
                                        marginLeft: "-1px",
                                      }}
                                      value={newLang}
                                      onChange={(e) =>
                                        handleLanguageChangenew(e.target.value)
                                      }
                                    >
                                      <option value="">Choose language</option>

                                      {language.map((lang, inx) => {
                                        return (
                                          <option key={inx} value={lang.code}>
                                            {lang.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <span className="padding-10"></span>

                                {newLang ? (
                                  multi?.length > 0 ? (
                                    <>
                                      {multi?.some(
                                        (item) => item.lang_code === newLang
                                      ) ? (
                                        <div className="row">
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-profile"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    multi[isMatchingLanguagenew]
                                                      ?.name
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value,
                                                      "name"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-profile"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Surname
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    multi[isMatchingLanguagenew]
                                                      ?.surname
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value,
                                                      "surname"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="row">
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-profile"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    multi[multi?.length - 1]
                                                      ?.name
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value,
                                                      "name"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-profile"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Surname
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    multi[multi?.length - 1]
                                                      ?.surname
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value,
                                                      "surname"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Email Address
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="email"
                                      value={list.email}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "email",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.email}
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Country
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-control"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={country}
                                      value={countrydata}
                                      onChange={(val) => setCountrydata(val)}
                                    ></Select>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.countrydata}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Preferred Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-control"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={language}
                                      value={selectpreferredlang}
                                      onChange={(val) =>
                                        setSelectpreferredlang(val)
                                      }
                                    ></Select>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.selectpreferredlang}
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Extranet Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-7">
                                      <MultiSelect
                                        options={language}
                                        value={selectextranetlang}
                                        onChange={(val) =>
                                          setSelectextranetlang(val)
                                        }
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectextranetlang}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-lg-12"
                                style={{ marginTop: "8px" }}
                              >
                                <div className="hstack gap-2 justify-content-end">
                                  <button
                                    type="button"
                                    className="btn db-save-button"
                                    onClick={Update}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div
                                className="col-lg-8"
                                style={{ marginLeft: "15%" }}
                              >
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Old Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      name="old_password"
                                      value={password.old_password}
                                      onChange={(e) =>
                                        handlePasswordChange(
                                          "old_password",
                                          e.target.value
                                        )
                                      }
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.old_password}
                                  </label>
                                </div>
                              </div>

                              <div
                                className="col-lg-8"
                                style={{ marginLeft: "15%" }}
                              >
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      New Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      name="new_password"
                                      value={password.new_password}
                                      onChange={(e) =>
                                        handlePasswordChange(
                                          "new_password",
                                          e.target.value
                                        )
                                      }
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.new_password}
                                  </label>
                                </div>
                              </div>

                              <div
                                className="col-lg-8"
                                style={{ marginLeft: "15%" }}
                              >
                                <div className="mb-1">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-profile"
                                      id="inputGroup-sizing-default"
                                    >
                                      Confirm Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      name="confirm_password"
                                      value={password.confirm_password}
                                      onChange={(e) =>
                                        handlePasswordChange(
                                          "confirm_password",
                                          e.target.value
                                        )
                                      }
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.confirm_password}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-center">
                                  <button
                                    type="button"
                                    className="btn db-save-button"
                                    onClick={ChangePassword}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />

      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ height: "120px", width: "500px" }}
        >
          <div>Do you want to remove your profile image?</div>

          <button className="" style={{ borderRadius: "4px" }}>
            <Link
              to="#"
              onClick={() => {
                tog_animationFlip();
              }}
              style={{ marginTop: "20px" }}
            >
              {" "}
              Cancel{" "}
            </Link>
          </button>
          <button
            className="board_create_click"
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            <Link to="#" onClick={RemoveImage}>
              {" "}
              Remove{" "}
            </Link>
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Profile;
