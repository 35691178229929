import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alerts from "../Alerts";
import Config from "../Config";
import detectBrowserLanguage from "detect-browser-language";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Loading from "../Loading";
import Cookies from "js-cookie";
import { UserContext } from "../Contexts/UserContext";
import { Spinner } from "reactstrap";

function Login() {
  let navigate = useNavigate();

  const { updateHotel_select, hotel_select, languageid, updatelanguage } =
    useContext(UserContext);

  const [button_disable, setButton_disable] = useState(false);
  const lang = detectBrowserLanguage();
  const array = lang.split("-");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [datas, setDatas] = useState([]);
  const [languagedata, setLanguagedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showpass, setShowpass] = useState(false);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values) {
      errors.username = 1;
    } else if (!regex.test(values)) {
      errors.username = 2;
    }

    return errors;
  };
  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.password = 3;
    }
    return errors;
  };

  const handleButtonClick = () => {
    setButton_disable(true);
    Login(); 
  };

  const Login = async () => {
    const ress = validate(username);
    const ress1 = validate1(password);

    setError(ress);
    setError1(ress1);

    if (!ress.username && !ress1.password) {
      const res = await fetch(`${Config.apiurl}admin/dr-login-panel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });
      const data1 = await res.json();
      if (data1.status === 200) {
        localStorage.setItem("email", username);
        localStorage.setItem("pass", password);

        setAlert(true);
        if (data1.message === "OTP sent successfully") {
          setAlertmessage(
            !languagedata.lang_array || languagedata.lang_array.length === 0
              ? languagedata.en.otp_send
              : languagedata.lang_array.otp_send
                ? languagedata.lang_array.otp_send
                : languagedata.en.otp_send
          );
          setAlerttype("success");
          setButton_disable(false);
          setTimeout(() => navigate("/verify"), 3000);
        }
      } else {
        setAlert(true);
        if (data1.message === "error") {
          setAlertmessage(
            !languagedata.lang_array || languagedata.lang_array.length === 0
              ? languagedata.en.invalid_credentials
              : languagedata.lang_array.invalid_credentials
                ? languagedata.lang_array.invalid_credentials
                : languagedata.en.invalid_credentials
          );
          setButton_disable(false);

        } else if (data1.message === "Invalid Credentials") {
          setAlertmessage(
            !languagedata.lang_array || languagedata.lang_array.length === 0
              ? languagedata.en.invalid_credentials
              : languagedata.lang_array.invalid_credentials
                ? languagedata.lang_array.invalid_credentials
                : languagedata.en.invalid_credentials
          );
          setButton_disable(false);

        } else if (data1.message === "Email or Password Mismatch") {
          setAlertmessage(
            !languagedata.lang_array || languagedata.lang_array.length === 0
              ? languagedata.en.email_mismatch
              : languagedata.lang_array.email_mismatch
                ? languagedata.lang_array.email_mismatch
                : languagedata.en.email_mismatch
          );
          setButton_disable(false);

        } else {
          setAlertmessage(
            !languagedata.lang_array || languagedata.lang_array.length === 0
              ? languagedata.en.something_wrong
              : languagedata.lang_array.something_wrong
                ? languagedata.lang_array.something_wrong
                : languagedata.en.something_wrong
          );
          setButton_disable(false);

        }
        setAlerttype("error");
      }

    }
  };

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine/languages`, {
      method: "GET",
    });
    const data = await res.json();
    if (res.status === 404 || !data) {
      console.log("error");
    } else {
      setDatas(data.data);
    }
  };

  function Languageset(id) {
    localStorage.setItem("be_language", id);
    updatelanguage(id);
  }
  
  const getinfo =useCallback(async (e) => {
    if (languageid === null || undefined) {
      const res = await fetch(`${Config.apiurl}get-page-contents/login`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      console.log(data);
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    } else {
      const res = await fetch(`${Config.apiurl}get-page-contents/login`, {
        method: "POST",
        body: JSON.stringify({
          language: languageid,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      console.log(data);
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    }
  },[languageid]);

  const forgotpass = async (e) => {
    const res = await fetch(`${Config.apiurl}forgot-password`, {
      method: "POST",

      body: JSON.stringify({
        email: username,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    const data1 = await res.json();
    if (res.status === 200) {
      if (data1.status === false) {
        setAlert(true);
        setAlerttype("error");
        setAlertmessage(data1.message);
      } else {
        localStorage.setItem("email", username);
        setAlert(true);

        setAlertmessage(data1.message);
        setAlerttype("success");
        setTimeout(() => navigate("/verify", { state: "forgot" }), 3000);
      }
    } else {
      setAlerttype("error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getinfo();
  }, [languageid,getinfo]);

  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <div className="wrapper">
          <div className="row">
            <div className="flex-placed ">
              {/* image */}
              <div className="col-md-6 img-sec-login">
                <div className="login-main2">
                  <div id="LoginHDisplay.Rowset1" className="xy-center">
                    <p id="LoginHDisplay.Rb10">
                      <span id="LoginHDisplay.Rb10.C2" className="alignvideo">
                        <video
                          className="video-slide active"
                          src="images/rooms/h.mp4"
                          autoPlay
                          muted
                          loop
                          // width="1280"
                          // height="720"
                        ></video>
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 form-section-2">
                <div className="login-main">
                  <div className="login-box-style">
                    <div className="login-box">
                      <div className="login-inner-box">
                        {alert ? (
                          <Alerts type={alerttype} message={alertmessage} />
                        ) : (
                          ""
                        )}
                        <div className="login-logo">
                          {/* <img src="/images/small-logo.png" alt="" /> */}
                          <img src="/images/IconDR.png" alt="" />
                        </div>

                        <div className="login-content">
                          <h2>
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.login_title
                              : languagedata.lang_array.login_title
                                ? languagedata.lang_array.login_title
                                : languagedata.en.login_title}
                          </h2>
                        </div>
                        <div className="login-form-field">
                          <div className="login-form">
                            <span className="login-form-span">
                              <i className="fa fa-user" aria-hidden="true"></i>
                            </span>
                            <input
                              type="text"
                              className="login-text-field"
                              placeholder={
                                !languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.email_placeholder
                                  : languagedata.lang_array.email_placeholder
                                    ? languagedata.lang_array.email_placeholder
                                    : languagedata.en.email_placeholder
                              }
                              // placeholder={`${newone.username_placeholder}`}

                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </div>
                          {formerror.username === 1 ||
                          formerror.username === 2 ? (
                            <label style={{ color: "red" }}>
                              {formerror.username === 1
                                ? !languagedata.lang_array ||
                                  languagedata.lang_array.length === 0
                                  ? languagedata.en.email_required
                                  : languagedata.lang_array.email_required
                                    ? languagedata.lang_array.email_required
                                    : languagedata.en.email_required
                                : formerror.username === 2
                                  ? !languagedata.lang_array ||
                                    languagedata.lang_array.length === 0
                                    ? languagedata.en.valid_email_format
                                    : languagedata.lang_array.valid_email_format
                                      ? languagedata.lang_array
                                          .valid_email_format
                                      : languagedata.en.valid_email_format
                                  : ""}
                            </label>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              formerror.username === 1 ||
                              formerror.username === 2
                                ? "login-form1"
                                : "login-form"
                            }
                          >
                            <span className="login-form-span">
                              <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            <input
                              type={showpass === true ? "text" : "password"}
                              className="login-text-field"
                              placeholder={
                                !languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.password_placeholder
                                  : languagedata.lang_array.password_placeholder
                                    ? languagedata.lang_array
                                        .password_placeholder
                                    : languagedata.en.password_placeholder
                              }
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="eye-icon">
                              {showpass === true ? (
                                <AiFillEyeInvisible
                                  onClick={(e) => setShowpass(!showpass)}
                                />
                              ) : (
                                <AiFillEye
                                  onClick={(e) => setShowpass(!showpass)}
                                />
                              )}
                            </div>
                          </div>
                          {formerror1.password === 3 ? (
                            <label style={{ color: "red" }}>
                              {formerror1.password === 3
                                ? !languagedata.lang_array ||
                                  languagedata.lang_array.length === 0
                                  ? languagedata.en.password_required
                                  : languagedata.lang_array.password_required
                                    ? languagedata.lang_array.password_required
                                    : languagedata.en.password_required
                                : ""}
                            </label>
                          ) : (
                            ""
                          )}

                          {button_disable === false ? (
                            <Link to="#" onClick={handleButtonClick} className="">
                              <div className="login-submit dr_login_btn">
                                {!languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.login_botton
                                  : languagedata.lang_array.login_botton
                                    ? languagedata.lang_array.login_botton
                                    : languagedata.en.login_botton}
                              </div>
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-center my-3 custom-spinner">
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                            // <Link to="#" className="">
                            //   <div className="login_button_disable dr_login_btn">
                            //     {!languagedata.lang_array ||
                            //     languagedata.lang_array.length === 0
                            //       ? languagedata.en.login_botton
                            //       : languagedata.lang_array.login_botton
                            //         ? languagedata.lang_array.login_botton
                            //         : languagedata.en.login_botton}
                            //   </div>
                            // </Link>
                          )}
                          <div className="flx-btn-links">
                            <div className="login-bottom">
                              <p>
                                {" "}
                                {!languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.go_to_direct_website
                                  : languagedata.lang_array.go_to_direct_website
                                    ? languagedata.lang_array
                                        .go_to_direct_website
                                    : languagedata.en.go_to_direct_website}
                              </p>
                            </div>
                            <div className="partition"></div>
                            <div className="login-forgot-section">
                              <p onClick={forgotpass}>
                                {!languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.forgot_password
                                  : languagedata.lang_array.forgot_password
                                    ? languagedata.lang_array.forgot_password
                                    : languagedata.en.forgot_password}{" "}
                                ?
                              </p>
                            </div>
                          </div>

                          <div className="language-choose">
                            <ul className="language-list">
                              {datas.slice(0, 4).map((lang, i) => {
                                return (
                                  <div key={i}>
                                    <li>
                                      {" "}
                                      <Link
                                        onClick={(e) =>
                                          Languageset(lang.lang_id)
                                        }
                                      >
                                        {lang.regional_font}
                                      </Link>
                                    </li>
                                  </div>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
