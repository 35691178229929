import React, { useCallback } from 'react'
import Footer from '../../Common/Footer';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Alerts from "../../Alerts";
import { useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Commonheader from '../../Common/Commonheader';
import Basicsettingsheader from '../../Common/Basicsettingsheader';
import { useAuthHandling } from '../../Common/useAuthHandling';

function Channelmanager() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

    const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [permission, setPermission] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedPermission, setselectedPermission] = useState([]);
  const [property, setProperty] = useState("");
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("en");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);

  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});

  const getData =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}admin/permissions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code === "200" ) {
      setPermission(data);

    } 
    else if(data1.reauth===true){
      handleUnauthorized();
      getData();
    }
    
    else {
      console.log("error");

    }
  },[handleUnauthorized]);

  const getDatalang = useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code === "200" ) {
      setLanguage(data);

    }
    else if(data1.reauth===true){
      handleUnauthorized();
      getDatalang();
    }
    else {
      console.log("error");

    }
  },[handleUnauthorized]);

  const validate = (values) => {
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/]+$/;

    const errors = {};
    if (!values) {
      errors.property = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.property = "Name should not contain this special character";
    }
    else if (values.length > 50) {
      errors.property = `Name cannot be more than 50 characters!`;
    }
    return errors;
  };

    const validate1 = (values) => {
      const errors = {};
      if (!values) {
        errors.status = "Status is required!";
      }
      return errors;
    };


  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(property);
    const ress1=validate1(status)
    setError(ress);
    setError1(ress1);
    let lang = [];

  
    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].name) {
        if (selectedMulti[i].code !== "en") {
          let multiarray = {};
          multiarray.lang_code = selectedMulti[i].code;
          multiarray.name = selectedMulti[i].name;

          lang.push(multiarray);
        }
      }
    }
  if (!ress.property && !ress1.status) {
    
      const res = await fetch(`${Config.apiurl}admin/property-type/add`, {
        method: "POST",
        body: JSON.stringify({
          en_name: property,
        
          is_active: status,
          multi_lang: lang,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
          if (data.status_code === "200" ) {
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("success");
          } 
          else if(data.reauth===true){
            handleUnauthorized();
            PostData();
          }
          else {
           
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("error");
           
          }
        
    }
  };

  function Reset() {
    setLanguage("");
    setProperty("");
    setStatus("");
    setselectedMulti("");
  }

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getDatalang();
  }, [getDatalang]);

  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Property Type </h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/property-type-list">Property Type</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Property Type
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button "
                          to="/basic_settings/property-type-list"
                          style={{ marginRight: "5px" }}
                        >
                         Property Type
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="" onClick={PostData}
                          
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Name
                            <span className="form-validation-required">*</span>
                              </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={property}
                                      onChange={(e) =>
                                        setProperty(e.target.value)
                                      }
                                    />
                                   
                                  </div>
                                  <label style={{ color: "red" }}>
                                      {formerror.property}
                                    </label>
                                </div>
                            

                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                   
                                  </div>
                                  <label style={{ color: "red" }}>
                                      {formerror1.status}
                                    </label>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="col-lg-4" style={{marginTop:"20px"}}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                  <div className="col-md-7">
                                  <MultiSelect
                                  options={language}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={true}
                                  aria-expanded="true"/>
                                </div>
                                </div>
                                </div>
                                <div className="appendmore"></div>
                                {selectedMulti.length > 0
                                  ? selectedMulti.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1" style={{marginTop:"20px"}}
                                        >
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div class="col-lg-4">
                                      <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default">Name
                                    <span className="form-validation-required">*</span>
                                      </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  selectedMulti[index].name
                                                }
                                                onChange={
                                                  (e) =>
                                                    (selectedMulti[index].name =
                                                      e.target.value)
                                                  // handletrans(
                                                  //   index,
                                                  //   e,
                                                  //   element.code
                                                  // )
                                                  // setRoletrans({
                                                  //   code: element.code,
                                                  //   names: e.target.value,
                                                  // })
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default Channelmanager
