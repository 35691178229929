import React, { useCallback, useEffect, useState } from "react";
import { Plus, Trash2, Edit2 } from "lucide-react";
import Commonheader from "../../Common/Commonheader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import { Modal, ModalBody } from "reactstrap";
import DeleteModal from "../../DeleteModal";
import Loading from "../../Loading";

const initialItems = [
  { id: 1, title: "Revenue Management System (RMS)", url:"https://extranet.lybra.tech/hrfrc/login" },
  { id: 2, title: "Premium Revenue Analysis & Management", url:"https://metahotels.dms.fornova.com/login" },
  { id: 3, title: "Upsell Voucher Engine (Gift Vouchers)", url:"https://giftvouchers.thedirectrate.com" },
  { id: 4, title: "Iceportal - GDS", url:"https://iceportal.shijigroup.com/login"},
  { id: 5, title: "Central Reservation Office (CRO)", url:"https://cro.thedirectrate.com" },
  { id: 6, title: "Online Reputation Tool", url:"https://guest-experience.com/users/sign_in" },
  { id: 7, title: "AI Hotel Chat Bot", url:"https://chatbot.thedirectrate.com" },
  { id: 8, title: "Central Reservation System (CRS)", url:"https://crs.thedirectrate.com/" },
  { id: 9, title: "Customer Relationship Management (CRM)", url:"https://crm.metahotels.ae/" },
  { id: 10, title: "Magic Parity Plus & Price Match Tool", url:"https://hotelmonitor.info/backend" },
];

const RibbonMenu = () => {
  const [items, setItems] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [modal_animationFlip_update, setmodal_animationFlip_update] = useState(false);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [id, setId] = useState("");
  const [update_id, setUpdate_id] = useState("");
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }

  function tog_animationFlip_update(id) {
    setUpdate_id(id);
    setmodal_animationFlip_update(!modal_animationFlip_update);
    View(id);
  }

  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setList({ ...list, [name]: value });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  const addNewRibbon = async (e) => {

    let isFormValid = true;
    const errors = {};

    if (!name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    if (!url) {
      isFormValid = false;
      errors.url = "URL is required!";
    }
    if (!sortOrder) {
      isFormValid = false;
      errors.sortOrder = "Sort Order is required!";
    }
    if (!status) {
      isFormValid = false;
      errors.status = "Status is required!";
    }

    setError(errors);

    if (isFormValid === true) {

    const res = await fetch(`${Config.apiurl}admin/drsuite/add`, {
      method: "POST",
      body: JSON.stringify({
        name:name,
        url:url,
        sort_order:sortOrder,
        status:status
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 201) {
      const data = await res.json();
      if (data.status_code === 201) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        tog_animationFlip();
        setName("");
        setUrl("");
        setSortOrder("");
        setStatus("");
        List();
      } else if (res.reauth === true) {
        handleUnauthorized();
        addNewRibbon();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      addNewRibbon();
    } else {
      console.log("error");
    }
   }
  };

  const Update = async (e) => {

    let isFormValid = true;
    const errors = {};

    if (!list.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    if (!list.url) {
      isFormValid = false;
      errors.url = "URL is required!";
    }
    if (!list.sort_order) {
      isFormValid = false;
      errors.sort_order = "Sort Order is required!";
    }
    if (!list.status) {
      isFormValid = false;
      errors.status = "Status is required!";
    }

    setError1(errors);

    if (isFormValid === true) {

    const res = await fetch(`${Config.apiurl}admin/drsuite/update/${update_id}`, {
      method: "PATCH",
      body: JSON.stringify({
        name:list.name,
        url:list.url,
        sort_order:list.sort_order,
        status:list.status
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === 200) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        tog_animationFlip_update();
        List();
      } else if (res.reauth === true) {
        handleUnauthorized();
        Update();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Update();
    } else {
      console.log("error");
    }
   }
  };

  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(id) {
    setId(id);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const DeleteData = async () => {
    const res = await fetch(`${Config.apiurl}admin/drsuite/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === 200) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setDelete_animationFlip(false);
        List();
      } else if (data.reauth === true) {
        handleUnauthorized();
        DeleteData();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        setDelete_animationFlip(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      DeleteData();
    } else {
      console.log("error");
    }
  };

  const List =useCallback( async () => {
    const res = await fetch(`${Config.apiurl}admin/drsuite/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === 200) {
        setItems(data.data);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        List();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      List();
    } else {
      console.log("error");
    }
  }, [handleUnauthorized]);

  const View = async (id) => {
    const res = await fetch(`${Config.apiurl}admin/drsuite/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status === 200) {
        setList(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        View();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      View();
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    List();
  }, [List]);

  return (
    <div>
      <Commonheader />
     
      <div className="container ma_top_W" style={{marginBottom:"5%"}}>
      {loader===false?
      <Loading/>
      :
      <>
        <div className="max-w-3xl mx-auto p-8 bg-white ma_bottom_W ">
          <div className="header-section mb-6">
            <div className="col-sm d-flex justify-content-between align-items-center ma_padding_W">
              <h5 className="text-teal-600 font-medium teal">DR SUITE</h5>
              <div className="flex-shrink-0">
                <button onClick={() => {tog_animationFlip()}} className="btn db-save-button">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container ma_left_W">
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="ribbon-list">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="ribbon-container"
                >
                  {items?.map((item, index) => (
                    <Draggable key={item.sort_order} draggableId={item.sort_order.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="ribbon_W-container"
                        >
                          <div className="ribbon_W">
                            <div className="number-section">
                              <div className="number-content">
                                {item.sort_order.toString().padStart(2, "0")}
                                <div className="twisted-edge"></div>
                                <div className="white-edge"></div>
                              </div>
                            </div>
                            <div className="content-section-nw">
                              <div className="content-wrapper_W">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="content-inner_W"
                                >
                                  {item.name}
                                </a>
                                <div className="action-buttons">
                                  <button className="action-button" onClick={() => tog_animationFlip_update(item.suite_id)}>
                                    <Edit2 color="white" size={16} />
                                  </button>
                                  <button
                                    onClick={() => tog_animationFlipDel(item.suite_id)}
                                    className="action-button"
                                  >
                                    <Trash2 color="white" size={16} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder} 
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* <div className="ribbon-container">
            {items.map((item, index) => (
              <div key={item.id} className="ribbon_W-container">
                <div className="ribbon_W">
                  <div className="number-section">
                    <div className="number-content">
                      {item.id.toString().padStart(2, "0")}
                      <div className="twisted-edge"></div>
                      <div className="white-edge"></div>
                    </div>
                  </div>
                  <div className="content-section-nw">
                  <div className="content-wrapper_W">
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="content-inner_W"
                  >
                    {item.title}
                    </a>
                    <div className="action-buttons">
                      <button className="action-button">
                        <Edit2 color="white" size={16} />
                      </button>
                      <button
                        onClick={() => removeRibbon(item.id)}
                        className="action-button"
                      >
                        <Trash2 color="white" size={16} />
                      </button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
        </>
       }
      </div>
      <Modal
        id="flipModal"
        className="new_modal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{
          fontFamily: "IBM Plex Sans,sans-serif",
          justifyContent: "center",
        }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ width: "650px" }}
        >
          <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
            <div
              className="row price-modify-filter"
              style={{ marginTop: "20px" }}
            >
              <form>
                <button
                  type="button"
                  className="btn btn-link link-success fw-medium text-decoration-none"
                  onClick={() => {
                    tog_animationFlip();
                  }}
                  style={{ float: "right", bottom: "30px" }}
                >
                  {" "}
                  <i
                    className="ri-close-line me-1 align-middle"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
                <div className="row">
                <div
                    class="col-md-12"
                    style={{ marginTop: "15px" }}>
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       Name{" "} <span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      />
                     
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror.name}
                      </label>
                  </div>
                  <div
                    class="col-md-12"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       URL{" "}<span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={url}
                        onChange={(e)=>setUrl(e.target.value)}
                      />
                     
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror.url}
                      </label>
                  </div>
                  <div
                    class="col-md-6"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       Sort Order{" "}<span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={sortOrder}
                        onChange={(e)=>setSortOrder(e.target.value)}
                      />
                      
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror.sortOrder}
                      </label>
                  </div>
                  <div
                        class="col-md-6"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Status{" "}<span className="form-validation-required">*</span>
                          </label>
                          <select
                            id="ForminputState"
                            className="form-select"
                            value={status}
                            onChange={(e) =>
                              setStatus(e.target.value)
                            }
                          >
                            <option selected value="">
                              Choose...
                            </option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                         
                        </div>
                        <label style={{ color: "red" }}>
                        {formerror.status}
                      </label>
                      </div>
                  </div>
              </form>
            </div>
          </div>
          <div style={{ marginTop: "5%" }}>
            <button
              type="button"
              className="btn db-save-button" onClick={addNewRibbon}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        className="new_modal"
        isOpen={modal_animationFlip_update}
        toggle={() => {
          tog_animationFlip_update();
        }}
        modalClassName="flip"
        centered
        style={{
          fontFamily: "IBM Plex Sans,sans-serif",
          justifyContent: "center",
        }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ width: "650px" }}
        >
          <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
            <div
              className="row price-modify-filter"
              style={{ marginTop: "20px" }}
            >
              <form>
                <button
                  type="button"
                  className="btn btn-link link-success fw-medium text-decoration-none"
                  onClick={() => setmodal_animationFlip_update(!modal_animationFlip_update)}
                  style={{ float: "right", bottom: "30px" }}
                >
                  {" "}
                  <i
                    className="ri-close-line me-1 align-middle"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
                <div className="row">
                <div
                    class="col-md-12"
                    style={{ marginTop: "15px" }}>
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       Name{" "} <span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={list.name}
                        onChange={handlechange}
                        name="name"
                      />
                     
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror1.name}
                      </label>
                  </div>
                  <div
                    class="col-md-12"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       URL{" "}<span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={list.url?list.url:""}
                        onChange={handlechange}
                        name="url"
                      />
                     
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror1.url}
                      </label>
                  </div>
                  <div
                    class="col-md-6"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                       Sort Order{" "}<span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={list.sort_order}
                        onChange={handlechange}
                        name="sort_order"
                      />
                      
                    </div>
                    <label style={{ color: "red" }}>
                        {formerror1.sort_order}
                      </label>
                  </div>
                  <div
                        class="col-md-6"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Status{" "}<span className="form-validation-required">*</span>
                          </label>
                          <select
                              id="ForminputState"
                              className="form-select"
                              value={list.status}
                              onChange={handlechange}
                              name="status"
                            >
                              <option value="">Choose...</option>
                              <option
                                {...(list.status === true
                                  ? "selected"
                                  : "")}
                                value={true}
                              >
                                Active
                              </option>
                              <option
                                {...(list.status === false
                                  ? "selected"
                                  : "")}
                                value={false}
                              >
                                Inactive
                              </option>
                            </select>
                         
                        </div>
                        <label style={{ color: "red" }}>
                        {formerror1.status}
                      </label>
                      </div>
                  </div>
              </form>
            </div>
          </div>
          <div style={{ marginTop: "5%" }}>
            <button
              type="button"
              className="btn db-save-button" onClick={Update}
            >
              Update
            </button>
          </div>
        </ModalBody>
      </Modal>

      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={DeleteData}
          pageid={id}
          message={"DR Suite"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default RibbonMenu;
