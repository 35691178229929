import React, { useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import moment, { months } from "moment";
import dayjs from "dayjs";
import Commonheader from "../../../Common/Commonheader";
import Config from "../../../Config";
import Alerts from "../../../Alerts";
import { UserContext } from "../../../Contexts/UserContext";
import Toastmodal from "../../../Common/Toastmodal";
import Footer from "../../../Common/Footer";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Loading from "../../../Loading";

function Availability() {

  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [selectedrooms, setSelectedrooms] = useState([]);
  const [selectedMultifilter, setselectedMultifilter] = useState([]);

  const [dateString1, setDateString1] = useState(
    moment().format("MMM DD, YYYY")
  );
  const [todaydate, setTodaydate] = useState(moment().format("MMM DD, YYYY"));

  const firstDayOfNextMonth = moment().add(1, "month").startOf("month");
  const lastDayOfNextMonth = firstDayOfNextMonth.clone().endOf("month");

  const [dateString2, setDateString2] = useState(
    lastDayOfNextMonth.format("MMM DD, YYYY")
  );
  // const [dateString2, setDateString2] = useState(moment().endOf('month').add(1, 'M').format('MMM DD, YYYY'));

  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [clickedDays, setClickedDays] = useState([]);
  const [datefrom, setDateFrom] = useState(null);
  const [datefilterfrom, setDatefilterFrom] = useState(null);

  const [dateto, setDateTo] = useState(null);
  const [datefilterto, setDatefilterTo] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [roomtype, setRoomtype] = useState([]);
  const [open, setOpen] = useState(false);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);
  const [errors, setError] = useState({});
  const [errorsfilter, setErrorfilter] = useState({});
  const [err, setErrorfilter1] = useState({});
  const [allroomrtypes, setAllRoomtypes] = useState([]);
  const [bulkroom, setBulkroom] = useState([]);
  const [bulkavailable, setBulkavailable] = useState("");
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [saveddata, setSaveddata] = useState([]);
  const [loader, setLoader] = useState(false);

  const getavailability =useCallback(async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room/availability/list/${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getavailability();
      } else {
        setAvailability(data.data.data);
        setLoader(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getavailability();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setAvailability(data.data);
    // }
  },[handleUnauthorized,id]);

  const getRoomtype =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${id}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  },[id]);

  const handleClickprev = async () => {
    let first;
    const currentDate = moment().format("DD-MM-YYYY");
    first = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstfull = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let end = moment(first).endOf("month").format("MMM DD, YYYY");
    let endfull = moment(first).endOf("month").format("DD-MM-YYYY");

    if (
      moment(firstfull, "DD-MM-YYYY").isBefore(
        moment(currentDate, "DD-MM-YYYY")
      )
    ) {
      firstfull = currentDate;
      first = moment(currentDate, "DD-MM-YYYY").format("MMM DD, YYYY");
    }
    setDateString1(first);
    setDateString2(end);

    let rooms = [];
    if (selectedMultifilter.length > 0) {
      for (let i = 0; i < selectedMultifilter.length; i++) {
        let arr = {};
        arr = selectedMultifilter[i].value;
        rooms.push(arr);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let arr = {};
        arr = roomtype[i].value;
        rooms.push(arr);
      }
    }
    const res = await fetch(`${Config.apiurl}hotel/room-availability/search`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        rooms: rooms,
        from: firstfull,
        to: endfull,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickprev();
      } else {
        setAvailability(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickprev();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setAvailability(data);
    // }
  };

  const handleClicknext = async () => {
    let firstnext = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstnextfull = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let endnext = moment(firstnext).endOf("month").format("MMM DD, YYYY");
    let endnextfull = moment(firstnext).endOf("month").format("DD-MM-YYYY");

    setDateString1(firstnext);
    setDateString2(endnext);

    let rooms = [];
    if (selectedMultifilter.length > 0) {
      for (let i = 0; i < selectedMultifilter.length; i++) {
        let arr = {};
        arr = selectedMultifilter[i].value;
        rooms.push(arr);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let arr = {};
        arr = roomtype[i].value;
        rooms.push(arr);
      }
    }
    const res = await fetch(`${Config.apiurl}hotel/room-availability/search`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        rooms: rooms,
        from: firstnextfull,
        to: endnextfull,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClicknext();
      } else {
        setAvailability(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClicknext();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setAvailability(data);
    // }
  };

  const Filter = async () => {
    let rooms = [];
    let isFormValid = true;

    const errors = {};
    if (selectedMultifilter.length === 0) {
      isFormValid = false;

      errors.selectedMultifilter = "This field is required!";
    }

    setError(errors);
    if (isFormValid === true) {
      for (let i = 0; i < selectedMultifilter.length; i++) {
        let arr = {};
        arr = selectedMultifilter[i].value;
        rooms.push(arr);
      }

      const res = await fetch(
        `${Config.apiurl}hotel/room-availability/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            rooms: rooms,
            from: moment(datefilterfrom).format("DD-MM-YYYY"),
            to: moment(datefilterto).format("DD-MM-YYYY"),
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          console.log("error");
        } else if (data.reauth === true) {
          handleUnauthorized();
          Filter();
        } else {
          setAvailability(data.data);
          setDatefilterFrom(null);
          setDatefilterTo(null);
          setDateString1(moment(datefilterfrom).format("MMM DD, YYYY"));
          setDateString2(moment(datefilterto).format("MMM DD, YYYY"));
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Filter();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data) {
      //   console.log("error");
      // } else {
      //   setAvailability(data);
      //   //    setselectedMultifilter([]);
      //   setDatefilterFrom(null);
      //   setDatefilterTo(null);
      //   setDateString1(moment(datefilterfrom).format("MMM DD, YYYY"));
      //   setDateString2(moment(datefilterto).format("MMM DD, YYYY"));
      // }
    }
  };

  const Singlesave = async () => {
    let rooms = [];
    setSaveddata(availability);

    for (let i = 0; i < availability.length; i++) {
      let arr = {};
      if (availability[i].flag && availability[i].flag === true) {
        arr.room = availability[i].room_id;
        let availabilityData = [];
        for (let j = 0; j < availability[i].availability.length; j++) {
          let ar = {};
          if (
            availability[i].availability[j].flag &&
            availability[i].availability[j].flag === true
          ) {
            let formattedDate = moment(
              `${availability[i].availability[j].year}-${availability[i].availability[j].month}-${availability[i].availability[j].date}`
            ).format("DD-MM-YYYY");
            ar.date = formattedDate;
            ar.availabilty = availability[i].availability[j].availability;
            availabilityData.push(ar);
          }
        }
        arr.room_availabilty = availabilityData;
        rooms.push(arr);
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/room/availability/update/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          availability: rooms,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Singlesave();
      } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getavailability();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Singlesave();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getavailability();
    // }
  };

  const Bulkupdate = async () => {
    let isFormValid = true;
    let arr = [];
    const errors1 = {};
    if (!datefrom) {
      isFormValid = false;

      errors1.datefrom = "From date is required!";
    }

    if (!dateto) {
      isFormValid = false;

      errors1.dateto = "To date is required!";
    }
    setErrorfilter(errors1);

    const errors = {};
    if (bulkroom.length === 0) {
      isFormValid = false;

      errors.rooms = "Room is required!";
    }
    if (!bulkavailable) {
      isFormValid = false;

      errors.availability = "Availability is required!";
    }

    setErrorfilter1(errors);

    if (isFormValid === true) {
      let exp = [];
      for (let i = 0; i < clickedDays.length; i++) {
        const numericValue = clickedDays[i];
        if (numericValue >= 0 && numericValue < weekdays.length) {
          const weekdayName = weekdays[numericValue];
          exp.push(weekdayName);
        }
      }
      let roomar = [];
      for (let j = 0; j < bulkroom.length; j++) {
        roomar.push(bulkroom[j].value);
      }

      const res = await fetch(`${Config.apiurl}hotel/room/availability/${id}`, {
        method: "POST",
        body: JSON.stringify({
          start_date: moment(datefrom).format("DD-MM-YYYY"),
          end_date: moment(dateto).format("DD-MM-YYYY"),
          exception: exp,
          rooms: roomar,
          availabilty: bulkavailable,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          Bulkupdate();
        } else {
            setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setBulkavailable("");
        setDateFrom("");
        setDateTo("");
        setClickedDays([]);
        getavailability();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Bulkupdate();
      } else {
        console.log("error");
      }
      // const data = await res.json();
      // if (res.status === 200) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   setBulkavailable("");
      //   setDateFrom("");
      //   setDateTo("");
      //   setClickedDays([]);
      //   getavailability();
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // }
    }
  };

  const [active, setActive] = useState(false);
  function clickEventHandler() {
    setActive(!active);
  }

  var loop = new Date(dateString1);
  var end = new Date(dateString2);

  var newDate1 = loop.setDate(loop.getDate() - 1);
  var newDate2 = end.setDate(end.getDate() - 1);
  let arr = [];

  while (loop <= end) {
    var newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(loop);
    let date = moment(loop).format("MMM DD,YYYY");
    arr.push(date);
  }

  const [values, setValues] = useState(arr.map(() => "0"));

  const handleInputChange = (index, i, value) => {
    const updatedValues = [...availability];
    updatedValues[index]["availability"][i]["availability"] = value;
    updatedValues[index]["flag"] = true;
    updatedValues[index]["availability"][i]["flag"] = true;

    setAvailability(updatedValues);
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDateFrom(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDateTo(selectedDates[0]);
  };

  const handleCalendarChangeFromfilter = (selectedDates) => {
    console.log("selectedDates", selectedDates);
    setDatefilterFrom(selectedDates[0]);
  };

  const handleCalendarChangeTofilter = (selectedDates) => {
    setDatefilterTo(selectedDates[0]);
  };

  const handleDayClick = (dayOfWeek) => {
    if (selectedWeekdays.includes(dayOfWeek)) {
      if (clickedDays.includes(dayOfWeek)) {
        setClickedDays((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDays((prevClickedDays) => [...prevClickedDays, dayOfWeek]);
      }
    }
  };

  const [columnsDisplayed, setColumnsDisplayed] = useState(0);

  useEffect(() => {

      const getWeekdays = (startDate, endDate) => {
        const weekdays = [];
        const currentDay = new Date(startDate);
        const lastDay = new Date(endDate);

        while (currentDay <= lastDay) {
          const dayOfWeek = currentDay.getDay();
          if (dayOfWeek >= 0 && dayOfWeek <= 6) {
            weekdays.push(dayOfWeek);
          }
          currentDay.setDate(currentDay.getDate() + 1);
        }

        return weekdays;
      };
      const getWeekdaysBetweenSelectedDates = (datefrom, dateto) => {
        const weekdays = getWeekdays(datefrom, dateto);
        setSelectedWeekdays(weekdays);
        setClickedDays([]);
      };

      if (datefrom && dateto) {
        getWeekdaysBetweenSelectedDates(datefrom, dateto);
      } else {
        setSelectedWeekdays([]);
      }

      const columnNumber =
        window.innerWidth / document.querySelector(".th-element").clientWidth;
      console.log(columnNumber);
      const datas = Math.ceil(columnNumber);
      setColumnsDisplayed(datas);
    },
    [dateto,datefrom]);

  useEffect(() => {
    getRoomtype();
  },[getRoomtype]);

  useEffect(() => {
    setselectedMultifilter(roomtype.map((option) => option));
    const allValuesArray = roomtype.map((option) => option);
    setAllRoomtypes(allValuesArray);
    setBulkroom(allValuesArray);
  }, [roomtype]);

  console.log(selectedMultifilter);

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const handleCalendarIconClickfilter = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  useEffect(() => {
    getavailability();
  }, [id,getavailability]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  console.log(loader);


  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="">
            <div className="row">
              <div className="col-12">
                <h4 className="" style={{ marginLeft: "10px" }}>
                  {hotelname}
                </h4>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title" style={{ color: "#008080" }}>
                    Availability
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="card">
                  <div className="db-avail-modify-sec">
                    <div className="db-avail-modify-collapse">
                      <span
                        className="db-modify_toggle_btn"
                        onClick={clickEventHandler}
                      >
                        <span className="bx bxs-chevron-down"></span>
                      </span>
                      <button
                        className="modify-button"
                        onClick={clickEventHandler}
                      >
                        Modify Period
                      </button>
                    </div>
                    <div
                      className={
                        active === false
                          ? "db-avail-modify-sec-main d-none"
                          : "db-avail-modify-sec-main b_b_1"
                      }
                    >
                      <div className="avail-modify-section">
                        <div className="avail-modify-title">
                          Apply to period
                        </div>
                        <div className="row avail-modify-filter">
                          <form>
                            <div className="row g-3">
                              <div className="col-xxl-2 col-sm-4 col-md-2 col-lg-3 form-calender-add">
                                <form action="#">
                                  <div className="input-group">
                                    <Flatpickr
                                      id="flatpickr-from"
                                      className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                      placeholder="FROM"
                                      options={{
                                        mode: "single",
                                        dateFormat: "d M, Y",
                                        minDate: "today",
                                      }}
                                      value={datefrom}
                                      onChange={handleCalendarChangeFrom}
                                    />
                                    <label style={{ color: "red" }}>
                                      {errorsfilter.datefrom}
                                    </label>

                                    <span
                                      className="form_calendar-style fon mdi mdi-calendar-month-outline cal1"
                                      onClick={() =>
                                        handleCalendarIconClick("from")
                                      }
                                    ></span>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-2 col-md-2 col-lg-3 col-sm-4 form-calender-add">
                                <form action="#">
                                  <div className="input-group">
                                    <Flatpickr
                                      id="flatpickr-to"
                                      className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                      placeholder="TO"
                                      options={{
                                        mode: "single",
                                        dateFormat: "d M, Y",
                                        minDate: "today",
                                      }}
                                      value={dateto}
                                      onChange={handleCalendarChangeTo}
                                    />
                                    <label style={{ color: "red" }}>
                                      {errorsfilter.dateto}
                                    </label>

                                    <span
                                      className="form_calendar-style fon mdi mdi-calendar-month-outline cal2"
                                      onClick={() =>
                                        handleCalendarIconClick("to")
                                      }
                                    ></span>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-6 col-md-8  calenter_down col-lg-6  col-sm-12">
                                <ul className="modify-week-list  week_alter">
                                  <li
                                    className={`modify-week-list-item card-title ${
                                      selectedWeekdays.includes(1)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(1) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(1)}
                                  >
                                    Mon
                                  </li>
                                  <li
                                    className={`modify-week-list-item card-title ${
                                      selectedWeekdays.includes(2)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(2) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(2)}
                                  >
                                    Tue
                                  </li>
                                  <li
                                    className={`modify-week-list-item ${
                                      selectedWeekdays.includes(3)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(3) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(3)}
                                  >
                                    Wed
                                  </li>
                                  <li
                                    className={`modify-week-list-item ${
                                      selectedWeekdays.includes(4)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(4) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(4)}
                                  >
                                    Thu
                                  </li>
                                  <li
                                    className={`modify-week-list-item ${
                                      selectedWeekdays.includes(5)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(5) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(5)}
                                  >
                                    Fri
                                  </li>
                                  <li
                                    className={`modify-week-list-item ${
                                      selectedWeekdays.includes(6)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(6) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(6)}
                                  >
                                    Sat
                                  </li>
                                  <li
                                    className={`modify-week-list-item ${
                                      selectedWeekdays.includes(0)
                                        ? "green-bg"
                                        : ""
                                    } ${
                                      clickedDays.includes(0) ? "red-bg" : ""
                                    }`}
                                    onClick={() => handleDayClick(0)}
                                  >
                                    Sun
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="new_options" id="new_option">
                              <div className="row">
                                <div className="col-xxl-12 col-sm-12 modify-room-sec room_top">
                                  <div className="row g-3">
                                    <div class="col-lg-3">
                                      <div className="input-group">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          Rooms :
                                        </label>
                                        <div className="col-md-8 room_availability">
                                          <MultiSelect
                                            options={roomtype}
                                            value={bulkroom}
                                            onChange={setBulkroom}
                                            labelledBy="Select"
                                            name="rooms"
                                            selectionType="counter"
                                            allowCustomValue={true}
                                            aria-expanded="true"
                                          />
                                        </div>

                                        <label style={{ color: "red" }}>
                                          {err.rooms}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-lg-1 available_col">
                                      <label className="price-modify-label">
                                        Availability :
                                      </label>
                                    </div>
                                    <div class="col-lg-1">
                                      <div className="modify-room-sub-input">
                                        <input
                                          className="form-control"
                                          type="number"
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          min={0}
                                          value={bulkavailable}
                                          onChange={(e) =>
                                            setBulkavailable(e.target.value)
                                          }
                                          name="availability"
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              "wheel",
                                              function (e) {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                        />

                                        <label style={{ color: "red" }}>
                                          {err.availability}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="modify-period-submit">
                              <button
                                type="button"
                                className="btn db-save-button"
                                onClick={Bulkupdate}
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-availability-type">
                  <div className="card availabilty-filter-card">
                    <div className="card-header">
                      <div className="row align-items-center gy-3">
                        <form>
                          <div className="row g-3">
                            <div className="col-xxl-1 col-lg-1 col-md-1 col-sm-6">
                              <h5 className="card-title db-title-header filter-head fw-medium">
                                Filter
                              </h5>
                            </div>
                            <div className="col-xxl-5 col-lg-4 col-md-4 col-sm-6">
                              <span
                                className="facility-form-dropdown"
                                style={{ width: "100%" }}
                              >
                                <MultiSelect
                                  options={roomtype}
                                  value={selectedMultifilter}
                                  onChange={setselectedMultifilter}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                  aria-expanded="true"
                                />

                                <label style={{ color: "red" }}>
                                  {errors.selectedMultifilter}
                                </label>
                              </span>
                            </div>
                            <div className="col-xxl-2 col-sm-4 col-md-2 col-lg-3 form-calender-add">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-fromfilter"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                      minDate: "today",
                                    }}
                                    value={datefilterfrom}
                                    onChange={handleCalendarChangeFromfilter}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline cal3"
                                    onClick={() =>
                                      handleCalendarIconClickfilter(
                                        "fromfilter"
                                      )
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="col-xxl-2 col-md-2 col-lg-3 col-sm-4 form-calender-add">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-tofilter"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="TO"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                      minDate: "today",
                                    }}
                                    value={datefilterto}
                                    onChange={handleCalendarChangeTofilter}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline cal4"
                                    onClick={() =>
                                      handleCalendarIconClickfilter("tofilter")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="col-xxl-1 col-md-1 col-lg-1  col-sm-4">
                              <button
                                type="button"
                                className="btn db-common-filter filter_btn"
                                style={{
                                  color: "white",
                                  backgroundColor: "black",
                                }}
                                onClick={Filter}
                              >
                                <span className="bx bx-filter-alt"></span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="card">
                    <div className="row available_filter_calender">
                      <span className="col-xxl-6 available_filter_calender_sec">
                        {dateString1 === todaydate ? (
                          ""
                        ) : (
                          <button
                            type="button"
                            title="Previous month"
                            aria-pressed="false"
                            className="fc-prev-button btn btn-availability-arrow"
                            onClick={handleClickprev}
                          >
                            <span className="fa fa-chevron-left"></span>
                          </button>
                        )}
                        <input
                          type="text"
                          value={`${dateString1} - ${dateString2}`}
                          style={{
                            marginTop: "10px",
                            border: "0px",
                            width: "200px",
                          }}
                        />
                        <button
                          type="button"
                          title="Next month"
                          aria-pressed="false"
                          className="fc-next-button btn btn-availability-arrow"
                          onClick={handleClicknext}
                        >
                          <span className="fa fa-chevron-right"></span>
                        </button>
                        <span className="calendar-show-count">
                          {" "}
                          A total of
                          <span className="calendar-date-count">
                            {" "}
                            {arr.length < columnsDisplayed - 4
                              ? arr.length
                              : columnsDisplayed - 4}{" "}
                            days
                          </span>{" "}
                          are displayed on this screen
                        </span>
                      </span>
                      <span className="col-xxl-6 available_update_btn">
                        <button
                          type="button"
                          className="btn db-save-button"
                          onClick={Singlesave}
                        >
                          Save
                        </button>
                      </span>
                    </div>
                    <div className="col-xxl-12 available_filter_room_info_sec">
                      <div className="card-body availability_table_cardbody">
                        <div className="table-responsive">
                          <table className="table room-availability-table table-bordered table-nowrap align-middle mb-0">
                            <thead>
                              <tr>
                                <th scope="col" className="stickyclass">
                                  <p className="room-available-filtersec-head">
                                    Rooms
                                  </p>
                                </th>

                                <div className="th-container">
                                  {arr.map((user, index) => {
                                    return (
                                      <>
                                        <th
                                          scope="col"
                                          key={index}
                                          className="th-element"
                                        >
                                          <div
                                            className="room-avail-date"
                                            style={{ width: "70px" }}
                                          >
                                            <span className="avail-month-name">
                                              {dayjs(user).format("MMM")}
                                            </span>
                                            <br></br>
                                            <span className="avail-date-bold">
                                              {dayjs(user).format("DD")}
                                            </span>
                                            <br></br>
                                            <span className="avail-week-name">
                                              {dayjs(user).format("ddd")}
                                            </span>
                                            <br></br>
                                          </div>
                                        </th>
                                      </>
                                    );
                                  })}
                                </div>
                              </tr>
                            </thead>
                            {loader === true ? 


                            <tbody>
                              {availability.map((avail, indx) => {
                                return (
                                  <tr key={indx}>
                                    <th scope="col" className="stickyclass">
                                      <div className="db-avail-room-sec">
                                        <h6 className="db-room-avail-title">
                                          {avail.room_name}
                                        </h6>
                                        <span
                                          className="availability-calender-show"
                                          id="availability-calender-show"
                                        >
                                          <img
                                            src="images/rooms/calendar_icons.png"
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                      </div>
                                    </th>

                                    <div className="th-container">
                                      {avail.availability.map((room, inx) => {
                                        const isZero =
                                          room.availability === "0" ||
                                          room.availability === 0;
                                        const boxColorClass = isZero
                                          ? "red-box"
                                          : "normal-box";
                                        let saveColorClass;
                                        if (saveddata.length > 0) {
                                          const isDesiredAvailability =
                                            room.availability ===
                                            saveddata[indx]?.availability[inx]
                                              ?.availability;
                                          saveColorClass = isDesiredAvailability
                                            ? ""
                                            : "ch_";
                                        }
                                        const displayText = isZero
                                          ? "zero"
                                          : room.availability;
                                        return (
                                          <th
                                            scope="col"
                                            className={`avail-count th-element `}
                                            key={inx}
                                          >
                                            {isZero ? (
                                              <input
                                                className={`form-control ${boxColorClass} ${saveColorClass}`}
                                                value={displayText}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    indx,
                                                    inx,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : (
                                              <input
                                                className={`form-control ${saveColorClass}`}
                                                value={room.availability}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    indx,
                                                    inx,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            )}
                                          </th>
                                        );
                                      })}
                                    </div>
                                  </tr>
                                );
                              })}
                            </tbody>
                            
                             : (
                              <tr>
                                <td
                                  style={{ textAlign: "center" }}
                                  colSpan={columnsDisplayed - 3}
                                >
                                  <Loading />
                                </td>
                              </tr>
                            )}
                        
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Availability;
