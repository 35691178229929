import React, { useCallback, useEffect, useState } from 'react'
import Footer from "../../Common/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Alerts from "../../Alerts";
import Config from "../../Config";
import { MultiSelect } from "react-multi-select-component";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import axios from "axios";
import { useAuthHandling } from '../../Common/useAuthHandling';
import Commonimagepath from '../../Commonimagepath';

function Hotelamenitiesedit() {
    let navigate = useNavigate();
    const location = useLocation();

    const [ids, setId] = useState(location.state.amenity_id);
    const { isAuthorized, handleUnauthorized } = useAuthHandling();

    const [name,setName]=useState("")
    const [tab,setTab]=useState("EN")
    const [formerror, setError] = useState({});
    const [status, setStatus] = useState("");
    const [language, setLanguage] = useState("");
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedMulti, setselectedMulti] = useState([]);
    const [image, setImage] = useState([]);
    const [icon, setIcon] = useState([]);
  
  
    const IconChange = (e) => {
      setIcon( e.target.files[0] );
    };
  
    const ImageChange = (e) => {  
      setImage( e.target.files[0] );
    };
  
    const getValue = async (e) => {
      const res = await fetch(`${Config.apiurl}optional-languages`, {
        method: "GET",
        headers:
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setLanguage(data.data);
        
      }
    };
  
    const getinfo = useCallback(async (e) => {
        const res = await fetch(`${Config.apiurl}edit-amenities/${ids}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            
          },
        });
        const data = await res.json();
      if (data.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setName(data.data.title);
        setStatus(data.data.status);
        setIcon(data.data.icon_image);
        setImage(data.data.image);
        
      }
      },[ids]);
      
    const PostData = async (e) => {
      e.preventDefault();
      let isFormValid = true;
      const errors = {};
  
      if (!name) {
        isFormValid = false;
        errors.name = "This field is required!";
      }
      if (!status) {
        isFormValid = false;
        errors.status = "This field is required!";
      }
      setError(errors);
  
      if (isFormValid===true) {
  
        const formData = new FormData();
        formData.append("title", name);
        formData.append("image", image);
        formData.append("icon_image", icon);
        formData.append("status", status);
        
            for (let i = 0; i < selectedMulti.length; i++) {
              if (selectedMulti[i].name) {
                if (selectedMulti[i].code !== "en") {
                  
                
                  formData.append(`multi_lang[${i}][lang_code]`, selectedMulti[i].code);
                  formData.append(`multi_lang[${i}][title]`, selectedMulti[i].name);
                  
                }
              }
            }               
          axios.post(`${Config.apiurl}update_basic_amenities/${ids}`, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-type": "application/json; charset=UTF-8",
              },
            })
            .then((res) => {
  
            if (res.status_code !== "200" || !res) {
              setAlert(true);
              setAlertmessage(res.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              setAlert(true);
              setAlertmessage(res.message);
              setOpen(true);
              setAlerttype("success");
              setTimeout(() => navigate("/basic_settings/hotel-amenities-list"), 3000);
            }
         }
       )
      }
    };

    useEffect(() => {
      getinfo();
    }, [getinfo]);
  
    useEffect(() => {
      getValue();
    }, []);

   
  
    return (
      <div id="layout-wrapper">
       <Basicsettingsheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Hotel Amenities</h4>
                    
                  </div>
                </div>
              </div>
              {alert ? 
                  <Alerts type={alerttype} message={alertmessage} open={open} />
                : 
                  ""}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Create New Hotel Amenity
                        </h4>
                        <div className="flex-shrink-0">
                          <Link
                            type="button"
                            className="btn db-other-button"
                            to="/basic_settings/hotel-amenities-list"
                            style={{ marginRight: "5px" }}
                          >
                           Hotel Amenities
                          </Link>
                          
                          <button
                            type="button"
                            className="btn db-save-button"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target="" onClick={PostData}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-translation-style">
                                  <ul>
                                    <li className={tab==="EN" ?"active" :""}onClick={(e)=>setTab("EN")}>EN</li>
                                    <li  className={tab==="TRANS" ?"active" :""} onClick={(e)=>setTab("TRANS")}>
                                    
                                        Translations
                                    
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {tab==="EN"?
                              <>
                               <div class="col-lg-4">
                                <div className="input-group">
                              <label className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default">Name
                              <span className="form-validation-required">*</span>
                                </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                   value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <label style={{ color: "red" }}>
                                      {formerror.name}
                                    </label>
                              </div>
                              <div class="col-lg-3">
                                  <div className="input-group">
                                <label className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default">Icon
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder=""
                                    onChange={IconChange}
                                    accept=".jpg, .jpeg, .webp, .png"
                                    />
                                  
                                </div>
                                <img
                                src={`${Commonimagepath.apiurl}${icon}`}
                                alt=""
                                 className="hotel_amenity_img"
                                /> 
                                <label style={{ color: "red" }}>
                                      {formerror.icon}
                                  </label>
                              </div>
                              <div class="col-lg-3">
                                  <div className="input-group">
                                <label className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"> Image
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder=""
                                    onChange={ImageChange}
                                    accept=".jpg, .jpeg, .webp, .png"
                                    />
                                   
                                </div>
                                <img
                                src={`${Commonimagepath.apiurl}${image}`}
                                alt=""
                                style={{width:"30%"}}
                                /> 
                                <label style={{ color: "red" }}>
                                      {formerror.image}
                                  </label>
                              </div>
                              <div class="col-lg-2">
                                <div className="input-group">
                              <label className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default">Status
                              <span className="form-validation-required">*</span>
                                </label>
                                <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e)=>setStatus(e.target.value)}
                                     
                                    >
                                      <option selected>Choose...</option>
                                      <option {...(status === true
                                    ? "selected"
                                    : "")}
                                  value={true}  >Active</option>
                                      <option {...(status === false
                                    ? "selected"
                                    : "")}
                                  value={false} >Inactive</option>
                                    </select>
                                 
                                </div>
                                <label style={{ color: "red" }}>
                                      {formerror.status}
                                    </label>
                              </div>
                              </>:<>
                              <div class="col-lg-4" style={{marginTop:"20px"}}>
                                    <div className="input-group">
                                      <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                    <div className="col-md-7">
                                    <MultiSelect
                                    options={language}
                                    value={selectedMulti}
                                    onChange={setselectedMulti}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    closeOnChangedValue={true}
                                    aria-expanded="true"/>
                                  </div>
                                  </div>
                                  </div>
                              <div className="appendmore"></div>
                              {selectedMulti.length > 0
                                ? selectedMulti.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        class="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"  style={{marginTop:"20px"}}
                                      >
                                        <div class="translation-selected-lang">
                                          <p>
                                            <span class="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
  
                                      <div class="col-lg-4">
                                    <div className="input-group">
                                  <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default">Name
                                  <span className="form-validation-required">*</span>
                                    </label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder=""
                                              value={
                                                selectedMulti[index].name
                                              }
                                              onChange={
                                                (e) =>
                                                  (selectedMulti[index].name =
                                                    e.target.value)}/>
                                           
                                          
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                              </>}
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <Footer />
        </div>
      </div>
    );
}

export default Hotelamenitiesedit