import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Commonheader from "../../../Common/Commonheader";
import { MultiSelect } from "react-multi-select-component";
import moment, { months } from "moment";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import Conditionsheader from "../../../Common/Conditionsheader";
import Config from "../../../Config";
import Alerts from "../../../Alerts";
import Loading from "../../../Loading";

import Cookies from "js-cookie";
import { UserContext } from "../../../Contexts/UserContext";
import Nodatafound from "../../../Common/Nodatafound";
import Toastmodal from "../../../Common/Toastmodal";
import { useAuthHandling } from "../../../Common/useAuthHandling";

function Openclose() {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [columnsDisplayed, setColumnsDisplayed] = useState(0);
  const [roomtype, setRoomtype] = useState([]);
  const [ratelist, setRatelist] = useState([]);
  const [boardlist, setBoardlist] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  const [openclose, setOpenclose] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [selectedbulkrooms, setselectedBulkrooms] = useState([]);
  const [selectedbulkrate, setselectedBulkrate] = useState([]);
  const [selectedbulkboard, setselectedBulkboard] = useState([]);
  const [selectedbulkoccupancy, setselectedBulkoccupancy] = useState([]);
  const [selectedfilterroom, setselectedFilterroom] = useState([]);
  const [selectedfilterrate, setselectedFilterrate] = useState([]);
  const [selectedfilterboard, setselectedFilterboard] = useState([]);
  const [selectedfilteroccupancy, setselectedFilteroccupancy] = useState([]);
  const [bulkstatus, setBulkstatus] = useState({
    stay_open_status: true,
    stay_open_condition: true,
    arrival_open_status: true,
    arrival_open_condition: true,
    departure_open_status: true,
    departure_open_condition: true,
    booking_open_status: true,
    booking_open_condition: true,
  });

  const [open, setOpen] = useState(false);
  const [todaydate, setTodaydate] = useState(moment().format("MMM DD, YYYY"));
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);

  const [dateString1, setDateString1] = useState(
    moment().format("MMM DD, YYYY")
  );
  const firstDayOfNextMonth = moment().add(1, "month").startOf("month");
  const lastDayOfNextMonth = firstDayOfNextMonth.clone().endOf("month");

  const [dateString2, setDateString2] = useState(
    lastDayOfNextMonth.format("MMM DD, YYYY")
  );

  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [clickedDays, setClickedDays] = useState([]);
  const [datefrom, setDateFrom] = useState(null);
  const [dateto, setDateTo] = useState(null);
  const [errors, setError] = useState({});
  const [errorsfilter, setErrorFilter] = useState({});

  const handleCalendarChangeFrom = (selectedDates) => {
    setDateFrom(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDateTo(selectedDates[0]);
  };

  const [opens, setOpens] = useState(false);
  function clickEventHandler() {
    setOpens(!opens);
  }
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  const [tab, setTab] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickprev = async () => {
    let first;
    const currentDate = moment().format("DD-MM-YYYY");

    first = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstfull = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let end = moment(first).endOf("month").format("MMM DD, YYYY");
    let endfull = moment(first).endOf("month").format("DD-MM-YYYY");

    if (
      moment(firstfull, "DD-MM-YYYY").isBefore(
        moment(currentDate, "DD-MM-YYYY")
      )
    ) {
      firstfull = currentDate;
      first = moment(currentDate, "DD-MM-YYYY").format("MMM DD, YYYY");
    }
    setDateString1(first);
    setDateString2(end);

    let rooms = [];
    if (selectedfilterroom.length > 0) {
      for (let i = 0; i < selectedfilterroom.length; i++) {
        let arr = {};
        arr = selectedfilterroom[i].value;
        rooms.push(arr);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let arr = {};
        arr = roomtype[i].value;
        rooms.push(arr);
      }
    }

    let rate = [];
    if (selectedfilterrate.length > 0) {
      for (let i = 0; i < selectedfilterrate.length; i++) {
        let arr = {};
        arr = selectedfilterrate[i].value;
        rate.push(arr);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let arr = {};
        arr = ratelist[i].value;
        rate.push(arr);
      }
    }

    let board = [];
    if (selectedfilterboard.length > 0) {
      for (let i = 0; i < selectedfilterboard.length; i++) {
        let arr = {};
        arr = selectedfilterboard[i].value;
        board.push(arr);
      }
    } else {
      for (let i = 0; i < boardlist.length; i++) {
        let arr = {};
        arr = boardlist[i].value;
        board.push(arr);
      }
    }

    let occupancy = [];
    if (selectedfilteroccupancy.length > 0) {
      for (let i = 0; i < selectedfilteroccupancy.length; i++) {
        let arr = {};
        arr = selectedfilteroccupancy[i].value;
        occupancy.push(arr);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let arr = {};
        arr = occupancy[i].value;
        occupancy.push(arr);
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/condition/open-stay/search`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: rooms,
          rates: rate,
          boards: board,
          occupancy: occupancy,
          from: firstfull,
          to: endfull,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickprev();
      } else {
        setOpenclose(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickprev();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setOpenclose(data);
    // }
  };

  const handleClicknext = async () => {
    let firstnext = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let endnext = moment(firstnext).endOf("month").format("MMM DD, YYYY");
    let firstnextfull = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let endnextfull = moment(firstnext).endOf("month").format("DD-MM-YYYY");

    setDateString1(firstnext);
    setDateString2(endnext);

    let rooms = [];
    if (selectedfilterroom.length > 0) {
      for (let i = 0; i < selectedfilterroom.length; i++) {
        let arr = {};
        arr = selectedfilterroom[i].value;
        rooms.push(arr);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let arr = {};
        arr = roomtype[i].value;
        rooms.push(arr);
      }
    }

    let rate = [];
    if (selectedfilterrate.length > 0) {
      for (let i = 0; i < selectedfilterrate.length; i++) {
        let arr = {};
        arr = selectedfilterrate[i].value;
        rate.push(arr);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let arr = {};
        arr = ratelist[i].value;
        rate.push(arr);
      }
    }

    let board = [];
    if (selectedfilterboard.length > 0) {
      for (let i = 0; i < selectedfilterboard.length; i++) {
        let arr = {};
        arr = selectedfilterboard[i].value;
        board.push(arr);
      }
    } else {
      for (let i = 0; i < boardlist.length; i++) {
        let arr = {};
        arr = boardlist[i].value;
        board.push(arr);
      }
    }

    let occupancy = [];
    if (selectedfilteroccupancy.length > 0) {
      for (let i = 0; i < selectedfilteroccupancy.length; i++) {
        let arr = {};
        arr = selectedfilteroccupancy[i].value;
        occupancy.push(arr);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let arr = {};
        arr = occupancy[i].value;
        occupancy.push(arr);
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/condition/open-stay/search`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: rooms,
          rates: rate,
          boards: board,
          occupancy: occupancy,
          from: firstnextfull,
          to: endnextfull,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClicknext();
      } else {
        setOpenclose(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClicknext();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setOpenclose(data);
    // }
  };

  var loop = new Date(dateString1);
  var end = new Date(dateString2);
  console.log(loop);
  var newDate1 = loop.setDate(loop.getDate() - 1);
  var newDate2 = end.setDate(end.getDate() - 1);
  let arr = [];

  while (loop <= end) {
    var newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(loop);
    let date = moment(loop).format("MMM DD,YYYY");
    arr.push(date);
  }
  let val = arr.length;
  const handleDayClick = (dayOfWeek) => {
    if (selectedWeekdays.includes(dayOfWeek)) {
      if (clickedDays.includes(dayOfWeek)) {
        setClickedDays((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDays((prevClickedDays) => [...prevClickedDays, dayOfWeek]);
      }
    }
  };

  const getRoomtype =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${id}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  },[id]);

  const getRatelist =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}hotel/rate-list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRatelist();
      } else {
        setRatelist(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRatelist();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setRatelist(data);
    // }
  },[handleUnauthorized,id]);

  const getBoardlist =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBoardlist();
      } else {
        setBoardlist(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBoardlist();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setBoardlist(data);
    // }
  },[handleUnauthorized,id]);

  const getOccupancy =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}hotel/occupancies`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getOccupancy();
      } else {
        setOccupancy(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getOccupancy();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setOccupancy(data);
    // }
  },[handleUnauthorized,id]);

  const getlist =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}hotel/condition/open-stay/list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        start_date: moment(dateString1).format("DD-MM-YYYY"),
        end_date: moment(dateString2).format("DD-MM-YYYY"),
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else if (data.length === 0) {
        setOpenclose([]);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlist();
      } else {
        setOpenclose(data.data);
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   if (data.length === 0) {
    //     setOpenclose([]);
    //     setLoading(true);
    //   } else {
    //     setOpenclose(data.data);
    //     setLoading(true);
    //   }
    // }
  },[handleUnauthorized,id,dateString1,dateString2]);

  let handleChangetogg = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setBulkstatus({ ...bulkstatus, [name]: value });
  };
  let handleTab = (val, i) => {
    let newval = [...tab];
    tab[i]["tab"] = val;
    setTab(newval);
  };

  let handleClickroom = (i) => {
    let newval = [...tab];
    tab[i]["click"] = !tab[i]["click"];
    setTab(newval);
  };

  const Bulkupdate = async () => {
    let isFormValid = true;

    const errors = {};
    if (selectedbulkrooms.length === 0) {
      isFormValid = false;

      errors.selectedbulkrooms = "Rooms is required!";
    }

    if (selectedbulkrate.length === 0) {
      isFormValid = false;

      errors.selectedbulkrate = "Rate is required!";
    }
    if (selectedbulkboard.length === 0) {
      isFormValid = false;

      errors.selectedbulkboard = "Board is required!";
    }
    if (selectedbulkoccupancy.length === 0) {
      isFormValid = false;

      errors.selectedbulkoccupancy = "Occupancy is required!";
    }
    if (!datefrom) {
      isFormValid = false;

      errors.datefrom = "From date is required!";
    }
    if (!dateto) {
      isFormValid = false;

      errors.dateto = "To date is required!";
    }

    setError(errors);

    if (isFormValid === true) {
      let exp = [];
      for (let i = 0; i < clickedDays.length; i++) {
        const numericValue = clickedDays[i];
        if (numericValue >= 0 && numericValue < weekdays.length) {
          const weekdayName = weekdays[numericValue];
          exp.push(weekdayName);
        }
      }

      let Rooms = [];
      for (let i = 0; i < selectedbulkrooms.length; i++) {
        Rooms.push(selectedbulkrooms[i].value);
      }
      let rates = [];
      for (let i = 0; i < selectedbulkrate.length; i++) {
        rates.push(selectedbulkrate[i].value);
      }

      let boards = [];
      for (let i = 0; i < selectedbulkboard.length; i++) {
        boards.push(selectedbulkboard[i].value);
      }

      let occupancies = [];
      for (let i = 0; i < selectedbulkoccupancy.length; i++) {
        occupancies.push(selectedbulkoccupancy[i].value);
      }
      const res = await fetch(
        `${Config.apiurl}hotel/condition/open-stay/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            start_date: moment(datefrom).format("DD-MM-YYYY"),
            end_date: moment(dateto).format("DD-MM-YYYY"),
            exception: exp,
            rooms: Rooms,
            rates: rates,
            boards: boards,
            occupancies: occupancies,
            stay_open_status: bulkstatus.stay_open_status,
            stay_open_condition: bulkstatus.stay_open_condition,
            arrival_open_status: bulkstatus.arrival_open_status,
            arrival_open_condition: bulkstatus.arrival_open_condition,
            departure_open_status: bulkstatus.departure_open_status,
            departure_open_condition: bulkstatus.departure_open_condition,
            booking_open_status: bulkstatus.booking_open_status,
            booking_open_condition: bulkstatus.booking_open_condition,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          Bulkupdate();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setClickedDays([]);
          getlist();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Bulkupdate();
      } else {
        console.log("error");
      }
      // const data = await res.json();
      // if (res.status === 200) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   setClickedDays([]);
      //   getlist();
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // }
    }
  };

  useEffect(() => {
    getlist();
  }, [id,getlist]);

  useEffect(() => {
    getRoomtype();
  }, [id,getRoomtype]);

  useEffect(() => {
    getRatelist();
  }, [id,getRatelist]);

  useEffect(() => {
    getBoardlist();
  }, [id,getBoardlist]);

  useEffect(() => {
    getOccupancy();
  }, [id,getOccupancy]);

  useEffect(
    () => {
      const getWeekdays = (startDate, endDate) => {
        const weekdays = [];
        const currentDay = new Date(startDate);
        const lastDay = new Date(endDate);

        while (currentDay <= lastDay) {
          const dayOfWeek = currentDay.getDay();
          if (dayOfWeek >= 0 && dayOfWeek <= 6) {
            weekdays.push(dayOfWeek);
          }
          currentDay.setDate(currentDay.getDate() + 1);
        }

        return weekdays;
      };

      const getWeekdaysBetweenSelectedDates = (datefrom, dateto) => {
        const weekdays = getWeekdays(datefrom, dateto);
        setSelectedWeekdays(weekdays);
        setClickedDays([]);
      };

      if (datefrom && dateto) {
        getWeekdaysBetweenSelectedDates(datefrom, dateto);
      } else {
        setSelectedWeekdays([]);
      }

      const columnNumber =
        window.innerWidth / document.querySelector(".th-element").clientWidth;
      console.log(columnNumber);
      const datas = Math.ceil(columnNumber);
      setColumnsDisplayed(datas);

      for (let i = 0; i < openclose.length; i++) {
        let ar = {};
        ar.tab = "default";
        ar.click = i === 0;
        tab.push(ar);
      }
    },
    [dateto, openclose,datefrom,tab]
  );

  useEffect(() => {
    setselectedBulkrooms(roomtype.map((option) => option));
    setselectedFilterroom(roomtype.map((option) => option));
    setselectedBulkrate(ratelist.map((option) => option));
    setselectedFilterrate(ratelist.map((option) => option));
    setselectedBulkboard(boardlist.map((option) => option));
    setselectedFilterboard(boardlist.map((option) => option));
    setselectedBulkoccupancy(occupancy.map((option) => option));
    setselectedFilteroccupancy(occupancy.map((option) => option));
  }, [roomtype, ratelist, boardlist, occupancy]);


  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const handleClickupdate = (i, ind, indx, index, indexx, name, value) => {
    const updatedValues = [...openclose];
    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "open_conditions"
    ][indexx][name] = !value;
    updatedValues[i]["flag"] = true;

    updatedValues[i]["rates"][ind]["flag"] = true;

    updatedValues[i]["rates"][ind]["hotel_board"][indx]["flag"] = true;

    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "flag"
    ] = true;

    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "open_conditions"
    ][indexx]["flag"] = true;

    setOpenclose(updatedValues);
  };

  const Filter = async () => {
    let isFormValid = true;

    const errors = {};
    if (selectedfilterroom.length === 0) {
      isFormValid = false;

      errors.selectedfilterroom = "This field is required!";
    }
    if (selectedfilterrate.length === 0) {
      isFormValid = false;

      errors.selectedfilterrate = "This field is required!";
    }
    if (selectedfilterboard.length === 0) {
      isFormValid = false;

      errors.selectedfilterboard = "This field is required!";
    }
    if (selectedfilteroccupancy.length === 0) {
      isFormValid = false;

      errors.selectedfilteroccupancy = "This field is required!";
    }

    setErrorFilter(errors);
    if (isFormValid === true) {
      let rooms = [];

      for (let i = 0; i < selectedfilterroom.length; i++) {
        let arr = {};
        arr = selectedfilterroom[i].value;
        rooms.push(arr);
      }

      let rate = [];

      for (let i = 0; i < selectedfilterrate.length; i++) {
        let arr = {};
        arr = selectedfilterrate[i].value;
        rate.push(arr);
      }

      let board = [];

      for (let i = 0; i < selectedfilterboard.length; i++) {
        let arr = {};
        arr = selectedfilterboard[i].value;
        board.push(arr);
      }

      let occupancy = [];
      for (let i = 0; i < selectedfilteroccupancy.length; i++) {
        let arr = {};
        arr = selectedfilteroccupancy[i].value;
        occupancy.push(arr);
      }

      const res = await fetch(
        `${Config.apiurl}hotel/condition/open-stay/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            rooms: rooms,
            rates: rate,
            boards: board,
            occupancy: occupancy,
            from: moment(dateString1).format("DD-MM-YYYY"),
            to: moment(dateString2).format("DD-MM-YYYY"),
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          console.log("error");
        } else if (data.reauth === true) {
          handleUnauthorized();
          Filter();
        } else {
          setOpenclose(data.data);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Filter();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data) {
      //   console.log("error");
      // } else {
      //   setOpenclose(data);
      // }
    }
  };

  const Singlesave = async () => {
    let roomssave = [];
    let newmin = [];
    for (let i = 0; i < openclose.length; i++) {
      if (openclose[i].flag && openclose[i].flag === true) {
        for (let j = 0; j < openclose[i].rates.length; j++) {
          if (
            openclose[i].rates[j].flag &&
            openclose[i].rates[j].flag === true
          ) {
            for (let k = 0; k < openclose[i].rates[j].hotel_board.length; k++) {
              if (
                openclose[i].rates[j].hotel_board[k].flag &&
                openclose[i].rates[j].hotel_board[k].flag === true
              ) {
                for (
                  let l = 0;
                  l < openclose[i].rates[j].hotel_board[k].occupancies.length;
                  l++
                ) {
                  if (
                    openclose[i].rates[j].hotel_board[k].occupancies[l].flag &&
                    openclose[i].rates[j].hotel_board[k].occupancies[l].flag ===
                      true
                  ) {
                    let multiarray = {};
                    multiarray.room = openclose[i].room_id;
                    multiarray.rate = openclose[i].rates[j].rate_id;
                    multiarray.board =
                      openclose[i].rates[j].hotel_board[k].board;
                    multiarray.occupancy =
                      openclose[i].rates[j].hotel_board[k].occupancies[
                        l
                      ].occupancy_id;

                    let newminstay = [];
                    for (
                      let m = 0;
                      m <
                      openclose[i].rates[j].hotel_board[k].occupancies[l]
                        .open_conditions.length;
                      m++
                    ) {
                      let minstayarray = {};
                      if (
                        openclose[i].rates[j].hotel_board[k].occupancies[l]
                          .open_conditions[m].flag &&
                        openclose[i].rates[j].hotel_board[k].occupancies[l]
                          .open_conditions[m].flag === true
                      ) {
                        minstayarray.stay_open_status =
                          openclose[i].rates[j].hotel_board[k].occupancies[
                            l
                          ].open_conditions[m].stay_open_status;
                        minstayarray.arrival_open_status =
                          openclose[i].rates[j].hotel_board[k].occupancies[
                            l
                          ].open_conditions[m].arrival_open_status;
                        minstayarray.departure_open_status =
                          openclose[i].rates[j].hotel_board[k].occupancies[
                            l
                          ].open_conditions[m].departure_open_status;
                        minstayarray.booking_open_status =
                          openclose[i].rates[j].hotel_board[k].occupancies[
                            l
                          ].open_conditions[m].booking_open_status;
                        let formattedDate = moment(
                          `${openclose[i].rates[j].hotel_board[k].occupancies[l].open_conditions[m].year}-${openclose[i].rates[j].hotel_board[k].occupancies[l].open_conditions[m].month}-${openclose[i].rates[j].hotel_board[k].occupancies[l].open_conditions[m].date}`
                        ).format("DD-MM-YYYY");
                        minstayarray.date = formattedDate;
                        newminstay.push(minstayarray);
                      }
                    }
                    multiarray.room_opens = newminstay;
                    newmin.push(multiarray);
                  }
                }
              }
            }
          }
        }
      }
    }
    const res = await fetch(
      `${Config.apiurl}hotel/condition/open-stay/update/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          start_date: moment(dateString1).format("DD-MM-YYYY"),
          end_date: moment(dateString2).format("DD-MM-YYYY"),
          open_conditions: newmin,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Singlesave();
      } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getlist();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Singlesave();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getlist();
    // }
  };

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  console.log(openclose);
  return (
    <div className="page-content">

      <Conditionsheader />
      <h4 className="" style={{ marginLeft: "10px" }}>
        {hotelname}
      </h4>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="condition-top-section">
              <div className="hotel-condition-modify-sec">
                <div className="db-condition-modify-collapse">
                  {/* <span className="card-title text-uppercase fs-13 fw-medium">Modify Conditions</span> */}
                  <span
                    className="db-modify_toggle_btn"
                    onClick={clickEventHandler}
                  >
                    <span className="bx bxs-chevron-down"></span>
                  </span>

                  <button className="modify-button" onClick={clickEventHandler}>
                    Modify Conditions
                  </button>
                </div>
                <div
                  className={
                    opens === true
                      ? "condition-modify-sec-main b_b_1"
                      : "condition-modify-sec-main d-none"
                  }
                >
                  <div className="row g-3 modify-condition-inner">
                    <div className="col-lg-3">
                      <div className="input-group">
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Rooms:
                        </label>
                        <div className="col-md-8">
                          <MultiSelect
                            options={roomtype}
                            value={selectedbulkrooms}
                            onChange={setselectedBulkrooms}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                        </div>
                        <label style={{ color: "red" }}>
                          {errors.selectedbulkrooms}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group">
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Rate List :
                        </label>
                        <div className="col-md-8">
                          <MultiSelect
                            options={ratelist}
                            value={selectedbulkrate}
                            onChange={setselectedBulkrate}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                        </div>
                        <label style={{ color: "red" }}>
                          {errors.selectedbulkrate}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group">
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Boards :
                        </label>
                        <div className="col-md-8">
                          <MultiSelect
                            options={boardlist}
                            value={selectedbulkboard}
                            onChange={setselectedBulkboard}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                        </div>
                        <label style={{ color: "red" }}>
                          {errors.selectedbulkboard}
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group">
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Occupancies :
                        </label>
                        <div className="col-md-6">
                          <MultiSelect
                            options={occupancy}
                            value={selectedbulkoccupancy}
                            onChange={setselectedBulkoccupancy}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                        </div>
                        <label style={{ color: "red" }}>
                          {errors.selectedbulkoccupancy}
                        </label>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-md-2 col-lg-3 col-sm-4 form-calender-add">
                      <form action="#">
                        <div className="input-group">
                          <Flatpickr
                            id="flatpickr-from"
                            className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                            placeholder="FROM"
                            options={{
                              mode: "single",
                              dateFormat: "d M, Y",
                              minDate: "today",
                            }}
                            value={datefrom}
                            onChange={handleCalendarChangeFrom}
                          />
                          <label style={{ color: "red" }}>
                            {errors.datefrom}
                          </label>
                          <span
                            className="form_calendar-style fon mdi mdi-calendar-month-outline"
                            onClick={() => handleCalendarIconClick("from")}
                          ></span>
                        </div>
                      </form>
                    </div>
                    <div className="col-xxl-2 col-sm-4 col-md-2 col-lg-3 form-calender-add">
                      <form action="#">
                        <div className="input-group">
                          <Flatpickr
                            id="flatpickr-to"
                            className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                            placeholder="TO"
                            options={{
                              mode: "single",
                              dateFormat: "d M, Y",
                              minDate: "today",
                            }}
                            value={dateto}
                            onChange={handleCalendarChangeTo}
                          />
                          <label style={{ color: "red" }}>
                            {errors.dateto}
                          </label>
                          <span
                            className="form_calendar-style fon mdi mdi-calendar-month-outline"
                            onClick={() => handleCalendarIconClick("to")}
                          ></span>

                        </div>
                      </form>
                    </div>
                    <div className="col-xxl-8 col-md-8 col-lg-6 col-sm-5">
                      <ul className="modify-week-list">
                        <li
                          className={`modify-week-list-item card-title ${
                            selectedWeekdays.includes(1) ? "green-bg" : ""
                          } ${clickedDays.includes(1) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(1)}
                        >
                          Mon
                        </li>
                        <li
                          className={`modify-week-list-item card-title ${
                            selectedWeekdays.includes(2) ? "green-bg" : ""
                          } ${clickedDays.includes(2) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(2)}
                        >
                          Tue
                        </li>
                        <li
                          className={`modify-week-list-item ${
                            selectedWeekdays.includes(3) ? "green-bg" : ""
                          } ${clickedDays.includes(3) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(3)}
                        >
                          Wed
                        </li>
                        <li
                          className={`modify-week-list-item ${
                            selectedWeekdays.includes(4) ? "green-bg" : ""
                          } ${clickedDays.includes(4) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(4)}
                        >
                          Thu
                        </li>
                        <li
                          className={`modify-week-list-item ${
                            selectedWeekdays.includes(5) ? "green-bg" : ""
                          } ${clickedDays.includes(5) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(5)}
                        >
                          Fri
                        </li>
                        <li
                          className={`modify-week-list-item ${
                            selectedWeekdays.includes(6) ? "green-bg" : ""
                          } ${clickedDays.includes(6) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(6)}
                        >
                          Sat
                        </li>
                        <li
                          className={`modify-week-list-item ${
                            selectedWeekdays.includes(0) ? "green-bg" : ""
                          } ${clickedDays.includes(0) ? "red-bg" : ""}`}
                          onClick={() => handleDayClick(0)}
                        >
                          Sun
                        </li>
                      </ul>
                    </div>
                    <div className="col-xxl-12 col-sm-12">
                      <ul className="modify-open-conditon-list">
                        <li>
                          <div className="form-check condition-status-check">
                            <input
                              className="form-check-input form-check-input-new-1"
                              type="checkbox"
                              id="formCheck6"
                              value={bulkstatus.stay_open_status}
                              name="stay_open_status"
                              checked={
                                bulkstatus.stay_open_status === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                          <div className="condition-title">Stay</div>
                          <div
                            className="form-check form-switch form-switch-danger condition-active-status"
                            dir="ltr"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input open-close-check"
                              id="inlineswitch5"
                              value={bulkstatus.stay_open_condition}
                              name="stay_open_condition"
                              checked={
                                bulkstatus.stay_open_condition === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-check condition-status-check">
                            <input
                              className="form-check-input form-check-input-new-1 "
                              type="checkbox"
                              id="formCheck6"
                              value={bulkstatus.arrival_open_status}
                              name="arrival_open_status"
                              checked={
                                bulkstatus.arrival_open_status === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                          <div className="condition-title">Arrival</div>
                          <div
                            className="form-check form-switch form-switch-danger condition-active-status"
                            dir="ltr"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input open-close-check"
                              id="inlineswitch5"
                              value={bulkstatus.arrival_open_condition}
                              name="arrival_open_condition"
                              checked={
                                bulkstatus.arrival_open_condition === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-check condition-status-check">
                            <input
                              className="form-check-input form-check-input-new-1"
                              type="checkbox"
                              id="formCheck6"
                              value={bulkstatus.departure_open_status}
                              name="departure_open_status"
                              checked={
                                bulkstatus.departure_open_status === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                          <div className="condition-title">Departure</div>
                          <div
                            className="form-check form-switch form-switch-danger condition-active-status"
                            dir="ltr"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input open-close-check"
                              id="inlineswitch5"
                              value={bulkstatus.departure_open_condition}
                              name="departure_open_condition"
                              checked={
                                bulkstatus.departure_open_condition === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-check condition-status-check">
                            <input
                              className="form-check-input form-check-input-new-1"
                              type="checkbox"
                              id="formCheck6"
                              value={bulkstatus.booking_open_status}
                              name="booking_open_status"
                              checked={
                                bulkstatus.booking_open_status === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                          <div className="condition-title">Booking Date</div>
                          <div
                            className="form-check form-switch form-switch-danger condition-active-status"
                            dir="ltr"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input open-close-check"
                              id="inlineswitch5"
                              value={bulkstatus.booking_open_condition}
                              name="booking_open_condition"
                              checked={
                                bulkstatus.booking_open_condition === true
                                  ? true
                                  : false
                              }
                              onChange={handleChangetogg}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-xxl-12 col-sm-12">
                      <div className="modify-period-submit">
                        <button
                          type="button"
                          className="btn db-save-button condition-save-btn"
                          onClick={Bulkupdate}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row project-wrapper price-wrapper">
        <div className="row dashboard-top-tab-style">
          <div className="col-xxl-12 dashboard-availability-type">
            <div className="card availabilty-filter-card">
              <div className="card-header">
                <div className="row align-items-center gy-3">
                  <div className="price-filter-sec">
                    <form>
                      <div className="row g-3">
                        <div className="col-xxl-1 col-lg-1 col-md-1 col-sm-6">
                          <h5 className="card-title db-title-header filter-head">
                            {" "}
                            Filter
                          </h5>
                        </div>
                        <div className="col-md-3 extrabed-rates-sec">
                          <MultiSelect
                            options={roomtype}
                            value={selectedfilterroom}
                            onChange={setselectedFilterroom}
                            labelledBy="Select"
                            selectionType="counter"
                            className="multi_select_op"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                          <label style={{ color: "red" }}>
                            {errorsfilter.selectedfilterroom}
                          </label>
                        </div>
                        <div className="col-md-2 extrabed-rates-sec">
                          <MultiSelect
                            options={ratelist}
                            value={selectedfilterrate}
                            onChange={setselectedFilterrate}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                          <label style={{ color: "red" }}>
                            {errorsfilter.selectedfilterrate}
                          </label>
                        </div>
                        <div className="col-md-2 extrabed-rates-sec">
                          <MultiSelect
                            options={boardlist}
                            value={selectedfilterboard}
                            onChange={setselectedFilterboard}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                          <label style={{ color: "red" }}>
                            {errorsfilter.selectedfilterboard}
                          </label>
                        </div>
                        <div className="col-md-2 extrabed-rates-sec">
                          <MultiSelect
                            options={occupancy}
                            value={selectedfilteroccupancy}
                            onChange={setselectedFilteroccupancy}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                          <label style={{ color: "red" }}>
                            {errorsfilter.selectedfilteroccupancy}
                          </label>
                        </div>
                        <div className="col-xxl-1 col-md-1 col-sm-4">
                          <button
                            type="button"
                            className="btn db-common-filter"
                            style={{ color: "white", backgroundColor: "black" }}
                            onClick={Filter}
                          >
                            <span className="bx bx-filter-alt"></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-header price-bottom-header">
                <div className="row available_filter_calender">
                  <span className="col-xxl-6 available_filter_calender_sec">
                    {dateString1 === todaydate ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        title="Previous month"
                        aria-pressed="false"
                        className="fc-prev-button btn btn-availability-arrow"
                        onClick={handleClickprev}
                      >
                        <span className="fa fa-chevron-left"></span>
                      </button>
                    )}
                    <input
                      type="text"
                      value={`${dateString1} - ${dateString2}`}
                      style={{
                        marginTop: "10px",
                        border: "0px",
                        width: "200px",
                      }}
                    />
                    <button
                      type="button"
                      title="Next month"
                      aria-pressed="false"
                      className="fc-next-button btn btn-availability-arrow"
                      onClick={handleClicknext}
                    >
                      <span className="fa fa-chevron-right"></span>
                    </button>
                    <span className="calendar-show-count">
                      A total of
                      <span className="calendar-date-count">
                        {" "}
                        {columnsDisplayed - 4} days
                      </span>{" "}
                      are displayed on this screen
                    </span>
                  </span>
                  <span className="col-xxl-6 available_update_btn">
                    <button
                      type="button"
                      className="btn db-save-button condition-save-btn"
                      onClick={Singlesave}
                    >
                      Save
                    </button>
                  </span>
                </div>
              </div>
              <div className="">
                <div className="table-responsive">
                  <table className="table room-price-table table-bordered table-nowrap align-middle mb-0">
                    <thead>
                      <tr>
                        <th scope="col" className="stickyclass">
                          <p
                            className="room-available-filtersec-head"
                            style={{ width: "200px" }}
                          >
                            Rooms
                          </p>
                        </th>
                        {arr.map((user, index) => {
                          return (
                            <th scope="col" key={index} className="th-element">
                              <div
                                className="room-avail-date"
                                style={{ width: "70px" }}
                              >
                                <span className="avail-month-name">
                                  {dayjs(user).format("MMM")}
                                </span>
                                <br></br>
                                <span className="avail-date-bold">
                                  {dayjs(user).format("DD")}
                                </span>
                                <br></br>
                                <span className="avail-week-name">
                                  {dayjs(user).format("ddd")}
                                </span>
                                <br></br>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {loading === true ? (
                      <>
                        {openclose.length > 0 ? (
                          openclose.map((open, i) => {
                            return (
                              <tbody key={i}>
                                <tr>
                                  <th
                                    colSpan={val + 1}
                                    className="price-inner-table stickyclass"
                                  >
                                    <table className="table room-price-inner-table table-bordered table-nowrap align-middle mb-0">
                                      <tbody>
                                        <tr>
                                          <td className=" ">
                                            <h6 className="price-sec-room-title stickyclass_11">
                                              <span
                                                className="room-default-expand"
                                                id="room1"
                                                onClick={(e) =>
                                                  handleClickroom(i)
                                                }
                                              >
                                                <span className="room-price-expanded ri-arrow-down-s-line text-dark"></span>
                                                {open.room_name}
                                              </span>
                                              <span className="default_price_show">
                                                <ul
                                                  className="price_show_select"
                                                  id="price_show_type"
                                                >
                                                  {tab.map((ta, idx) => {
                                                    return (
                                                      <div key={idx}>
                                                        {i === idx ? (
                                                          <>
                                                            <li
                                                              className={
                                                                ta.tab ===
                                                                "default"
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              data-value="default"
                                                              onClick={(e) =>
                                                                handleTab(
                                                                  "default",
                                                                  idx
                                                                )
                                                              }
                                                            >
                                                              Default View
                                                            </li>
                                                            <li
                                                              className={
                                                                ta.tab ===
                                                                "extend"
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              data-value="extend"
                                                              onClick={(e) =>
                                                                handleTab(
                                                                  "extend",
                                                                  idx
                                                                )
                                                              }
                                                            >
                                                              Extended View
                                                            </li>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    );
                                                  })}
                                                </ul>
                                              </span>
                                              <input
                                                type="hidden"
                                                name="list_value"
                                                id="li_change_value"
                                                value="default"
                                              />
                                              <input
                                                type="hidden"
                                                name="list_room_id"
                                                id="list_room_id"
                                                value="room1"
                                              />
                                            </h6>
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              {open.rates.length > 0 ? (
                                        <>
                                {tab[i]?.click === true ? (
                                  <>
                                    {tab[i]?.tab === "extend" ? (
                                      <>
                                        {open.rates.map((rate, ind) => {
                                          return (

                                            <>
                                              {rate.hotel_board.map(
                                                (board, indx) => {
                                                  return (
                                                    <>
                                                      {board.occupancies.map(
                                                        (cond, index) => {
                                                          return (
                                                            <tr
                                                              key={index}
                                                              className="room-price-expanded-sec"
                                                              id="room1_default"
                                                            >
                                                              <th
                                                                colSpan="col"
                                                                className="stickyclass"
                                                                style={{
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                <span
                                                                  className="price-td-rates"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  {
                                                                    rate.internal_name
                                                                  }{" "}
                                                                </span>
                                                                <span className="price-td-boards">
                                                                  {board.name}
                                                                </span>
                                                                <span
                                                                  className="price-td-occupancy-in"
                                                                  style={{
                                                                    float:
                                                                      "right",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  {cond.adult +
                                                                    cond.child >
                                                                    4 && (
                                                                    <>
                                                                      {cond.adult >
                                                                        0 && (
                                                                        <>
                                                                          <img
                                                                            src="images/icon/user.png"
                                                                            alt="Adult"
                                                                            style={{
                                                                              paddingLeft:
                                                                                "1px",
                                                                            }}
                                                                          />
                                                                          (
                                                                          {
                                                                            cond.adult
                                                                          }
                                                                          )
                                                                        </>
                                                                      )}
                                                                      {cond.child >
                                                                        0 && (
                                                                        <>
                                                                          {" "}
                                                                          X{" "}
                                                                          <img
                                                                            src="images/icon/user.png"
                                                                            alt="Child"
                                                                            className="child-icon"
                                                                            style={{
                                                                              marginLeft:
                                                                                "2px",
                                                                            }}
                                                                          />
                                                                          (
                                                                          {
                                                                            cond.child
                                                                          }
                                                                          )
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}

                                                                  {cond.adult +
                                                                    cond.child <=
                                                                    4 && (
                                                                    <>
                                                                      {Array.from(
                                                                        {
                                                                          length:
                                                                            cond.adult,
                                                                        },
                                                                        (
                                                                          _,
                                                                          index
                                                                        ) => (
                                                                          <img
                                                                            key={`adult-${index}`}
                                                                            src="images/icon/user.png"
                                                                            alt="Adult"
                                                                            style={{
                                                                              paddingLeft:
                                                                                "1px",
                                                                            }}
                                                                          />
                                                                        )
                                                                      )}
                                                                      {Array.from(
                                                                        {
                                                                          length:
                                                                            cond.child,
                                                                        },
                                                                        (
                                                                          _,
                                                                          index
                                                                        ) => (
                                                                          <img
                                                                            key={`child-${index}`}
                                                                            src="images/icon/user.png"
                                                                            alt="Child"
                                                                            className="child-icon"
                                                                            style={{
                                                                              marginLeft:
                                                                                "2px",
                                                                            }}
                                                                          />
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </span>
                                                                {/* <div className='openclose_rooms_icon'>
                                                                                    {cond.adult?
                                                                                    <>
                                                                                    <span className="price-td-occupancy-in">
                                                                                        <img src="images/icon/user.png" />
                                                                                        
                                                                                    </span>
                                                                                  (<span  className='mt-2'>{cond.adult}</span>)
                                                                                  </>
                                                                                    :""}
                                                                                      {cond.child?
                                                                                      <>
                                                                                      
                                                                                      <span className="price-td-occupancy-in ml_1">
                                                                                      X
                                                                                      <img className="child-icon ml_1" src="images/icon/user.png"/>
                                                                                          
                                                                                      </span>
                                                                                      (<span className='mt-2'>{cond.child}</span>)
                                                                                      </>
                                                                                      :""}
                                                                                  </div> */}
                                                              </th>
                                                              {cond.open_conditions.map(
                                                                (
                                                                  con,
                                                                  indexx
                                                                ) => {
                                                                  return (
                                                                    <th
                                                                      className="room-condition-td"
                                                                      key={
                                                                        indexx
                                                                      }
                                                                    >
                                                                      <div className="condition_outer">
                                                                        <div className="room-condition-cat">
                                                                          <ul className="room-condition-cat-list">
                                                                            <li
                                                                              name="stay_open_status"
                                                                              onClick={(
                                                                                e
                                                                              ) =>
                                                                                handleClickupdate(
                                                                                  i,
                                                                                  ind,
                                                                                  indx,
                                                                                  index,
                                                                                  indexx,
                                                                                  "stay_open_status",
                                                                                  con.stay_open_status
                                                                                )
                                                                              }
                                                                              value={
                                                                                con.stay_open_status
                                                                              }
                                                                            >
                                                                              ST
                                                                              <p
                                                                                className={`db-condition-list-status ${
                                                                                  con.stay_open_status
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                                }`}
                                                                              ></p>
                                                                            </li>
                                                                            <li
                                                                              name="arrival_open_status"
                                                                              onClick={(
                                                                                e
                                                                              ) =>
                                                                                handleClickupdate(
                                                                                  i,
                                                                                  ind,
                                                                                  indx,
                                                                                  index,
                                                                                  indexx,
                                                                                  "arrival_open_status",
                                                                                  con.arrival_open_status
                                                                                )
                                                                              }
                                                                              value={
                                                                                con.arrival_open_status
                                                                              }
                                                                            >
                                                                              AR
                                                                              <p
                                                                                className={`db-condition-list-status ${
                                                                                  con.arrival_open_status
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                                }`}
                                                                              ></p>
                                                                            </li>
                                                                          </ul>
                                                                        </div>
                                                                        <div className="room-condition-cat">
                                                                          <ul className="room-condition-cat-list">
                                                                            <li
                                                                              name="departure_open_status"
                                                                              onClick={(
                                                                                e
                                                                              ) =>
                                                                                handleClickupdate(
                                                                                  i,
                                                                                  ind,
                                                                                  indx,
                                                                                  index,
                                                                                  indexx,
                                                                                  "departure_open_status",
                                                                                  con.departure_open_status
                                                                                )
                                                                              }
                                                                              value={
                                                                                con.departure_open_status
                                                                              }
                                                                            >
                                                                              DE
                                                                              <p
                                                                                className={`db-condition-list-status ${
                                                                                  con.departure_open_status
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                                }`}
                                                                              ></p>
                                                                            </li>
                                                                            <li
                                                                              name="booking_open_status"
                                                                              onClick={(
                                                                                e
                                                                              ) =>
                                                                                handleClickupdate(
                                                                                  i,
                                                                                  ind,
                                                                                  indx,
                                                                                  index,
                                                                                  indexx,
                                                                                  "booking_open_status",
                                                                                  con.booking_open_status
                                                                                )
                                                                              }
                                                                              value={
                                                                                con.booking_open_status
                                                                              }
                                                                            >
                                                                              BO
                                                                              <p
                                                                                className={`db-condition-list-status ${
                                                                                  con.booking_open_status
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                                }`}
                                                                              ></p>
                                                                            </li>
                                                                          </ul>
                                                                        </div>
                                                                      </div>
                                                                    </th>
                                                                  );
                                                                }
                                                              )}
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}

                                              <tr className="span_separate"></tr>
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {open.rates.map((rate, ind) => {
                                          return (
                                            <>
                                              {rate.hotel_board.map(
                                                (board, indx) => {
                                                  return (
                                                    <tr
                                                      key={indx}
                                                      className="room-price-expanded-sec"
                                                      id="room1_default"
                                                    >
                                                      {board.occupancies.map(
                                                        (cond, index) => {
                                                          return (
                                                            <>
                                                              {index === 0 ? (
                                                                <>
                                                                  <th
                                                                    colSpan="col"
                                                                    className="stickyclass"
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      className="price-td-rates"
                                                                      style={{
                                                                        paddingLeft:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        rate.internal_name
                                                                      }{" "}
                                                                    </span>
                                                                    <span className="price-td-boards">
                                                                      {
                                                                        board.name
                                                                      }
                                                                    </span>
                                                                    <span
                                                                      className="price-td-occupancy-in"
                                                                      style={{
                                                                        float:
                                                                          "right",
                                                                        paddingRight:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      {cond.adult +
                                                                        cond.child >
                                                                        4 && (
                                                                        <>
                                                                          {cond.adult >
                                                                            0 && (
                                                                            <>
                                                                              <img
                                                                                src="images/icon/user.png"
                                                                                alt="Adult"
                                                                                style={{
                                                                                  paddingLeft:
                                                                                    "1px",
                                                                                }}
                                                                              />
                                                                              (
                                                                              {
                                                                                cond.adult
                                                                              }
                                                                              )
                                                                            </>
                                                                          )}
                                                                          {cond.child >
                                                                            0 && (
                                                                            <>
                                                                              {" "}
                                                                              X{" "}
                                                                              <img
                                                                                src="images/icon/user.png"
                                                                                alt="Child"
                                                                                className="child-icon"
                                                                                style={{
                                                                                  marginLeft:
                                                                                    "2px",
                                                                                }}
                                                                              />
                                                                              (
                                                                              {
                                                                                cond.child
                                                                              }
                                                                              )
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}

                                                                      {cond.adult +
                                                                        cond.child <=
                                                                        4 && (
                                                                        <>
                                                                          {Array.from(
                                                                            {
                                                                              length:
                                                                                cond.adult,
                                                                            },
                                                                            (
                                                                              _,
                                                                              index
                                                                            ) => (
                                                                              <img
                                                                                key={`adult-${index}`}
                                                                                src="images/icon/user.png"
                                                                                alt="Adult"
                                                                                style={{
                                                                                  paddingLeft:
                                                                                    "1px",
                                                                                }}
                                                                              />
                                                                            )
                                                                          )}
                                                                          {Array.from(
                                                                            {
                                                                              length:
                                                                                cond.child,
                                                                            },
                                                                            (
                                                                              _,
                                                                              index
                                                                            ) => (
                                                                              <img
                                                                                key={`child-${index}`}
                                                                                src="images/icon/user.png"
                                                                                alt="Child"
                                                                                className="child-icon"
                                                                                style={{
                                                                                  marginLeft:
                                                                                    "2px",
                                                                                }}
                                                                              />
                                                                            )
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </span>
                                                                  </th>
                                                                  {cond.open_conditions.map(
                                                                    (
                                                                      con,
                                                                      indexx
                                                                    ) => {
                                                                      return (
                                                                        <th
                                                                          className="room-condition-td"
                                                                          key={
                                                                            indexx
                                                                          }
                                                                        >
                                                                          <div className="condition_outer">
                                                                            <div className="room-condition-cat">
                                                                              <ul className="room-condition-cat-list">
                                                                                <li
                                                                                  name="stay_open_status"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleClickupdate(
                                                                                      i,
                                                                                      ind,
                                                                                      indx,
                                                                                      index,
                                                                                      indexx,
                                                                                      "stay_open_status",
                                                                                      con.stay_open_status
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  ST
                                                                                  <p
                                                                                    className={`db-condition-list-status ${
                                                                                      con.stay_open_status
                                                                                        ? "active"
                                                                                        : "inactive"
                                                                                    }`}
                                                                                  ></p>
                                                                                </li>
                                                                                <li
                                                                                  name="arrival_open_status"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleClickupdate(
                                                                                      i,
                                                                                      ind,
                                                                                      indx,
                                                                                      index,
                                                                                      indexx,
                                                                                      "arrival_open_status",
                                                                                      con.arrival_open_status
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  AR
                                                                                  <p
                                                                                    className={`db-condition-list-status ${
                                                                                      con.arrival_open_status
                                                                                        ? "active"
                                                                                        : "inactive"
                                                                                    }`}
                                                                                  ></p>
                                                                                </li>
                                                                              </ul>
                                                                            </div>
                                                                            <div className="room-condition-cat">
                                                                              <ul className="room-condition-cat-list">
                                                                                <li
                                                                                  name="departure_open_status"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleClickupdate(
                                                                                      i,
                                                                                      ind,
                                                                                      indx,
                                                                                      index,
                                                                                      indexx,
                                                                                      "departure_open_status",
                                                                                      con.departure_open_status
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  DE
                                                                                  <p
                                                                                    className={`db-condition-list-status ${
                                                                                      con.departure_open_status
                                                                                        ? "active"
                                                                                        : "inactive"
                                                                                    }`}
                                                                                  ></p>
                                                                                </li>
                                                                                <li
                                                                                  name="booking_open_status"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleClickupdate(
                                                                                      i,
                                                                                      ind,
                                                                                      indx,
                                                                                      index,
                                                                                      indexx,
                                                                                      "booking_open_status",
                                                                                      con.booking_open_status
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  BO
                                                                                  <p
                                                                                    className={`db-condition-list-status ${
                                                                                      con.booking_open_status
                                                                                        ? "active"
                                                                                        : "inactive"
                                                                                    }`}
                                                                                  ></p>
                                                                                </li>
                                                                              </ul>
                                                                            </div>
                                                                          </div>
                                                                        </th>
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  );
                                                }
                                              )}
                                              <tr className="span_separate"></tr>
                                            </>
                                          );
                                        })}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                </>
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={100}
                                        className="rate_col_clr"
                                      >
                                        Please add the rate plan to the{" "}
                                        <b>{open.room_name}</b> at the{" "}
                                        <b>{hotelname}</b> Hotel.
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              style={{ textAlign: "center" }}
                              colSpan={columnsDisplayed - 3}
                            >
                              <Nodatafound />
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td
                          style={{ textAlign: "center" }}
                          colSpan={columnsDisplayed - 3}
                        >
                          <Loading />
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Openclose;
