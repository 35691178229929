import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import Commonheader from "../Commonheader";
import Date from "../Date";
import Footer from "../Footer";
import { useAuthHandling } from "../useAuthHandling";
import HotelOverviews from "./HotelOverviews";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import Loading from "../../Loading";

function HotelDbcomparison() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [initial, setInitial] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [original, setOriginal] = useState([]);
  const [roomnight, setRoomnight] = useState([]);
  const [upselldata, setUpselldata] = useState([]);
  const [loyaltydata, setLoyaltydata] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [isDefaultRange, setIsDefaultRange] = useState(true);
  const [list,setList] = useState([]);
  const [listall, setListall] = useState([]);
  const [currencycode, setCurrencycode] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [selectedcurrency,setSelectedcurrency]=useState({});
  const [country, setCountry] = useState([]);

  const [ip, setIp] = useState("");
  const [countrycode, setCountrycode] = useState("");


  const [initialcurrent, setInitialcurrent] = useState(0);
  const [initialprev, setInitialprev] = useState(0);

  const [cancelledcurrent, setCancelledcurrent] = useState(0);
  const [cancelledprev, setCancelledprev] = useState(0);

  const [originalcurrent, setOriginalcurrent] = useState(0);
  const [originalprev, setOriginalprev] = useState(0);

  const [roomnightcurrent, setRoomnightcurrent] = useState(0);
  const [roomnightprev, setRoomnightprev] = useState(0);

  const [dateRangecurrent, setDateRangecurrent] = useState(0);
  const [dateRangeprev, setDateRangeprev] = useState(0);

  const [loyaltycurrent, setLoyaltycurrent] = useState(0);
  const [loyaltyprev, setLoyaltyprev] = useState(0);

  const [upsellcurrent, setUpsellcurrent] = useState(0);
  const [upsellprev, setUpsellprev] = useState(0);
  const [loader, setLoader] = useState(false);


  const handleCalendarChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateRange(selectedDates);
      setIsDefaultRange(false);
      Search(selectedDates); 
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const Search = async (selectedDates) => {

    const fromDate = moment(selectedDates[0]).format("DD-MM-YYYY");
    const untilDate = moment(selectedDates[1]).format("DD-MM-YYYY");

      const res = await fetch(
        `${Config.apiurl}reservation-comparision/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotel_selectid,
            from:fromDate,
            until:untilDate
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
        } else {
          // setInitial(data.data?.intitial_reservations);
          // setCancelled(data.data.cancelled_reservations);
          // setOriginal(data.data.original_reservations);
          // setRoomnight(data.data.room_night_data);
          // setLoyaltydata(data.data.loyalty_data);
          // setUpselldata(data.data.upsell_reservations);
          setList(data.data);
          setListall(data.data);
          setLoader(true);

        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Search();
      } else {
        console.log("error");
      }
  };
  

  const getData =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}reservation-comparision`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        // setInitial(data.data?.intitial_reservations);
        // setCancelled(data.data.cancelled_reservations);
        // setOriginal(data.data.original_reservations);
        // setRoomnight(data.data.room_night_data);
        // setUpselldata(data.data.upsell_reservations);
        // setLoyaltydata(data.data.loyalty_data);
        setList(data.data);
        setListall(data.data);
        setLoader(true);

      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  // function processDBValue(dbValue) {
  //   if (dbValue === null || dbValue === undefined) {
  //     return "";
  //   }
  //   dbValue = dbValue.toString();
  //   const numericValue = parseFloat(dbValue.replace(/,/g, ""));

  //   const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

  //   return result.endsWith(".00") ? result.slice(0, -3) : result;
  // }
  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    
    dbValue = dbValue.toString();
    let numericValue = parseFloat(dbValue.replace(/,/g, ""));
    
    let result = numericValue.toFixed(2);
    
    return result;
  }

  function processDBValuenew(dbValue) {
    // Ensure dbValue is not null or undefined
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));

    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }
  useEffect(() => {
    const startOfMonth = moment().startOf('month').toDate();
    
    const today = moment().toDate();
  
    setDateRange([startOfMonth, today]);
  }, []);

  const currentMonthDays = () => {
    return moment().date(); 
  };

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    getData();
  }, [id,getData]);


  const getcurrency = async (e) => {

    const res = await fetch(`${Config.apiurl}currencies-select`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCurrency(data);
    }
  }

  const getcountry = async (e) => {

    const res = await fetch(`${Config.apiurl}countries`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountry(data);
    }
  }

  useEffect(()=>{
    getcurrency();
    getcountry();
  },[])

  useEffect(() => {
    if (country && countrycode) {
      for (let i = 0; i < country.length; i++) {
        if (country[i].alpha_code2 === countrycode) {
          if (Object.keys(selectedcurrency).length === 0) {
           setSelectedcurrency(country[i].currencydata);
           setCurrencycode(country[i].currencydata.code);
           sessionStorage.setItem("dash_select_currency", JSON.stringify(country[i].currencydata)); 
           sessionStorage.setItem("dash_select_currency_code", country[i].currencydata.code); 

          }

        }
      }
    }
  }, [country, countrycode, selectedcurrency]);




  function handleselect(data){
    setSelectedcurrency(data);
    setCurrencycode(data.code);
    sessionStorage.setItem("dash_select_currency", JSON.stringify(data)); 
    sessionStorage.setItem("dash_select_currency_code", data.code); 

  }


  useEffect(()=>{
    if(listall.intitial_reservations){
      let av=listall.intitial_reservations;
      let cance=listall.cancelled_reservations
      let or=listall.original_reservations
      let lo=listall.loyalty_data
      let ro=listall.room_night_data
      let up=listall.upsell_reservations
      let averagecurrent=0;
      let cancelcurrent=0;
      let orgincurrent=0;
      let loyalcurrent=0;
      let roomcurrent=0;
      let upcurrent=0;
      let averageprev=0;
      let cancelprev=0;
      let orginprev=0;
      let loyalprev=0;
      let roommprev=0;
      let upprev=0;

      
        if(av.current.reservation_count>0){
        const fetchPromises = av.current.reservation_rate.map(async (room) => {
          if (currencycode) {
              const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
              
              try {
                  const response = await fetch(url);
                  const data = await response.json();

                  if (Object.hasOwn(data.rates, currencycode)) {
                      const exchangeRate = data.rates[currencycode];
                      return processDBValuenew(room.rate) * exchangeRate;
                  } else {
                      throw new Error("Exchange rate not found");
                  }
              } catch (error) {
                  console.error("Error:", error);
                  return 0;  // If there's an error, return 0 for this rate
              }
          }
          return 0;
       });

      // Wait for all fetches to complete and then calculate the total average
      Promise.all(fetchPromises).then((results) => {
          // Sum up all the converted rates
          averagecurrent = results.reduce((acc, val) => acc + val, 0);
          setInitialcurrent(averagecurrent);
      });
        }
        else{
          setInitialcurrent(0);
        }

          if(av.previous.reservation_count>0){
          const fetchPromises12 = av.previous.reservation_rate.map(async (room) => {
            if (currencycode) {
                const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                
                try {
                    const response = await fetch(url);
                    const data = await response.json();
  
                    if (Object.hasOwn(data.rates, currencycode)) {
                        const exchangeRate = data.rates[currencycode];
                        return processDBValuenew(room.rate) * exchangeRate;
                    } else {
                        throw new Error("Exchange rate not found");
                    }
                } catch (error) {
                    console.error("Error:", error);
                    return 0;  // If there's an error, return 0 for this rate
                }
            }
            return 0;
         });
  
        // Wait for all fetches to complete and then calculate the total average
        Promise.all(fetchPromises12).then((results) => {
            // Sum up all the converted rates
            averageprev = results.reduce((acc, val) => acc + val, 0);
            setInitialprev(averageprev);
        });
          }
          else{
            setInitialprev(0);
          }

          if(cance.current.reservation_count>0){
            const fetchPromises2 = cance.current.reservation_rate.map(async (room) => {
              if (currencycode) {
                  const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                  
                  try {
                      const response = await fetch(url);
                      const data = await response.json();
    
                      if (Object.hasOwn(data.rates, currencycode)) {
                          const exchangeRate = data.rates[currencycode];
                          return processDBValuenew(room.rate) * exchangeRate;
                      } else {
                          throw new Error("Exchange rate not found");
                      }
                  } catch (error) {
                      console.error("Error:", error);
                      return 0;  // If there's an error, return 0 for this rate
                  }
              }
              return 0;
           });
    
          // Wait for all fetches to complete and then calculate the total average
          Promise.all(fetchPromises2).then((results) => {
              // Sum up all the converted rates
              cancelcurrent = results.reduce((acc, val) => acc + val, 0);
              setCancelledcurrent(cancelcurrent);
          });
            }
            else{
              setCancelledcurrent(0);
            }
    
              if(cance.previous.reservation_count>0){
              const fetchPromises21 = cance.previous.reservation_rate.map(async (room) => {
                if (currencycode) {
                    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                    
                    try {
                        const response = await fetch(url);
                        const data = await response.json();
      
                        if (Object.hasOwn(data.rates, currencycode)) {
                            const exchangeRate = data.rates[currencycode];
                            return processDBValuenew(room.rate) * exchangeRate;
                        } else {
                            throw new Error("Exchange rate not found");
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        return 0;  // If there's an error, return 0 for this rate
                    }
                }
                return 0;
             });
      
            // Wait for all fetches to complete and then calculate the total average
            Promise.all(fetchPromises21).then((results) => {
                // Sum up all the converted rates
                cancelprev = results.reduce((acc, val) => acc + val, 0);
                setCancelledprev(cancelprev);
            });
              }
              else{
                setCancelledprev(0);
              }


          if(or.current.reservation_count>0){
            const fetchPromises3 = or.current.reservation_rate.map(async (room) => {
              if (currencycode) {
                  const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                  
                  try {
                      const response = await fetch(url);
                      const data = await response.json();
    
                      if (Object.hasOwn(data.rates, currencycode)) {
                          const exchangeRate = data.rates[currencycode];
                          return processDBValuenew(room.rate) * exchangeRate;
                      } else {
                          throw new Error("Exchange rate not found");
                      }
                  } catch (error) {
                      console.error("Error:", error);
                      return 0;  // If there's an error, return 0 for this rate
                  }
              }
              return 0;
            });
    
          // Wait for all fetches to complete and then calculate the total average
          Promise.all(fetchPromises3).then((results) => {
              // Sum up all the converted rates
              orgincurrent = results.reduce((acc, val) => acc + val, 0);
              setOriginalcurrent(orgincurrent);
          });
            }
            else{
              setOriginalcurrent(0);
            }
    
              if(or.previous.reservation_count>0){
              const fetchPromises31 = or.previous.reservation_rate.map(async (room) => {
                if (currencycode) {
                    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                    
                    try {
                        const response = await fetch(url);
                        const data = await response.json();
      
                        if (Object.hasOwn(data.rates, currencycode)) {
                            const exchangeRate = data.rates[currencycode];
                            return processDBValuenew(room.rate) * exchangeRate;
                        } else {
                            throw new Error("Exchange rate not found");
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        return 0;  // If there's an error, return 0 for this rate
                    }
                }
                return 0;
              });
      
            // Wait for all fetches to complete and then calculate the total average
            Promise.all(fetchPromises31).then((results) => {
                // Sum up all the converted rates
                orginprev = results.reduce((acc, val) => acc + val, 0);
                setOriginalprev(orginprev);
            });
              }
              else{
                setOriginalprev(0);
              }




          if(lo.current.reservation_count>0){
            const fetchPromises4 = lo.current.reservation_rate.map(async (room) => {
              if (currencycode) {
                  const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                  
                  try {
                      const response = await fetch(url);
                      const data = await response.json();
    
                      if (Object.hasOwn(data.rates, currencycode)) {
                          const exchangeRate = data.rates[currencycode];
                          return processDBValuenew(room.rate) * exchangeRate;
                      } else {
                          throw new Error("Exchange rate not found");
                      }
                  } catch (error) {
                      console.error("Error:", error);
                      return 0;  // If there's an error, return 0 for this rate
                  }
              }
              return 0;
            });
    
          // Wait for all fetches to complete and then calculate the total average
          Promise.all(fetchPromises4).then((results) => {
              // Sum up all the converted rates
              loyalcurrent = results.reduce((acc, val) => acc + val, 0);
              setLoyaltycurrent(loyalcurrent);
          });
            }
            else{
              setLoyaltycurrent(0);
            }
    
              if(lo.previous.reservation_count>0){
              const fetchPromises41 = lo.previous.reservation_rate.map(async (room) => {
                if (currencycode) {
                    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                    
                    try {
                        const response = await fetch(url);
                        const data = await response.json();
      
                        if (Object.hasOwn(data.rates, currencycode)) {
                            const exchangeRate = data.rates[currencycode];
                            return processDBValuenew(room.rate) * exchangeRate;
                        } else {
                            throw new Error("Exchange rate not found");
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        return 0;  // If there's an error, return 0 for this rate
                    }
                }
                return 0;
              });
      
            // Wait for all fetches to complete and then calculate the total average
            Promise.all(fetchPromises41).then((results) => {
                // Sum up all the converted rates
                loyalprev = results.reduce((acc, val) => acc + val, 0);
                setLoyaltyprev(loyalprev);
            });
              }
              else{
                setLoyaltyprev(0);
              }



        if(ro.current.room_nights>0){
          const fetchPromises5 = ro.current.average_room_rate.map(async (room) => {
            if (currencycode) {
                const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                
                try {
                    const response = await fetch(url);
                    const data = await response.json();
  
                    if (Object.hasOwn(data.rates, currencycode)) {
                        const exchangeRate = data.rates[currencycode];
                        return processDBValuenew(room.rate) * exchangeRate;
                    } else {
                        throw new Error("Exchange rate not found");
                    }
                } catch (error) {
                    console.error("Error:", error);
                    return 0;  // If there's an error, return 0 for this rate
                }
            }
            return 0;
          });
  
        // Wait for all fetches to complete and then calculate the total average
        Promise.all(fetchPromises5).then((results) => {
            // Sum up all the converted rates
            roomcurrent = results.reduce((acc, val) => acc + val, 0);
            setRoomnightcurrent(roomcurrent);
        });
          }
          else{
            setRoomnightcurrent(0);
          }
  
            if(ro.previous.room_nights>0){
            const fetchPromises51 = ro.previous.average_room_rate.map(async (room) => {
              if (currencycode) {
                  const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                  
                  try {
                      const response = await fetch(url);
                      const data = await response.json();
    
                      if (Object.hasOwn(data.rates, currencycode)) {
                          const exchangeRate = data.rates[currencycode];
                          return processDBValuenew(room.rate) * exchangeRate;
                      } else {
                          throw new Error("Exchange rate not found");
                      }
                  } catch (error) {
                      console.error("Error:", error);
                      return 0;  // If there's an error, return 0 for this rate
                  }
              }
              return 0;
            });
    
          // Wait for all fetches to complete and then calculate the total average
          Promise.all(fetchPromises51).then((results) => {
              // Sum up all the converted rates
              roommprev = results.reduce((acc, val) => acc + val, 0);
              setRoomnightprev(roommprev);
          });
            }
            else{
              setRoomnightprev(0);
            }



            if(up.current.reservation_count>0){
              const fetchPromises6 = up.current.reservation_rate.map(async (room) => {
                if (currencycode) {
                    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                    
                    try {
                        const response = await fetch(url);
                        const data = await response.json();
      
                        if (Object.hasOwn(data.rates, currencycode)) {
                            const exchangeRate = data.rates[currencycode];
                            return processDBValuenew(room.rate) * exchangeRate;
                        } else {
                            throw new Error("Exchange rate not found");
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        return 0;  // If there's an error, return 0 for this rate
                    }
                }
                return 0;
              });
      
            // Wait for all fetches to complete and then calculate the total average
            Promise.all(fetchPromises6).then((results) => {
                // Sum up all the converted rates
                upcurrent = results.reduce((acc, val) => acc + val, 0);
                setUpsellcurrent(upcurrent);
            });
              }
              else{
                setUpsellcurrent(0);
              }
      
                if(up.previous.reservation_count>0){
                const fetchPromises61 = up.previous.reservation_rate.map(async (room) => {
                  if (currencycode) {
                      const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency_code}`;
                      
                      try {
                          const response = await fetch(url);
                          const data = await response.json();
        
                          if (Object.hasOwn(data.rates, currencycode)) {
                              const exchangeRate = data.rates[currencycode];
                              return processDBValuenew(room.rate) * exchangeRate;
                          } else {
                              throw new Error("Exchange rate not found");
                          }
                      } catch (error) {
                          console.error("Error:", error);
                          return 0;  // If there's an error, return 0 for this rate
                      }
                  }
                  return 0;
                });
        
              // Wait for all fetches to complete and then calculate the total average
              Promise.all(fetchPromises61).then((results) => {
                  // Sum up all the converted rates
                  upprev = results.reduce((acc, val) => acc + val, 0);
                  setUpsellprev(upprev);
              });
                }
                else{
                  setUpsellprev(0);
                }
  
    }
  },[listall,currencycode])
  return (
    <div>
      <div id="layout-wrapper">
        <Commonheader />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Dashboard</h4>
                  </div>
                </div>
              </div> */}

              <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style dashboard-top-tab-style_new">
              <div className=" col-xl-6 col-lg-5 dashboard-main-style">
                    <div className="col-xxl-8">
                      <HotelOverviews  setIp={setIp} setCountrycode={setCountrycode} ip={ip} setSelectedcurrency={setSelectedcurrency} setCurrencycode={setCurrencycode}/>
                    </div>
                  </div>
                  {/* <div className="col-xxl-3 dashboard-main-style">
                    <div className="col-sm-auto">
                      <Date />
                    </div>
                  </div> */}

                <div className="col-xl-3 col-lg-4 col-sm-6 dashboard_select_full">
                  <label class=" input-group-tex" for="inputGroupSelect01">Currency</label>
                  <Select
                      value={selectedcurrency}
                      onChange={(data)=>handleselect(data)}
                      options={currency}
                      name="choices-single-default"
                      id="inputGroupSelect01"
                      className="form-control dashboard_select"
                  ></Select>
                  </div>  
                  <div className="  col-xl-3 col-lg-3 col-sm-6 form-calender-add">
                    <form action="#">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                          placeholder="Choose date"
                          id="flatpickr-from"
                          options={{
                            mode: "range",
                            dateFormat: "d M, Y",
                          }}
                          value={dateRange}
                          onChange={handleCalendarChange}
                        />
                        
                        <span
                          className="dashboard_calendar_new fon mdi mdi-calendar-month-outline"
                          onClick={() =>handleCalendarIconClick("from")}></span>
                      </div>
                    </form>
                  </div>

                  </div>
                  {loader===false?
                  <Loading/>
                  :
                  <div className="row dashboard-top-tab-style">

                  {list.length===0?
                   <div class="col-xxl-9 dashboard-main-style">
                   <div class="row">
                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #A48BCF" }}
                         >
                           <div class="d-flex align-items-center">
                             <div class="flex-grow-1 overflow-hidden">
                               <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                 INITIAL RESERVATION /
                                 <span className="rev-plus">+</span> REVENUE
                               </p>
                             </div>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   {""}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #E06F95" }}
                         >
                           <div class="d-flex align-items-center">
                             <div class="flex-grow-1 overflow-hidden">
                               <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                 CANCELLATIONS /
                                 <span className="rev-minus"> -</span> REVENUE
                               </p>
                             </div>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #E5A43D" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               ROOM NIGHTS /ARR
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #EBCF54" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               UPSELL /<span className="rev-plus">+</span>{" "}
                               REVENUE
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                 0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #81C784" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               ORIGINAL RESERVATIONS /
                               <span className="rev-plus">+</span> REVENUE
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #F0E687" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               LOYALTY
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="db-comparision-up-color text-muted fs-16 fw-semibold">
                                 0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                 0 /<span className="font-15"> {currencycode}</span>{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                 <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 :
                  <div class="col-xxl-9 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #A48BCF" }}
                          >
                            <div class="d-flex align-items-center">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  INITIAL RESERVATION /
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                              </div>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {initial?.current?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue( initialcurrent)}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( initialcurrent) >processDBValue(initialprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {initial?.previous?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {""}
                                    {processDBValue(
                                      initialprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( initialcurrent) < processDBValue(initialprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E06F95" }}
                          >
                            <div class="d-flex align-items-center">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  CANCELLATIONS /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                              </div>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {cancelled?.current?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      cancelledcurrent
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( cancelledcurrent) >processDBValue(cancelledprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {cancelled?.previous?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      cancelledprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( cancelledcurrent) < processDBValue(cancelledprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E5A43D" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                ROOM NIGHTS /ARR
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {roomnight?.current?.room_nights}/<span className="font-15"> {currencycode}</span>{" "}{processDBValue(roomnightcurrent)}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( roomnightcurrent) > processDBValue(roomnightprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {roomnight?.previous?.room_nights} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      roomnightprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( roomnightcurrent) < processDBValue(roomnightprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #EBCF54" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                UPSELL /<span className="rev-plus">+</span>{" "}
                                REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                  {upselldata?.current?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      upsellcurrent
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( upsellcurrent) > processDBValue(upsellprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                  {upselldata?.previous?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      upsellprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( upsellcurrent) < processDBValue(upsellprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #81C784" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                ORIGINAL RESERVATIONS /
                                <span className="rev-plus">+</span> REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {original?.current?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      originalcurrent
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( originalcurrent) > processDBValue(originalprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {original?.previous?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      originalprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( originalcurrent) < processDBValue(originalprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #95C3BF" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                RETRIEVED RESERVATION /
                                <span className="rev-plus">+</span> REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #8DB6D8" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                DISPARITY /<span className="rev-minus">-</span>{" "}
                                REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #BCBF64" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                COMMISSION
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/AED 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #F0E687" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                LOYALTY
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="db-comparision-up-color text-muted fs-16 fw-semibold">
                                  {loyaltydata?.current?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      loyaltycurrent
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( loyaltycurrent) > processDBValue(loyaltyprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                  {loyaltydata?.previous?.reservation_count} /<span className="font-15"> {currencycode}</span>{" "}
                                    {processDBValue(
                                      loyaltyprev
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                  {processDBValue( loyaltycurrent) < processDBValue(loyaltyprev) ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  <div class="col-xxl-3 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-12 col-md-12">
                        <div class="card">
                          <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">
                              Latest News
                            </h4>
                          </div>
                          <div class="card-body">
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website.in addition Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. in addition Read
                              more..
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HotelDbcomparison;
