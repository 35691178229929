import React, { useEffect, useState } from "react";
import { useNavigate, Route,useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";
import CryptoJS from "crypto-js";


const NonAuthProtected = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [user_type, setUser_type] = useState("");

  const secretKey ="fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";
  
  useEffect(() => {
    const encryptedString = localStorage.getItem("user_type");
    if (encryptedString) {
      const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
      const decryptedUserType = bytes.toString(CryptoJS.enc.Utf8); 
      setUser_type(decryptedUserType); 
    }
  }, []);

  if(token){
   
      if (token && (location.pathname === '/' || location.pathname === '/verify')) {
        if(user_type==="admin-user"){
          navigate("/dashboard");
        }
        else{
          navigate("/hotel-dashboard");
        }
      } else if (!token && location.pathname !== '/') {
       navigate('/'); 
      }   
  }
  useEffect(() => {
    if (token) {
      
      if (token && (location.pathname === '/' || location.pathname === '/verify')) {
        if(user_type==="admin-user"){
          navigate("/dashboard");
        }
        else{
          navigate("/hotel-dashboard");
        } 
       } else if (!token && location.pathname !== '/') {
        navigate('/'); 
       }
    }
  }, [token, navigate,user_type,location.pathname]);

 

  // <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { NonAuthProtected, AccessRoute };
