import React, { useCallback } from "react";
import Commonheader from "../../../Common/Commonheader";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import Footer from "../../../Common/Footer";
import Config from "../../../Config";
import { useEffect } from "react";
import Select from "react-select";
import Alerts from "../../../Alerts";
import Toastmodal from "../../../Common/Toastmodal";
import axios from "axios";
import { useAuthHandling } from "../../../Common/useAuthHandling";

function Ratesplan() {
  let navigate = useNavigate();
  let { selecthotelid } = useParams();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [country, setCountry] = useState([]);
  const [id, setId] = useState(localStorage.getItem("selected-hotel-id"));
  const [roomtype, setRoomtype] = useState([]);
  const [board, setBoard] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState({});
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [image, setImage] = useState([]);
  const [rateplan, setRateplan] = useState({
    confidential_status: false,
    loyalty_member_status: false,
    general_increment_status: true,
    basic_rateplan_status: false,
  });

  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMulti1, setselectedMulti1] = useState([]);
  const [selectedMulti2, setselectedMulti2] = useState([]);
  const [selectedMulti3, setselectedMulti3] = useState([]);
  const [selectedMulti4, setselectedMulti4] = useState([]);
  const [selectedMulti5, setselectedMulti5] = useState([]);
  const [selectedMulti6, setselectedMulti6] = useState([]);

  const [tab, setTab] = useState("EN");
  const [tab1, setTab1] = useState("by_arrival");

  const getValue =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setLanguage(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getValue();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getCurrencies =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}currencies-symbols`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setCurrency(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getCurrencies();
    } else {
      // const currencyOptions = data.data.map((currency) => {
      //   const labelParts = currency.label.split(" - ");
      //   const currencyCode = labelParts[0]; // Extract the currency code
      //   return {
      //     label: currencyCode, // Set the label to the currency code only
      //     value: currency.value,
      //   };
      // });
      console.log("error");
    }
  },[handleUnauthorized]);

  const getCountries =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setCountry(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getCountries();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getRoomtype =useCallback(async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${selecthotelid}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  },[selecthotelid]);

  const getBoard =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: [selecthotelid],
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setBoard(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBoard();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBoard();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code === "200") {
    //   setBoard(data);
    // } else if (data1.reauth === true) {
    //   handleUnauthorized();
    //   getBoard();
    // } else {
    //   console.log("error");
    // }
  },[handleUnauthorized,selecthotelid]);

  const getPolicy =useCallback(async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/cancelation-policy/${selecthotelid}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code === "200") {
      setPolicy(data);
    } else if (data1.reauth === true) {
      handleUnauthorized();
      getPolicy();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,selecthotelid]);

  let handleChange = (e) => {
    setRateplan({ ...rateplan, [e.target.name]: e.target.value });
  };
  let handleChangetogghotel = (e) => {
    let value = e.target.checked;

    let name = e.target.name;
    setRateplan({ ...rateplan, [name]: value });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const PostData = async (e) => {
    e.preventDefault();
    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!rateplan.en_public_name) {
      isFormValid = false;
      errors.en_public_name = "Public Name is required!";
    }
    else if (!noSpecialCharRegex.test(rateplan.en_public_name)) {
      isFormValid = false;
      errors.en_public_name = "Public name should not contain this special character";
    }
    else if (rateplan.en_public_name.length > 50) {
      isFormValid = false;
      errors.en_public_name = `Public name cannot be more than 50 characters!`;
    }

    if (!rateplan.internal_name) {
      isFormValid = false;
      errors.internal_name = "Internal Name is required!";
    }
    else if (!noSpecialCharRegex.test(rateplan.internal_name)) {
      isFormValid = false;
      errors.internal_name = "Internal name should not contain this special character";
    }
    else if (rateplan.internal_name.length > 50) {
      isFormValid = false;
      errors.internal_name = `Internal name cannot be more than 50 characters!`;
    }

    if (!rateplan.minimum_lead_days) {
      isFormValid = false;

      errors.minimum_lead_days = "This field is required!";
    }
    if (!selectedMulti) {
      isFormValid = false;
      errors.selectedMulti = "Currency is required!";
    }
    if (selectedMulti1.length === 0) {
      isFormValid = false;

      errors.selectedMulti1 = "Market is required!";
    }
    if (selectedMulti3.length === 0) {
      isFormValid = false;

      errors.selectedMulti3 = "Board is required!";
    }
    if (selectedMulti2.length === 0) {
      isFormValid = false;

      errors.selectedMulti2 = "Room is required!";
    }
    if (selectedMulti4.length === 0) {
      isFormValid = false;

      errors.selectedMulti4 = "Assigned policy is required!";
    }
    if (selectedMulti6.length===0) {
      isFormValid = false;

      errors.selectedMulti6 = "Default policy is required!";
    }

    if (!rateplan.status) {
      isFormValid = false;

      errors.status = "Status is required!";
    }

    if (!image || !image === null) {
      isFormValid = false;
      errors.image = "Image is required!";
    }
    setError(errors);

    let lang = [];

    // for (let i = 0; i < selectedMulti5.length; i++) {
    //   if (selectedMulti5[i].name) {
    //     if (selectedMulti5[i].code !== "en") {
    //       let multiarray = {};
    //       multiarray.lang_code = selectedMulti5[i].code;
    //       multiarray.public_name = selectedMulti5[i].name;

    //       lang.push(multiarray);
    //     }
    //   }
    // }

    // let countryVal = [];
    // for (let i = 0; i < selectedMulti1.length; i++) {
    //   countryVal.push(selectedMulti1[i].value);
    // }

    // let roomVal = [];
    // for (let i = 0; i < selectedMulti2.length; i++) {
    //   roomVal.push(selectedMulti2[i].value);
    // }

    // let boardVal = [];
    // for (let i = 0; i < selectedMulti3.length; i++) {
    //   boardVal.push(selectedMulti3[i].hotel_borad);
    // }
    // let policyVal = [];
    // for (let i = 0; i < selectedMulti4.length; i++) {
    //   policyVal.push(selectedMulti4[i].value);
    // }

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", selecthotelid);
      formData.append("en_public_name", rateplan.en_public_name);
      formData.append("internal_name", rateplan.internal_name);
      formData.append("minimum_lead_days", rateplan.minimum_lead_days);
      formData.append("currency", selectedMulti.value);
      for (let i = 0; i < selectedMulti1.length; i++) {
        formData.append(`markets[${i}]`, selectedMulti1[i].value);
      }
      for (let i = 0; i < selectedMulti2.length; i++) {
        formData.append(`rooms[${i}]`, selectedMulti2[i].value);
      }
      for (let i = 0; i < selectedMulti3.length; i++) {
        formData.append(`boards[${i}]`, selectedMulti3[i].hotel_borad);
      }
      for (let i = 0; i < selectedMulti4.length; i++) {
        formData.append(`policy[${i}]`, selectedMulti4[i].value);
      }
      if(rateplan.confidential_status===true){
        formData.append("confidential_status", 1);
      }
      else{
        formData.append("confidential_status", 0);
      }
      if(rateplan.loyalty_member_status===true){
        formData.append("loyalty_member_status", 1);
      }
      else{
        formData.append("loyalty_member_status", 0);
      }
      if(rateplan.general_increment_status===true){
        formData.append("general_increment_status",1);
      }
      else{
        formData.append("general_increment_status",0);
      }
      if(rateplan.basic_rateplan_status===true){
       formData.append("basic_rateplan_status", 1);
      }
      else{
        formData.append("basic_rateplan_status", 0);
      }
      // formData.append("rooms", roomVal);
      // formData.append("boards", boardVal);
      // formData.append("policy", policyVal);
      formData.append("default_policy", selectedMulti6.value);
      formData.append("stay_reference", tab1);
      formData.append("status", rateplan.status);
      let count = 0;
      for (let i = 0; i < selectedMulti5.length; i++) {
        if (selectedMulti5[i].name) {
          if (selectedMulti5[i].code !== "en") {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              selectedMulti5[i].code
            );
            formData.append(
              `multi_lang[${count}][public_name]`,
              selectedMulti5[i].name
            );
            count += 1;
          }
        }
      }
      // formData.append("multi_lang", lang);
      formData.append("image", image);
      axios
        .post(`${Config.apiurl}hotel/rate-list/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.status_code === "200") {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);
            setTimeout(() => navigate("/rate-plan-list"), 3000);
          } else if (res.reauth === true) {
            handleUnauthorized();
            PostData();
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          }
        });

      // const res = await fetch(`${Config.apiurl}hotel/rate-list/add`, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     hotel_id: selecthotelid,
      //     en_public_name: rateplan.en_public_name,
      //     internal_name: rateplan.internal_name,
      //     minimum_lead_days: rateplan.minimum_lead_days,
      //     currency: selectedMulti.value,
      //     markets: countryVal,
      //     confidential_status: rateplan.confidential_status,
      //     loyalty_member_status: rateplan.loyalty_member_status,
      //     general_increment_status: rateplan.general_increment_status,
      //     rooms: roomVal,
      //     boards: boardVal,
      //     policy: policyVal,
      //     default_policy: selectedMulti6.value,
      //     stay_reference: tab1,
      //     status: rateplan.status,
      //     multi_lang: lang,
      //   }),
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-type": "application/json; charset=UTF-8",
      //   },
      // });
      // const data = await res.json();
      // if (data.status_code !== "200" || !data) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(false);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(false);
      //   setTimeout(() => navigate("/rate-plan-list"), 3000);
      // }
    }
  };
  
  useEffect(() => {
    getValue();
  }, [getValue]);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    getRoomtype();
  }, [getRoomtype]);

  useEffect(() => {
    getBoard();
  }, [getBoard]);

  useEffect(() => {
    getPolicy();
  }, [getPolicy]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between"></div>
            </div>
          </div>

          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">
                          Create New Rate List
                        </h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link
                            className="btn  db-other-button"
                            to="/rate-plan-list"
                          >
                            Rate List
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button
                            className="btn db-save-button"
                            onClick={PostData}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {tab === "EN" ? (
                            <>
                              <div class="col-lg-3">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Public Name
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="en_public_name"
                                    value={rateplan.en_public_name}
                                    onChange={handleChange}
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror.en_public_name}
                                  </label>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "120px" }}
                                  >
                                    Internal Name
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="internal_name"
                                    value={rateplan.internal_name}
                                    onChange={handleChange}
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror.internal_name}
                                  </label>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Default release
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                    <span
                                      className="info-tag"
                                      style={{ backgroundColor: "black" }}
                                    >
                                      i
                                      <span className="tooltiptext">
                                        Minimum lead days
                                      </span>
                                    </span>
                                  </label>
                                  <input
                                    onKeyDown={(e) =>
                                      symbolsArr.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    type="number"
                                    className="form-control"
                                    name="minimum_lead_days"
                                    value={rateplan.minimum_lead_days}
                                    onChange={handleChange}
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror.minimum_lead_days}
                                  </label>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ height: "40px" }}
                                  >
                                    Currency
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-7">
                                    <Select
                                      className="form-control"
                                      id="exampleInputUsername1"
                                      options={currency}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.selectedMulti}
                                  </label>
                                </div>
                              </div>
                              <div className="row g-3">
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ height: "40px" }}
                                    >
                                      Market
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-7">
                                      <MultiSelect
                                        options={country}
                                        value={selectedMulti1}
                                        onChange={setselectedMulti1}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectedMulti1}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Confidential{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        with promotional code
                                      </span>
                                    </span>
                                    <div
                                      className="form-check form-switch form-switch-lg mb-3"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="inlineswitch5"
                                        name="confidential_status"
                                        value={rateplan.confidential_status}
                                        checked={
                                          rateplan.confidential_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangetogghotel}
                                        style={{
                                          width: "50px",
                                          height: "25px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Loyalty club{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div
                                      className="form-check form-switch form-switch-lg mb-3"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="inlineswitch5"
                                        name="loyalty_member_status"
                                        value={rateplan.loyalty_member_status}
                                        checked={
                                          rateplan.loyalty_member_status ===
                                          true
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangetogghotel}
                                        style={{
                                          width: "50px",
                                          height: "25px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Apply general increment{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                      <span
                                        className="info-tag"
                                        style={{
                                          marginLeft: "165px",
                                          bottom: "21px",
                                        }}
                                      >
                                        i
                                        <span className="tooltiptext">
                                          If an offer exists, the offer setting
                                          will prevail
                                        </span>
                                      </span>
                                    </label>
                                    <div
                                      className="form-check form-switch form-switch-lg mb-3"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="inlineswitch5"
                                        name="general_increment_status"
                                        value={
                                          rateplan.general_increment_status
                                        }
                                        checked={
                                          rateplan.general_increment_status ===
                                          true
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangetogghotel}
                                        style={{
                                          width: "50px",
                                          height: "25px",
                                          bottom: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Basic Rate Plan Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div
                                      className="form-check form-switch form-switch-lg mb-3"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="inlineswitch5"
                                        name="basic_rateplan_status"
                                        value={rateplan.basic_rateplan_status}
                                        checked={
                                          rateplan.basic_rateplan_status ===
                                          true
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangetogghotel}
                                        style={{
                                          width: "50px",
                                          height: "25px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="padding-10"></div>
                              <div className="col-ms-12">
                                <div className="form-translation-style">
                                  <h6 className="sub-title-form">
                                    Assign to Rooms / Occupancy and Boards
                                  </h6>
                                </div>
                              </div>
                              <div className="row">
                                <div class="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "160px", height: "40px" }}
                                    >
                                      Rooms and Occupancy
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-8">
                                      <MultiSelect
                                        options={roomtype}
                                        value={selectedMulti2}
                                        onChange={setselectedMulti2}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                        aria-expanded="true"
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectedMulti2}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ height: "40px" }}
                                    >
                                      Boards
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-8">
                                      <MultiSelect
                                        options={board}
                                        value={selectedMulti3}
                                        onChange={setselectedMulti3}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                        aria-expanded="true"
                                      />
                                    </div>
                                   
                                  </div>
                                  <label style={{ color: "red" }}>
                                      {formerror.selectedMulti3}
                                    </label>
                                </div>
                              </div>
                              <div className="padding-10"></div>
                              <div className="col-ms-12">
                                <div className="form-translation-style">
                                  <h6 className="sub-title-form">
                                    Cancellation Payment Policies
                                  </h6>
                                </div>
                              </div>
                              <div className="row">
                                <div class="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "160px", height: "40px" }}
                                    >
                                      Assigned Policies
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-8">
                                      <MultiSelect
                                        options={policy}
                                        value={selectedMulti4}
                                        onChange={setselectedMulti4}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                        aria-expanded="true"
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectedMulti4}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "103px", height: "40px" }}
                                    >
                                      Default policy
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-8">
                                      <Select
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        options={policy}
                                        value={selectedMulti6}
                                        onChange={setselectedMulti6}
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectedMulti6}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="padding-10"></div>
                              <div className="col-ms-12">
                                <div className="form-translation-style">
                                  <h6 className="sub-title-form">
                                    Other Configuration{" "}
                                  </h6>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Reference for calculating minimum /
                                      maximum stay{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <ul
                                      className="stay_type_form"
                                      id="referencr_stay_type"
                                    >
                                      <li
                                        className={
                                          tab1 === "by_arrival" ? "active" : ""
                                        }
                                        onClick={(e) => setTab1("by_arrival")}
                                      >
                                        By Arrival
                                      </li>
                                      <li
                                        className={
                                          tab1 === "by_stay" ? "active" : ""
                                        }
                                        onClick={(e) => setTab1("by_stay")}
                                      >
                                        By Stay
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ height: "38px" }}
                                    >
                                      Status
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-6">
                                      <select
                                        id="ForminputState"
                                        className="form-select"
                                        value={rateplan.status}
                                        name="status"
                                        onChange={handleChange}
                                      >
                                        <option selected>Choose...</option>
                                        <option value={true} name="status">
                                          Active
                                        </option>
                                        <option value={false} name="status">
                                          Inactive
                                        </option>
                                      </select>
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.status}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Image
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      placeholder=""
                                      onChange={onFileChange}
                                      accept=".jpg, .jpeg, .webp, .png"
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.image}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="padding-20"></div>
                            </>
                          ) : (
                            <>
                              <div
                                class="col-lg-4"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "130px" }}
                                  >
                                    {" "}
                                    Choose Language
                                  </label>
                                  <div className="col-md-7">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti5}
                                      onChange={setselectedMulti5}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="appendmore"
                                style={{ marginTop: "20px" }}
                              ></div>
                              {selectedMulti5.length > 0
                                ? selectedMulti5.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>

                                        <div class="col-lg-4">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Public Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                              value={selectedMulti5[index].name}
                                              onChange={(e) =>
                                                (selectedMulti5[index].name =
                                                  e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Ratesplan;
