import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Directrateloyaltyheader from "../Common/Directrateloyaltyheader";
import Footer from "../Common/Footer";
import Toastmodal from "../Common/Toastmodal";
import { useAuthHandling } from "../Common/useAuthHandling";
import Commonimagepath from "../Commonimagepath";
import Config from "../Config";
import DeleteModal from "../DeleteModal";
import jwt from "jwt-decode";

function Directrateloyaltylevel() {
  const [basiclevel, setbasiclevel] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [multi, setMulti] = useState([]);
  const [status, setStatus] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const [isMatchingLanguagenew, setisMatchingLanguagenew] = useState("");
  const [newLang, setNewLang] = useState("");
  const [newLangpop, setNewLangpop] = useState("");
  const [langall, setLangall] = useState([]);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [loyaltyadd, setLoyaltyadd] = useState({ expiry_status: false });
  const [isMatchingLanguagepop, setisMatchingLanguage] = useState("");
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [Error, setError] = useState({});
  const [Error1, setError1] = useState({});
  const [Error2, setError2] = useState([{}]);
  const [logoFile, setLogoFile] = useState("");
  const [levelid, setLevelid] = useState("");
  const [newlogo, setNewlogo] = useState("");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [popup, setPopup] = useState(false);
  const [tabimg, setTabimage] = useState("upload");
  const [optionimage, setOptionimage] = useState([]);
  const [popuploop, setPopuploop] = useState(false);
  const [tabimgloop, setTabimageloop] = useState("upload");
  const [popupid, setPopupId] = useState("");
  const [popupcreate, setPopupcreate] = useState(false);
  const [tabimgcreate, setTabimagecreate] = useState("upload");
  const [currencynew, setCurrencynew] = useState([]);
  const [levels, setLevels] = useState([]);

  const token = localStorage.getItem("token");
  const tokens = jwt(token);

  const currencyid = tokens.currency;

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
    setLogoFile("");
    setLoyaltyadd([]);
  }
  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(ids) {
    setLevelid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const getlanguage =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlanguage();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlanguage();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getCurrency =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}currencies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCurrencynew(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getCurrency();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getCurrency();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const getList =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}admin/global/loyalty-level/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoading(true);
        if(data.data?.loyalty_levels.basic_level){
          setbasiclevel(data.data?.loyalty_levels?.basic_level);
        }
        if(data?.data?.loyalty_levels?.basic_level && data?.data?.loyalty_levels?.basic_level.multi_lang){
        setMulti(data?.data?.loyalty_levels?.basic_level?.multi_lang);
        setLangall(data?.data?.loyalty_levels?.basic_level?.multi_lang);
        }
        if(data.data?.loyalty_levels?.levels){
        setLevels(data.data?.loyalty_levels?.levels);
        }
        setOptionimage(data?.data?.default_image);
        if (data?.data?.loyalty_levels?.basic_level?.logo) {
          setNewlogo(data?.data?.loyalty_levels?.basic_level?.logo);
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getList();
      } else {
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getList();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const handleLanguageChangenew = (selectedOption) => {
    setNewLang(selectedOption);
    const updatedLanguages = [...multi];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
        updatedLanguages.push({ lang_code: selectedOption, name: "" });
      }
    } else {
      updatedLanguages.push({ lang_code: selectedOption, name: "" });
    }
    setMulti(updatedLanguages);
  };

  const handleloyallangChange = (addonValue) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["name"] = addonValue;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["name"] = addonValue;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  let handleloyalty = (e) => {
    let newval = { ...basiclevel };
    let name = e.target.name;
    let value = e.target.value;
    newval[name] = value;
    setbasiclevel(newval);
  };

  let handleChange = (i, e) => {
    let newval = [...levels];
    newval[i][e.target.name] = e.target.value;
    setLevels(newval);
  };

  const handleLogoChange = (index, event) => {
    const selectedFile = event.target.files[0];

    setbasiclevel((prevCommonLevel) => {
      const updatedCommonLevel = [...prevCommonLevel];
      updatedCommonLevel[index] = {
        ...updatedCommonLevel[index],
        logo: selectedFile,
      };
      return updatedCommonLevel;
    });
  };

  const handleConditionChange = (i, name, value) => {
    const updatedValues = [...levels];
    updatedValues[i][name] = value;
    setLevels(updatedValues);
  };

  const handleLanguageChange = (index, selectedOption) => {
    const updatedLanguages = [...levels];
    updatedLanguages[index]["newlangsub"] = selectedOption;
    if (updatedLanguages[index].multi_language === null) {
      updatedLanguages[index].multi_language = [];
    }
    if (
      !updatedLanguages[index]?.multi_language.some(
        (item) => item.lang_code === selectedOption
      )
    ) {
      updatedLanguages[index]?.multi_language?.push({
        lang_code: selectedOption,
        name: "",
      });
    }
    setLevels(updatedLanguages);
  };

  const handleloyaltyChangelang = (index, Value) => {
    const updatedList = [...levels];
    const matchingIndex = updatedList[index]?.multi_language?.findIndex(
      (language) => language.lang_code === updatedList[index].newlangsub
    );
    if (matchingIndex !== -1) {
      updatedList[index]["multi_language"][matchingIndex]["name"] = Value;
    } else {
      const newLanguage = { lang_code: updatedList[index].newlangsub };
      newLanguage["name"] = Value;
      updatedList[index]["multi_language"].push(newLanguage);
    }
    setLevels(updatedList);
  };

  const handleStatusChange = (index, selectedStatus) => {
    let newCommonLevel = [...levels];
    newCommonLevel[index] = {
      ...newCommonLevel[index],
      status: selectedStatus,
    };
    setLevels(newCommonLevel);
  };

  const handleStatusbasic = (selectedStatus) => {
    setbasiclevel({ ...basiclevel, status: selectedStatus });
  };

  let handleloyaltypop = (e) => {
    let newval = { ...loyaltyadd };
    let name = e.target.name;
    let value = e.target.value;
    newval[name] = value;
    setLoyaltyadd(newval);
  };

  const handleChangeExpiration = (i, name, value) => {
    const updatedValues = [...levels];
    updatedValues[i][name] = value;
    setLevels(updatedValues);
  };
  const handleChangeExpirationpop = (name, value) => {
    setLoyaltyadd((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLanguageChangepop = (selectedOption) => {
    setNewLangpop(selectedOption);
    const updatedLanguages = [...selectedMulti];

    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages.push({ lang_code: selectedOption, name: "" });
    }
    setSelectedMulti(updatedLanguages);
  };

  const handleloyaltyChangepop = (Value) => {
    const updatedList = [...selectedMulti];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLangpop
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["name"] = Value;
    } else {
      const newLanguage = { lang_code: newLangpop };
      newLanguage["name"] = Value;
      updatedList.push(newLanguage);
    }
    setSelectedMulti(updatedList);
  };

  const handleCurrency = (e) => {
    const { name, value } = e.target;
    setLoyaltyadd((prevLoyaltyadd) => ({
      ...prevLoyaltyadd,
      [name]: value,
    }));
  };

  const BasiclevelAdd = async () => {
    let isFormValid = true;

    const errors = {};
    if (!basiclevel.name) {
      isFormValid = false;
      errors.name = "Loyalty name is required!";
    }
    if (!basiclevel.status) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError1(errors);

    if (isFormValid === true) {
      const formData = new FormData();

      formData.append("name", basiclevel.name);
      formData.append("baisc_level_status", 1);
      formData.append("status", basiclevel.status);

      if (newlogo) {
        if (newlogo instanceof File) {
          formData.append("logo", newlogo);
        } else {
          formData.append("choosen_image", newlogo);
        }
      }
      for (let i = 0; i < multi?.length; i++) {
        formData.append(`multi_lang[${i}][lang_code]`, multi[i].lang_code);
        formData.append(`multi_lang[${i}][name]`, multi[i].name);
      }

      axios
        .post(`${Config.apiurl}admin/basic/loyalty-level/add`, formData, {})
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            getList();
          }
        });
    }
  };
console.log(basiclevel);
  const Add = async () => {
    let isFormValid = true;

    const errors = {};
    if (!loyaltyadd.name) {
      isFormValid = false;
      errors.name = "Loyalty name is required!";
    }
    if (!loyaltyadd.minimum_reservation) {
      isFormValid = false;
      errors.minimum_reservation = "Minimum reservation is required!";
    }
    if (!loyaltyadd.minimum_room_nights) {
      isFormValid = false;
      errors.minimum_room_nights = "Minimum room night is required!";
    }
    if (!loyaltyadd.amount_spend) {
      isFormValid = false;
      errors.amount_spend = "Amount spend is required!";
    }
    if (loyaltyadd.expiry_status === true) {
      if (!loyaltyadd.period) {
        isFormValid = false;
        errors.period = "Period is required!";
      }
    }
    if (!loyaltyadd.discount) {
      isFormValid = false;
      errors.discount = "Discount is required!";
    }
    if (status.length === 0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("name", loyaltyadd.name);
      formData.append("minimum_reservation", loyaltyadd.minimum_reservation);
      if (loyaltyadd.condition) {
        formData.append("minimum_reservation", loyaltyadd.condition);
      } else {
        formData.append("condition", "or");
      }
      formData.append("minimum_room_night", loyaltyadd.minimum_room_nights);
      if (loyaltyadd.expiry_status === true) {
        formData.append("expiry_status", 1);
      } else {
        formData.append("expiry_status", 0);
      }
      if (loyaltyadd.expiry_status === true) {
        formData.append("expiry_period", loyaltyadd.period);
      }
      formData.append("status", status);
      if (logoFile) {
        if (newlogo instanceof File) {
          formData.append("logo", logoFile);
        } else {
          formData.append(" choosen_image", logoFile);
        }
      }
      formData.append("discount", loyaltyadd.discount);
      if (loyaltyadd.unit_value) {
        formData.append("unit_value", loyaltyadd.unit_value);
      } else {
        formData.append("unit_value", "currency");
      }
      formData.append("amount_spend", loyaltyadd.amount_spend);
      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(
          `multi_lang[${i}][lang_code]`,
          selectedMulti[i].lang_code
        );
        formData.append(`multi_lang[${i}][name]`, selectedMulti[i].name);
      }

      axios
        .post(`${Config.apiurl}admin/global/loyalty-level/add`, formData, {})
        .then((res) => {
          if (res.status_code === "200") {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            tog_animationFlip(false);
            getList();
            setLoyaltyadd([]);
            setLogoFile("");
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          }
        });
    }
  };

  const Update = async () => {
    let isFormValid = true;
    let isFormValid1 = true;

    const errors = {};
    if (!basiclevel.name) {
      isFormValid = false;
      errors.name = "Loyalty name is required!";
    }
    if (!basiclevel.status) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    let arr = [];
    for (let i = 0; i < levels.length; i++) {
      const error = {};
      if (!levels[i].name) {
        isFormValid1 = false;
        error.name = "Loyalty name is required!";
      }
      if (!levels[i].minimum_reservation) {
        isFormValid1 = false;
        error.minimum_reservation = "Minimum reservation is required!";
      }
      if (!levels[i].minimum_room_nights) {
        isFormValid1 = false;
        error.minimum_room_nights = "Minimum room night is required!";
      }
      if (!levels[i].amount_spend) {
        isFormValid1 = false;
        error.amount_spend = "Amount spend is required!";
      }
      if (levels[i].expiry_status === true) {
        if (!levels[i].period) {
          isFormValid1 = false;
          error.period = "Period is required!";
        }
      }
      if (!levels[i].sort_order) {
        isFormValid1 = false;
        error.sort_order = "Sort order is required!";
      }
      if (!levels[i].discount) {
        isFormValid1 = false;
        error.discount = "Discount is required!";
      }

      if (levels[i].status.length === 0) {
        isFormValid = false;
        error.status = "Status is required!";
      }
      arr.push(error);
    }
    setError1(errors);
    setError2(arr);

    if (isFormValid === true && isFormValid1 === true) {
      const formData = new FormData();
      if (basiclevel.loyalty_id) {
        formData.append("basic_level_id", basiclevel.loyalty_id);
      }
      formData.append("basic_level_name", basiclevel.name);
      formData.append("basic_level_status", basiclevel.status);
      if (newlogo) {
        if (newlogo instanceof File) {
          formData.append("basic_level_logo", newlogo);
        } else {
          formData.append("basic_level_choosen_image", newlogo);
        }
      }
      for (let i = 0; i < multi?.length; i++) {
        formData.append(
          `basic_level_multi_lang[${i}][lang_code]`,
          multi[i].lang_code
        );
        formData.append(`basic_level_multi_lang[${i}][name]`, multi[i].name);
      }
      for (let i = 0; i < levels.length; i++) {
        formData.append(`level[${i}][level_id]`, levels[i].level_id);
        formData.append(`level[${i}][name]`, levels[i].name);
        if (levels[i].logo) {
          if (levels[i].logo instanceof File) {
            formData.append(`level[${i}][logo]`, levels[i].logo);
          } else {
            formData.append(`level[${i}][choosen_image]`, levels[i].logo);
          }
        }
        formData.append(`level[${i}][status]`, levels[i].status);
        formData.append(
          `level[${i}][minimum_reservation]`,
          levels[i].minimum_reservation
        );
        if (levels[i].condition) {
          formData.append(`level[${i}][condition]`, levels[i].condition);
        } else {
          formData.append(`level[${i}][condition]`, "or");
        }
        formData.append(
          `level[${i}][minimum_room_night]`,
          levels[i].minimum_room_nights
        );
        formData.append(`level[${i}][discount]`, levels[i].discount);
        if (levels[i].unit_value) {
          formData.append(`level[${i}][unit_value]`, levels[i].unit_value);
        } else {
          formData.append(`level[${i}][unit_value]`, "currency");
        }
        if (levels[i].expiry_status === true) {
          formData.append(`level[${i}][expiry_status]`, 1);
        } else {
          formData.append(`level[${i}][expiry_status]`, 0);
        }
        if (levels[i].expiry_status === true) {
          formData.append(`level[${i}][expiry_period]`, levels[i].period);
        }
        formData.append(`level[${i}][sort_order]`, levels[i].sort_order);
        formData.append(`level[${i}][amount_spend]`, levels[i].amount_spend);

        for (let j = 0; j < levels[i]?.multi_language?.length; j++) {
          formData.append(
            `level[${i}][multi_lang][${j}][lang_code]`,
            levels[i].multi_language[j]?.lang_code
          );
          formData.append(
            `level[${i}][multi_lang][${j}][name]`,
            levels[i].multi_language[j]?.name
          );
        }
      }

      axios
        .post(`${Config.apiurl}admin/global/loyalty-level/update`, formData, {})
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
          }
        });
    }
  };

  const Delete = async (ids) => {
    const res = await fetch(
      `${Config.apiurl}admin/global/loyalty-level/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          level_id: ids,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlipDel(false);
      getList();
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  };

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    getlanguage();
  }, [getlanguage]);

  useEffect(() => {
    getCurrency();
  }, [getCurrency]);

  useEffect(() => {
    for (let i = 0; i < currencynew.length; i++) {
      if (currencyid === currencynew[i].currency_id) {
        setCurrency({
          currency_id: currencynew[i].currency_id,
          name: currencynew[i].name,
          code: currencynew[i].code,
        });
      }
    }
  }, [currencynew, currency?.length, currencyid]);

  useEffect(() => {
    const isMatching = multi?.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguagenew(isMatching);
  }, [newLang,multi]);

  useEffect(() => {
    let newval;

    langall?.forEach((lang) => {
      if (lang.name) {
        newval = lang.lang_code;
      }
    });
    setNewLang(newval);
  }, [langall]);

  useEffect(() => {
    let lang;
    const updatedList = [...levels];
    for (let i = 0; i < updatedList?.length; i++) {
      if (updatedList[i]?.multi_language != null) {
        for (let j = 0; j < updatedList[i]?.multi_language?.length; j++) {
          lang = updatedList[i]?.multi_language[j]?.lang_code;
        }
      } else {
        lang = "";
      }
      updatedList[i].newlangsub = lang;
    }
  }, [levels]);

  useEffect(() => {
    const isMatching = selectedMulti.findIndex(
      (language) => language.lang_code === newLangpop
    );
    setisMatchingLanguage(isMatching);
  }, [newLangpop,selectedMulti]);

  const onFileChange = (e) => {
    setPopup(false);
    setNewlogo(e.target.files[0]);
    e.target.value = null;
  };

  const onFileChangelopp = (index, event) => {
    setPopuploop(false);

    const selectedFile = event.target.files[0];

    setLevels((prevCommonLevel) => {
      const updatedCommonLevel = [...prevCommonLevel];
      updatedCommonLevel[index] = {
        ...updatedCommonLevel[index],
        logo: selectedFile,
      };
      return updatedCommonLevel;
    });
    event.target.value = null;
  };

  const handleImageClickloop = (imgsrc, index) => {
    setPopuploop(false);
    setLevels((prevCommonLevel) => {
      const updatedCommonLevel = [...prevCommonLevel];
      updatedCommonLevel[index] = {
        ...updatedCommonLevel[index],
        logo: imgsrc,
      };
      return updatedCommonLevel;
    });
  };

  const handleImageClick = (imageSrc) => {
    setNewlogo(imageSrc);
    setPopup(false);
  };

  const onFileChangecreate = (e) => {
    setPopupcreate(false);
    setLogoFile(e.target.files[0]);

    e.target.value = null;
  };

  const handleImageClickcreate = (imageSrc) => {
    setLogoFile(imageSrc);

    setPopupcreate(false);
  };

  return (
    <div>
      <Directrateloyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              <div className="card">
                <div className="card-body">
                  <div className="live-preview">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="db-page-title">Loyalty Level</h4>
                    </div>

                    <div
                      className="flex-shrink-0 d-flex justify-content-between"
                      style={{ float: "right" }}
                    >
                      <Link
                        type="button"
                        to="#"
                        className="btn db-save-button-loyalty"
                        style={{ height: "30px" }}
                        onClick={tog_animationFlip}
                      >
                        Create New Loyalty
                      </Link>
                      {basiclevel?.loyalty_id ? (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Update}
                        >
                          {" "}
                          Save
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={BasiclevelAdd}
                        >
                          {" "}
                          Save
                        </Link>
                      )}
                    </div>

                    <div
                      className="be_loyalty_level"
                      style={{ marginTop: "10px" }}
                    >
                      <h5 className="be_loyalty_level_title">
                        Loyalty - Basic Level
                      </h5>
                      <span className="padding-5"></span>
                      <span className="be_form_loyalty_label_50">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Level Name{" "}
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new">i</div>
                          <span className="tooltiptext-new">
                            Name of the loyalty level you want to call, for
                            example Bronze
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={basiclevel?.name}
                          name="name"
                          onChange={handleloyalty}
                        />

                        <label style={{ color: "red" }}>{Error1.name}</label>
                      </span>

                      <span className="loyalty_logo_new">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Logo <div class="info-tag-new mar_1">i</div>
                          <span className="tooltiptext-new">
                            Upload logo in this particular level, only accept
                            jpg,jpeg,png format
                          </span>
                          <span className="span-img-size">
                            ( 140 px * 40px)
                          </span>
                        </label>
                        <div className="form-translation-style_offer">
                          <ul
                            className="price_show_select_loyal"
                            id="price_show_type"
                          >
                            <li
                              className={tabimg === "upload" ? "active" : ""}
                              onClick={(e) => setTabimage("upload")}
                            >
                              <button className="photo_offer_pen_choose">
                                Upload Image
                              </button>
                              <input
                                className="photo_offer_input"
                                type="file"
                                name="image"
                                onChange={onFileChange}
                                accept=".jpg, .jpeg, .webp, .png"
                              />
                            </li>

                            <li
                              className={tabimg === "list" ? "active" : ""}
                              onClick={(e) => {
                                setPopup(!popup);
                                setTabimage("list");
                              }}
                            >
                              <button className="photo_offer_pen_choose">
                                Choose From List
                              </button>
                            </li>
                          </ul>
                        </div>

                        <label className="label_image_crop">
                          {" "}
                          (<span class="form-validation-required"></span>if you
                          do not resize it as per the mentioned size, it can
                          affect the quality of the images)
                        </label>

                        {basiclevel && newlogo ? (
                          <div className="" style={{ float: "left" }}>
                            {newlogo ? (
                              newlogo instanceof File ? (
                                <p className="pdf_img_style_img_loyal">
                                  {newlogo.name}
                                </p>
                              ) : (
                                <img
                                  src={`${Commonimagepath.apiurl}${newlogo}`}
                                  alt=""
                                  className="position-absolute"
                                  style={{
                                    width: "24px",
                                    marginTop: "21px",
                                    marginLeft: "10px",
                                    height: "24px",
                                  }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>

                      <div className="col-md-12">
                        <div className="col-lg-6">
                          <div
                            className="d-flex"
                            style={{ float: "left", marginTop: "15px" }}
                          >
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                              style={{
                                width: "130px",
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              Add/edit in
                            </label>
                            <select
                              className="form-control form-select"
                              data-choices
                              data-choices-search-false
                              id="idPayment"
                              style={{
                                width: "225px",
                                height: "38px",
                                marginLeft: "-1px",
                              }}
                              value={newLang}
                              onChange={(e) =>
                                handleLanguageChangenew(e.target.value)
                              }
                            >
                              <option value="">Choose language</option>

                              {language.map((lang, inx) => {
                                return (
                                  <option key={inx} value={lang.code}>
                                    {lang.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <span className="padding-5"></span>
                        <div
                          className="appendmore"
                          style={{ marginTop: "7px" }}
                        ></div>
                        {newLang ? (
                          multi?.length > 0 ? (
                            <>
                              {multi?.some(
                                (item) => item.lang_code === newLang
                              ) ? (
                                <div
                                  className="col-md-8 translation-fill-section"
                                  data-value="1"
                                >
                                  <div className="col-lg-8">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Loyalty Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={
                                          multi[isMatchingLanguagenew]?.name
                                        }
                                        onChange={(e) =>
                                          handleloyallangChange(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-md-10 translation-fill-section"
                                  data-value="1"
                                >
                                  <div className="col-lg-8">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Loyalty Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={multi[multi?.length - 1]?.name}
                                        onChange={(e) =>
                                          handleloyallangChange(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <span
                          className="loyalty_joint_style"
                          style={{
                            float: "right",
                            paddingRight: "12%",
                            bottom: "60px",
                          }}
                        >
                          <span
                            className="input-group"
                            style={{ width: "338px" }}
                          >
                            <span
                              className="input-group-text input-joint-color1"
                              id="inputGroup-sizing-default"
                            >
                              Status{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                            </span>
                            <select
                              id="ForminputState"
                              className="form-select"
                              value={basiclevel.status}
                              onChange={(e) =>
                                handleStatusbasic(e.target.value)
                              }
                            >
                              <option value="" selected>
                                Choose...
                              </option>
                              <option
                                {...(basiclevel.status === true
                                  ? "selected"
                                  : "")}
                                value={true}
                              >
                                Active
                              </option>
                              <option
                                {...(basiclevel.status === false
                                  ? "selected"
                                  : "")}
                                value={false}
                              >
                                Inactive
                              </option>
                            </select>
                          </span>
                          <label style={{ color: "red" }}>
                            {Error1.status}
                          </label>
                        </span>
                      </div>
                    </div>

                    {loading
                      ? levels?.map((data, i) => {
                          const isMatchingLanguagesub =
                            data.multi_language?.findIndex(
                              (language) =>
                                language.lang_code === data.newlangsub
                            );

                          return (
                            <>
                              <div className="be_loyalty_level" key={i}>
                                <span
                                  className="remove_loyalty"
                                  onClick={(e) =>
                                    tog_animationFlipDel(data.level_id)
                                  }
                                >
                                  Delete
                                </span>
                                <span className="padding-5"></span>
                                <span className="be_form_loyalty_label_50">
                                  <label
                                    for="firstNameinput"
                                    className="form-label be_form_label"
                                  >
                                    Name of Loyalty Level-{i + 1}{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                    <div class="info-tag-new">i</div>
                                    <span className="tooltiptext-new">
                                      Name of the loyalty level you want to
                                      call, for example Bronze
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={data.name}
                                    name="name"
                                    onChange={(e) => handleChange(i, e)}
                                  />

                                  {Error2.map((err, ind) => {
                                    return (
                                      <>
                                        {i === ind && err && err.name ? (
                                          <label style={{ color: "red" }}>
                                            {err.name}
                                          </label>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                </span>

                                <span className="loyalty_logo_new">
                                  <label
                                    for="firstNameinput"
                                    className="form-label be_form_label"
                                  >
                                    Logo <div class="info-tag-new mar_1">i</div>
                                    <span className="tooltiptext-new">
                                      Upload logo in this particular level, only
                                      accept jpg,jpeg,webp,png format
                                    </span>{" "}
                                    <span className="span-img-size">
                                      ( 140 px * 40px)
                                    </span>
                                  </label>
                                  <div className="form-translation-style_offer">
                                    <ul
                                      className="price_show_select_loyal"
                                      id="price_show_type"
                                    >
                                      <li
                                        className={
                                          tabimgloop === "upload"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={(e) =>
                                          setTabimageloop("upload")
                                        }
                                      >
                                        <button className="photo_offer_pen_choose">
                                          Upload Image
                                        </button>
                                        <input
                                          className="photo_offer_input"
                                          type="file"
                                          name="image"
                                          onChange={(e) =>
                                            onFileChangelopp(i, e)
                                          }
                                          accept=".jpg, .jpeg, .webp, .png"
                                        />
                                      </li>

                                      <li
                                        className={
                                          tabimgloop === "list" ? "active" : ""
                                        }
                                        onClick={(e) => {
                                          setPopuploop(!popuploop);
                                          setTabimageloop("list");
                                          setPopupId(i);
                                        }}
                                      >
                                        <button className="photo_offer_pen_choose">
                                          Choose From List
                                        </button>
                                      </li>
                                    </ul>
                                  </div>

                                  <label className="label_image_crop">
                                    {" "}
                                    (
                                    <span class="form-validation-required"></span>
                                    if you do not resize it as per the mentioned
                                    size, it can affect the quality of the
                                    images)
                                  </label>
                                  {data.logo ? (
                                    <div className="" style={{ float: "left" }}>
                                      {data.logo ? (
                                        data.logo instanceof File ? (
                                          <p className="pdf_img_style_img_loyal">
                                            {data.logo.name}
                                          </p>
                                        ) : (
                                          <img
                                            src={`${Commonimagepath.apiurl}${data.logo}`}
                                            alt=""
                                            className="position-absolute"
                                            style={{
                                              width: "24px",
                                              marginTop: "21px",
                                              marginLeft: "10px",
                                              height: "24px",
                                            }}
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>

                                <div
                                  className="col-md-12"
                                  style={{ marginTop: "15px" }}
                                >
                                  <span className="be_form_loyalty_label_50">
                                    <div
                                      className="d-flex"
                                      style={{ float: "left" }}
                                    >
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{
                                          borderBottomRightRadius: "0px",
                                          borderTopRightRadius: "0px",
                                        }}
                                      >
                                        Add/edit in
                                      </label>

                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default"
                                        id="idPayment"
                                        style={{
                                          width: "225px",
                                          height: "38px",
                                          marginLeft: "-1px",
                                        }}
                                        value={data.newlangsub}
                                        onChange={(e) =>
                                          handleLanguageChange(
                                            i,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Choose language
                                        </option>
                                        {language.map((lang, inx) => {
                                          return (
                                            <option key={inx} value={lang.code}>
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </span>
                                  {data.newlangsub ? (
                                    <>
                                      {data.multi_language?.length > 0 ? (
                                        <>
                                          {data.multi_language?.some(
                                            (item) =>
                                              item.lang_code === data.newlangsub
                                          ) ? (
                                            <div
                                              className="col-md-12 translation-fill-section"
                                              data-value="1"
                                            >
                                              <div className="col-lg-5">
                                                <div className="input-group">
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Loyalty Name
                                                  </label>

                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={
                                                      data.multi_language[
                                                        isMatchingLanguagesub
                                                      ]?.name
                                                    }
                                                    onChange={(e) =>
                                                      handleloyaltyChangelang(
                                                        i,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="col-md-12 translation-fill-section"
                                              data-value="1"
                                            >
                                              <div className="col-lg-5">
                                                <div className="input-group">
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Loyalty Name
                                                  </label>

                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={
                                                      data.multi_language[
                                                        data.multi_language
                                                          ?.length - 1
                                                      ]?.name
                                                    }
                                                    onChange={(e) =>
                                                      handleloyaltyChangelang(
                                                        data.multi_language
                                                          ?.length - 1,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="padding-5"></div>
                                <span className="be_form_loyalty_label_full">
                                  <p className="loyalty_form_subhead">
                                    Classification Rules
                                  </p>
                                </span>

                                <span className="loyalty_label_new loyalty_joint_style">
                                  <span
                                    className="input-group"
                                    style={{ width: "310px" }}
                                  >
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Minimum No.of Reservations{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <input
                                      type="number"
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      className="form-control"
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-default"
                                      value={data.minimum_reservation}
                                      name="minimum_reservation"
                                      onChange={(e) => handleChange(i, e)}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />

                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind &&
                                          err &&
                                          err.minimum_reservation ? (
                                            <label style={{ color: "red" }}>
                                              {err.minimum_reservation}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                </span>

                                <span
                                  className="loyalty_or_and_new"
                                  style={{ marginLeft: "45px" }}
                                >
                                  <select
                                    className="form-select"
                                    value={data.condition}
                                    onChange={(e) =>
                                      handleConditionChange(
                                        i,
                                        "condition",
                                        e.target.value
                                      )
                                    }
                                    style={{ width: "100px" }}
                                  >
                                    <option value="or">or</option>
                                    <option value="and">and </option>
                                  </select>
                                </span>

                                <span className="loyalty_label_new loyalty_joint_style">
                                  <span
                                    className="input-group"
                                    style={{
                                      width: "310px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Minimum No.of Room Nights{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <input
                                      type="number"
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      className="form-control"
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-default"
                                      name="minimum_room_nights"
                                      value={data.minimum_room_nights}
                                      onChange={(e) => handleChange(i, e)}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />

                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind &&
                                          err &&
                                          err.minimum_room_nights ? (
                                            <label style={{ color: "red" }}>
                                              {err.minimum_room_nights}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                </span>

                                <span
                                  className="loyalty_label_new loyalty_joint_style"
                                  style={{ marginLeft: "6%" }}
                                >
                                  <span
                                    className="input-group"
                                    style={{ width: "280px" }}
                                  >
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Amount Spend{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <input
                                      type="number"
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      className="form-control"
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-default"
                                      name="amount_spend"
                                      value={data.amount_spend}
                                      onChange={(e) => handleChange(i, e)}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                    <span
                                      className="input-group-text input-joint-color-loyal"
                                      id="inputGroup-sizing-default"
                                    >
                                      {currency.code}
                                    </span>

                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind &&
                                          err &&
                                          err.amount_spend ? (
                                            <label style={{ color: "red" }}>
                                              {err.amount_spend}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                </span>
                                <span className="padding-5"></span>

                                <div
                                  className="form-check form-check-inline"
                                  style={{
                                    float: "left",
                                    marginTop: "7px",
                                    right: "2%",
                                  }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Criteria
                                  </label>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input per_room_once_check"
                                      type="radio"
                                      id={`WithInlineRadioYes${i}`}
                                      name={`expiry_status${i}`}
                                      value={true}
                                      checked={data.expiry_status === true}
                                      onChange={(e) =>
                                        handleChangeExpiration(
                                          i,
                                          "expiry_status",
                                          true
                                        )
                                      }
                                    />
                                    <label>Yes</label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input per_room_once_check"
                                      type="radio"
                                      id={`WithInlineRadioNo${i}`}
                                      name={`expiry_status${i}`}
                                      value={false}
                                      checked={data.expiry_status === false}
                                      onChange={(e) =>
                                        handleChangeExpiration(
                                          i,
                                          "expiry_status",
                                          false
                                        )
                                      }
                                    />
                                    <label>No</label>
                                  </div>
                                </div>

                                {data.expiry_status === true ? (
                                  <span
                                    className="loyalty_period_new loyalty_joint_style"
                                    style={{ right: "1%" }}
                                  >
                                    <span
                                      className="input-group"
                                      style={{ width: "215px" }}
                                    >
                                      <span
                                        className="input-group-text input-joint-color1"
                                        id="inputGroup-sizing-default"
                                      >
                                        Period{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <div class="info-tag-new">i</div>
                                        <span className="tooltiptext-new_loyal">
                                          Only reservations or room nights in
                                          the last X years will be considered
                                        </span>
                                      </span>

                                      <input
                                        type="number"
                                        onKeyDown={(e) =>
                                          symbolsrate.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        min={0}
                                        className="form-control"
                                        aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-default"
                                        name="period"
                                        value={data.period}
                                        onChange={(e) => handleChange(i, e)}
                                        style={{ textAlign: "center" }}
                                        onFocus={(e) =>
                                          e.target.addEventListener(
                                            "wheel",
                                            function (e) {
                                              e.preventDefault();
                                            },
                                            { passive: false }
                                          )
                                        }
                                      />

                                      <span
                                        className="input-group-text input-joint-color1"
                                        id="inputGroup-sizing-default"
                                      >
                                        Year
                                      </span>
                                    </span>
                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind && err && err.period ? (
                                            <label style={{ color: "red" }}>
                                              {err.period}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                ) : (
                                  ""
                                )}

                                <span
                                  className="input-group loyalty_dis_sort"
                                  style={{ width: "260px" }}
                                >
                                  <span className="input-group">
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Discount{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <input
                                      type="number"
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      className="form-control"
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-default"
                                      name="discount"
                                      value={data.discount}
                                      onChange={(e) => handleChange(i, e)}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />

                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind && err && err.discount ? (
                                            <label style={{ color: "red" }}>
                                              {err.discount}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                    <span
                                      className=""
                                      style={{ width: "90px" }}
                                    >
                                      <select
                                        id="ForminputState"
                                        className="form-select"
                                        value={data.unit_value}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            i,
                                            "unit_value",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="currency" selected>
                                          {currency.code}
                                        </option>
                                        <option value="percentage">%</option>
                                      </select>
                                    </span>
                                  </span>
                                </span>

                                <span
                                  className="input-group loyalty_dis_sort_new"
                                  style={{ right: "2%" }}
                                >
                                  <span
                                    className="input-group"
                                    style={{ marginLeft: "14%" }}
                                  >
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Sort Order{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <input
                                      type="number"
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      className="form-control"
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-default"
                                      name="sort_order"
                                      value={data.sort_order}
                                      onChange={(e) => handleChange(i, e)}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />

                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind &&
                                          err &&
                                          err.sort_order ? (
                                            <label style={{ color: "red" }}>
                                              {err.sort_order}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                </span>

                                <span
                                  className="loyalty_label_status_new loyalty_joint_style"
                                  style={{ right: "2%" }}
                                >
                                  <span
                                    className="input-group"
                                    style={{
                                      width: "200px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>

                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={data.status}
                                      onChange={(e) =>
                                        handleStatusChange(i, e.target.value)
                                      }
                                    >
                                      <option value="" selected>
                                        Choose...
                                      </option>
                                      <option
                                        {...(data.status === true
                                          ? "selected"
                                          : "")}
                                        value={true}
                                      >
                                        Active
                                      </option>
                                      <option
                                        {...(data.status === false
                                          ? "selected"
                                          : "")}
                                        value={false}
                                      >
                                        Inactive
                                      </option>
                                    </select>
                                    {Error2.map((err, ind) => {
                                      return (
                                        <>
                                          {i === ind && err && err.status ? (
                                            <label style={{ color: "red" }}>
                                              {err.status}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </span>
                                </span>

                                <span className="padding-5"></span>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{
          fontFamily: "IBM Plex Sans,sans-serif",
          justifyContent: "center",
        }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ width: "770px" }}
        >
          <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
            <div
              className="row price-modify-filter"
              style={{ marginTop: "20px" }}
            >
              <form>
                <button
                  type="button"
                  className="btn btn-link link-success fw-medium text-decoration-none"
                  onClick={() => {
                    tog_animationFlip();
                  }}
                  style={{ float: "right", bottom: "30px" }}
                >
                  {" "}
                  <i
                    className="ri-close-line me-1 align-middle"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>

                <div className="be_loyalty_level">
                  <span className="padding-5"></span>
                  <span className="loyalty_name_popup">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Name of Loyalty Level{" "}
                      <span className="form-validation-required">*</span>
                      <div class="info-tag-new">i</div>
                      <span className="tooltiptext-new">
                        Name of the loyalty level you want to call, for example
                        Bronze
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={loyaltyadd.name}
                      name="name"
                      onChange={handleloyaltypop}
                    />
                    <label style={{ color: "red" }}>{Error.name}</label>
                  </span>

                  <span className="loyalty_name_popup">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Logo <div class="info-tag-new mar_1">i</div>
                      <span className="tooltiptext-new">
                        Upload logo in this particular level, only accept
                        jpg,jpeg,png format
                      </span>{" "}
                      <span className="span-img-size">( 140 px * 40px)</span>
                    </label>

                    <div className="form-translation-style_offer">
                      <ul
                        className="price_show_select_loyal"
                        id="price_show_type"
                      >
                        <li
                          className={tabimgcreate === "upload" ? "active" : ""}
                          onClick={(e) => setTabimagecreate("upload")}
                        >
                          <button
                            className="photo_offer_pen_choose"
                            type="button"
                          >
                            Upload Image
                          </button>
                          <input
                            className="photo_offer_input"
                            type="file"
                            name="image"
                            onChange={onFileChangecreate}
                            accept=".jpg, .jpeg, .webp, .png"
                          />
                        </li>

                        <li
                          className={tabimgcreate === "list" ? "active" : ""}
                          onClick={(e) => {
                            setPopupcreate(!popupcreate);
                            setTabimagecreate("list");
                          }}
                        >
                          <button
                            className="photo_offer_pen_choose"
                            type="button"
                          >
                            Choose From List
                          </button>
                        </li>
                      </ul>
                    </div>

                    <label className="label_image_crop">
                      {" "}
                      (<span class="form-validation-required"></span>if you do
                      not resize it as per the mentioned size, it can affect the
                      quality of the images)
                    </label>
                    {logoFile ? (
                      <div className="" style={{ float: "left" }}>
                        {logoFile ? (
                          logoFile instanceof File ? (
                            <p className="pdf_img_style_img_loyal">
                              {logoFile.name}
                            </p>
                          ) : (
                            <img
                              src={`${Commonimagepath.apiurl}${logoFile}`}
                              alt=""
                              className="position-absolute"
                              style={{
                                width: "24px",
                                marginTop: "21px",
                                marginLeft: "10px",
                                marginBottom: "10px",
                                height: "24px",
                              }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>

                  <div
                    className={
                      logoFile
                        ? !(logoFile instanceof File)
                          ? "padding_45 col-lg-5"
                          : "col-lg-5"
                        : "col-lg-5"
                    }
                    style={{ float: "left" }}
                  >
                    <div className="d-flex">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                        style={{
                          borderBottomRightRadius: "0px",
                          borderTopRightRadius: "0px",
                        }}
                      >
                        Add/edit in
                      </label>
                      <select
                        className="form-control form-select"
                        data-choices
                        data-choices-search-false
                        name="choices-single-default"
                        id="idPayment"
                        style={{
                          width: "180px",
                          height: "38px",
                          marginLeft: "-1px",
                        }}
                        value={newLangpop}
                        onChange={(e) =>
                          handleLanguageChangepop(e.target.value)
                        }
                      >
                        <option value="">Choose language</option>

                        {language.map((lang, inx) => {
                          return (
                            <option key={inx} value={lang.code}>
                              {lang.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {newLangpop ? (
                    <>
                      {selectedMulti.length > 0 ? (
                        <>
                          {selectedMulti.some(
                            (item) => item.lang_code === newLangpop
                          ) ? (
                            <div
                              className={
                                logoFile
                                  ? !(logoFile instanceof File)
                                    ? "padding_45 col-lg-6"
                                    : "col-lg-6"
                                  : "col-lg-6"
                              }
                              style={{ float: "right", marginRight: "50px" }}
                            >
                              <div
                                className="input-group"
                                style={{ width: "385px" }}
                              >
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Loyalty Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={
                                    selectedMulti[isMatchingLanguagepop]?.name
                                  }
                                  onChange={(e) =>
                                    handleloyaltyChangepop(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-lg-6"
                              style={{ float: "right", marginRight: "55px" }}
                            >
                              <div
                                className="input-group"
                                style={{ width: "385px" }}
                              >
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Loyalty Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={
                                    selectedMulti[selectedMulti?.length - 1]
                                      ?.name
                                  }
                                  onChange={(e) =>
                                    handleloyaltyChangepop(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <div className="padding-5"></div>

                  <span className="loyalty_label_new_pop loyalty_joint_style">
                    <span className="input-group" style={{ width: "280px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Minimum No.of Reservations{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={loyaltyadd.minimum_reservation}
                        name="minimum_reservation"
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>
                        {Error.minimum_reservation}
                      </label>
                    </span>
                  </span>
                  <span className="loyalty_or_and_new_pop">
                    <select
                      className="form-select"
                      value={loyaltyadd.condition}
                      name="condition"
                      onChange={handleCurrency}
                    >
                      <option value="or">or</option>
                      <option value="and">and </option>
                    </select>
                  </span>
                  <span
                    className="loyalty_label_new_pop loyalty_joint_style"
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="input-group" style={{ width: "280px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Minimum No.of Room Nights{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="minimum_room_nights"
                        value={loyaltyadd.minimum_room_nights}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>
                        {Error.minimum_room_nights}
                      </label>
                    </span>
                  </span>

                  <span className="loyalty_direct_new_popup loyalty_joint_style">
                    <span className="input-group" style={{ width: "300px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Amount Spend{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="amount_spend"
                        value={loyaltyadd.amount_spend}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />{" "}
                      <span
                        className="input-group-text input-joint-color-loyal"
                        id="inputGroup-sizing-default"
                        style={{
                          borderBottomRightRadius: "5px",
                          borderTopRightRadius: "5px",
                          width: "80px",
                        }}
                      >
                        {currency.code}
                      </span>
                      <label style={{ color: "red" }}>
                        {Error.amount_spend}
                      </label>
                    </span>
                  </span>

                  <span className="loyalty_label_new loyalty_joint_style">
                    <span className="input-group" style={{ width: "280px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Status{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <select
                        id="ForminputState"
                        className="form-select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="" selected>
                          Choose...
                        </option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                    </span>
                    <label style={{ color: "red" }}>{Error.status}</label>
                  </span>
                  <span
                    className="input-group loyalty_discount_popup"
                    style={{ width: "300px" }}
                  >
                    <span className="input-group">
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Discount{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsrate.includes(e.key) && e.preventDefault()
                        }
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="discount"
                        value={loyaltyadd.discount}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <span className="" style={{ right: "0" }}>
                        <select
                          id="ForminputState"
                          className="form-select"
                          value={loyaltyadd.unit_value}
                          name="unit_value"
                          onChange={handleCurrency}
                        >
                          <option value="currency" selected>
                            {currency.code}
                          </option>
                          <option value="percentage">%</option>
                        </select>
                      </span>
                      <label style={{ color: "red" }}>{Error.discount}</label>
                    </span>
                  </span>

                  <div
                    className="form-check form-check-inline"
                    style={{ float: "left", marginTop: "7px", right: "3%" }}
                  >
                    <label style={{ marginRight: "10px" }}>Criteria</label>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input per_room_once_check"
                        type="radio"
                        id="WithInlineRadioNo"
                        name="expiry_status"
                        value={true}
                        checked={loyaltyadd.expiry_status === true}
                        onChange={(e) =>
                          handleChangeExpirationpop("expiry_status", true)
                        }
                      />
                      <label>Yes</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input per_room_once_check"
                        type="radio"
                        id="WithInlineRadioNo"
                        name="expiry_status"
                        value={false}
                        checked={loyaltyadd.expiry_status === false}
                        onChange={(e) =>
                          handleChangeExpirationpop("expiry_status", false)
                        }
                      />
                      <label>No</label>
                    </div>
                  </div>
                  {loyaltyadd.expiry_status === true ? (
                    <span
                      className="loyalty_period_new_popup loyalty_joint_style"
                      style={{ right: "6%" }}
                    >
                      <span className="input-group" style={{ width: "205px" }}>
                        <span
                          className="input-group-text input-joint-color1"
                          id="inputGroup-sizing-default"
                        >
                          Period{" "}
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new">i</div>
                          <span className="tooltiptext-new_loyal">
                            Only reservations or room nights in the last X years
                            will be considered
                          </span>
                        </span>
                        <input
                          type="number"
                          onKeyDown={(e) =>
                            symbolsrate.includes(e.key) && e.preventDefault()
                          }
                          min={0}
                          className="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                          name="period"
                          value={loyaltyadd.period}
                          onChange={handleloyaltypop}
                          style={{ textAlign: "center" }}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />

                        <span
                          className="input-group-text input-joint-color1"
                          id="inputGroup-sizing-default"
                        >
                          Year
                        </span>
                        <label style={{ color: "red" }}>{Error.period}</label>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <button type="button" className="btn db-save-button" onClick={Add}>
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popup}
        toggle={() => {
          setPopup(!popup);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopup(!popup)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        alt=""
                        // alt={`Image ${ind + 1}`}
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() => handleImageClick(imgs)}
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popuploop}
        toggle={() => {
          setPopuploop(!popuploop);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopuploop(!popuploop)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={(e) => handleImageClickloop(imgs, popupid)}
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popupcreate}
        toggle={() => {
          setPopupcreate(!popupcreate);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopupcreate(!popupcreate)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() => handleImageClickcreate(imgs, popupid)}
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={levelid}
          message={"Loyalty"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Directrateloyaltylevel;
