import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Footer from "../../Common/Footer";
import Loyaltyheader from "../../Common/Loyaltyheader";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Commonimagepath from "../../Commonimagepath";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import DeleteModal from "../../DeleteModal";
import Loading from "../../Loading";

function Loyaltylevel() {
  const [loyalty, setLoyalty] = useState([]);
  const [commonlevel, setCommonlevel] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [multi, setMulti] = useState([]);
  const [status, setStatus] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const [newLang, setNewLang] = useState("");
  const [newLangpop, setNewLangpop] = useState("");
  const [langall, setLangall] = useState([]);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [loyaltyadd, setLoyaltyadd] = useState([]);
  const [isMatchingLanguagepop, setisMatchingLanguage] = useState("");
  const [loyaltybenefit, setLoyaltybenefit] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [Error, setError] = useState({});
  const [Error1, setError1] = useState([{}]);
  const [Error2, setError2] = useState([{}]);
  const [logoFile, setLogoFile] = useState("");
  const [levelid, setLevelid] = useState("");
  const [newlogo, setNewlogo] = useState("");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [popup, setPopup] = useState(false);
  const [tabimg, setTabimage] = useState("upload");
  const [optionimage, setOptionimage] = useState([]);
  const [popuploop, setPopuploop] = useState(false);
  const [tabimgloop, setTabimageloop] = useState("upload");
  const [popupid, setPopupId] = useState("");
  const [popupindex, setPopupIndex] = useState("");

  const [popupcreate, setPopupcreate] = useState(false);
  const [tabimgcreate, setTabimagecreate] = useState("upload");
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [list, setList] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);
  const [langCodes, setLangCodes] = useState([]);
  const [selehotel, setSelechotel] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("");

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
    setLogoFile("");
    setLoyaltyadd([]);
  }
  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(ids) {
    setLevelid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const getall =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}loyalty-levels/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data.hotels);
        setLanguage(data?.data?.optional_languages);
        setBenefit(data?.data?.benefit_data);
        setOptionimage(data?.data?.default_images);
        setLoading(true);
        // setLoyalty(data.data.loyalty_levels);
        // setMulti(data?.data?.basic_level?.multi_language);
        // setLangall(data?.data?.basic_level?.multi_language);
        // setCurrency(data?.data?.currency);
        // if (data?.data??.basic_level?.logo) {
        //   setNewlogo(data?.data?.basic_level?.logo);
        // }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getall();
      } else {
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getall();
    } else {
      console.log("error");
    }
  },[handleUnauthorized,id]);

  const handleloyalty = (index, e, name) => {
    setList((prevList) => {
      const newList = [...prevList];
      const value = e.target.value;
      if (!newList[index].basic_level) {
        newList[index].basic_level = {};
      }
      newList[index].basic_level[name] = value;
      return newList;
    });

    setChangeddata((prevChangedData) => {
      let updatedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === list[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedData[hotelToUpdateIndex] = {
          ...updatedData[hotelToUpdateIndex],
          basic_level: {
            ...updatedData[hotelToUpdateIndex].basic_level,
            [name]: e.target.value,
          },
        };
      } else {
        updatedData = [
          ...updatedData,
          {
            hotel_id: list[index].hotel_id,
            loyalty_status: list[index].loyalty_status,
            basic_level: {
              [name]: e.target.value,
            },
          },
        ];
      }

      return updatedData;
    });
  };

  const handleChange = (index, i, e) => {
    setList((prevList) => {
      const newList = [...prevList];
      newList[index].common_level[i][e.target.name] = e.target.value;
      return newList;
    });

    setChangeddata((prevChangedData) => {
      let updatedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === list[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedData[hotelToUpdateIndex] = {
          ...updatedData[hotelToUpdateIndex],
          common_level: [...list[index].common_level],
          loyalty_status: list[index].loyalty_status,
          basic_level: { ...list[index].basic_level },
        };
      } else {
        updatedData = [
          ...updatedData,
          {
            hotel_id: list[index].hotel_id,
            basic_level: { ...list[index].basic_level },
            loyalty_status: list[index].loyalty_status,
            common_level: [...list[index].common_level],
          },
        ];
      }

      return updatedData;
    });
  };

  const handleConditionChange = (index, i, name, value) => {
    const updatedValues = [...list];
    updatedValues[index].common_level[i][name] = value;
    setList(updatedValues);

    setChangeddata((prevChangedData) => {
      const updatedChangedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedChangedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === updatedValues[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedChangedData[hotelToUpdateIndex] = { ...updatedValues[index] };
      } else {
        updatedChangedData.push({ ...updatedValues[index] });
      }

      return updatedChangedData;
    });
  };

  const handleChangebenefit = (index, i, data) => {
    const updatedValues = [...list];
    updatedValues[index].common_level[i]["loyalty_benefits"] = data;
    setList(updatedValues);

    setChangeddata((prevChangedData) => {
      const updatedChangedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedChangedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === updatedValues[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedChangedData[hotelToUpdateIndex] = { ...updatedValues[index] };
      } else {
        updatedChangedData.push({ ...updatedValues[index] });
      }

      return updatedChangedData;
    });
  };

  const handleLanguageChangebasic = (index, selectedOption) => {
    const updatedLanguages = [...list];
    updatedLanguages[index]["newlang"] = selectedOption;
    if (
      updatedLanguages[index].basic_level === null ||
      !updatedLanguages[index].basic_level.multi_language
    ) {
      updatedLanguages[index].basic_level = {
        ...updatedLanguages[index].basic_level,
        multi_language: [{ lang_code: selectedOption, title: "" }],
      };
    } else {
      updatedLanguages[index].newlang = selectedOption;
      if (
        !updatedLanguages[index].basic_level.multi_language.some(
          (item) => item.lang_code === selectedOption
        )
      ) {
        updatedLanguages[index].basic_level.multi_language.push({
          lang_code: selectedOption,
          title: "",
        });
      }
    }
    setList(updatedLanguages);

    setChangeddata((prevChangedData) => {
      let updatedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === list[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedData[hotelToUpdateIndex] = {
          ...updatedData[hotelToUpdateIndex],
          basic_level: { ...list[index].basic_level },
        };
      } else {
        updatedData = [
          ...updatedData,
          {
            hotel_id: list[index].hotel_id,
            loyalty_status: list[index].loyalty_status,
            basic_level: { ...list[index].basic_level },
          },
        ];
      }

      return updatedData;
    });
    // const changedHotel = updatedLanguages[index];
    // changeddata[index] = changedHotel;
  };

  const handleloyaltyChangelangbasic = (index, Value) => {
    const updatedList = [...list];
    const matchingIndex = updatedList[
      index
    ].basic_level.multi_language.findIndex(
      (language) => language.lang_code === list[index].newlang
    );
    if (matchingIndex !== -1) {
      updatedList[index].basic_level["multi_language"][matchingIndex]["title"] =
        Value;
    } else {
      const newLanguage = { lang_code: list[index].newlang };
      newLanguage["title"] = Value;
      updatedList[index].basic_level["multi_language"].push(newLanguage);
    }
    setList(updatedList);
    setChangeddata((prevChangedData) => {
      let updatedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === list[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedData[hotelToUpdateIndex] = {
          ...updatedData[hotelToUpdateIndex],
          basic_level: { ...list[index].basic_level },
        };
      } else {
        updatedData = [
          ...updatedData,
          {
            hotel_id: list[index].hotel_id,
            loyalty_status: list[index].loyalty_status,
            basic_level: { ...list[index].basic_level },
          },
        ];
      }

      return updatedData;
    });
  };

  const handleLanguageChange = (index, i, selectedOption) => {
    setList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index]["newlangsub"] = selectedOption;

      const commonLevel = { ...updatedList[index].common_level[i] };
      if (
        !commonLevel.multi_language?.some(
          (item) => item.lang_code === selectedOption
        )
      ) {
        commonLevel.multi_language.push({
          lang_code: selectedOption,
          title: "",
        });
      }
      updatedList[index].common_level[i] = commonLevel;

      setChangeddata((prevChangedData) => {
        const updatedChangedData = [...prevChangedData];
        const hotelToUpdateIndex = updatedChangedData.findIndex(
          (hotel) => hotel && hotel.hotel_id === updatedList[index].hotel_id
        );

        if (hotelToUpdateIndex !== -1) {
          updatedChangedData[hotelToUpdateIndex] = { ...updatedList[index] };
        } else {
          updatedChangedData.push({ ...updatedList[index] });
        }

        return updatedChangedData;
      });

      return updatedList;
    });
  };

  const handleloyaltyChangelang = (index, i, Value) => {
    setList((prevList) => {
      const updatedList = [...prevList];
      const matchingIndex = updatedList[index].common_level[
        i
      ]?.multi_language?.findIndex(
        (language) => language.lang_code === prevList[index].newlangsub
      );

      if (matchingIndex !== -1) {
        updatedList[index].common_level[i]["multi_language"][matchingIndex][
          "title"
        ] = Value;
      } else {
        const newLanguage = {
          lang_code: prevList[index].newlangsub,
          title: Value,
        };
        updatedList[index].common_level[i]?.multi_language?.push(newLanguage);
      }

      setChangeddata((prevChangedData) => {
        const updatedChangedData = [...prevChangedData];
        const hotelToUpdateIndex = updatedChangedData.findIndex(
          (hotel) => hotel && hotel.hotel_id === updatedList[index].hotel_id
        );

        if (hotelToUpdateIndex !== -1) {
          updatedChangedData[hotelToUpdateIndex] = { ...updatedList[index] };
        } else {
          updatedChangedData.push({ ...updatedList[index] });
        }

        return updatedChangedData;
      });

      return updatedList;
    });
  };

  const handleLanguageChangenew = (selectedOption) => {
    setNewLang(selectedOption);
    const updatedLanguages = [...multi];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
        updatedLanguages.push({ lang_code: selectedOption, title: "" });
      }
    } else {
      updatedLanguages.push({ lang_code: selectedOption, title: "" });
    }
    setMulti(updatedLanguages);
    // setLoyalty({...loyalty,
    //   basic_level: {...loyalty.basic_level, multi_language: updatedLanguages}
    // });
  };

  const handleloyallangChange = (addonValue) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["title"] = addonValue;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["title"] = addonValue;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  const handleStatusChange = (index, i, selectedStatus) => {
    setList((prevList) => {
      const updatedList = [...prevList];
      const commonLevel = { ...updatedList[index] };

      if (commonLevel.common_level && commonLevel.common_level[i]) {
        commonLevel.common_level[i] = {
          ...commonLevel.common_level[i],
          status: selectedStatus,
        };
      }

      updatedList[index] = commonLevel;

      setChangeddata((prevChangedData) => {
        const updatedChangedData = [...prevChangedData];
        const hotelToUpdateIndex = updatedChangedData.findIndex(
          (hotel) => hotel && hotel.hotel_id === commonLevel.hotel_id
        );

        if (hotelToUpdateIndex !== -1) {
          updatedChangedData[hotelToUpdateIndex] = { ...commonLevel };
        } else {
          updatedChangedData.push({ ...commonLevel });
        }

        return updatedChangedData;
      });

      return updatedList;
    });
  };

  const handleStatusbasic = (index, selectedStatus) => {
    const newList = [...list];
    const changedHotel = {
      ...newList[index],
      basic_level: {
        ...newList[index]?.basic_level,
        status: selectedStatus,
      },
    };
    newList[index] = changedHotel;
    setList(newList);

    setChangeddata((prevChangedData) => {
      let updatedData = [...prevChangedData];
      const hotelToUpdateIndex = updatedData.findIndex(
        (hotel) => hotel && hotel.hotel_id === newList[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        updatedData[hotelToUpdateIndex] = {
          ...updatedData[hotelToUpdateIndex],
          basic_level: {
            ...updatedData[hotelToUpdateIndex]?.basic_level,
            status: selectedStatus,
          },
        };
      } else {
        updatedData = [
          ...updatedData,
          {
            hotel_id: newList[index].hotel_id,
            basic_level: {
              ...updatedData[hotelToUpdateIndex]?.basic_level,
              status: selectedStatus,
            },
          },
        ];
      }

      return updatedData;
    });
  };

  let handleloyaltypop = (e) => {
    let newval = { ...loyaltyadd };
    let name = e.target.name;
    let value = e.target.value;
    newval[name] = value;
    setLoyaltyadd(newval);
  };

  const handleChangeExpiration = (index, i, name, value) => {
    setList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index].common_level[i][name] = value;

      setChangeddata((prevChangedData) => {
        const changedDataCopy = [...prevChangedData];
        const hotelToUpdateIndex = changedDataCopy.findIndex(
          (hotel) => hotel && hotel.hotel_id === updatedList[index].hotel_id
        );

        if (hotelToUpdateIndex !== -1) {
          changedDataCopy[hotelToUpdateIndex] = {
            ...changedDataCopy[hotelToUpdateIndex],
            common_level: [...updatedList[index].common_level],
            loyalty_status: list[index].loyalty_status,
            basic_level: { ...list[index].basic_level },
          };
        } else {
          changedDataCopy.push({
            hotel_id: updatedList[index].hotel_id,
            loyalty_status: list[index].loyalty_status,
            common_level: [...updatedList[index].common_level],
            basic_level: { ...list[index].basic_level },
          });
        }

        return changedDataCopy;
      });

      return updatedList;
    });
  };

  const handleChangeExpirationpop = (name, value) => {
    setLoyaltyadd((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLanguageChangepop = (selectedOption) => {
    setNewLangpop(selectedOption);
    const updatedLanguages = [...selectedMulti];

    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages.push({ lang_code: selectedOption, title: "" });
    }
    setSelectedMulti(updatedLanguages);
  };

  const handleloyaltyChangepop = (Value) => {
    const updatedList = [...selectedMulti];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLangpop
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["title"] = Value;
    } else {
      const newLanguage = { lang_code: newLangpop };
      newLanguage["title"] = Value;
      updatedList.push(newLanguage);
    }
    setSelectedMulti(updatedList);
  };

  const handleCurrency = (e) => {
    const { name, value } = e.target;
    setLoyaltyadd((prevLoyaltyadd) => ({
      ...prevLoyaltyadd,
      [name]: value,
    }));
  };

  const handleloyaltyToggle = (index, isChecked, name) => {
    setList((prevList) => {
      const newList = prevList.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [name]: isChecked,
          };
        }
        return item;
      });
      return newList;
    });

    setChangeddata((prevChangedData) => {
      const updatedData = prevChangedData.map((hotel) => {
        if (hotel && hotel.hotel_id === list[index].hotel_id) {
          return {
            ...hotel,
            [name]: isChecked,
            loyalty_status: list[index].loyalty_status,
            basic_level: { ...list[index].basic_level },
          };
        }
        return hotel;
      });

      if (
        !updatedData.some((hotel) => hotel.hotel_id === list[index].hotel_id)
      ) {
        updatedData.push({
          hotel_id: list[index].hotel_id,
          loyalty_status: list[index].loyalty_status,
          basic_level: { ...list[index].basic_level },
          [name]: isChecked,
        });
      }

      return updatedData;
    });
  };

  let handleLogopop = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const getCommonlevel =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}common-loyalty-levels/active`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCommonlevel(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getCommonlevel();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getCommonlevel();
    } else {
      console.log("error");
    }
  },[handleUnauthorized]);

  const Add = async () => {
    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!loyaltyadd.name) {
      isFormValid = false;
      errors.name = "Loyalty name is required!";
    }
    else if (!noSpecialCharRegex.test(loyaltyadd.name)) {
      isFormValid = false;
      errors.name = "Loyalty name should not contain this special character";
    }
    else if (loyaltyadd.name.length > 50) {
      isFormValid = false;
      errors.name = `Loyalty name cannot be more than 50 characters!`;
    }

    if (!loyaltyadd.minimum_reservation) {
      isFormValid = false;
      errors.minimum_reservation = "Minimum reservation is required!";
    }
    if (!loyaltyadd.minimum_room_nights) {
      isFormValid = false;
      errors.minimum_room_nights = "Minimum room night is required!";
    }
    if (!loyaltyadd.amount_spend) {
      isFormValid = false;
      errors.amount_spend = "Amount spend is required!";
    }
    if (loyaltyadd.expiry_status === true) {
      if (!loyaltyadd.period) {
        isFormValid = false;
        errors.period = "Period is required!";
      }
    }
    if (!loyaltyadd.sort_order) {
      isFormValid = false;
      errors.sort_order = "Sort order is required!";
    }
    if (!loyaltyadd.discount) {
      isFormValid = false;
      errors.discount = "Discount is required!";
    }
    if (loyaltybenefit.length === 0) {
      isFormValid = false;
      errors.loyaltybenefit = "This field is required!";
    }
    if (status.length === 0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", list[selehotel]?.hotel_id);
      formData.append("en_name", loyaltyadd.name);
      formData.append("reservation", loyaltyadd.minimum_reservation);
      if (loyaltyadd.condition) {
        formData.append("condition", loyaltyadd.condition);
      } else {
        formData.append("condition", "or");
      }
      formData.append("min_room_nights", loyaltyadd.minimum_room_nights);
      if (loyaltyadd.expiry_status === true) {
        formData.append("expiry_status", 1);
      } else {
        formData.append("expiry_status", 0);
      }
      if (loyaltyadd.expiry_status === true) {
        formData.append("expirations", loyaltyadd.period);
      }
      // formData.append("expirations", "");
      formData.append("status", status);
      if (logoFile) {
        if (newlogo instanceof File) {
          formData.append("logo", logoFile);
        } else {
          formData.append(" choosen_image", logoFile);
        }
      }
      formData.append("discount", loyaltyadd.discount);
      if (loyaltyadd.unit_value) {
        formData.append("unit_value", loyaltyadd.unit_value);
      } else {
        formData.append("unit_value", "currency");
      }
      formData.append("amount_spend", loyaltyadd.amount_spend);
      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(
          `multi_lang[${i}][lang_code]`,
          selectedMulti[i].lang_code
        );
        formData.append(`multi_lang[${i}][name]`, selectedMulti[i].title);
      }
      for (let i = 0; i < loyaltybenefit.length; i++) {
        formData.append(`benefit[${i}]`, loyaltybenefit[i].value);
      }

      const validCommonLevels = commonlevel.filter(level => level.sort_order > 0); 
      let levelToPass = null;
      for (let i = 0; i < list.length; i++) {
        if (list[i].common_level.length === 0) {
          levelToPass = validCommonLevels.find(level => level.sort_order === 1);
        } 
        else if (list[i].common_level.length === 1) {
          levelToPass = validCommonLevels.find(level => level.sort_order === 2);
        } 
        else if (list[i].common_level.length === 2) {
          levelToPass = validCommonLevels.find(level => level.sort_order === 3);
        }
        else if (list[i].common_level.length === 3) {
          levelToPass = validCommonLevels.find(level => level.sort_order === 4);
        }
    }

    if (levelToPass) {
      formData.append(`common_level_id`, levelToPass.value); 
    }

      axios
        .post(`${Config.apiurl}hotel/loyalty/levels/add`, formData, {})
        .then((res) => {
          if (res.status_code === "200") {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            tog_animationFlip(false);
            getall();
            setLoyaltyadd([]);
            setLogoFile("");
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          }
        });
    }
  };


  const Update = async () => {
    let isFormValid = true;
    let isFormValid1 = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    let arr1 = [];
    let arr = [];

    for (let i = 0; i < list?.length; i++) {
      const errors = {};

      if (!list[i]?.basic_level?.name) {
        isFormValid = false;
        errors.name = "Loyalty name is required!";
      }
      else if (!noSpecialCharRegex.test(list[i]?.basic_level?.name)) {
        isFormValid = false;
        errors.name = "Loyalty name should not contain this special character";
      }
      else if (list[i]?.basic_level?.name.length > 50) {
        isFormValid = false;
        errors.name = `Loyalty name cannot be more than 50 characters!`;
      }
      if (!list[i]?.basic_level?.status) {
        isFormValid = false;
        errors.status = "Status is required!";
      }
      for (let j = 0; j < list[i].common_level?.length; j++) {
        const error = {};
        if (!list[i].common_level[j]?.name) {
          isFormValid1 = false;
          error.name = "Loyalty name is required!";
        }
        else if (!noSpecialCharRegex.test(list[i].common_level[j]?.name)) {
          isFormValid1 = false;
          error.name = "Loyalty name should not contain this special character";
        }
        else if (list[i].common_level[j]?.name.length > 50) {
          isFormValid1 = false;
          error.name = `Loyalty name cannot be more than 50 characters!`;
        }
        if (!list[i].common_level[j]?.minimum_reservation) {
          isFormValid1 = false;
          error.minimum_reservation = "Minimum reservation is required!";
        }
        if (!list[i].common_level[j]?.minimum_room_nights) {
          isFormValid1 = false;
          error.minimum_room_nights = "Minimum room night is required!";
        }
        if (!list[i].common_level[j]?.amount_spend) {
          isFormValid1 = false;
          error.amount_spend = "Amount spend is required!";
        }
        if (list[i].common_level[j]?.expiry_status === true) {
          if (!list[i].common_level[j]?.period) {
            isFormValid1 = false;
            error.period = "Period is required!";
          }
        }
        if (!list[i].common_level[j]?.sort_order) {
          isFormValid1 = false;
          error.sort_order = "Sort order is required!";
        }
        if (!list[i].common_level[j]?.discount) {
          isFormValid1 = false;
          error.discount = "Discount is required!";
        }
        if (list[i].common_level[j]?.loyalty_benefits?.length === 0) {
          isFormValid = false;
          error.loyalty_benefits = "This field is required!";
        }
        if (list[i].common_level[j]?.status?.length === 0) {
          isFormValid = false;
          error.status = "This field is required!";
        }
        arr.push(error);
      }
      arr1.push(errors);
    }
    setError1(arr1);
    setError2(arr);

    if (isFormValid === true && isFormValid1 === true) {
      const formData = new FormData();
      if (changeddata?.length > 0) {
        for (let i = 0; i < changeddata?.length; i++) {
          if (changeddata[i] !== undefined) {
            formData.append(`hotels[${i}][hotel_id]`, changeddata[i].hotel_id);
            formData.append(
              `hotels[${i}][loyalty_status]`,
              changeddata[i].loyalty_status === true ? 1 : 0
            );

            if (changeddata[i].basic_level?.level_id) {
              formData.append(
                `hotels[${i}][basic_level_id]`,
                changeddata[i].basic_level?.level_id
              );
            }
            formData.append(
              `hotels[${i}][basic_level_name]`,
              changeddata[i].basic_level?.name
            );
            const basicLevelWithSortOrder0 = commonlevel.find(
              (level) => level.sort_order === 0
            );
            if (basicLevelWithSortOrder0) {
              formData.append(
                `hotels[${i}][basic_common_level_id]`,
                basicLevelWithSortOrder0.value
              );
            }
            formData.append(
              `hotels[${i}][basic_level_status]`,
              changeddata[i].basic_level?.status === true ||
                changeddata[i].basic_level?.status === "true"
                ? 1
                : 0
            );
            if (langCodes.length > 0) {
              for (let r = 0; r < langCodes?.length; r++) {
                formData.append(`lang_code[${r}]`, langCodes[r]);
              }
            }
            if (changeddata[i].basic_level?.logo) {
              if (changeddata[i].basic_level?.logo instanceof File) {
                formData.append(
                  `hotels[${i}][basic_level_logo]`,
                  changeddata[i].basic_level?.logo
                );
              } else {
                formData.append(
                  `hotels[${i}][basic_level_choosen_image]`,
                  changeddata[i].basic_level?.logo
                );
              }
            }
            for (
              let j = 0;
              j < changeddata[i].basic_level?.multi_language?.length;
              j++
            ) {
              formData.append(
                `hotels[${i}][basic_level_multi_lang][${j}][lang_code]`,
                changeddata[i].basic_level?.multi_language[j]?.lang_code
              );
              formData.append(
                `hotels[${i}][basic_level_multi_lang][${j}][name]`,
                changeddata[i].basic_level?.multi_language[j]?.title
              );
            }
            for (let k = 0; k < changeddata[i].common_level?.length; k++) {
              formData.append(
                `hotels[${i}][level][${k}][level_id]`,
                changeddata[i].common_level[k].level_id
              );
              formData.append(
                `hotels[${i}][level][${k}][name]`,
                changeddata[i].common_level[k].name
              );
              if (changeddata[i].common_level[k].logo) {
                if (changeddata[i].common_level[k].logo instanceof File) {
                  formData.append(
                    `hotels[${i}][level][${k}][logo]`,
                    changeddata[i].common_level[k].logo
                  );
                } else {
                  formData.append(
                    `hotels[${i}][level][${k}][choosen_image]`,
                    changeddata[i].common_level[k].logo
                  );
                }
              }
              formData.append(
                `hotels[${i}][level][${k}][status]`,
                changeddata[i].common_level[k].status
              );
              formData.append(
                `hotels[${i}][level][${k}][reservation]`,
                changeddata[i].common_level[k].minimum_reservation
              );
              if (changeddata[i].common_level[k].condition) {
                formData.append(
                  `hotels[${i}][level][${k}][condition]`,
                  changeddata[i].common_level[k].condition
                );
              } else {
                formData.append(`hotels[${i}][level][${k}][condition]`, "or");
              }
              formData.append(
                `hotels[${i}][level][${k}][minimum_room_nights]`,
                changeddata[i].common_level[k].minimum_room_nights
              );
              formData.append(
                `hotels[${i}][level][${k}][discount]`,
                changeddata[i].common_level[k].discount
              );
              if (changeddata[i].common_level[k].unit_value) {
                formData.append(
                  `hotels[${i}][level][${k}][unit_value]`,
                  changeddata[i].common_level[k].unit_value
                );
              } else {
                formData.append(
                  `hotels[${i}][level][${k}][unit_value]`,
                  "currency"
                );
              }
              if (changeddata[i].common_level[k].expiry_status === true) {
                formData.append(`hotels[${i}][level][${k}][expiry_status]`, 1);
              } else {
                formData.append(`hotels[${i}][level][${k}][expiry_status]`, 0);
              }
              if (changeddata[i].common_level[k].expiry_status === true) {
                formData.append(
                  `hotels[${i}][level][${k}][period]`,
                  changeddata[i].common_level[k].period
                );
              }
              formData.append(
                `hotels[${i}][level][${k}][sort_order]`,
                changeddata[i].common_level[k].sort_order
              );
              formData.append(
                `hotels[${i}][level][${k}][amount_spend]`,
                changeddata[i].common_level[k].amount_spend
              );
              for (
                let l = 0;
                l < changeddata[i].common_level[k].loyalty_benefits.length;
                l++
              ) {
                formData.append(
                  `hotels[${i}][level][${k}][benefit][${l}]`,
                  changeddata[i].common_level[k].loyalty_benefits[l].value
                );
              }
              for (
                let m = 0;
                m < changeddata[i].common_level[k].multi_language?.length;
                m++
              ) {
                formData.append(
                  `hotels[${i}][level][${k}][multi_lang][${m}][lang_code]`,
                  changeddata[i].common_level[k].multi_language[m]?.lang_code
                );
                formData.append(
                  `hotels[${i}][level][${k}][multi_lang][${m}][name]`,
                  changeddata[i].common_level[k].multi_language[m]?.title
                );
              }
              
              let validCommonLevels = commonlevel.filter(level => level.sort_order !== 0);
              let maxLevels = changeddata[i].common_level.length;
              let selectedCommonLevels = validCommonLevels.slice(0, maxLevels);
              
              for (let n = 0; n < selectedCommonLevels.length; n++) {
                formData.append(
                  `hotels[${i}][level][${k}][common_level_id]`,
                  selectedCommonLevels[n].value
                );
              }
            }
          }
        }
        axios
          .post(`${Config.apiurl}loyalty-levels/bulk-update`, formData, {})
          .then((res) => {
            if (res.status_code !== "200" || !res) {
              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("error");
              setToastlarge(true);
            } else {
              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("success");
              setToastlarge(true);
            }
          });
      } else {
        setToastmodal(true);
        setToastmessage("You have made no changes to save");
        setToasttype("success");
        setToastlarge(true);
      }
    }
  };

  const Delete = async (ids) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty/levels/delete`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: list[selehotel]?.hotel_id,
        level_id: ids,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      getall();
      tog_animationFlipDel(false);
      loyaltybenefit([]);
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  };

  useEffect(() => {
    getCommonlevel();
  }, [id,getCommonlevel]);

  useEffect(() => {
    getall();
  }, [id,getall]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [list]);

  // useEffect(() => {
  //   const isMatching = multi?.findIndex(
  //     (language) => language.lang_code === newLang
  //   );
  //   setisMatchingLanguagenew(isMatching);
  // }, [newLang]);

  // useEffect(() => {
  //   let newval;

  //   langall?.forEach((lang) => {
  //     if (lang.title) {
  //       newval = lang.lang_code;
  //     }
  //   });
  //   setNewLang(newval);
  // }, [langall?.length]);

  useEffect(() => {
    let newarray = [];
    let lang;
    const updatedList = [...list];
    for (let i = 0; i < updatedList.length; i++) {
      for (
        let j = 0;
        j < updatedList[i].basic_level?.multi_language?.length;
        j++
      ) {
        let ar = {};
        ar.lang_code = updatedList[i].basic_level?.multi_language[j]?.lang_code;
        ar.title = updatedList[i].basic_level?.multi_language[j]?.title;
        newarray.push(ar);
        lang = updatedList[i].basic_level?.multi_language[j]?.lang_code;
      }
      updatedList[i].newlang = lang;
    }
  }, [list]);

  useEffect(() => {
    let newarray = [];
    let lang;
    const updatedList = [...list];
    for (let i = 0; i < updatedList.length; i++) {
      for (let j = 0; j < updatedList[i]?.common_level?.length; j++) {
        for (
          let k = 0;
          k < updatedList[i]?.common_level[j]?.multi_language?.length;
          k++
        ) {
          let ar = {};
          ar.lang_code =
            updatedList[i]?.common_level[j]?.multi_language[j]?.lang_code;
          ar.title = updatedList[i]?.common_level[j]?.multi_language[j]?.title;
          newarray.push(ar);
          lang = updatedList[i]?.common_level[j]?.multi_language[j]?.lang_code;
        }
      }

      updatedList[i].newlangsub = lang;
    }
  }, [list]);

  useEffect(() => {
    const isMatching = selectedMulti.findIndex(
      (language) => language.lang_code === newLangpop
    );
    setisMatchingLanguage(isMatching);
  }, [newLangpop,selectedMulti]);

  // const onFileChange = (e) => {
  //   setPopup(false);
  //   setNewlogo(e.target.files[0]);
  //   e.target.value = null;
  // };

  const onFileChange = (index, event) => {
    setPopup(false);
    const selectedFile = event.target.files[0];

    setList((prevList) => {
      const updatedList = prevList.map((item, i) => {
        if (i === index && item.basic_level) {
          return {
            ...item,
            basic_level: {
              ...item.basic_level,
              logo: selectedFile,
            },
          };
        }
        return item;
      });
      const updatedChangedData = [...changeddata];
      updatedChangedData[index] = { ...updatedList[index] };
      setChangeddata(updatedChangedData);

      return updatedList;
    });

    event.target.value = null;
  };

  const onFileChangelopp = (index, i, event) => {
    setPopuploop(false);

    const selectedFile = event.target.files[0];

    setList((prevCommonLevel) => {
      const updatedCommonLevel = [...prevCommonLevel];
      updatedCommonLevel[index].common_level[i] = {
        ...updatedCommonLevel[index].common_level[i],
        logo: selectedFile,
      };
      const updatedChangedData = [...changeddata];
      updatedChangedData[index] = { ...updatedCommonLevel[index] };
      setChangeddata(updatedChangedData);

      return updatedCommonLevel;
    });
    event.target.value = null;
  };

  const handleImageClickloop = (imgsrc, index, i) => {
    setList((prevCommonLevel) => {
      const updatedCommonLevel = [...prevCommonLevel];
      updatedCommonLevel[index].common_level[i] = {
        ...updatedCommonLevel[index].common_level[i],
        logo: imgsrc,
      };

      setPopuploop(false);

      setChangeddata((prevChangedData) => {
        const changedDataCopy = [...prevChangedData];
        const hotelToUpdateIndex = changedDataCopy.findIndex(
          (hotel) =>
            hotel && hotel.hotel_id === updatedCommonLevel[index].hotel_id
        );

        if (hotelToUpdateIndex !== -1) {
          // Ensure common_level is an array
          if (
            !Array.isArray(changedDataCopy[hotelToUpdateIndex].common_level)
          ) {
            changedDataCopy[hotelToUpdateIndex].common_level = [];
          }
          changedDataCopy[hotelToUpdateIndex].common_level.push(
            updatedCommonLevel[index].common_level[i]
          );
        } else {
          changedDataCopy.push({
            hotel_id: updatedCommonLevel[index].hotel_id,
            common_level: [updatedCommonLevel[index].common_level[i]],
          });
        }

        return changedDataCopy;
      });

      return updatedCommonLevel;
    });
  };

  const handleImageClick = (imageSrc, index) => {
    setList((prevList) => {
      const updatedList = [...prevList];
      if (updatedList[index].basic_level) {
        updatedList[index].basic_level = {
          ...updatedList[index].basic_level,
          logo: imageSrc,
        };
      }

      const changedDataCopy = [...changeddata];
      const hotelToUpdateIndex = changedDataCopy.findIndex(
        (hotel) => hotel && hotel.hotel_id === updatedList[index].hotel_id
      );

      if (hotelToUpdateIndex !== -1) {
        changedDataCopy[hotelToUpdateIndex] = updatedList[index];
      } else {
        changedDataCopy.push(updatedList[index]);
      }

      setChangeddata(changedDataCopy);
      return updatedList;
    });

    setPopup(false);
  };

  // const handleImageClickloop = (imgsrc, index, i) => {
  //   setList((prevCommonLevel) => {
  //     const updatedCommonLevel = [...prevCommonLevel];
  //     updatedCommonLevel[index].common_level[i] = {
  //       ...updatedCommonLevel[index]?.common_level[i],
  //       logo: imgsrc,
  //     };

  //     const changedDataCopy = [...changeddata];

  //     changedDataCopy.forEach((hotel, dataIndex) => {
  //       if (hotel && hotel.hotel_id === updatedCommonLevel[index].hotel_id) {
  //         changedDataCopy[dataIndex] = {
  //           ...changedDataCopy[dataIndex],
  //           common_level: updatedCommonLevel[index].common_level[i],
  //         };
  //       }
  //     });

  //     setChangeddata(changedDataCopy);
  //     return updatedCommonLevel;
  //   });
  //   setPopuploop(false);
  // };

  // const handleImageClick = (imageSrc, index) => {
  //   setList((prevList) => {
  //     const updatedList = [...prevList];

  //     if (updatedList[index].basic_level) {
  //       updatedList[index].basic_level = {
  //         ...updatedList[index].basic_level,
  //         logo: imageSrc,
  //       };
  //     }

  //     const updatedChangedData = [...changeddata];
  //     updatedChangedData[index] = updatedList[index];
  //     setChangeddata(updatedChangedData);

  //     return updatedList;
  //   });

  //   setPopup(false);
  // };

  const onFileChangecreate = (e) => {
    setPopupcreate(false);
    setLogoFile(e.target.files[0]);

    e.target.value = null;
  };

  const handleImageClickcreate = (imageSrc) => {
    setLogoFile(imageSrc);

    setPopupcreate(false);
  };

  useEffect(() => {
    const allLangCodes = [];

    changeddata?.forEach((data) => {
      if (data?.basic_level && data.basic_level?.multi_language) {
        data.basic_level?.multi_language?.forEach((item) => {
          if (item && !allLangCodes.includes(item.lang_code)) {
            allLangCodes.push(item.lang_code);
          }
        });
      }

      if (data.common_level && data.common_level?.length > 0) {
        data.common_level?.forEach((level) => {
          if (level.multi_language) {
            level?.multi_language?.forEach((item) => {
              if (item && !allLangCodes.includes(item.lang_code)) {
                allLangCodes.push(item.lang_code);
              }
            });
          }
        });
      }
    });

    setLangCodes(allLangCodes);
  }, [changeddata]);

  useEffect(() => {
    const selectedHotelId = list[selehotel]?.hotel_id; 
  
    const hotel = list.find((item) => item.hotel_id === selectedHotelId);
    if (hotel) {
      setCurrencyCode(hotel.currency.code);
    }
  }, [selehotel, list]);

  // useEffect(() => {
  //   const hotel = list.find(
  //     (item) => item.hotel_id === list[selehotel]?.hotel_id
  //   );
  //   if (hotel) {
  //     setCurrencyCode(hotel.currency.code);
  //   }
  // }, [list[selehotel]?.hotel_id]);

  return (
    <div>
      <Loyaltyheader />
      {list?.length === 0 ? (
        ""
      ) : (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="">
               
                <div className="card">
                
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="db-page-title">Loyalty Level</h4>
                      </div>

                      <div
                        className="flex-shrink-0 d-flex justify-content-between"
                        style={{ float: "right" }}
                      >
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button-loyalty"
                          style={{ height: "30px" }}
                          onClick={tog_animationFlip}
                        >
                          Create New Loyalty
                        </Link>
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Update}
                        >
                          {" "}
                          Update
                        </Link>
                      </div>
                      {loading===false?
                        <Loading/>
                        :
                      <div className="row" style={{ display: "contents" }}>
                        {list?.map((li, index) => {
                          const isMatchingLanguage =
                            li?.basic_level?.multi_language?.findIndex(
                              (language) => language.lang_code === li.newlang
                            );
                          return (
                            <>
                              <div
                                className="row hotel_single_list"
                                key={index}
                              >
                                <div className="hotel_service_display hotel_service_display_12">
                                  <div className="hotel_service_display_14">
                                    {list.length > 1 ? (
                                      <input
                                        type="radio"
                                        id="radio-one"
                                        className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                        value={true}
                                        checked={
                                          index === selehotel ? true : false
                                        }
                                        onChange={(e) => setSelechotel(index)}
                                        style={{
                                          position: "relative",
                                          top: "8px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div className="hk_service_all_hotel">
                                      <span className=""> {index + 1}.</span>
                                      <span style={{ marginLeft: "10px" }}>
                                        {li.hotel_name}
                                      </span>
                                    </div>
                                  </div>
                                  {showFullData[index]?.hotel === true ? (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(index)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-up"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  ) : (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(index)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-down"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  )}
                                </div>
                                <div
                                  className={`row ${
                                    showFullData[index]?.hotel === true
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <div
                                    className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                    dir="ltr"
                                    style={{ right: "2%" }}
                                  >
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineswitch5"
                                      style={{
                                        marginRight: "40px",
                                        float: "left",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Activate
                                    </label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input offer_checkbox1"
                                      id="inlineswitch5"
                                      style={{ width: "50px", height: "25px" }}
                                      value={li.loyalty_status}
                                      checked={
                                        li.loyalty_status === true
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleloyaltyToggle(
                                          index,
                                          e.target.checked,
                                          "loyalty_status"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="be_loyalty_level"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <h5 className="be_loyalty_level_title">
                                      Loyalty - Basic Level
                                    </h5>
                                    <span className="padding-5"></span>
                                    <span className="be_form_loyalty_label_50">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Level Name{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <div class="info-tag-new">i</div>
                                        <span className="tooltiptext-new">
                                          Name of the loyalty level you want to
                                          call, for example Bronze
                                        </span>
                                      </label>
                                      {li.loyalty_status === true ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={li.basic_level?.name}
                                          name="name"
                                          onChange={(e) =>
                                            handleloyalty(index, e, "name")
                                          }
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={li.basic_level?.name}
                                          name="name"
                                          onChange={(e) =>
                                            handleloyalty(index, e, "name")
                                          }
                                          disabled
                                        />
                                      )}
                                      {Error1.map((err, indx) => {
                                        return (
                                          <>
                                            {index === indx &&
                                            err &&
                                            err.name ? (
                                              <label
                                                style={{
                                                  color: "red",
                                                }}
                                              >
                                                {err.name}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </span>

                                    <span className="loyalty_logo_new">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Logo{" "}
                                        <div class="info-tag-new mar_1">i</div>
                                        <span className="tooltiptext-new">
                                          Upload logo in this particular level,
                                          only accept jpg,jpeg,png format
                                        </span>
                                        <span className="span-img-size">
                                          ( 140 px * 40px)
                                        </span>
                                      </label>
                                      {li.loyalty_status === true ? (
                                        <div className="form-translation-style_offer">
                                          <ul
                                            className="price_show_select_loyal"
                                            id="price_show_type"
                                          >
                                            <li
                                              className={
                                                tabimg === "upload"
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={(e) =>
                                                setTabimage("upload")
                                              }
                                            >
                                              <button className="photo_offer_pen_choose">
                                                Upload Image
                                              </button>
                                              <input
                                                className="photo_offer_input"
                                                type="file"
                                                name="image"
                                                onChange={(e) =>
                                                  onFileChange(index, e)
                                                }
                                                accept=".jpg, .jpeg, .webp, .png"
                                              />
                                            </li>

                                            <li
                                              className={
                                                tabimg === "list"
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={(e) => {
                                                setPopup(!popup);
                                                setTabimage("list");
                                                setPopupId(index);
                                              }}
                                            >
                                              <button className="photo_offer_pen_choose">
                                                Choose From List
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      ) : (
                                        <div className="form-translation-style_offer">
                                          <ul
                                            className="price_show_select_offer"
                                            id="price_show_type"
                                          >
                                            <li
                                              className={
                                                tabimg === "upload"
                                                  ? "active"
                                                  : ""
                                              }
                                            >
                                              <button
                                                className="photo_offer_pen_choose"
                                                disabled
                                              >
                                                Upload Image
                                              </button>
                                            </li>
                                            <li
                                              className={
                                                tabimg === "list"
                                                  ? "active"
                                                  : ""
                                              }
                                            >
                                              <button
                                                className="photo_offer_pen_choose"
                                                disabled
                                              >
                                                Choose From List
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                      <label className="label_image_crop">
                                        {" "}
                                        (
                                        <span class="form-validation-required"></span>
                                        if you do not resize it as per the
                                        mentioned size, it can affect the
                                        quality of the images)
                                      </label>

                                      {list && li.basic_level?.logo ? (
                                        <div
                                          className=""
                                          style={{ float: "left" }}
                                        >
                                          {li.basic_level?.logo ? (
                                            li.basic_level?.logo instanceof
                                            File ? (
                                              <p className="pdf_img_style_img_loyal">
                                                {li.basic_level?.logo?.name}
                                              </p>
                                            ) : (
                                              <img
                                                src={`${Commonimagepath.apiurl}${li.basic_level?.logo}`}
                                                alt=""
                                                className="position-absolute"
                                                style={{
                                                  width: "23px",
                                                  marginTop: "28px",
                                                  marginLeft: "10px",
                                                  height: "23px",
                                                }}
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </span>

                                    <div className="col-md-12">
                                      <div className="col-lg-6">
                                        <div
                                          className="d-flex"
                                          style={{
                                            float: "left",
                                            marginTop: "40px",
                                          }}
                                        >
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{
                                              width: "130px",
                                              borderBottomRightRadius: "0px",
                                              borderTopRightRadius: "0px",
                                            }}
                                          >
                                            Add/edit in
                                          </label>
                                          {li.loyalty_status === true ? (
                                            <select
                                              className="form-control"
                                              data-choices
                                              data-choices-search-false
                                              id="idPayment"
                                              style={{
                                                width: "225px",
                                                height: "38px",
                                                marginLeft: "-1px",
                                              }}
                                              value={li.newlang}
                                              onChange={(e) =>
                                                handleLanguageChangebasic(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">
                                                Choose language
                                              </option>

                                              {language.map((lang, inx) => {
                                                return (
                                                  <option
                                                    key={inx}
                                                    value={lang.code}
                                                  >
                                                    {lang.label}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          ) : (
                                            <select
                                              className="form-control"
                                              data-choices
                                              data-choices-search-false
                                              id="idPayment"
                                              style={{
                                                width: "225px",
                                                height: "38px",
                                                marginLeft: "-1px",
                                              }}
                                              value={li.newlang}
                                              onChange={(e) =>
                                                handleLanguageChangebasic(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              disabled
                                            >
                                              <option value="">
                                                Choose language
                                              </option>

                                              {language.map((lang, inx) => {
                                                return (
                                                  <option
                                                    key={inx}
                                                    value={lang.code}
                                                  >
                                                    {lang.label}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                      <span className="padding-5"></span>
                                      <div
                                        className="appendmore"
                                        style={{ marginTop: "7px" }}
                                      ></div>
                                      {li.newlang ? (
                                        li.basic_level?.multi_language?.length >
                                        0 ? (
                                          <>
                                            {li.basic_level?.multi_language?.some(
                                              (item) =>
                                                item.lang_code === li.newlang
                                            ) ? (
                                              <div
                                                className="col-md-8 translation-fill-section"
                                                data-value="1"
                                              >
                                                <div className="col-lg-8">
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Loyalty Name
                                                    </label>
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                          li.basic_level
                                                            ?.multi_language[
                                                            isMatchingLanguage
                                                          ]?.title
                                                        }
                                                        onChange={(e) =>
                                                          handleloyaltyChangelangbasic(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                          li.basic_level
                                                            ?.multi_language[
                                                            isMatchingLanguage
                                                          ]?.title
                                                        }
                                                        onChange={(e) =>
                                                          handleloyaltyChangelangbasic(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="col-md-10 translation-fill-section"
                                                data-value="1"
                                              >
                                                <div className="col-lg-8">
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Loyalty Name
                                                    </label>
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                          li.basic_level
                                                            ?.multi_language[
                                                            li.basic_level
                                                              ?.multi_language
                                                              ?.length - 1
                                                          ]?.title
                                                        }
                                                        onChange={(e) =>
                                                          handleloyaltyChangelangbasic(
                                                            li.basic_level
                                                              ?.multi_language
                                                              ?.length - 1,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                          li.basic_level
                                                            ?.multi_language[
                                                            li.basic_level
                                                              .multi_language
                                                              ?.length - 1
                                                          ]?.title
                                                        }
                                                        onChange={(e) =>
                                                          handleloyaltyChangelangbasic(
                                                            li.basic_level
                                                              ?.multi_language
                                                              ?.length - 1,
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      <span
                                        className="loyalty_joint_style"
                                        style={{
                                          float: "right",
                                          paddingRight: "12%",
                                          bottom: "50px",
                                        }}
                                      >
                                        <span
                                          className="input-group"
                                          style={{ width: "338px" }}
                                        >
                                          <span
                                            className="input-group-text input-joint-color1"
                                            id="inputGroup-sizing-default"
                                          >
                                            Status{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </span>
                                          {li.loyalty_status === true ? (
                                            <select
                                              id="ForminputState"
                                              className="form-select"
                                              value={li.basic_level?.status}
                                              onChange={(e) =>
                                                handleStatusbasic(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>
                                                Choose...
                                              </option>
                                              <option
                                                {...(li.basic_level?.status ===
                                                true
                                                  ? "selected"
                                                  : "")}
                                                value={true}
                                              >
                                                Active
                                              </option>
                                              <option
                                                {...(li.basic_level?.status ===
                                                false
                                                  ? "selected"
                                                  : "")}
                                                value={false}
                                              >
                                                Inactive
                                              </option>
                                            </select>
                                          ) : (
                                            <select
                                              id="ForminputState"
                                              className="form-select"
                                              value={li.basic_level?.status}
                                              onChange={(e) =>
                                                handleStatusbasic(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              disabled
                                            >
                                              <option selected>
                                                Choose...
                                              </option>
                                              <option
                                                {...(li.basic_level?.status ===
                                                true
                                                  ? "selected"
                                                  : "")}
                                                value={true}
                                              >
                                                Active
                                              </option>
                                              <option
                                                {...(li.basic_level?.status ===
                                                false
                                                  ? "selected"
                                                  : "")}
                                                value={false}
                                              >
                                                Inactive
                                              </option>
                                            </select>
                                          )}
                                        </span>

                                        {Error1.map((err, indx) => {
                                          return (
                                            <>
                                              {index === indx &&
                                              err &&
                                              err.status ? (
                                                <label
                                                  style={{
                                                    color: "red",
                                                  }}
                                                >
                                                  {err.status}
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })}
                                      </span>
                                    </div>
                                  </div>

                                  {loading
                                    ? li?.common_level?.map((data, i) => {
                                        const isMatchingLanguagesub =
                                          data?.multi_language?.findIndex(
                                            (language) =>
                                              language.lang_code ===
                                              li.newlangsub
                                          );
                                        return (
                                          <>
                                            <div
                                              className="be_loyalty_level"
                                              key={i}
                                            >
                                              <span
                                                className="remove_loyalty"
                                                onClick={(e) =>
                                                  tog_animationFlipDel(
                                                    data?.level_id
                                                  )
                                                }
                                              >
                                                Delete
                                              </span>
                                              <span className="padding-5"></span>
                                              <span className="be_form_loyalty_label_50">
                                                <label
                                                  for="firstNameinput"
                                                  className="form-label be_form_label"
                                                >
                                                  Name of Loyalty Level-{i + 1}{" "}
                                                  <span className="form-validation-required">
                                                    *
                                                  </span>
                                                  <div class="info-tag-new">
                                                    i
                                                  </div>
                                                  <span className="tooltiptext-new">
                                                    Name of the loyalty level
                                                    you want to call, for
                                                    example Bronze
                                                  </span>
                                                </label>
                                                {li.loyalty_status === true ? (
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={data?.name}
                                                    name="name"
                                                    onChange={(e) =>
                                                      handleChange(index, i, e)
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={data?.name}
                                                    name="name"
                                                    onChange={(e) =>
                                                      handleChange(index, i, e)
                                                    }
                                                    disabled
                                                  />
                                                )}
                                                {Error2.map((err, indx) => {
                                                  return (
                                                    <>
                                                      {i === indx &&
                                                      err &&
                                                      err.name ? (
                                                        <label
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {err.name}
                                                        </label>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  );
                                                })}
                                              </span>

                                              <span className="loyalty_logo_new">
                                                <label
                                                  for="firstNameinput"
                                                  className="form-label be_form_label"
                                                >
                                                  Logo{" "}
                                                  <div class="info-tag-new mar_1">
                                                    i
                                                  </div>
                                                  <span className="tooltiptext-new">
                                                    Upload logo in this
                                                    particular level, only
                                                    accept jpg,jpeg,png format
                                                  </span>{" "}
                                                  <span className="span-img-size">
                                                    ( 140 px * 40px)
                                                  </span>
                                                </label>
                                                {li.loyalty_status === true ? (
                                                  <div className="form-translation-style_offer">
                                                    <ul
                                                      className="price_show_select_loyal"
                                                      id="price_show_type"
                                                    >
                                                      <li
                                                        className={
                                                          tabimgloop ===
                                                          "upload"
                                                            ? "active"
                                                            : ""
                                                        }
                                                        onClick={(e) =>
                                                          setTabimageloop(
                                                            "upload"
                                                          )
                                                        }
                                                      >
                                                        <button className="photo_offer_pen_choose">
                                                          Upload Image
                                                        </button>
                                                        <input
                                                          className="photo_offer_input"
                                                          type="file"
                                                          name="image"
                                                          onChange={(e) =>
                                                            onFileChangelopp(
                                                              index,
                                                              i,
                                                              e
                                                            )
                                                          }
                                                          accept=".jpg, .jpeg, .webp, .png"
                                                        />
                                                      </li>

                                                      <li
                                                        className={
                                                          tabimgloop === "list"
                                                            ? "active"
                                                            : ""
                                                        }
                                                        onClick={(e) => {
                                                          setPopuploop(
                                                            !popuploop
                                                          );
                                                          setTabimageloop(
                                                            "list"
                                                          );
                                                          setPopupId(index);
                                                          setPopupIndex(i);
                                                        }}
                                                      >
                                                        <button className="photo_offer_pen_choose">
                                                          Choose From List
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                ) : (
                                                  <div className="form-translation-style_offer">
                                                    <ul
                                                      className="price_show_select_offer"
                                                      id="price_show_type"
                                                    >
                                                      <li
                                                        className={
                                                          tabimgloop ===
                                                          "upload"
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <button
                                                          className="photo_offer_pen_choose"
                                                          disabled
                                                        >
                                                          Upload Image
                                                        </button>
                                                      </li>
                                                      <li
                                                        className={
                                                          tabimgloop === "list"
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <button
                                                          className="photo_offer_pen_choose"
                                                          disabled
                                                        >
                                                          Choose From List
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                )}
                                                <label className="label_image_crop">
                                                  {" "}
                                                  (
                                                  <span class="form-validation-required"></span>
                                                  if you do not resize it as per
                                                  the mentioned size, it can
                                                  affect the quality of the
                                                  images)
                                                </label>
                                                {loyalty && data.logo ? (
                                                  <div
                                                    className=""
                                                    style={{ float: "left" }}
                                                  >
                                                    {data?.logo ? (
                                                      data.logo instanceof
                                                      File ? (
                                                        <p className="pdf_img_style_img_loyal">
                                                          {data.logo?.name}
                                                        </p>
                                                      ) : (
                                                        <img
                                                          src={`${Commonimagepath.apiurl}${data.logo}`}
                                                          alt=""
                                                          className="position-absolute"
                                                          style={{
                                                            width: "24px",
                                                            marginTop: "40px",
                                                            marginLeft: "10px",
                                                            height: "24px",
                                                          }}
                                                        />
                                                      )
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </span>

                                              <div
                                                className="col-md-12"
                                                style={{ marginTop: "15px" }}
                                              >
                                                <span className="be_form_loyalty_label_50">
                                                  <div
                                                    className="d-flex"
                                                    style={{ float: "left" }}
                                                  >
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                      style={{
                                                        borderBottomRightRadius:
                                                          "0px",
                                                        borderTopRightRadius:
                                                          "0px",
                                                      }}
                                                    >
                                                      Add/edit in
                                                    </label>
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <select
                                                        className="form-control"
                                                        data-choices
                                                        data-choices-search-false
                                                        name="choices-single-default"
                                                        id="idPayment"
                                                        style={{
                                                          width: "225px",
                                                          height: "38px",
                                                          marginLeft: "-1px",
                                                        }}
                                                        value={li.newlangsub}
                                                        onChange={(e) =>
                                                          handleLanguageChange(
                                                            index,
                                                            i,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          Choose language
                                                        </option>
                                                        {language.map(
                                                          (lang, inx) => {
                                                            return (
                                                              <option
                                                                key={inx}
                                                                value={
                                                                  lang.code
                                                                }
                                                              >
                                                                {lang.label}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    ) : (
                                                      <select
                                                        className="form-control"
                                                        data-choices
                                                        data-choices-search-false
                                                        name="choices-single-default"
                                                        id="idPayment"
                                                        style={{
                                                          width: "225px",
                                                          height: "38px",
                                                          marginLeft: "-1px",
                                                        }}
                                                        value={li?.newlangsub}
                                                        onChange={(e) =>
                                                          handleLanguageChange(
                                                            index,
                                                            i,
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled
                                                      >
                                                        <option value="">
                                                          Choose language
                                                        </option>
                                                        {language.map(
                                                          (lang, inx) => {
                                                            return (
                                                              <option
                                                                key={inx}
                                                                value={
                                                                  lang.code
                                                                }
                                                              >
                                                                {lang.label}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    )}
                                                  </div>
                                                </span>
                                                {li.newlangsub ? (
                                                  <>
                                                    {data?.multi_language
                                                      ?.length > 0 ? (
                                                      <>
                                                        {data.multi_language?.some(
                                                          (item) =>
                                                            item.lang_code ===
                                                            li.newlangsub
                                                        ) ? (
                                                          <div
                                                            className="col-md-12 translation-fill-section"
                                                            data-value="1"
                                                          >
                                                            <div className="col-lg-5">
                                                              <div className="input-group">
                                                                <label
                                                                  className="input-group-text input-joint-color"
                                                                  id="inputGroup-sizing-default"
                                                                >
                                                                  Loyalty Name
                                                                </label>
                                                                {li.loyalty_status ===
                                                                true ? (
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                      data
                                                                        .multi_language[
                                                                        isMatchingLanguagesub
                                                                      ]?.title
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleloyaltyChangelang(
                                                                        index,
                                                                        i,
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                      data
                                                                        .multi_language[
                                                                        isMatchingLanguagesub
                                                                      ]?.title
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleloyaltyChangelang(
                                                                        index,
                                                                        i,
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    disabled
                                                                  />
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="col-md-12 translation-fill-section"
                                                            data-value="1"
                                                          >
                                                            <div className="col-lg-5">
                                                              <div className="input-group">
                                                                <label
                                                                  className="input-group-text input-joint-color"
                                                                  id="inputGroup-sizing-default"
                                                                >
                                                                  Loyalty Name
                                                                </label>
                                                                {li.loyalty_status ===
                                                                true ? (
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                      data
                                                                        .multi_language[
                                                                        data
                                                                          .multi_language
                                                                          ?.length -
                                                                          1
                                                                      ]?.title
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleloyaltyChangelang(
                                                                        index,
                                                                        data
                                                                          .multi_language
                                                                          ?.length -
                                                                          1,
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                      data
                                                                        .multi_language[
                                                                        data
                                                                          .multi_language
                                                                          ?.length -
                                                                          1
                                                                      ]?.title
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleloyaltyChangelang(
                                                                        index,
                                                                        data
                                                                          .multi_language
                                                                          ?.length -
                                                                          1,
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    disabled
                                                                  />
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>

                                              <div className="padding-5"></div>
                                              <span className="be_form_loyalty_label_full">
                                                <p className="loyalty_form_subhead">
                                                  Classification Rules
                                                </p>
                                              </span>

                                              <span className="loyalty_label_new loyalty_joint_style">
                                                <span
                                                  className="input-group"
                                                  style={{ width: "310px" }}
                                                >
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Minimum No.of Reservations{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      value={
                                                        data.minimum_reservation
                                                      }
                                                      name="minimum_reservation"
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      value={
                                                        data.minimum_reservation
                                                      }
                                                      name="minimum_reservation"
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      disabled
                                                    />
                                                  )}
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.minimum_reservation ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              err.minimum_reservation
                                                            }
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              </span>

                                              <span
                                                className="loyalty_or_and_new"
                                                style={{ marginLeft: "45px" }}
                                              >
                                                {li.loyalty_status === true ? (
                                                  <select
                                                    className="form-select"
                                                    value={data.condition}
                                                    onChange={(e) =>
                                                      handleConditionChange(
                                                        index,
                                                        i,
                                                        "condition",
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{ width: "100px" }}
                                                  >
                                                    <option value="or">
                                                      or
                                                    </option>
                                                    <option value="and">
                                                      and{" "}
                                                    </option>
                                                  </select>
                                                ) : (
                                                  <select
                                                    className="form-select"
                                                    value={data.condition}
                                                    disabled
                                                    onChange={(e) =>
                                                      handleConditionChange(
                                                        index,
                                                        i,
                                                        "condition",
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{ width: "100px" }}
                                                  >
                                                    <option value="or">
                                                      or
                                                    </option>
                                                    <option value="and">
                                                      and{" "}
                                                    </option>
                                                  </select>
                                                )}
                                              </span>

                                              <span className="loyalty_label_new loyalty_joint_style">
                                                <span
                                                  className="input-group"
                                                  style={{
                                                    width: "310px",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Minimum No.of Room Nights{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="minimum_room_nights"
                                                      value={
                                                        data.minimum_room_nights
                                                      }
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="minimum_room_nights"
                                                      value={
                                                        data.minimum_room_nights
                                                      }
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      disabled
                                                    />
                                                  )}
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.minimum_room_nights ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              err.minimum_room_nights
                                                            }
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              </span>

                                              <span
                                                className="loyalty_label_new loyalty_joint_style"
                                                style={{ marginLeft: "6%" }}
                                              >
                                                <span
                                                  className="input-group"
                                                  style={{ width: "280px" }}
                                                >
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Amount Spend{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <>
                                                      <input
                                                        type="number"
                                                        onKeyDown={(e) =>
                                                          symbolsArr.includes(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                        min={0}
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        name="amount_spend"
                                                        value={
                                                          data.amount_spend
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                        onFocus={(e) =>
                                                          e.target.addEventListener(
                                                            "wheel",
                                                            function (e) {
                                                              e.preventDefault();
                                                            },
                                                            { passive: false }
                                                          )
                                                        }
                                                      />
                                                      <span
                                                        className="input-group-text input-joint-color-loyal"
                                                        id="inputGroup-sizing-default"
                                                      >
                                                        {li.currency.code}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <input
                                                        type="number"
                                                        onKeyDown={(e) =>
                                                          symbolsArr.includes(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                        min={0}
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        name="amount_spend"
                                                        value={
                                                          data.amount_spend
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                        disabled
                                                      />
                                                      <span
                                                        className="input-group-text input-joint-color-loyal"
                                                        id="inputGroup-sizing-default"
                                                      >
                                                        {li.currency.code}
                                                      </span>
                                                    </>
                                                  )}
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.amount_spend ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {err.amount_spend}
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              </span>
                                              <span className="padding-5"></span>

                                              <div
                                                className="form-check form-check-inline"
                                                style={{
                                                  float: "left",
                                                  marginTop: "7px",
                                                  right: "2%",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  Criteria
                                                </label>

                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input per_room_once_check"
                                                    type="radio"
                                                    id={`WithInlineRadioYes${index}${i}`}
                                                    name={`expiry_status${index}${i}`}
                                                    value={true}
                                                    checked={
                                                      data.expiry_status ===
                                                      true
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeExpiration(
                                                        index,
                                                        i,
                                                        "expiry_status",
                                                        true
                                                      )
                                                    }
                                                  />
                                                  <label>Yes</label>
                                                </div>

                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input per_room_once_check"
                                                    type="radio"
                                                    id={`WithInlineRadioNo${index}${i}`}
                                                    name={`expiry_status${index}${i}`}
                                                    value={false}
                                                    checked={
                                                      data.expiry_status ===
                                                      false
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeExpiration(
                                                        index,
                                                        i,
                                                        "expiry_status",
                                                        false
                                                      )
                                                    }
                                                  />
                                                  <label>No</label>
                                                </div>
                                              </div>

                                              {data.expiry_status === true ? (
                                                <span
                                                  className="loyalty_period_new loyalty_joint_style"
                                                  style={{ right: "3%" }}
                                                >
                                                  <span
                                                    className="input-group"
                                                    style={{ width: "215px" }}
                                                  >
                                                    <span
                                                      className="input-group-text input-joint-color1"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Period{" "}
                                                      <span className="form-validation-required">
                                                        *
                                                      </span>
                                                      <div class="info-tag-new">
                                                        i
                                                      </div>
                                                      <span className="tooltiptext-new_loyal">
                                                        Only reservations or
                                                        room nights in the last
                                                        X years will be
                                                        considered
                                                      </span>
                                                    </span>
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <input
                                                        type="number"
                                                        onKeyDown={(e) =>
                                                          symbolsrate.includes(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                        min={0}
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        name="period"
                                                        value={data.period}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                        onFocus={(e) =>
                                                          e.target.addEventListener(
                                                            "wheel",
                                                            function (e) {
                                                              e.preventDefault();
                                                            },
                                                            { passive: false }
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        type="number"
                                                        onKeyDown={(e) =>
                                                          symbolsrate.includes(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                        min={0}
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        name="period"
                                                        value={data.period}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                        disabled
                                                      />
                                                    )}

                                                    <span
                                                      className="input-group-text input-joint-color1"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      Year
                                                    </span>
                                                  </span>
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.period ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {err.period}
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              ) : (
                                                ""
                                              )}

                                              <span
                                                className="input-group loyalty_dis_sort"
                                                style={{
                                                  right: "2%",
                                                  width: "260px",
                                                }}
                                              >
                                                <span className="input-group">
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Discount{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsrate.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="discount"
                                                      value={data.discount}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsrate.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      min={0}
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="discount"
                                                      value={data.discount}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      disabled
                                                    />
                                                  )}
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.discount ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {err.discount}
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                  <span
                                                    className=""
                                                    style={{ width: "90px" }}
                                                  >
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <select
                                                        id="ForminputState"
                                                        className="form-select"
                                                        value={data.unit_value}
                                                        onChange={(e) =>
                                                          handleConditionChange(
                                                            index,
                                                            i,
                                                            "unit_value",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option
                                                          value="currency"
                                                          selected
                                                        >
                                                          {li.currency.code}
                                                        </option>
                                                        <option value="percentage">
                                                          %
                                                        </option>
                                                      </select>
                                                    ) : (
                                                      <select
                                                        id="ForminputState"
                                                        className="form-select"
                                                        value={data.unit_value}
                                                        disabled
                                                        onChange={(e) =>
                                                          handleConditionChange(
                                                            index,
                                                            i,
                                                            "unit_value",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option
                                                          value="currency"
                                                          selected
                                                        >
                                                          {li.currency.code}
                                                        </option>
                                                        <option value="percentage">
                                                          %
                                                        </option>
                                                      </select>
                                                    )}
                                                  </span>
                                                </span>
                                              </span>

                                              <span
                                                className="input-group loyalty_dis_sort_new"
                                                style={{ right: "2%" }}
                                              >
                                                <span
                                                  className="input-group"
                                                  style={{ marginLeft: "14%" }}
                                                >
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Sort Order{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsrate.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="sort_order"
                                                      value={data.sort_order}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      type="number"
                                                      onKeyDown={(e) =>
                                                        symbolsrate.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      className="form-control"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="sort_order"
                                                      value={data.sort_order}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      disabled
                                                    />
                                                  )}
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.sort_order ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {err.sort_order}
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              </span>

                                              <span
                                                className="loyalty_label_status_new loyalty_joint_style"
                                                style={{ right: "2%" }}
                                              >
                                                <span
                                                  className="input-group"
                                                  style={{
                                                    width: "200px",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Status{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  {li.loyalty_status ===
                                                  true ? (
                                                    <select
                                                      id="ForminputState"
                                                      className="form-select"
                                                      value={data.status}
                                                      onChange={(e) =>
                                                        handleStatusChange(
                                                          index,
                                                          i,
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option selected>
                                                        Choose...
                                                      </option>
                                                      <option
                                                        {...(data.status ===
                                                        true
                                                          ? "selected"
                                                          : "")}
                                                        value={true}
                                                      >
                                                        Active
                                                      </option>
                                                      <option
                                                        {...(data.status ===
                                                        false
                                                          ? "selected"
                                                          : "")}
                                                        value={false}
                                                      >
                                                        Inactive
                                                      </option>
                                                    </select>
                                                  ) : (
                                                    <select
                                                      id="ForminputState"
                                                      className="form-select"
                                                      value={data.status}
                                                      onChange={(e) =>
                                                        handleStatusChange(
                                                          index,
                                                          i,
                                                          e.target.value
                                                        )
                                                      }
                                                      disabled
                                                    >
                                                      <option selected>
                                                        Choose...
                                                      </option>
                                                      <option
                                                        {...(data.status ===
                                                        true
                                                          ? "selected"
                                                          : "")}
                                                        value={true}
                                                      >
                                                        Active
                                                      </option>
                                                      <option
                                                        {...(data.status ===
                                                        false
                                                          ? "selected"
                                                          : "")}
                                                        value={false}
                                                      >
                                                        Inactive
                                                      </option>
                                                    </select>
                                                  )}
                                                </span>
                                              </span>

                                              {Error2.map((err, indx) => {
                                                return (
                                                  <>
                                                    {i === indx &&
                                                    err &&
                                                    err.status ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.status}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}

                                              <span className="padding-5"></span>

                                              <span className="be_form_loyalty_label_full">
                                                <span className="input-group be_loyalty_level_sec_select">
                                                  <span
                                                    className="input-group-text input-joint-color1"
                                                    id="inputGroup-sizing-default"
                                                  >
                                                    Benefits{" "}
                                                    <span className="form-validation-required">
                                                      *
                                                    </span>
                                                  </span>
                                                  <span
                                                    className=""
                                                    style={{ width: "90%" }}
                                                  >
                                                    {li.loyalty_status ===
                                                    true ? (
                                                      <MultiSelect
                                                        options={benefit}
                                                        value={
                                                          data?.loyalty_benefits
                                                        }
                                                        onChange={(e) =>
                                                          handleChangebenefit(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        labelledBy="Select"
                                                        selectionType="counter"
                                                        allowCustomValue={true}
                                                        aria-expanded="true"
                                                      />
                                                    ) : (
                                                      <MultiSelect
                                                        options={benefit}
                                                        value={
                                                          data?.loyalty_benefits
                                                        }
                                                        onChange={(e) =>
                                                          handleChangebenefit(
                                                            index,
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        labelledBy="Select"
                                                        selectionType="counter"
                                                        allowCustomValue={true}
                                                        aria-expanded="true"
                                                        disabled
                                                      />
                                                    )}
                                                  </span>
                                                  {Error2.map((err, indx) => {
                                                    return (
                                                      <>
                                                        {i === indx &&
                                                        err &&
                                                        err.loyalty_benefits ? (
                                                          <label
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              err.loyalty_benefits
                                                            }
                                                          </label>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </span>
                                              </span>
                                            </div>
                                          </>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      }
                    </div>
                  </div>
                  
                </div>
               
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{
          fontFamily: "IBM Plex Sans,sans-serif",
          justifyContent: "center",
        }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ width: "770px" }}
        >
          <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
            <div
              className="row price-modify-filter"
              style={{ marginTop: "20px" }}
            >
              <form>
                <button
                  type="button"
                  className="btn btn-link link-success fw-medium text-decoration-none"
                  onClick={() => {
                    tog_animationFlip();
                  }}
                  style={{ float: "right", bottom: "30px" }}
                >
                  {" "}
                  <i
                    className="ri-close-line me-1 align-middle"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>

                <div className="be_loyalty_level">
                  <span className="padding-5"></span>
                  <span className="loyalty_name_popup">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Name of Loyalty Level{" "}
                      <span className="form-validation-required">*</span>
                      <div class="info-tag-new">i</div>
                      <span className="tooltiptext-new">
                        Name of the loyalty level you want to call, for example
                        Bronze
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={loyaltyadd.name}
                      name="name"
                      onChange={handleloyaltypop}
                    />
                    <label style={{ color: "red" }}>{Error.name}</label>
                  </span>

                  <span className="loyalty_name_popup">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Logo <div class="info-tag-new mar_1">i</div>
                      <span className="tooltiptext-new">
                        Upload logo in this particular level, only accept
                        jpg,jpeg,png format
                      </span>{" "}
                      <span className="span-img-size">( 140 px * 40px)</span>
                    </label>

                    <div className="form-translation-style_offer">
                      <ul
                        className="price_show_select_loyal"
                        id="price_show_type"
                      >
                        <li
                          className={tabimgcreate === "upload" ? "active" : ""}
                          onClick={(e) => setTabimagecreate("upload")}
                        >
                          <button
                            className="photo_offer_pen_choose"
                            type="button"
                          >
                            Upload Image
                          </button>
                          <input
                            className="photo_offer_input"
                            type="file"
                            name="image"
                            onChange={onFileChangecreate}
                            accept=".jpg, .jpeg, .webp, .png"
                          />
                        </li>

                        <li
                          className={tabimgcreate === "list" ? "active" : ""}
                          onClick={(e) => {
                            setPopupcreate(!popupcreate);
                            setTabimagecreate("list");
                          }}
                        >
                          <button
                            className="photo_offer_pen_choose"
                            type="button"
                          >
                            Choose From List
                          </button>
                        </li>
                      </ul>
                    </div>

                    <label className="label_image_crop">
                      {" "}
                      (<span class="form-validation-required"></span>if you do
                      not resize it as per the mentioned size, it can affect the
                      quality of the images)
                    </label>
                    {logoFile ? (
                      <div className="" style={{ float: "left" }}>
                        {logoFile ? (
                          logoFile instanceof File ? (
                            <p className="pdf_img_style_img_loyal">
                              {logoFile.name}
                            </p>
                          ) : (
                            <img
                              src={`${Commonimagepath.apiurl}${logoFile}`}
                              alt=""
                              className="position-absolute"
                              style={{
                                width: "24px",
                                marginTop: "21px",
                                marginLeft: "10px",
                                marginBottom: "10px",
                                height: "24px",
                              }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>

                  <div
                    className={
                      logoFile
                        ? !(logoFile instanceof File)
                          ? "padding_45 col-lg-5"
                          : "col-lg-5"
                        : "col-lg-5"
                    }
                    style={{ float: "left" }}
                  >
                    <div className="d-flex">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                        style={{
                          width: "130px",
                          borderBottomRightRadius: "0px",
                          borderTopRightRadius: "0px",
                        }}
                      >
                        Add/edit in
                      </label>
                      <select
                        className="form-control"
                        data-choices
                        data-choices-search-false
                        name="choices-single-default"
                        id="idPayment"
                        style={{
                          width: "130px",
                          height: "38px",
                          marginLeft: "-1px",
                        }}
                        value={newLangpop}
                        onChange={(e) =>
                          handleLanguageChangepop(e.target.value)
                        }
                      >
                        <option value="">Choose language</option>

                        {language.map((lang, inx) => {
                          return (
                            <option key={inx} value={lang.code}>
                              {lang.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {newLangpop ? (
                    <>
                      {selectedMulti.length > 0 ? (
                        <>
                          {selectedMulti.some(
                            (item) => item.lang_code === newLangpop
                          ) ? (
                            <div
                              className={
                                logoFile
                                  ? !(logoFile instanceof File)
                                    ? "padding_45 col-lg-6"
                                    : "col-lg-6"
                                  : "col-lg-6"
                              }
                              style={{ float: "right", marginRight: "55px" }}
                            >
                              <div
                                className="input-group"
                                style={{ width: "385px" }}
                              >
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Loyalty Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={
                                    selectedMulti[isMatchingLanguagepop]?.title
                                  }
                                  onChange={(e) =>
                                    handleloyaltyChangepop(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-lg-6"
                              style={{ float: "right", marginRight: "55px" }}
                            >
                              <div
                                className="input-group"
                                style={{ width: "385px" }}
                              >
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Loyalty Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={
                                    selectedMulti[selectedMulti?.length - 1]
                                      ?.title
                                  }
                                  onChange={(e) =>
                                    handleloyaltyChangepop(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <div className="padding-5"></div>

                  <span className="loyalty_label_new_pop loyalty_joint_style">
                    <span className="input-group" style={{ width: "280px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Minimum No.of Reservations{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={loyaltyadd.minimum_reservation}
                        name="minimum_reservation"
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>
                        {Error.minimum_reservation}
                      </label>
                    </span>
                  </span>
                  <span className="loyalty_or_and_new_pop">
                    <select
                      className="form-select"
                      value={loyaltyadd.condition}
                      name="condition"
                      onChange={handleCurrency}
                    >
                      <option value="or">or</option>
                      <option value="and">and </option>
                    </select>
                  </span>
                  <span
                    className="loyalty_label_new_pop loyalty_joint_style"
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="input-group" style={{ width: "280px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Minimum No.of Room Nights{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="minimum_room_nights"
                        value={loyaltyadd.minimum_room_nights}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>
                        {Error.minimum_room_nights}
                      </label>
                    </span>
                  </span>

                  <span className="loyalty_label_new_popup loyalty_joint_style">
                    <span className="input-group" style={{ width: "240px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Amount Spend{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="amount_spend"
                        value={loyaltyadd.amount_spend}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />{" "}
                      <span
                        className="input-group-text input-joint-color-loyal"
                        id="inputGroup-sizing-default"
                        style={{
                          borderBottomRightRadius: "5px",
                          borderTopRightRadius: "5px",
                        }}
                      >
                        {currencyCode}
                      </span>
                      <label style={{ color: "red" }}>
                        {Error.amount_spend}
                      </label>
                    </span>
                  </span>

                  <span
                    className="input-group loyalty_dis_sort_pop"
                    style={{ marginLeft: "8%" }}
                  >
                    <span className="input-group" style={{ width: "200px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Sort Order{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsrate.includes(e.key) && e.preventDefault()
                        }
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="sort_order"
                        value={loyaltyadd.sort_order}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>{Error.sort_order}</label>
                    </span>
                  </span>
                  <span className="loyalty_label_new loyalty_joint_style">
                    <span className="input-group" style={{ width: "217px" }}>
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Status{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <select
                        id="ForminputState"
                        className="form-select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option selected>Choose...</option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                      <label style={{ color: "red" }}>{Error.status}</label>
                    </span>
                  </span>
                  <span
                    className="input-group loyalty_discount_popup"
                    style={{ width: "300px" }}
                  >
                    <span className="input-group">
                      <span
                        className="input-group-text input-joint-color1"
                        id="inputGroup-sizing-default"
                      >
                        Discount{" "}
                        <span className="form-validation-required">*</span>
                      </span>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsrate.includes(e.key) && e.preventDefault()
                        }
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="discount"
                        value={loyaltyadd.discount}
                        onChange={handleloyaltypop}
                        style={{ textAlign: "center" }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                      <label style={{ color: "red" }}>{Error.discount}</label>
                      <span className="" style={{ right: "0" }}>
                        <select
                          id="ForminputState"
                          className="form-select"
                          value={loyaltyadd.unit_value}
                          name="unit_value"
                          onChange={handleCurrency}
                        >
                          <option value="currency" selected>
                            {currencyCode}
                          </option>
                          <option value="percentage">%</option>
                        </select>
                      </span>
                    </span>
                  </span>

                  <div
                    className="form-check form-check-inline"
                    style={{ float: "left", marginTop: "7px", right: "3%" }}
                  >
                    <label style={{ marginRight: "10px" }}>Criteria</label>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input per_room_once_check"
                        type="radio"
                        id="WithInlineRadioNo"
                        name="expiry_status"
                        value={true}
                        checked={loyaltyadd.expiry_status === true}
                        onChange={(e) =>
                          handleChangeExpirationpop("expiry_status", true)
                        }
                      />
                      <label>Yes</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input per_room_once_check"
                        type="radio"
                        id="WithInlineRadioNo"
                        name="expiry_status"
                        value={false}
                        checked={loyaltyadd.expiry_status === false}
                        onChange={(e) =>
                          handleChangeExpirationpop("expiry_status", false)
                        }
                      />
                      <label>No</label>
                    </div>
                  </div>
                  {loyaltyadd.expiry_status === true ? (
                    <span
                      className="loyalty_period_new_popup loyalty_joint_style"
                      style={{ right: "6%" }}
                    >
                      <span className="input-group" style={{ width: "205px" }}>
                        <span
                          className="input-group-text input-joint-color1"
                          id="inputGroup-sizing-default"
                        >
                          Period{" "}
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new">i</div>
                          <span className="tooltiptext-new_loyal">
                            Only reservations or room nights in the last X years
                            will be considered
                          </span>
                        </span>
                        <input
                          type="number"
                          onKeyDown={(e) =>
                            symbolsrate.includes(e.key) && e.preventDefault()
                          }
                          min={0}
                          className="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                          name="period"
                          value={loyaltyadd.period}
                          onChange={handleloyaltypop}
                          style={{ textAlign: "center" }}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />

                        <span
                          className="input-group-text input-joint-color1"
                          id="inputGroup-sizing-default"
                        >
                          Year
                        </span>
                      </span>
                      <label style={{ color: "red" }}>{Error.period}</label>
                    </span>
                  ) : (
                    ""
                  )}
                  <span className="input-group">
                    <span
                      className="input-group-text input-joint-color1"
                      id="inputGroup-sizing-default"
                    >
                      Benefits{" "}
                      <span className="form-validation-required">*</span>
                    </span>
                    <span className="" style={{ width: "80%" }}>
                      <MultiSelect
                        options={benefit}
                        value={loyaltybenefit}
                        onChange={(e) => setLoyaltybenefit(e)}
                        labelledBy="Select"
                        selectionType="counter"
                        allowCustomValue={true}
                        aria-expanded="true"
                      />
                    </span>
                  </span>
                  <label style={{ color: "red" }}>{Error.loyaltybenefit}</label>
                </div>
              </form>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <button type="button" className="btn db-save-button" onClick={Add}>
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popup}
        toggle={() => {
          setPopup(!popup);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopup(!popup)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.default_image.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs.main_image}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() =>
                          handleImageClick(imgs.main_image, popupid)
                        }
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popuploop}
        toggle={() => {
          setPopuploop(!popuploop);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopuploop(!popuploop)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.default_image.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs.main_image}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={(e) =>
                          handleImageClickloop(
                            imgs.main_image,
                            popupid,
                            popupindex
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={popupcreate}
        toggle={() => {
          setPopupcreate(!popupcreate);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopupcreate(!popupcreate)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.default_image.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs.main_image}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() =>
                          handleImageClickcreate(imgs.main_image, popupid)
                        }
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={levelid}
          message={"Loyalty"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Loyaltylevel;
