import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Common/Footer";
import Loyaltyheader from "../../../Common/Loyaltyheader";
import Nodatafound from "../../../Common/Nodatafound";
import Toastmodal from "../../../Common/Toastmodal";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import { UserContext } from "../../../Contexts/UserContext";
import DeleteModal from "../../../DeleteModal";
import Loading from "../../../Loading";

function Loyaltyprogramlist() {
  const [programList, setProgramList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [programid, setProgramid] = useState("");
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [selehotel, setSelechotel] = useState(0);
  const [showFullData, setShowFullData] = useState([]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(ids) {
    setProgramid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const getlist =useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}loyalty-programs/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setLoader(false);
        setProgramList(data.data);
        setCurrency(data.data.currency);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setLoader(false);
    //   setProgramList(data.data);
    //   setCurrency(data.data.currency);
    // }
  },[handleUnauthorized,id]);

  const Delete = async (ids) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty/programs/delete`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        program_id: ids,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (res.status !== 200 || !res) {
      console.log("error");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    } else {
      console.log("success");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlipDel(false);
      getlist();
    }
  };

  useEffect(() => {
    getlist();
  }, [id,getlist]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (programList.length > 0) {
      const initialState = programList.map((item, index) => ({
        hotel: index === 0,
        // rateplans: Array(item.rateplans.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [programList]);

  return (
    <div className="main-content">
      <Loyaltyheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="" style={{ fontWeight: "500" }}>
                    Loyalty Program
                  </h4>
                </div>
                <div className="extra-create-btn">
                  <Link
                    type="button"
                    className="btn db-save-button"
                    to={`/loyalty-program-create/${programList[selehotel]?.hotel_id}`}
                  >
                    Create
                  </Link>
                </div>
              </div>
              <div className="row">
                {loader === true ? (
                  <Loading />
                ) : (
                  <>
                    {programList.map((data, i) => {
                      return (
                        <>
                          <div
                            className={
                              programList.length > 1
                                ? "row hotel_single_list"
                                : "row"
                            }
                            key={i}
                          >
                            {programList.length > 1 ? (
                              <div className="hotel_service_display hotel_service_display_12">
                                <div className="hotel_service_display_14">
                                  <input
                                    type="radio"
                                    id="radio-one"
                                    className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                    value={true}
                                    checked={i === selehotel ? true : false}
                                    onChange={(e) => setSelechotel(i)}
                                    style={{
                                      position: "relative",
                                      top: "8px",
                                    }}
                                  />

                                  <div className="hk_service_all_hotel">
                                    <span className=""> {i + 1}.</span>
                                    <span style={{ marginLeft: "10px" }}>
                                      {data.hotel_name}
                                    </span>
                                  </div>
                                </div>
                                {showFullData[i]?.hotel === true ? (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-up"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                ) : (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-down"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className={`row ${
                                showFullData[i]?.hotel === true ? "" : "d-none"
                              }`}
                            >
                              {data.loyalty_programs.length > 0 ? (
                                <>
                                  {data.loyalty_programs?.map(
                                    (program, index) => {
                                      return (
                                        <>
                                          <div
                                            className="col-md-12"
                                            key={index}
                                          >
                                            <div className="card extra-lists">
                                              <div
                                                className="extra-list-item"
                                                style={{
                                                  border: "1px solid #d2d2d2",
                                                }}
                                              >
                                                <div
                                                  className="extra-image"
                                                  style={{ marginTop: "8px" }}
                                                >
                                                  <img
                                                    src="images/icon/loyalty-program.png"
                                                    style={{ width: "40px" }}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="col-md-5 extra-title">
                                                  <Link
                                                    to={`/loyalty-program/view/${program.program_id}/${data.hotel_id}`}
                                                  >
                                                    <h6
                                                      className="extra_head"
                                                      style={{
                                                        marginLeft: "15px",
                                                        marginTop: "6px",
                                                      }}
                                                    >
                                                      {program.program_name}
                                                    </h6>
                                                  </Link>
                                                </div>

                                                {program.discount ? (
                                                  <div className="loyalty_sub_field_list">
                                                    <span>
                                                      Discount :{" "}
                                                      {program.discount}{" "}
                                                      {program.unit_type ===
                                                      "percentage"
                                                        ? "% ,"
                                                        : program.unit_type ===
                                                            "currency"
                                                          ? program.currency
                                                              ?.code + ", "
                                                          : ""}{" "}
                                                      {program.condition}
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="loyalty_sub_field_list">
                                                  <span>
                                                    {program.condition}
                                                  </span>
                                                  </div>
                                                )}
                                                <div
                                                  className="extra-status-button"
                                                  style={{
                                                    paddingRight: "10%",
                                                  }}
                                                >
                                                  {program.status === true ? (
                                                    <>
                                                      <Link
                                                        to={`/loyalty-program/view/${program.program_id}/${data.hotel_id}`}
                                                        className="offer-status-enabled"
                                                      >
                                                        Enabled
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Link
                                                        to={`/loyalty-program/view/${program.program_id}/${data.hotel_id}`}
                                                        className="offer-status-enabled"
                                                      >
                                                        Draft
                                                      </Link>
                                                    </>
                                                  )}
                                                </div>
                                                <span className="offer-links">
                                                  <Link
                                                    className="offer-duplicate-icon"
                                                    to={`/loyalty-program/view/${program.program_id}/${data.hotel_id}`}
                                                    title="Edit"
                                                    style={{ top: "4px" }}
                                                  >
                                                    <i className="ri-pencil-fill fs-16"></i>
                                                  </Link>
                                                  <Link
                                                    className="offer-delete-icon"
                                                    to=""
                                                    title="Delete"
                                                    onClick={(e) =>
                                                      tog_animationFlipDel(
                                                        program.program_id
                                                      )
                                                    }
                                                  >
                                                    <img src="images/trash.png" alt="" />
                                                  </Link>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <Nodatafound />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={programid}
          message={"Loyalty Program"}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Loyaltyprogramlist;
