import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";

import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import Config from "../../Config";
import axios from "axios";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import Countryedit from "./Countryedit";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Loading from "../../Loading";

function Countries() {
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const[page,setPage]=useState(1);
const[pageindex,setPageindex]=useState(1);
  const [country, setCountry] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [pageid, setPageid] = useState("");
const[countrydetail,setCountrydetail]=useState([]);
  const [currency, setCurreny] = useState([]);
  const [countryimage,setCountryImage]=useState([]);
  const [loader,setLoader] = useState(false);

  const [countrydata, setCountrydata] = useState({
    name: "",
    code: "",
    currency: "",
    flag: "",
    calling_code: "",
    status: "",
  });

  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [open, setOpen] = useState(false);

  const getData = useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}admin/country/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code === "200" ) {
      setCountry(data.data);
      setPaginate(data.last_page);
      setLoader(true);
    }
    else if(data1.reauth===true){
      handleUnauthorized();
      getData();
    }
    else {
      console.log("error");

    }
  },[handleUnauthorized]);

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/country/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    if (data.status_code === "200" ) {
      return data.data.data;
    }
    else if(data.reauth===true){
      handleUnauthorized();
      fetchComments(currentPage);
    }
    else {
      console.log("error");

    }
    
  };

  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setCountry(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setCountry(commentsFormServer);
    }
  };
  const getinfo = useCallback(async (e) => {
    const res = await fetch(`${Config.apiurl}currencies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code === "200" ) {
      setCurreny(data);
     
    }
    else if(res.reauth===true){
      handleUnauthorized();
      getinfo();
    }
    else {
      console.log("error");
    }
  },[handleUnauthorized]);

  let name, value;
  const handleInput = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setCountrydata({ ...countrydata, [name]: value });
  };

  const onFileChange = (e) => {
    console.log(e);
    let name = e.target.name;
    setCountrydata({ ...countrydata, [name]: e.target.files[0] });
  };

  function Reset() {
    setCountrydata({
      name: "",
      code: "",
      currency: "",
      flag: "",
      calling_code: "",
      status: "",
    });
  }

  const validate = (values) => {
    const errors = {};
    var regex = /^\+(?:[0-9] ?)/;
    const noSpecialCharRegex = /^[A-Za-z0-9\s]+$/;

    if (!values.name) {
      errors.name = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values.name)) {
      errors.name = "Name should not contain this special character";
    }
    else if (values.name.length > 50) {
      errors.name = `Name cannot be more than 50 characters!`;
    }
    if (!values.code) {
      errors.code = "Code is required!";
    }
    if (!values.currency) {
      errors.currency = "Currency is required!";
    }
    if (!values.flag) {
      errors.flag = "Flag is required!";
    }
    else{
      if(
        values.flag.type === "image/jpeg" ||
        values.flag.type === "image/jpg" ||
        values.flag.type === "image/png" ||
        values.flag.type === "image/webp" 
      ){
      console.log("");
    } else {  
      errors.flag = "Only support jpg, jpeg, png, webp image!";
    }
  }
    if (!values.calling_code) {
      errors.calling_code = "Calling Code is required!";
    } else if (
      !regex.test(values.calling_code) ||
      values.calling_code.length > 4
    ) {
      errors.calling_code = "Enter valid data";
    }
    if (!values.status) {
      errors.status = "Status is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(countrydata);
    setError(ress);
    if (
      !ress.name &&
      !ress.code &&
      !ress.currency &&
      !ress.flag &&
      !ress.calling_code &&
      !ress.status
    ) {
      const formData = new FormData();
      formData.append("name", countrydata.name);
      formData.append("code", countrydata.code);
      formData.append("currency", countrydata.currency);
      formData.append("calling_code", countrydata.calling_code);
      formData.append("is_active", countrydata.status);
      formData.append("flag", countrydata.flag);
      axios
        .post(`${Config.apiurl}admin/country/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status_code === "200" ) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
          
            handlePageClick(page);
          } 
          else if(res.reauth===true){
            handleUnauthorized();
            PostData();
          }
          else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");

          }
        });
    }
  };


  function tog_backdrop(ids) {
    setCountrydetail([]);
    setmodal_backdrop(!modal_backdrop);
    setPageid(ids);

    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/country/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code === "200" ) {
        setCountrydetail(data[0]);
      } 
      else if(data1.reauth===true){
        handleUnauthorized();
        getinfo();
      }
      else {
        console.log("error");

      }
    };
    getinfo();
  }

  const handleInputedit = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setCountrydetail({ ...countrydetail, [name]: value });
  };

  const onFileChangeedit = (e) => {
    console.log(e);
    let name = e.target.name;
    setCountryImage({ ...countryimage, [name]: e.target.files[0] });
  };

  const UpdateData = async (id) => {
    const formData = new FormData();
    formData.append("name", countrydetail.name);
    if(countryimage.image===undefined)
    {
      formData.append("flag", "");

    }
    else{
      formData.append("flag", countryimage.image);

    }
    formData.append("code", countrydetail.code);
    formData.append("currency", countrydetail.currency);
    formData.append("calling_code", countrydetail.calling_code);
    formData.append("is_active", countrydetail.status);

    axios
      .post(`${Config.apiurl}admin/country/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status_code === "200" ) {

          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          setmodal_backdrop(false);       
          handlePageClick(page);
          window.scrollTo(0, 0);          
        } 
        else if(res.reauth===true){
          handleUnauthorized();
          UpdateData();
        }
        else {
         
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        }
      });
  };
  useEffect(() => {
    getinfo();
  }, [getinfo]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Countries</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Location Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Countries</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Country
                      </h4>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Country Name{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={countrydata.name}
                                  onChange={handleInput}
                                  name="name"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.name}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Country Code{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={countrydata.code}
                                  onChange={handleInput}
                                  name="code"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.code}
                                </label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label for="firstNameinput" class="form-label">
                                  Currency{" "}
                                  <span class="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  class="form-select"
                                  value={countrydata.currency}
                                  onChange={handleInput}
                                  name="currency"
                                >
                                  <option selected>Choose...</option>
                                  {currency.map((element, i) => {
                                    return (
                                      <option
                                        key={i}
                                        name="currency"
                                        value={element.currency_id}
                                      >
                                        {element.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.currency}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="lastNameinput"
                                  className="form-label"
                                >
                                  Flag
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  onChange={onFileChange}
                                  name="flag"
                                  accept=".jpg, .jpeg, .webp, .png"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.flag}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Calling Code{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={countrydata.calling_code}
                                  onChange={handleInput}
                                  name="calling_code"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.calling_code}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Status
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  name="status"
                                  value={countrydata.status}
                                  onChange={handleInput}
                                >
                                  <option selected>Choose...</option>
                                  <option name="status" value="1">
                                    Yes
                                  </option>
                                  <option name="status" value="0">
                                    No
                                  </option>
                                </select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.status}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm d-flex justify-content-between">
                          <h5 className="card-title mb-0">List Of Countries</h5>
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th className="" data-sort="id">
                                  SL
                                </th>
                                <th className="" data-sort="customer_name">
                                  Country
                                </th>
                                {/* <th className="" data-sort="customer_name">
                                  Flag
                                </th> */}
                                <th className="" data-sort="customer_name">
                                    Status
                                  </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {loader===false?
                            <tbody>
                               <tr>
                              <td
                                style={{ textAlign: "center" }}
                                colSpan={8}
                              >
                                <Loading />
                              </td>
                            </tr>
                            </tbody>
                          
                            :
                            <tbody className="list form-check-all">
                              {country.map((user, index) => {
                                return (
                                  <tr key={user.id}>
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.name}
                                    </td>
                                    <td className="status">
                                        {user.status === false ? (
                                          <span class="badge badge-soft-danger text-uppercase">
                                            {" "}
                                            DISABLED
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-success text-uppercase">
                                            ACTIVE
                                          </span>
                                        )}
                                      </td>
                                    {/* <td className="customer_name">
                                      <img
                                        src={`${Commonimagepath.apiurl}${user.country_flag}`}
                                        alt=""
                                      />
                                    </td> */}
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              tog_backdrop(user.country_id)
                                            }
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          {/* <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip()}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link> */}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                           }
                          </table>
                        </div>
                        {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Modal
        id="flipModal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="mt-4 text-center">
            <h4>You are about to delete a page ?</h4>
            <p className="text-muted fs-15 mb-4">
              Deleting your page will remove all of your information from our
              database.
            </p>
          </div>

          <button
            className="btn btn-link link-success fw-medium text-decoration-none"
            onClick={() => {
              tog_animationFlip();
            }}
          >
            {" "}
            <i className="ri-close-line me-1 align-middle"></i>
            Close{" "}
          </button>
          <Button color="danger"> Yes, Delete It </Button>
        </ModalBody>
      </Modal>



      {modal_backdrop ? (
          <Countryedit
            pagedata={countrydetail}          
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            currency={currency}
            UpdateData={UpdateData}
            handleInputedit={handleInputedit}
            onFileChangeedit={onFileChangeedit}
          />
        ) : (
          ""
        )}
    </div>
  );
}

export default Countries;
