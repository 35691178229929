import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Previousmodal from "./Previousmodal";

function HotelFreequency({ setTab ,
  service,
  frequency,
  otherhk,
  addInputFieldHK,
  removeInputFieldsHK,
  handleChangehk,
  ser_length,
  handleChangetogghk,
  hfnext,
  formerror7,
  hkfreequency,
  setHkfreequency,
  hkupdatestatus,
  hk_info_status,
  handleChangehkother,
  hkprevtab,
  hkprev,
  setHkprev,
  Prvfunction,
  closetab,
  edit,
  setEdit,
  hkbutton,
  loaderspin
}) {

  let defaults= frequency[2];

 if(hkfreequency.length===0){
  if(service.length > 0){
    let array=[];
        for(let j=0;j<service.length;j++){
          let ser={};
        //  ser.label = service[j].en_title;
        //   ser.value = service[j].service_id;
         ser.label = service[j].label;
          ser.value = service[j].value;
        array.push({ service: ser ,frequency:defaults});
          setHkfreequency(array);
        }
  }
 }
 else{
  setEdit(true)
 }

console.log(hkfreequency);
  return (
    <div className="tabcontent">
     
      <div className="card-body">
        <p style={{ color: "#111" }}>
          House Keeping Service Frequencies can be added below.
        </p>
        <div className="live-preview">
          <form action="#">
            <div className="be_form_hk_frequency_title">
              <span className="be_form_hk_frequency_label30">
                <label for="firstNameinput" className="form-label be_form_label">
                  Service <span className="form-validation-required">*</span>
                </label>
              </span>
              <span className="be_form_hk_frequency_label30">
                <label for="lastNameinput" className="form-label be_form_label">
                  Frequency
                  <span className="form-validation-required">*</span>
                </label>
              </span>
            </div>
            {hkfreequency.map((data, index) => {
                              return (
                                <div key={index}>
            <div className="be_form_hk_frequency">
              <span className="be_form_hk_frequency_label30">
              {data.service.value?
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  
                  value={data.service.label}
                  name="label"
                />
                :
                <input
                type="text"
                className="form-control"
                placeholder=""
                onChange={(e) => handleChangehk(index, e)}
                value={data.service.label}
                name="label"
              />
            }
               
               {formerror7.map((err,i)=>{        
                                return(
                                    <>
                                          {index===i && err && err.service_name?
                                              <label style={{ color: "red" }}>
                                                    {err.service_name}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })}  
              </span>
              <span className="be_form_hk_frequency_label30">
                
                  <Select
                  className="form-control"
                  name="frequency"
                  options={frequency}
                 
                  defaultValue={defaults}
                
                  value={data.frequency==="not_available"?{label:"Not Availabele",value:"not_available"}:data.frequency}
                  onChange={(val)=>handleChangetogghk(index,val,"frequency")}
                  />
               
                {/* <Select
                                  className="form-control"
                                 name="frequency"
                                  options={frequency}
                                 value={data.frequency?data.frequency:{label:"Not Availabele",value:"not_available"}}
                    onChange={(val)=>handleChangetogghk(index,val,"frequency")}
                    /> */}

                  {formerror7.map((err,i)=>{        
                                return(
                                    <>
                                          {index===i && err && err.frequency?
                                              <label style={{ color: "red" }}>
                                                    {err.frequency}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })} 
               
              </span>
              {data?.frequency?.value==="other"?
              <span
                className="be_form_hk_frequency_label30"
                id="hk_frequency_other"
                
              >
                <input
                  type="text"
                  className="form-control hf"
                  placeholder=""
                  onChange={(e) => handleChangehkother(index, e)}
               value={data.other_value}
               name="other_value"
                  
                />

              {formerror7.map((err,i)=>{        
                                return(
                                    <>
                                          {index===i && err && err.other_value?
                                              <label style={{ color: "red" }}>
                                                    {err.other_value}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })} 
              </span>
              :""}
                                     
                                      <Link className={index<ser_length?"remove_more d-none":"remove_more hotelfre_addremove"} to="#" onClick={(e)=>removeInputFieldsHK(index)}>X</Link>

                                      <span className={index===(hkfreequency.length-1)?"extrarate-room-link  hotelfre_addremove":"d-none"}>
                                        <Link className="extra-rate-add" to="#"  onClick={addInputFieldHK}>+</Link>
                                      </span>

            </div>
          
            <div className="clearfix"></div>
            </div>
                              )})}
            <div className="hk_frequency_appendmore"></div>
            <div className="padding-10"></div>
            
          </form>
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns_form">
              <button
                className="btn btn-be-form-cancel btn-be-back"
                // onClick={(e) => setTab("Room-info")}
                onClick={hkprevtab}
              >
                Prev
              </button>
              {hkupdatestatus===true && hkbutton===true?
              <button
              className="btn btn-success btn-be-common"
              // onClick={(e) => setTab("Tax")}
              onClick={hfnext}
              disabled
            >
             Save &  Next
            </button>
              :hkupdatestatus===true && hkbutton===false?

              <button
                className="btn btn-success btn-be-common"
                // onClick={(e) => setTab("Tax")}
                onClick={hfnext}
              >
               Save &  Next
              </button>
              :hkupdatestatus===false && hk_info_status===true ?
              <button
                className="btn btn-success btn-be-common"
                onClick={(e) => setTab("Tax")}
              >
              Next
              </button>
              : hkbutton===true?
              <button
              className="btn btn-success btn-be-common"
              // onClick={() => setTab("Room-info")}
              onClick={hfnext}
              disabled
            >
             Save &  Next

            </button>
              :
               <button
              className="btn btn-success btn-be-common"
              // onClick={() => setTab("Room-info")}
              onClick={hfnext}
            >
             Save &  Next
            </button>
              }
            </div>
          </div>
          { hkprev===true?
          <Previousmodal message="HK frequency" roomprev={hkprev} setRoomprev={setHkprev} tag="hk" Prvfunction={Prvfunction} closetab={closetab}/>
          :""}
        </div>
      </div>
     
    </div>
  );
}

export default HotelFreequency;
