import React, { useCallback, useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Config from "../Config";
import { MultiSelect } from "react-multi-select-component";
import Alerts from "../Alerts";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import axios from "axios";
import Commonheader from "../Common/Commonheader";

function Managepagefileds() {
  const location = useLocation();

  const [ids, setId] = useState(location.state.page_id);
  const [pageid, setPageid] = useState("");

  const [pagecontent, setPagecontent] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const language = localStorage.getItem("be_language");

  const [page, setPage] = useState([]);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [paginate, setPaginate] = useState([]);

  const [formerror, setError] = useState({});

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const deletefunction = async (val) => {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(val);
  };
  const getinfo =useCallback(async (e) => {
    if (language === null) {
      const res = await fetch(`${Config.apiurl}admin/pagecontent/list`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setPagecontent(data.data);
      }
    } else {
      const res = await fetch(`${Config.apiurl}admin/pagecontent/list`, {
        method: "POST",
        body: JSON.stringify({
          language: language,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setPagecontent(data.data);
      }
    }
  },[language]);

  const getData =useCallback(async (e) => {
    const res = await fetch(
      `${Config.apiurl}admin/page/contents/${ids}?page=1`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await res.json();
    if (data.status === 404 || !data) {
      console.log("error");
    } else {
      setPage(data.data.data);
      setPaginate(data.data.last_page);
    }
  },[ids]);

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/page/contents/${ids}?page=${currentPage}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setPage(commentsFormServer);
  };
  const validate = (values) => {
    const errors = {};
    if (values.length === 0) {
      errors.content = "Page content is required!";
    }

    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(selectedMulti);

    let lang = [];
    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].value) {
        lang.push(selectedMulti[i].value);
      }
    }
    setError(ress);
    if (!ress.content) {
      const res = await fetch(
        `${Config.apiurl}admin/page/manage-contents/${ids}`,
        {
          method: "POST",
          body: JSON.stringify({
            page_contents: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      } else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        getData();
      }
    }
  };

  const DeleteData = async (valu) => {
    const res = await fetch(
      `${Config.apiurl}admin/page/manage-contents/content/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          page_id: ids,
          page_content_id: valu,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      setAlert(true);
      setAlertmessage(data.message);
      setOpen(true);
      setAlerttype("error");
      setmodal_animationFlip(false);
    } else {
      setAlert(true);
      setAlertmessage(data.message);
      setOpen(true);
      setAlerttype("success");
      setmodal_animationFlip(false);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getinfo();
  }, [getinfo]);

  return (
    <div id="layout-wrapper">
     <Commonheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Manage Page Contents</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/pages">Pages</Link>
                      </li>
                      <li className="breadcrumb-item active">Page Contents</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>

            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Add Page Contents for {location.state.page_name} page
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <label for="firstNameinput" className="form-label">
                              Page Content{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                            </label>

                            <div className="col-md-4">
                              <MultiSelect
                                options={pagecontent}
                                value={selectedMulti}
                                onChange={setselectedMulti}
                                labelledBy="Select"
                                selectionType="counter"
                              />
                              <label style={{ color: "red", marginTop: "3px" }}>
                                {formerror.content}
                              </label>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                className="btn db-save-single-button"
                                data-bs-toggle="modal"
                                id="create-btn"
                                data-bs-target=""
                                onClick={PostData}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm">
                          <h5 className="card-title mb-0">
                            List Of Page Contents
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th className="" data-sort="id">
                                  SL
                                </th>
                                <th className="" data-sort="customer_name">
                                  Page Content
                                </th>
                                <th className="" data-sort="customer_name">
                                  Type
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {page.map((pages, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="id">{i + 1}</td>
                                    <td className="customer_name">
                                      {pages.en_title}
                                    </td>
                                    <td className="customer_name">
                                      {pages.type}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <button
                                            className="text-danger d-inline-block remove-item-btn link-btn"
                                            //   data-bs-toggle="modal"

                                            // onClick={DeleteData}
                                            onClick={() => {
                                              deletefunction(
                                                pages.page_content_id
                                              );
                                            }}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </button>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {modal_animationFlip ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip}
          tog_animationFlip={deletefunction}
          pageid={pageid}
          DeleteData={DeleteData}
          message={"Page content"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Managepagefileds;
