import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Sidebarbasicsettings({showhotel}) {


  return (
    <div>
      <div
        id="removeNotificationModal"
        className="modal fade zoomIn"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="NotificationModalbtn-close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you sure you want to remove this Notification ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger"
                  id="delete-notification"
                >
                  Yes, Delete It!
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content  */}
        </div>
        {/* /.modal-dialog  */}
      </div>
      <div className="app-menu navbar-menu">
        {/* LOGO  */}
        <div className="navbar-brand-box">
          {/* Dark Logo */}
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          {/* Light Logo */}
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <span data-key="t-menu">Menu</span>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
            
{/* < i className="ri-arrow-left-line"></i>{" "}
                 
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="/currency-list"
                 
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                > */}
                <span style={{marginRight:"5px"}}>Basic Settings </span>
                  <  i className="ri-arrow-right-line"></i>{" "}
                 
                 </Link>
               </li>
               <li className="nav-item">
                 <Link
                   className="nav-link menu-link common"
                   to="/basic_settings/currency-list"
                  
                   role="button"
                   aria-expanded="false"
                   aria-controls="sidebarMaps"
                 >
                
                 < i className="ri-currency-line"></i>{" "}
                  <span data-key="t-maps">Currencies</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="ri-rocket-line"></i>{" "}
                  <span data-key="t-maps">Location Settings</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarSuperAdmin">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/countries"
                        className="nav-link common"
                        data-key="t-google"
                      >
                       Country
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="" className="nav-link" data-key="t-vector">
                        Permissions
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/cities"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        City
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/timezone"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                       Timezone
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </li>
             
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-pager"></i>{" "}
                  <span data-key="t-maps">Payment Settings</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarMaps">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/credit-card"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                      Cards
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/payment-method-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                     Payment Method
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/payment-option-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                     Payment Option
                      </Link>
                     
                    </li>
                  </ul>
                </div> 
               
              </li>
             
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="/basic_settings/star-category"
                
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="ri-star-line"></i>{" "}
                  <span data-key="t-maps">Star Category</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="ri-briefcase-2-line"></i>{" "}
                  <span data-key="t-maps">More</span>
                </Link>
               
                <div className="collapse menu-dropdown" id="sidebarMaps">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/board-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                    <i className="ri-keyboard-box-line"></i>{" "}
                  <span data-key="t-maps">Meal Board</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/frequency-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                  <i className="ri-codepen-line"></i>{" "}
                  <span data-key="t-maps">HK Frequencies</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/service-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="ri-boxing-fill"></i>{" "}
                  <span data-key="t-maps">HK Services</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/facility-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-dolly-flatbed"></i>{" "}
                  <span data-key="t-maps">Facilities</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/bedtype-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="ri-hotel-bed-line"></i>{" "}
                  <span data-key="t-maps">Bed Type</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/hotel-inclusion"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-calendar-check"></i>{" "}
                  <span data-key="t-maps">Room Amenities</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/hotel-amenities-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-hotel"></i>{" "}
                  <span data-key="t-maps">Hotel Amenities</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/property-type-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-flask"></i>{" "}
                  <span data-key="t-maps">Property Type</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/channel-manager-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-user-cog"></i>{" "}
                  <span data-key="t-maps">Channel Manager</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/property-management-system"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-cogs"></i>{" "}
                  <span data-key="t-maps">Property Management System</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/loyalty-benefit-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i className="las la-gift"></i>{" "}
                  <span data-key="t-maps">Loyalty Benefits</span>
                      </Link>
                     
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/basic_settings/policy-contract-list"
                       
                        className="nav-link common"
                        data-key="t-google"
                      >
                   <i class="las la-book-open"></i>{" "}
                  <span data-key="t-maps">Default Policies</span>
                      </Link>
                     
                    </li>
                  </ul>
                </div> 
                
              </li>

              {/* <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Meal board</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
               
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">HK Freequency</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
               
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Services</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Facility</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                 
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Bed Type</span>
                </Link>
              
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Aminities</span>
                </Link>
              
              </li> */}
            </ul>
          </div>
          {/* <!-- Sidebar --> */}
        </div>

        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </div>
  );
}

export default Sidebarbasicsettings;



