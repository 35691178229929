import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Commonimagepath from "../../../Commonimagepath";

function Offerstep6sugg({
  setTab,
  offer6fields,
  language,
  selectedMulti6,
  setselectedMulti6,
  handlechangeoffer6,
  // onFileChange,
  handleoffer6checkbox,
  onFileChangepdf,
  Save,
  setImage,
  setImagechoose,
  imagechoose,
  image,
  optionimage,
  currency,
  pdf,
}) {
  const [tab1, setTab1] = useState("EN");
  const [popup, setPopup] = useState(false);
  const [tabimg, setTabimage] = useState("upload");

  const fileInputRef = useRef(null);

  const handleImageClick = (imageSrc) => {
    // setImagechoose(imageSrc);
    console.log("123");

    setImage(imageSrc);

    setPopup(false);
  };

  const onFileChange = (e) => {
    console.log("111");

    setPopup(false);
    setImage(e.target.files[0]);
    e.target.value = null;
  };
  console.log(image);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-creation-media  offer-create-wid">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                Offer marketing refers to the strategies and activities
                undertaken to promote and advertise a specific offer or
                promotion to target audiences.{" "}
              </h6>

              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <div className="form-translation-style">
                    <ul>
                      <li
                        className={tab1 === "EN" ? "active en" : ""}
                        onClick={(e) => setTab1("EN")}
                      >
                        EN
                      </li>
                      <li
                        className={tab1 === "TRANS" ? "active en" : ""}
                        onClick={(e) => setTab1("TRANS")}
                      >
                        Translations
                      </li>
                    </ul>
                  </div>
                </div>
                {tab1 === "EN" ? (
                  <>
                    <div className="offer-content-section">
                      <div className="col-md-12">
                        <label>
                          Description (Optional) (Eg. Getting ahead has a prize:
                          Enjoy our offer for reservations made with two or more
                          months of advance. 15% discount on the normal price.
                          Offer only available for reservations through the
                          hotel website.)
                          {/* <span className="info-tag">i<span className="tooltiptext">(Eg. Getting ahead has a prize: Enjoy our offer for reservations made with two or more months of advance. 15% discount on the normal price. Offer only available for reservations through the hotel website.)</span></span> */}
                        </label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={offer6fields.description}
                          onChange={handlechangeoffer6}
                        ></textarea>
                      </div>
                      <div className="padding-5"></div>
                      <div className="col-md-12">
                        <label>
                          Price Description (Optional)
                          <span className="info-tag">
                            i
                            <span className="tooltiptext">
                              Max. 35 characters (Eg. From 120€ by double
                              room/night)
                            </span>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={offer6fields.price_description}
                          name="price_description"
                          onChange={handlechangeoffer6}
                        />
                        <p style={{ marginTop: "5px" }}>Examples</p>
                        <ul>
                          <li>-10%</li>
                          <li>
                            From 120{currency.symbol} by double room/night
                          </li>
                          <li>Free Night</li>
                        </ul>
                      </div>
                      <div className="padding-5"></div>
                      <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input offer_include_check"
                            type="checkbox"
                            id="formCheck1"
                            onChange={(e) =>
                              handleoffer6checkbox(
                                e.target.checked,
                                "hide_offer_status"
                              )
                            }
                            value={offer6fields.hide_offer_status}
                            checked={
                              offer6fields.hide_offer_status === true
                                ? true
                                : false
                            }
                          />
                          <label>
                            Hide it at at the dynamic website section
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input offer_include_check"
                            type="checkbox"
                            id="formCheck1"
                            onChange={(e) =>
                              handleoffer6checkbox(
                                e.target.checked,
                                "show_offer_discount"
                              )
                            }
                            value={offer6fields.show_offer_discount}
                            checked={
                              offer6fields.show_offer_discount === true
                                ? true
                                : false
                            }
                          />
                          <label>
                            Show the calculated exact discount after the offer
                            name
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input offer_include_check"
                            type="checkbox"
                            id="formCheck1"
                            onChange={(e) =>
                              handleoffer6checkbox(
                                e.target.checked,
                                "hide_offer_in_search"
                              )
                            }
                            value={offer6fields.hide_offer_in_search}
                            checked={
                              offer6fields.hide_offer_in_search === true
                                ? true
                                : false
                            }
                          />
                          <label>Hide offer from booking sections</label>
                        </div>
                      </div>

                      <div className="padding-5"></div>
                      <div className="col-md-12 photo_offer">
                        <label>Photo</label>
                        <div className="form-translation-style_offer">
                          <ul
                            className="price_show_select_offer"
                            id="price_show_type"
                          >
                            <li
                              className={tabimg === "upload" ? "active" : ""}
                              onClick={(e) => setTabimage("upload")}
                            >
                              <button className="photo_offer_pen_choose">
                                Upload Image
                              </button>
                              <input
                                className="photo_offer_input"
                                type="file"
                                name="image"
                                onChange={onFileChange}
                                accept=".jpg, .jpeg, .webp, .png"
                              />
                            </li>

                            <li
                              className={tabimg === "list" ? "active" : ""}
                              onClick={(e) => {
                                setPopup(!popup);
                                setTabimage("list");
                              }}
                            >
                              <button className="photo_offer_pen_choose">
                                Choose From List
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>

                        <div>
                          {image ? (
                            image instanceof File ? (
                              <p className="pdf_img_style_img">{image.name}</p>
                            ) : (
                              <img
                                className="pdf_img_style_img"
                                alt=""
                                style={{ width: "100px", height: "100px" }}
                                src={`${Commonimagepath.apiurl}${image}`}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div></div>

                        {/* <img src="images/deleteone.png" id="suggestionImage" onclick="selectImage()" style={{cursor: "pointer"}}/>
                                        <input type="file" id="fileInput" style={{display: "none"}}></input> */}
                        {/* <div className="">
                                            <input type="file" className="form-control" style={{width:"250px"}} onChange={onFileChange} accept=".jpg, .jpeg, .webp, .png"></input>
                                        </div> */}
                      </div>
                      <div className="padding-10"></div>
                      <div className="clearfix"></div>

                      <div className="padding-5"></div>
                      <div className="col-md-12 pdf_offer_1">
                        <label>Attach PDF (optional)</label>
                        <div className="pdf_offer">
                          <button>Upload PDF</button>
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf,.doc,.dox"
                            onChange={onFileChangepdf}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          {pdf ? (
                            <p className="pdf_img_style">{pdf.name}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="offer-content-section of-content">
                      <div class="col-lg-4" style={{ marginTop: "20px" }}>
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color box-wid"
                            id="inputGroup-sizing-default"
                            style={{ width: "130px" }}
                          >
                            {" "}
                            Choose Language:
                          </label>
                          <div className="col-md-7 choose-lang-step">
                            <MultiSelect
                              options={language}
                              value={selectedMulti6}
                              onChange={setselectedMulti6}
                              labelledBy="Select"
                              selectionType="counter"
                              closeOnChangedValue={true}
                              aria-expanded="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="appendmore"
                        style={{ marginTop: "20px" }}
                      ></div>
                      {selectedMulti6.length > 0
                        ? selectedMulti6.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="col-md-12 translation-fill-section"
                                id="lang_ +val"
                                data-value="1"
                              >
                                <div className="translation-selected-lang">
                                  <p>
                                    <span className="translation-selected-lang-head">
                                      {element.code}
                                    </span>
                                  </p>
                                </div>

                                <div class="col-lg-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Description
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={selectedMulti6[index].description}
                                      onChange={(e) =>
                                        (selectedMulti6[index].description =
                                          e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <br></br>
                                <div class="col-lg-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "120px" }}
                                    >
                                      Price Description
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={
                                        selectedMulti6[index].price_description
                                      }
                                      onChange={(e) =>
                                        (selectedMulti6[
                                          index
                                        ].price_description = e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </>
                )}
              </div>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  {/* <Link className="btn btn-be-form-cancel btn-be-back" to="#" onClick={(e)=>nextoffterstep6('prev')}>Prev</Link> */}
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => setTab("step5")}
                  >
                    Prev
                  </Link>

                  <Link
                    className="btn btn-success btn-be-common"
                    to=""
                    onClick={Save}
                  >
                    Save
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="flipModal"
        isOpen={popup}
        toggle={() => {
          setPopup(!popup);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopup(!popup)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          {/* <div className="photo_offer_pen">
                <button className='photo_offer_pen_upload'>Upload Image</button>
                <input className='photo_offer_input' type="file" name="image" onChange={onFileChange} accept=".jpg, .jpeg, .webp, .png" />
            </div> */}
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        // alt={`Image ${ind + 1}`}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() => handleImageClick(imgs)}
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Offerstep6sugg;
