import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Footer from "../../Common/Footer";
import Reservationheader from "../../Common/Reservationheader";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { UserContext } from "../../Contexts/UserContext";
import Config from "../../Config";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import Nodatafound from "../../Common/Nodatafound";
import Loading from "../../Loading";

function Reservations() {
  const SingleOptions = [
    { value: "check_in", label: "Checkin date " },
    { value: "check_out", label: "Checkout date " },
    { value: "reserved", label: "Reserved on" },
    { value: "cancelled", label: "Cancelled on" },
  ];

  const Searchby = [
    { value: "reservation_code", label: "Reservation Code" },
    { value: "email", label: "Email" },
    { value: "name", label: "Name" },
  ];

  const SingleOptions2 = [
    { value: "oneline", label: "Show one line per room" },
    { value: "notaccepted", label: "Upselling proposed and not accepted" },
    { value: "applied", label: "Upselling applied" },
    { value: "external", label: "Reservations External Contact Center" },
    { value: "internal", label: "Reservations Internal Contact Center" },
  ];
  const SingleOptions3 = [
    {
      value: "onereservation",
      label: "Download extended report (one reservation per line)",
    },
    { value: "oneroom", label: "Download extended report (one room per line)" },
    { value: "basic", label: " Download basic reservation report" },
    { value: "postponed", label: "Download postponed reservation report" },
  ];
  const [tab, setTab] = useState("view");
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(false);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const [datefrom, setDateFrom] = useState(firstDayOfMonth);
  const [dateto, setDateTo] = useState(lastDayOfMonth);
  const [hotelId, setHotelId] = useState([]);

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [hotel, setHotel] = useState([]);
  const [hotelnew, setHotelnew] = useState([]);
  const [selectedhotel, setSelectedHotel] = useState([]);
  const [selectCondition, setSelectCondition] = useState({value: "reserved", label: "Reserved on"});
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);

  const [list, setList] = useState(null);
  const [list1, setList1] = useState(null);

  const [showFullData, setShowFullData] = useState([]);
  const [reservationcode, setReservationcode] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [Error, setError] = useState({});
  const [searchbyval, setSearchbyval] = useState({
    value: "reservation_code",
    label: "Reservation Code",
  });

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDateFrom(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDateTo(selectedDates[0]);
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const getHotel = async (e) => {
    const res = await fetch(`${Config.apiurl}hotels`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setHotelnew(data);
    }
  };

  const searchLoader = () => {
   setLoader(true);
   getSearch();
  };

  const getSearch =useCallback(async (e) => {
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const errors = {};
    if (tab === "view") {
      if (selectCondition.length === 0) {
        isFormValid = false;
        errors.selectCondition = "This field is required!";
      }
      if (!datefrom) {
        isFormValid = false;
        errors.datefrom = "From date is required!";
      }
      if (!dateto) {
        isFormValid = false;
        errors.dateto = "To date is required!";
      }
      if (hotel.length > 1) {
        if (selectedhotel.length === 0) {
          isFormValid = false;
          errors.selectedhotel = "This field is required!";
        }
      }
    }

    if (tab === "search") {
      if (searchbyval.value === "reservation_code") {
        if (!reservationcode) {
          isFormValid = false;
          errors.reservationcode = "Reservation code is required!";
        }
      }
      if (searchbyval.value === "email") {
        if (!email) {
          isFormValid = false;
          errors.email = "Email is required!";
        } else if (!regex.test(email)) {
          isFormValid = false;
          errors.email = "This is not a valid email format!";
        }
      }
      if (searchbyval.value === "name") {
        if (!name) {
          isFormValid = false;
          errors.name = "Customer Name is required!";
        }
      }
    }
    setError(errors);

    if (isFormValid === true) {
      // let hotelId = [];
      // if (hotel.length > 1) {
      //   for (let i = 0; i < selectedhotel.length; i++) {
      //     hotelId.push(selectedhotel[i].value);
      //   }
      // } else {
      //   hotelId = id;
      // }

      let condition;
      if (tab === "view") {
        condition = selectCondition.value;
      }

      let from;
      if (tab === "view") {
        from = moment(datefrom).format("DD-MM-YYYY");
      }

      let to;
      if (tab === "view") {
        to = moment(dateto).format("DD-MM-YYYY");
      }

      let newVal;
      if (tab === "search") {
        if (searchbyval.value === "reservation_code") {
          newVal = reservationcode;
        } else if (searchbyval.value === "email") {
          newVal = email;
        } else if (searchbyval.value === "name") {
          newVal = name;
        }
      }
      // if(tab==="search"){
      //   code=reservationcode
      // }

      // let mail;
      // if(tab==="search"){
      //   mail=email
      // }

      // let customer_name;
      // if(tab==="search"){
      //   customer_name=name
      // }

      if (tab === "view") {
        const res = await fetch(`${Config.apiurl}reservation/search/list`, {
          method: "POST",
          body: JSON.stringify({
            hotels: hotelId,
            type: tab,
            condition: condition,
            from: from,
            until: to,
            // search_field: searchbyval.value,
            // value: newVal,
            // reservation_code:code,
            // email:mail,
            // customer_name:customer_name
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        const data = await res.json();
        if (data.status_code !== "200" || !data) {
          console.log("error");
        } else {
          setLoader(false);
          setList(data.data);
        }
      } else {
        const res = await fetch(`${Config.apiurl}reservation/search/list`, {
          method: "POST",
          body: JSON.stringify({
            hotels: tab === "view" ? hotelId : id,
            type: tab,
            // condition: condition,
            // from: from,
            // until: to,
            search_field: searchbyval.value,
            value: newVal,
            // reservation_code:code,
            // email:mail,
            // customer_name:customer_name
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        const data = await res.json();
        if (data.status_code !== "200" || !data) {
          console.log("error");
        } else {
          setLoader1(false);
          setList1(data.data);
        }
      }
    }
  },[hotelId,tab,datefrom,dateto,email,hotel.length,id,name,reservationcode,searchbyval.value,selectCondition.length,selectCondition.value,selectedhotel.length]);

  const Export = async (e) => {
    try {
      const res = await fetch(
        `${Config.apiurl}reservation/search/list/download`,
        {
          method: "POST",

          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (res.status !== 200) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      console.log(contentDisposition);
      const fileNameMatch =
        contentDisposition &&
        contentDisposition.match(/filename=(["'])(?:(?=(\\?))\2.)*?\1/);

      const suggestedFileName = fileNameMatch
        ? fileNameMatch[0].substring(10, fileNameMatch[0].length - 1)
        : "reservation.xlsx";

      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = suggestedFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

 
  useEffect(() => {
    let hotels=[];

    if (hotel.length > 1) {
      for (let i = 0; i < selectedhotel.length; i++) {
        hotels.push(selectedhotel[i].value);
      }
    } else {
      hotels = id;
    }
    setHotelId(hotels);
  }, [hotel.length,selectedhotel,id]);


  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    getHotel();
  }, []);

  useEffect(() => {
    const matchedHotels = [];

    id.forEach((ids) => {
      const newHotel = hotelnew.find((hotel) => hotel.hotel_id === ids);
      if (newHotel) {
        matchedHotels.push({
          value: newHotel.hotel_id,
          label: newHotel.hotel_name,
        });
      }
    });

    setHotel(matchedHotels);
  }, [id, hotelnew]);

  useEffect(() => {
    getSearch(); 
  }, [hotelId,getSearch]);

  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    
    dbValue = dbValue.toString();
    let numericValue = parseFloat(dbValue.replace(/,/g, ""));
    
    let result = numericValue.toFixed(2);
    
    return result;
  }

  
  // function processDBValue(dbValue) {
  //   if (dbValue === null || dbValue === undefined) {
  //     return "";
  //   }
  //   dbValue = dbValue.toString();
  //   const numericValue = parseFloat(dbValue.replace(/,/g, ""));
  //   const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;
  //   return result.endsWith(".00") ? result.slice(0, -3) : result;
  // }

  //  useEffect(() => {
  //     if (list.length > 0) {
  //       const initialState = list.map((item, index) => ({
  //         hotel: index === 0,
  //       }));
  //       setShowFullData(initialState);
  //     }
  //   }, [list?.length]);
  return (
    <div className="main-content">
      <Reservationheader />
      <div className="page-content reservation-page-content">
        <div className="">
          <div className="project-wrapper">
            <div className="row">
              <div className="col-xxl-12">
                <div className="card">
                  <div className="reservation-fiter-section">
                    <div className="resevation-filter-switch">
                      <ul className="reservation-switch">
                        <li
                          className={tab === "view" ? "active" : ""}
                          data-value="r_view"
                          onClick={(e) => setTab("view")}
                        >
                          View Reservations
                        </li>
                        <li
                          className={tab === "search" ? "active" : ""}
                          data-value="r_code"
                          onClick={(e) => setTab("search")}
                        >
                          Search By
                        </li>
                      </ul>
                      {/* <div
                        className="reservation-search-btn-sec-new"
                        style={{ float: "right", marginRight: "15px" }}
                      >
                        <Link className="reservation-search-btn">
                          <span className=" las la-download"></span>
                          <span
                            className="reserv_search_label"
                            onClick={Export}
                          >
                            Download
                          </span>
                        </Link>
                      </div> */}
                    </div>
                    <div
                      className={
                        tab === "view"
                          ? "resevation-fiter-main radio r_view_display"
                          : "resevation-fiter-main radio r_view_display d-none"
                      }
                    >
                      <div className="row">
                        <div className=" col-md-2">
                          <div className="input-group">
                            <label
                              className="input-group-text input-joint-color"
                              for="choices-single-default"
                            >
                              Criteria
                              <span className="form-validation-required">
                                *
                              </span>
                            </label>
                            <Select
                              placeholder="Select..."
                              className="form-control"
                              data-choices
                              name="choices-single-default"
                              id="choices-single-default"
                              options={SingleOptions}
                              value={selectCondition}
                              onChange={setSelectCondition}
                            ></Select>
                          </div>
                          <label style={{ color: "red" }}>
                            {Error.selectCondition}
                          </label>
                        </div>
                        <div className="col-md-2 reservation_calendar_width">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              From{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                            </span>
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="FROM"
                              id="flatpickr-from"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={datefrom}
                              onChange={handleCalendarChangeFrom}
                            />

                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("from")}
                            ></span>
                            <label style={{ color: "red" }}>
                              {Error.datefrom}
                            </label>
                            {/* <Flatpickr className="form-control dash-filter-picker-new shadow fs-13 form-control flatpickr-input active"
                                                options={{ mode: "single", dateFormat: "d M, Y" }} value={datefrom} onChange={handleCalendarChangeFrom}/>                                                
                                                <span className="reservation_calendar-style fon mdi mdi-calendar-month-outline" style={{marginTop:"5px"}}
                                                onClick={() =>handleCalendarIconClick("from")}></span> */}
                          </div>
                        </div>
                        <div className=" col-md-2 reservation_calendar_width">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              To
                              <span className="form-validation-required">
                                *{" "}
                              </span>
                            </span>
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="To"
                              id="flatpickr-until"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={dateto}
                              onChange={handleCalendarChangeTo}
                            />

                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("until")}
                            ></span>
                            <label style={{ color: "red" }}>
                              {Error.dateto}
                            </label>
                            {/* <Flatpickr className="form-control dash-filter-picker-new shadow fs-13 form-control flatpickr-input active"
                                                options={{ mode: "single", dateFormat: "d M, Y" }} value={dateto} onChange={handleCalendarChangeTo}/>                                                
                                                <span className="reservation_calendar-style fon mdi mdi-calendar-month-outline" style={{marginTop:"5px"}}
                                                onClick={() =>handleCalendarIconClick("until")}></span> */}
                          </div>
                        </div>
                        {hotel.length > 1 ? (
                          <div className="col-md-3">
                            <div className="input-group">
                              <label
                                className="input-group-text input-joint-color"
                                for="inputGroupSelect01"
                              >
                                Hotels
                                <span className="form-validation-required">
                                  *
                                </span>
                              </label>
                              <div className="col-md-8">
                                <MultiSelect
                                  options={hotel}
                                  value={selectedhotel}
                                  onChange={setSelectedHotel}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={false}
                                  aria-expanded="true"
                                />
                              </div>
                              <label style={{ color: "red" }}>
                                {Error.selectedhotel}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-1">
                          {/* <div className="col-xxl-8 col-xl-8 col-sm-8 col-sm-8 col-xs-8" style={{float: "left"}}>
                                            <div className="input-group">
                                              <label className="input-group-text input-joint-color" for="inputGroupSelect01">
                                              <span className="ri  ri-filter-line" style={{fontSize: "14px"}}></span>
                                              <span className="">Filter</span></label>
                                              <Select
                                                placeholder="Select..."
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                options={SingleOptions2}>
                                             </Select>
                                              
                                            </div>
                                          </div> */}
                          <div
                            className="reservation-search-btn-sec"
                            onClick={searchLoader}
                            // onClick={getSearch}
                            // onClick={(e)=>setTab1(true)}
                          >
                            <Link className="reservation-search-btn">
                              <span className="ri  ri-search-line reser_search_icon"></span>
                              <span className="reserv_search_label">
                                Search
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        tab === "search"
                          ? "resevation-fiter-main radio r_code_display"
                          : "resevation-fiter-main radio r_code_display d-none"
                      }
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              Search option
                              <span className="form-validation-required">
                                *
                              </span>
                            </span>
                            <Select
                              placeholder="Select..."
                              className="form-control"
                              data-choices
                              name="choices-single-default"
                              id="choices-single-default"
                              options={Searchby}
                              value={searchbyval}
                              onChange={setSearchbyval}
                            ></Select>
                          </div>
                        </div>
                        {searchbyval.value === "reservation_code" ? (
                          <div className="col-md-3">
                            <div className="input-group">
                              <span
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-sm"
                              >
                                Reservation Code
                                <span className="form-validation-required">
                                  *
                                </span>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                value={reservationcode}
                                onChange={(e) =>
                                  setReservationcode(e.target.value)
                                }
                              />
                              <label style={{ color: "red" }}>
                                {Error.reservationcode}
                              </label>
                            </div>
                          </div>
                        ) : searchbyval.value === "email" ? (
                          <div className="col-md-3">
                            <div className="input-group">
                              <span
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-sm"
                              >
                                Email
                                <span className="form-validation-required">
                                  *
                                </span>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <label style={{ color: "red" }}>
                              {Error.email}
                            </label>
                          </div>
                        ) : (
                          <div className="col-md-3">
                            <div className="input-group">
                              <span
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-sm"
                              >
                                Name
                                <span className="form-validation-required">
                                  *
                                </span>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                              <label style={{ color: "red" }}>
                                {Error.name}
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="col-md-1">
                          <div
                            className="reservation-search-btn-sec"
                            onClick={getSearch}
                            style={{ width: "85px" }}
                          >
                            <Link className="reservation-search-btn">
                              <span className="ri ri-search-line reser_search_icon"></span>
                              <span className="reserv_search_label">
                                Search
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-xl-1 col-md-1"></div>
                </div>
              </div>
            </div>
          </div>

          {tab === "view" ? (
            <>
            {loader===true?
            <Loading/>
            :
            <>
              {list && list.length > 0 ? (
                <>
                  {list?.map((data, i) => {
                    return (
                      <>
                        <div key={i} className="row">
                          {/* <div className="hotel_service_display hotel_service_display_12">
                                <div className="hotel_service_display_14">
                                  
                                  <div className="hk_service_all_hotel">
                                    <span
                                      className=""
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {" "}
                                      {i+1}.
                                    </span>
                                    <span style={{ marginLeft: "10px" }}>
                                      {data.hotel_name}
                                    </span>
                                  </div>
                                </div>
                                {showFullData[i]?.hotel === true ? (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-up"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                ) : (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-down"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                )}
                              </div>
                              <div className={`row ${
                                showFullData[i]?.hotel === true
                                  ? ""
                                  : "d-none"
                              }`}> */}

                          <div className="reservation-main-sec">
                            <div className="row">
                              <div className="col-xxl-12">
                                <div className="reservation-filter-results">
                                  <table className="table reservation-list-table">
                                    <tr>
                                      <th></th>
                                      <th
                                        className="reserv-style-th-new"
                                        title="Reservations"
                                      >
                                        Reservations
                                      </th>
                                      <th
                                        className="reserv-style-th-new"
                                        title="Average length of stay"
                                      >
                                        Average LOS
                                      </th>
                                      {/* <th className="reserv-style-th-new"  title="Total Nights"> Total Nights
                                                  </th> */}
                                      <th
                                        className="reserv-style-th-new"
                                        title="Total Room Nights"
                                      >
                                        Total Room Nights
                                      </th>
                                      <th
                                        className="reserv-style-th-new"
                                        title="Average Price Without Tax"
                                      >
                                        Average Price
                                      </th>
                                      {/* <th className="reserv-style-th-new"  title="Total Upselling">
                                                      Total Upselling 
                                                  </th> */}
                                      <th
                                        className="reserv-style-th-new"
                                        title="Production (Without Tax)"
                                      >
                                        Production
                                      </th>
                                      <th
                                        className="reserv-style-th-new"
                                        title="Production with Extras (Without Tax)"
                                      >
                                        Production with Extras
                                      </th>
                                    </tr>
                                    {data.basic_data.map((basic, ind) => {
                                      return (
                                        <>
                                          <tr>
                                            <td> Initial Reservations</td>
                                            <td>
                                              {
                                                basic.initial_reservations
                                                  .total_reservation
                                              }
                                            </td>
                                            <td>
                                              {
                                                basic.initial_reservations
                                                  .average_room_nights
                                              }
                                            </td>
                                            <td>
                                              {
                                                basic.initial_reservations
                                                  .total_room_nights
                                              }
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.average_price)}
                                              
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.reservation_total_without_extra)}
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.reservation_total_with_extra)}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {" "}
                                              After cancellations & saved
                                              revisions
                                            </td>
                                            <td>
                                              {
                                                basic.save_reservations
                                                  .total_reservation
                                              }
                                            </td>
                                            <td>
                                              {
                                                basic.save_reservations
                                                  .average_room_nights
                                              }
                                            </td>
                                            <td>
                                              {
                                                basic.save_reservations
                                                  .total_room_nights
                                              }
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.average_price)}
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.reservation_total_without_extra)}
                                            </td>
                                            <td>
                                            <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.reservation_total_with_extra)}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xxl-12">
                                <div
                                  className=""
                                  style={{overflowX:"auto" }}
                                >
                                  <table className="table reservation-list-table reservation-main-table-style">
                                    <tr>
                                      <th className="serial-number-new">
                                        Code
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Reserved Date"
                                      >
                                        Reserved
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Hotel Name"
                                      >
                                        Hotel
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Customer Name"
                                      >
                                        Name
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Phone Number"
                                      >
                                        Phone Number
                                      </th>
                                      <th className="new-head" title="Email">
                                        Email
                                      </th>
                                      <th className="new-head" title="Check In">
                                        Check In
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Check Out"
                                      >
                                        Check Out
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Stay (Nights)"
                                      >
                                        Stay (Nights)
                                      </th>
                                      <th
                                        className="new-head"
                                        title="No.of Rooms"
                                      >
                                      Rooms
                                      </th>
                                      <th className="new-head" title="Status">
                                        Status
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Room Night"
                                      >
                                        Room Nights
                                      </th>
                                      <th className="new-head" title="Source">
                                        Source
                                      </th>
                                      <th className="new-head" title="Medium">
                                        Medium
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Room Type"
                                      >
                                        Room Type
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Promo code"
                                      >
                                        Promo code
                                      </th>
                                      <th className="new-head" title="Total">
                                        Total
                                      </th>
                                      <th
                                        className="new-head"
                                        title="Payment Method & Amount"
                                      >
                                        Payment Method..
                                      </th>
                                    </tr>
                                    {data.reservation_list.map((reserve, i) => {
                                      return (
                                        <>
                                          <tr>
                                            <td className="serial-number-new-td reserve_key-new-td">
                                            <Link to={`/reservation-view/${reserve.reservation_id}`}> {reserve.reservation_key}</Link>
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={moment(reserve.reservation_date).format("DD-MMM-YYYY")}
                                            >
                                              {moment(reserve.reservation_date).format("DD-MMM-YYYY")}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.hotel_name}
                                            >
                                              {reserve.hotel_name}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={`${reserve.customer_first_name} ${reserve.customer_last_name}`}
                                            >
                                              {reserve.customer_first_name}{" "}
                                              {reserve.customer_last_name}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={`${reserve.customer_calling_code} ${reserve.customer_phone}`}
                                              style={{ color: "#45b658" }}
                                            >
                                              {reserve.customer_calling_code}{" "}
                                              {reserve.customer_phone}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.customer_email}
                                              style={{ color: "#15c" }}
                                            >
                                              {reserve.customer_email}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={moment(reserve.check_in).format("DD-MMM-YYYY")}
                                            >
                                              {moment(reserve.check_in).format("DD-MMM-YYYY")}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={moment(reserve.check_out).format("DD-MMM-YYYY")}
                                            >
                                              {moment(reserve.check_out).format("DD-MMM-YYYY")}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.stay_nights}
                                            >
                                              {reserve.stay_nights}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.total_room_count}
                                            >
                                              {reserve.total_room_count}
                                            </td>
                                           
                                            {reserve.is_revised === false ? (
                                              <td
                                                className="scrollable-cols"
                                                title="Not Revised"
                                                style={{ color: "#008080",cursor:"pointer" }}
                                              >
                                                <Link to={`/reconciliation-single/${reserve.hotel_id}/${reserve.reservation_id}`}>Not Revised</Link>
                                              </td>
                                            ) : (
                                              <>
                                                {reserve.revision_type ===
                                                "cancelled" ? (
                                                  <td
                                                    className="scrollable-cols"
                                                    title="Cancelled"
                                                    style={{ color: "#FF7979" }}
                                                  >
                                                    Cancelled{" "}
                                                  </td>
                                                ) : reserve.revision_type ===
                                                  "correct" ? (
                                                  <td
                                                    className="scrollable-cols"
                                                    title="Correct"
                                                    style={{
                                                      color: "rgb(51 ,190,51)",
                                                    }}
                                                  >
                                                   Correct{" "}
                                                  </td>
                                                ) : reserve.revision_type ===
                                                  "modified" ? (
                                                  <td
                                                    className="scrollable-cols"
                                                    title="Modified"
                                                    style={{ color: "#7084c7" }}
                                                  >
                                                    Modified{" "}
                                                  </td>
                                                ) : reserve.revision_type ===
                                                  "no_show" ? (
                                                  <td
                                                    className="scrollable-cols"
                                                    title="No Show"
                                                    style={{ color: "orange" }}
                                                  >
                                                   No Show{" "}
                                                  </td>
                                                ) : reserve.revision_type ===
                                                  "repeated" ? (
                                                  <td
                                                    className="scrollable-cols"
                                                    title="Repeated"
                                                  >
                                                   Repeated
                                                  </td>
                                                ) : (
                                                  <td className="scrollable-cols"></td>
                                                )}
                                              </>
                                            )}
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.room_nights}
                                            >
                                              {reserve.room_nights}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.source}
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {reserve.source}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.medium}
                                            >
                                              {reserve.medium}
                                            </td>
                                            <td className="scrollable-cols">
                                              {reserve?.room_data?.map(
                                                (room, ind) => {
                                                  return (
                                                    <span
                                                      key={ind}
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                      title={room.title}
                                                    >
                                                      {room.title}
                                                      {ind <
                                                        reserve?.room_data
                                                          .length -
                                                          1 && ","}
                                                      <br />
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={reserve.promocode}
                                            >
                                              {reserve.promocode}
                                            </td>
                                            <td
                                              className="scrollable-cols"
                                              title={`${reserve.currency.code} ${reserve.reservation_total}`}
                                            >
                                              <span className="revision_currency"> {reserve.currency.code}</span>{processDBValue(reserve.reservation_total)}{" "}
                                             
                                            </td>

                                            <td
                                              className="scrollable-cols"
                                              title={`${
                                                reserve.payment_options
                                              } ${
                                                reserve.deposite
                                                  ? reserve.deposite
                                                  : ""
                                              }`}
                                            >
                                              {reserve.payment_options}{" "}
                                              
                                              {reserve.deposite ? (
                                                <span className="revision_currency">
                                                  {reserve.currency.code}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {processDBValue(reserve.deposite)}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : list && list.length === 0 ? (
                <Nodatafound />
              ) : (
                ""
              )}
              </>
            }

         
            </>
          ) : list1 && list1.length > 0 ? (
            <>
              {list1?.map((data, i) => {
                return (
                  <>
                    <div key={i} className="row">
                      <div className="reservation-main-sec">
                        <div className="row">
                          <div className="col-xxl-12">
                            <div className="reservation-filter-results">
                              <table className="table reservation-list-table">
                                <tr>
                                  <th></th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Reservations"
                                  >
                                    Reservations
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Average length of stay"
                                  >
                                    Average LOS
                                  </th>
                                  {/* <th className="reserv-style-th-new"  title="Total Nights"> Total Nights
                                                  </th> */}
                                  <th
                                    className="reserv-style-th-new"
                                    title="Total Room Nights"
                                  >
                                    Total Room Nights
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Average Price Without Tax"
                                  >
                                    Average Price
                                  </th>
                                  {/* <th className="reserv-style-th-new"  title="Total Upselling">
                                                      Total Upselling 
                                                  </th> */}
                                  <th
                                    className="reserv-style-th-new"
                                    title="Production (Without Tax)"
                                  >
                                    Production
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Production with Extras (Without Tax)"
                                  >
                                    Production with Extras
                                  </th>
                                </tr>
                                {data.basic_data.map((basic, ind) => {
                                  return (
                                    <>
                                      <tr>
                                        <td> Initial Reservations</td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .total_reservation
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .average_room_nights
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .total_room_nights
                                          }
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.average_price)}                                       
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.reservation_total_without_extra)}
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.initial_reservations.reservation_total_with_extra)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          After cancellations & saved revisions
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .total_reservation
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .average_room_nights
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .total_room_nights
                                          }
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.average_price)}
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.reservation_total_without_extra)}
                                        </td>
                                        <td>
                                        <span className="revision_currency">{basic.currency.code}</span>{processDBValue(basic.save_reservations.reservation_total_with_extra)}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-12">
                            <div
                              className="reservation-filter-result"
                              style={{ height: "219px" }}
                            >
                              <table className="table reservation-list-table reservation-main-table-style">
                                <tr>
                                  <th className="serial-number-new">Code</th>
                                  <th
                                    className="new-head"
                                    title="Reserved Date"
                                  >
                                    Reserved
                                  </th>
                                  <th className="new-head" title="Hotel Name">
                                    Hotel
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Customer Name"
                                  >
                                    Name
                                  </th>
                                  <th className="new-head" title="Phone Number">
                                    Phone Number
                                  </th>
                                  <th className="new-head" title="Email">
                                    Email
                                  </th>
                                  <th className="new-head" title="Check-In">
                                    Check In
                                  </th>
                                  <th className="new-head" title="Check-Out">
                                    Check Out
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Stay (Nights)"
                                  >
                                    Stay (Nights)
                                  </th>
                                  <th className="new-head" title="No.of Rooms">
                                   Rooms
                                  </th>
                                  <th className="new-head" title="Status">
                                    Status
                                  </th>
                                  <th className="new-head" title="Room Night">
                                    Room Nights
                                  </th>
                                  <th className="new-head" title="Source">
                                    Source
                                  </th>
                                  <th className="new-head" title="Medium">
                                    Medium
                                  </th>
                                  <th className="new-head" title="Room Type">
                                    Room Type
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Promo code"
                                  >
                                   Promo code
                                  </th>
                                  <th className="new-head" title="Total">
                                    Total
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Payment Method & Amount"
                                  >
                                    Payment Method..
                                  </th>
                                </tr>
                                {data.reservation_list.map((reserve, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="serial-number-new-td  reserve_key-new-td">
                                          <Link to={`/reservation-view/${reserve.reservation_id}`}> {reserve.reservation_key}</Link>
                                         
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={moment(reserve.reservation_date).format("DD-MMM-YYYY")}
                                        >
                                          {moment(reserve.reservation_date).format("DD-MMM-YYYY")}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.hotel_name}
                                        >
                                          {reserve.hotel_name}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={`${reserve.customer_first_name} ${reserve.customer_last_name}`}
                                        >
                                          {reserve.customer_first_name}{" "}
                                          {reserve.customer_last_name}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={`${reserve.customer_calling_code} ${reserve.customer_phone}`}
                                          style={{ color: "#45b658" }}
                                        >
                                          {reserve.customer_calling_code}{" "}
                                          {reserve.customer_phone}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.customer_email}
                                          style={{ color: "#15c" }}
                                        >
                                          {reserve.customer_email}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={moment(reserve.check_in).format("DD-MMM-YYYY")}
                                        >
                                          {moment(reserve.check_in).format("DD-MMM-YYYY")}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={moment(reserve.check_out).format("DD-MMM-YYYY")}
                                        >
                                          {moment(reserve.check_out).format("DD-MMM-YYYY")}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.stay_nights}
                                        >
                                          {reserve.stay_nights}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.total_room_count}
                                        >
                                          {reserve.total_room_count}
                                        </td>
                                        {reserve.is_revised === false ? (
                                          <td
                                            className="scrollable-cols"
                                            title="Not Revised"
                                            style={{ color: "#008080",cursor:"pointer" }}
                                          >
                                            <Link to={`/reconciliation-single/${reserve.hotel_id}/${reserve.reservation_id}`}>Not Revised</Link>
                                          
                                          </td>
                                        ) : (
                                          <>
                                            {reserve.revision_type ===
                                            "cancelled" ? (
                                              <td
                                                className="scrollable-cols"
                                                title="Cancelled"
                                                style={{ color: "#FF7979" }}
                                              >
                                               Cancelled{" "}
                                              </td>
                                            ) : reserve.revision_type ===
                                              "correct" ? (
                                              <td
                                                className="scrollable-cols"
                                                title="Correct"
                                                style={{
                                                  color: "rgb(51 ,190,51)",
                                                }}
                                              >
                                                Correct{" "}
                                              </td>
                                            ) : reserve.revision_type ===
                                              "modified" ? (
                                              <td
                                                className="scrollable-cols"
                                                title="Modified"
                                                style={{ color: "#7084c7" }}
                                              >
                                               Modified{" "}
                                              </td>
                                            ) : reserve.revision_type ===
                                              "no_show" ? (
                                              <td
                                                className="scrollable-cols"
                                                title="No Show"
                                                style={{ color: "orange" }}
                                              >
                                               No Show{" "}
                                              </td>
                                            ) : reserve.revision_type ===
                                              "repeated" ? (
                                              <td
                                                className="scrollable-cols"
                                                title="Repeated"
                                              >
                                                Repeated
                                              </td>
                                            ) : (
                                              <td className="scrollable-cols"></td>
                                            )}
                                          </>
                                        )}
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.room_nights}
                                        >
                                          {reserve.room_nights}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.source}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {reserve.source}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.medium}
                                        >
                                          {reserve.medium}
                                        </td>
                                        <td className="scrollable-cols">
                                          {reserve?.room_data?.map(
                                            (room, ind) => {
                                              return (
                                                <span
                                                  key={ind}
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  title={room.title}
                                                >
                                                  {room.title}
                                                  {ind <
                                                    reserve?.room_data.length -
                                                      1 && ","}
                                                  <br />
                                                </span>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.promocode}
                                        >
                                          {reserve.promocode}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={`${reserve.currency.code} ${reserve.reservation_total}`}
                                        ><span className="revision_currency">{reserve.currency.code}</span>{processDBValue(reserve.reservation_total)}
                                          
                                        </td>

                                        <td
                                          className="scrollable-cols"
                                          title={`${reserve.payment_options} ${
                                            reserve.deposite
                                              ? reserve.deposite
                                              : ""
                                          }`}
                                        >
                                          {reserve.payment_options}{" "}
                                         
                                          {reserve.deposite ? (
                                            <span className="revision_currency">{reserve.currency.code}</span>
                                          ) : (
                                            ""
                                          )}
                                           {processDBValue(reserve.deposite)}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                {/* <tr>
                                                  <td className="serial-number">2305</td>
                                                  <td className="scrollable-cols" title="Jun 30, 2023">Jun 30, 2023</td>
                                                  <td className="scrollable-cols" title="Pearl Marina Hotel and Apartment">Pearl Marina...</td>
                                                  <td className="scrollable-cols" title="Sunghee  Choi">Sunghee  Choi</td>
                                                  <td className="scrollable-cols" title="+91 9895262314" style={{color:"#45b658"}}>+91 9895262..</td>
                                                  <td className="scrollable-cols" title="example@gmail.com" style={{color: "#15c"}}>example@gmail..</td>
                                                  <td className="scrollable-cols" title="Jul 06, 2023">Jul 06, 2023</td>
                                                  <td className="scrollable-cols" title="Jul 09, 2023">Jul 09, 2023</td>
                                                  <td className="scrollable-cols" title="3">3</td>
                                                  <td className="scrollable-cols" title=""></td>
                                                  <td className="scrollable-cols" title="Cancelled" style={{color:"#FF7979"}}>Cancelled</td>
                                                  <td className="scrollable-cols" title="20">20</td>
                                                  <td className="scrollable-cols" title="Website">Website</td>
                                                  <td className="scrollable-cols" title="Offer">Offer</td>
                                                  <td className="scrollable-cols" title="Deluxe Two-Bedroom Apartment">Deluxe Two-Bed..</td>
                                                  <td className="scrollable-cols" title="1">1</td>
                                                  <td className="scrollable-cols" title="2,827.50 AED">2,827.50..</td>
                                                  <td className="scrollable-cols" title="Prepay Credit card 501.64 AED">Prepay Credit card..</td>
    
                                                </tr>
                                                <tr>
                                                  <td className="serial-number">2305</td>
                                                  <td className="scrollable-cols" title="Jun 30, 2023">Jun 30, 2023</td>
                                                  <td className="scrollable-cols" title="Pearl Marina Hotel and Apartment">Pearl Marina...</td>
                                                  <td className="scrollable-cols" title="Sunghee  Choi">Sunghee  Choi</td>
                                                  <td className="scrollable-cols" title="+91 9895262314" style={{color:"#45b658"}}>+91 9895262..</td>
                                                  <td className="scrollable-cols" title="example@gmail.com" style={{color: "#15c"}}>example@gmail..</td>
                                                  <td className="scrollable-cols" title="Jul 06, 2023">Jul 06, 2023</td>
                                                  <td className="scrollable-cols" title="Jul 09, 2023">Jul 09, 2023</td>
                                                  <td className="scrollable-cols" title="3">3</td>
                                                  <td className="scrollable-cols" title=""></td>
                                                  <td className="scrollable-cols" title="Completed"  style={{color:"rgb(51 ,190,51)"}}>Completed</td>
                                                  <td className="scrollable-cols" title="20">20</td>
                                                  <td className="scrollable-cols" title="Website">Website</td>
                                                  <td className="scrollable-cols" title="Offer">Offer</td>
                                                  <td className="scrollable-cols" title="Deluxe Two-Bedroom Apartment">Deluxe Two-Bed..</td>
                                                  <td className="scrollable-cols" title="1">1</td>
                                                  <td className="scrollable-cols" title="2,827.50 AED">2,827.50..</td>
                                                  <td className="scrollable-cols" title="Prepay Credit card 501.64 AED">Prepay Credit card..</td>
                                                </tr>
                                                <tr>
                                                  <td className="serial-number">2305</td>
                                                  <td className="scrollable-cols" title="Jun 30, 2023">Jun 30, 2023</td>
                                                  <td className="scrollable-cols" title="Pearl Marina Hotel and Apartment">Pearl Marina...</td>
                                                  <td className="scrollable-cols" title="Sunghee  Choi">Sunghee  Choi</td>
                                                  <td className="scrollable-cols" title="+91 9895262314" style={{color:"#45b658"}}>+91 9895262..</td>
                                                  <td className="scrollable-cols" title="example@gmail.com" style={{color: "#15c"}}>example@gmail..</td>
                                                  <td className="scrollable-cols" title="Jul 06, 2023">Jul 06, 2023</td>
                                                  <td className="scrollable-cols" title="Jul 09, 2023">Jul 09, 2023</td>
                                                  <td className="scrollable-cols" title="3">3</td>
                                                  <td className="scrollable-cols" title=""></td>
                                                  <td className="scrollable-cols" title="Not Revised" style={{color:"orange"}}>Not Revised</td>
                                                  <td className="scrollable-cols" title="20">20</td>
                                                  <td className="scrollable-cols" title="Website">Website</td>
                                                  <td className="scrollable-cols" title="Offer">Offer</td>
                                                  <td className="scrollable-cols" title="Deluxe Two-Bedroom Apartment">Deluxe Two-Bed..</td>
                                                  <td className="scrollable-cols" title="1">1</td>
                                                  <td className="scrollable-cols" title="2,827.50 AED">2,827.50..</td>
                                                  <td className="scrollable-cols" title="Prepay Credit card 501.64 AED">Prepay Credit card..</td>
                                                </tr>
                                                <tr>
                                                  <td className="serial-number">2305</td>
                                                  <td className="scrollable-cols" title="Jun 30, 2023">Jun 30, 2023</td>
                                                  <td className="scrollable-cols" title="Pearl Marina Hotel and Apartment">Pearl Marina...</td>
                                                  <td className="scrollable-cols" title="Sunghee  Choi">Sunghee  Choi</td>
                                                  <td className="scrollable-cols" title="+91 9895262314" style={{color:"#45b658"}}>+91 9895262..</td>
                                                  <td className="scrollable-cols" title="example@gmail.com" style={{color: "#15c"}}>example@gmail..</td>
                                                  <td className="scrollable-cols" title="Jul 06, 2023">Jul 06, 2023</td>
                                                  <td className="scrollable-cols" title="Jul 09, 2023">Jul 09, 2023</td>
                                                  <td className="scrollable-cols" title="3">3</td>
                                                  <td className="scrollable-cols" title=""></td>
                                                  <td className="scrollable-cols" title="Cancelled" style={{color:"#FF7979"}}>Cancelled</td>
                                                  <td className="scrollable-cols" title="20">20</td>
                                                  <td className="scrollable-cols" title="Website">Website</td>
                                                  <td className="scrollable-cols" title="Offer">Offer</td>
                                                  <td className="scrollable-cols" title="Deluxe Two-Bedroom Apartment">Deluxe Two-Bed..</td>
                                                  <td className="scrollable-cols" title="1">1</td>
                                                  <td className="scrollable-cols" title="2,827.50 AED">2,827.50..</td>
                                                  <td className="scrollable-cols" title="Prepay Credit card 501.64 AED">Prepay Credit card..</td>
                                                </tr> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>)
            :list1 && list1.length===0?
            <Nodatafound/>
            
            : list && list.length === 0 ? (
            <Nodatafound />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Reservations;
